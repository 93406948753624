export var ScheduledJobType;
(function (ScheduledJobType) {
    ScheduledJobType["cardSubscriptionPayment"] = "cardSubscriptionPayment";
    ScheduledJobType["cardSubscriptionPaymentCorrection"] = "cardSubscriptionPaymentCorrection";
    ScheduledJobType["bankTransferSubscriptionPayment"] = "bankTransferSubscriptionPayment";
    ScheduledJobType["deactivateCompanyPremiumFeatures"] = "deactivateCompanyPremiumFeatures";
})(ScheduledJobType || (ScheduledJobType = {}));
export var TransactionSource;
(function (TransactionSource) {
    TransactionSource["recurring"] = "recurring";
    TransactionSource["unscheduled"] = "unscheduled";
    TransactionSource["recurring_first"] = "recurring_first";
    TransactionSource["moto"] = "moto";
})(TransactionSource || (TransactionSource = {}));
