export var AccountType;
(function (AccountType) {
    AccountType[AccountType["company"] = 1] = "company";
    AccountType[AccountType["applicant"] = 2] = "applicant";
})(AccountType || (AccountType = {}));
export var CompanyAccountRole;
(function (CompanyAccountRole) {
    CompanyAccountRole["owner"] = "owner";
    CompanyAccountRole["admin"] = "admin";
    CompanyAccountRole["publisher"] = "publisher";
    CompanyAccountRole["viewer"] = "viewer";
    CompanyAccountRole["acceptation_pending"] = "acceptation_pending";
})(CompanyAccountRole || (CompanyAccountRole = {}));
export var AccountErrorCode;
(function (AccountErrorCode) {
    AccountErrorCode["login_canceled"] = "login_canceled";
})(AccountErrorCode || (AccountErrorCode = {}));
export var ProfileExperienceType;
(function (ProfileExperienceType) {
    ProfileExperienceType["school"] = "school";
    ProfileExperienceType["job"] = "job";
    ProfileExperienceType["certificate"] = "certificate";
})(ProfileExperienceType || (ProfileExperienceType = {}));
export var AccountSex;
(function (AccountSex) {
    AccountSex["man"] = "man";
    AccountSex["woman"] = "woman";
    AccountSex["other"] = "other";
})(AccountSex || (AccountSex = {}));
export var DriverLicence;
(function (DriverLicence) {
    DriverLicence["AM"] = "AM";
    DriverLicence["A1"] = "A1";
    DriverLicence["A2"] = "A2";
    DriverLicence["A"] = "A";
    DriverLicence["B1"] = "B1";
    DriverLicence["B"] = "B";
    DriverLicence["BE"] = "BE";
    DriverLicence["C1"] = "C1";
    DriverLicence["C1E"] = "C1E";
    DriverLicence["C"] = "C";
    DriverLicence["CE"] = "CE";
    DriverLicence["D1"] = "D1";
    DriverLicence["D1E"] = "D1E";
    DriverLicence["D"] = "D";
    DriverLicence["DE"] = "DE";
    DriverLicence["T"] = "T";
})(DriverLicence || (DriverLicence = {}));
export var LanguageLevel;
(function (LanguageLevel) {
    LanguageLevel["A1"] = "A1";
    LanguageLevel["A2"] = "A2";
    LanguageLevel["B1"] = "B1";
    LanguageLevel["B2"] = "B2";
    LanguageLevel["C1"] = "C1";
    LanguageLevel["C2"] = "C2";
})(LanguageLevel || (LanguageLevel = {}));
export var AccountEmptyFieldsNames;
(function (AccountEmptyFieldsNames) {
    AccountEmptyFieldsNames["birth_date"] = "birth_date";
    AccountEmptyFieldsNames["description"] = "description";
    AccountEmptyFieldsNames["profile_picture_url"] = "profile_picture_url";
    AccountEmptyFieldsNames["telephone"] = "telephone";
    AccountEmptyFieldsNames["job"] = "job";
    AccountEmptyFieldsNames["school"] = "school";
    AccountEmptyFieldsNames["language"] = "language";
    AccountEmptyFieldsNames["certificate"] = "certificate";
    AccountEmptyFieldsNames["social_media"] = "social_media";
    AccountEmptyFieldsNames["sex"] = "sex";
    AccountEmptyFieldsNames["portfolio"] = "portfolio";
    AccountEmptyFieldsNames["driver_licence"] = "driver_licence";
    AccountEmptyFieldsNames["address"] = "address";
})(AccountEmptyFieldsNames || (AccountEmptyFieldsNames = {}));
export var EPersonalInfoField;
(function (EPersonalInfoField) {
    EPersonalInfoField["firstName"] = "first_name";
    EPersonalInfoField["lastName"] = "last_name";
    EPersonalInfoField["titleBefore"] = "title_before";
    EPersonalInfoField["titleAfter"] = "title_after";
    EPersonalInfoField["telephone"] = "telephone";
    EPersonalInfoField["newsletter"] = "newsletter";
})(EPersonalInfoField || (EPersonalInfoField = {}));
