import { CloudUploadOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
export const DropzoneContainer = styled.div `
	position: relative;
`;
export const DropzoneOverlay = styled.div `
	outline: 2px dashed ${props => props.theme.color.brand4};
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background: linear-gradient(
		-45deg,
		rgba(85, 139, 221, 0.4) 0%,
		rgba(85, 139, 221, 0.1) 20%,
		rgba(85, 139, 221, 0.4) 40%,
		rgba(85, 139, 221, 0.1) 60%,
		rgba(85, 139, 221, 0.4) 80%,
		rgba(85, 139, 221, 0.1) 100%
	);
	z-index: 2;
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${props => props.isDraggingOverBody &&
    css `
			display: flex;
		`}
`;
export const DropzoneContent = styled.div `
	${props => props.isDraggingOverBody &&
    css `
			opacity: 0.25;
		`}
`;
export const DropzoneOverlayText = styled.span `
	color: ${props => props.theme.color.brand1};
	font-size: 24px;
	font-weight: bold;
`;
export const StyledCloudUpload = styled(CloudUploadOutlined) `
	font-size: 150px;
	color: ${props => props.theme.color.brand1};
`;
