import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { RichTextEditor } from './RichTextEditor';
const RichTextEditorAntOverride = createGlobalStyle `
	.ant-form-item-has-error .remirror-editor-wrapper .remirror-editor {
		border: 1px solid ${({ theme }) => theme.color.error};
	}
`;
export function RichTextEditorAnt(props) {
    return (React.createElement("div", { className: "rte-ant-wrapper" },
        React.createElement(RichTextEditorAntOverride, null),
        React.createElement(RichTextEditor, Object.assign({}, props))));
}
