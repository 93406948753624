import React from 'react';
import { Modal, Link, Button } from '@src/components/elements';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import { AccountEmptyFieldsNames } from '@src/types/account';
function getEmptyFieldText(emptyField) {
    switch (emptyField) {
        case AccountEmptyFieldsNames.birth_date:
            return React.createElement(FormattedMessage, { id: "profile.resume.birth_date" });
        case AccountEmptyFieldsNames.description:
            return React.createElement(FormattedMessage, { id: "profile.resume.description" });
        case AccountEmptyFieldsNames.profile_picture_url:
            return React.createElement(FormattedMessage, { id: "profile.resume.profile_picture" });
        case AccountEmptyFieldsNames.telephone:
            return React.createElement(FormattedMessage, { id: "profile.resume.phone_number" });
        case AccountEmptyFieldsNames.job:
            return (React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "profile.resume.job" }),
                " *"));
        case AccountEmptyFieldsNames.school:
            return (React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "profile.resume.school" }),
                " *"));
        case AccountEmptyFieldsNames.language:
            return React.createElement(FormattedMessage, { id: "profile.resume.language" });
        case AccountEmptyFieldsNames.certificate:
            return React.createElement(FormattedMessage, { id: "profile.resume.certificate" });
        case AccountEmptyFieldsNames.social_media:
            return React.createElement(FormattedMessage, { id: "profile.resume.social_media" });
        case AccountEmptyFieldsNames.portfolio:
            return React.createElement(FormattedMessage, { id: "profile.resume.portfolio" });
        default:
            break;
    }
}
const EmptyFields = ({ profileEmptyFields }) => {
    return (React.createElement("ul", null, profileEmptyFields.map(emptyField => {
        const emptyFieldName = getEmptyFieldText(emptyField);
        if (!emptyFieldName) {
            return null;
        }
        return React.createElement("li", { key: emptyField }, emptyFieldName);
    })));
};
function ConfirmProfileAsResumeModal(props) {
    const intl = useIntl();
    const EmptyFieldsText = () => (React.createElement("div", null,
        React.createElement(FormattedMessage, { id: "profile.resume.modal.has_empty_fields", values: {
                breakingLine: React.createElement("br", null),
            } }),
        React.createElement(EmptyFields, { profileEmptyFields: props.profileEmptyFields }),
        props.hasEmptyRequiredFields && (React.createElement(Link, { className: "flex-row justify-center m-b-m", to: "/ucet/profil/uprava-profilu" },
            React.createElement(Button, { type: "default", icon: React.createElement(EditOutlined, { className: "m-r-m" }) },
                React.createElement(FormattedMessage, { id: "profile.resume.change_profile" })))),
        React.createElement(FormattedMessage, { id: "profile.resume.modal.you_can_fill", values: {
                link: chunks => React.createElement(Link, { to: "/ucet/profil/uprava-profilu" }, chunks),
            } }),
        props.hasEmptyRequiredFields && (React.createElement("p", null,
            React.createElement(FormattedMessage, { id: "profile.resume.modal.has_empty_required_fields" })))));
    const CompleteFilledFieldsText = () => (React.createElement("div", null,
        React.createElement(FormattedMessage, { id: "profile.resume.modal.fully_filled_fields", values: {
                link: chunks => React.createElement(Link, { to: "/ucet/profil" }, chunks),
            } })));
    return (React.createElement(Modal, { visible: props.visible, destroyOnClose: true, closable: false, okButtonProps: Object.assign(Object.assign({}, props.okButtonProps), { disabled: props.hasEmptyRequiredFields }), onCancel: () => props.setShowConfirmationModal(false), onOk: () => props.setShowConfirmationModal(false), okText: intl.formatMessage({
            id: 'profile.resume.modal.button_accept',
        }), cancelText: intl.formatMessage({
            id: 'profile.resume.modal.button_cancel',
        }), title: intl.formatMessage({
            id: 'profile.resume.modal.title',
        }) },
        props.profileEmptyFields.length > 0 && React.createElement(EmptyFieldsText, null),
        props.profileEmptyFields.length === 0 && React.createElement(CompleteFilledFieldsText, null)));
}
export { ConfirmProfileAsResumeModal };
