import { getCdnImageUrl } from '@shared/get-cdn-image-url';
const colorMap = new Map([
    [1, '2978C5'],
    [2, 'CD7300'],
    [3, '1D3976'],
    [4, '00C57F'],
    [5, '2592A3'],
    [6, '085f63'],
    [7, '464CA2'],
    [8, '974BA3'],
    [9, '3DA33A'],
    [10, '6E48A4'],
]);
const backgroundMap = new Map([
    [1, 'information-technology'],
    [2, 'construction'],
    [3, 'economics'],
    [4, 'construction'],
    [5, 'biology'],
    [6, 'other'],
    [7, 'forestry'],
    [8, 'economics'],
    [9, 'other'],
    [10, 'other'],
    [11, 'pharmacy'],
    [12, 'social-sciences'],
    [13, 'police'],
    [14, 'social-sciences'],
    [15, 'construction'],
    [16, 'information-technology'],
    [17, 'arts'],
    [18, 'agriculture'],
    [19, 'mathematics'],
    [20, 'marketing'],
    [21, 'marketing'],
    [22, 'other'],
    [23, 'police'],
    [24, 'social-sciences'],
    [25, 'veterinary'],
    [26, 'law'],
    [27, 'social-sciences'],
    [28, 'information-technology'],
    [29, 'other'],
    [30, 'other'],
    [31, 'other'],
    [32, 'social-sciences'],
    [33, 'law'],
    [34, 'law'],
    [35, 'architecture'],
    [36, 'construction'],
    [37, 'environmental'],
    [38, 'information-technology'],
    [39, 'other'],
    [40, 'medicine'],
    [41, 'information-technology'],
    [42, 'education'],
    [43, 'medicine'],
    [44, 'environmental'],
    [45, 'information-technology'],
]);
const getOverlayColor = id => {
    const result = (id % Array.from(colorMap).length) + 1;
    return colorMap.get(result);
};
const getSocialImageUrl = args => {
    const { offerId, companyImage, jobAreaId, locale } = args;
    const overlayColorIndex = offerId || Math.floor(Math.random() * Array.from(colorMap).length);
    const backgroundId = jobAreaId || Math.floor(Math.random() * Array.from(backgroundMap).length) || 22; // fallback to other
    const file = `web/meta/company_area_${backgroundMap.get(backgroundId)}.png`;
    let result = locale === 'uk'
        ? `${process.env.PUBLIC_URL}/media/images/meta-homepage-uk.jpg`
        : `${process.env.PUBLIC_URL}/media/images/meta-homepage.jpg`;
    if (companyImage) {
        result = getCdnImageUrl({
            transformation: `l_${process.env.ENVIRONMENT}:web:meta:overlay-neutral,o_85,w_1200,h_630,e_colorize,co_rgb:${getOverlayColor(overlayColorIndex)}/l_${process.env.ENVIRONMENT}:web:meta:overlay-neutral,w_500,h_390,c_fill,e_colorize,co_rgb:ffffff,r_30,y_-30/l_${process.env.ENVIRONMENT}:web:meta:logo-address-text-pracuj,w_180,y_240,c_fit/l_${process.env.ENVIRONMENT}:web:company:${companyImage},w_450,h_340,c_fit,r_15,y_-30`,
            file,
        });
    }
    return result;
};
export default getSocialImageUrl;
