import React from 'react';
import { FormattedMessage } from 'react-intl';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
const features = [
    {
        key: 1,
        name: React.createElement(FormattedMessage, { id: "company_premium_profile.features.analytics" }),
        image: 'increasing-line-bar-chart-business',
        description: React.createElement(FormattedMessage, { id: "company_premium_profile.features.analytics_description" }),
    },
    {
        key: 2,
        name: React.createElement(FormattedMessage, { id: "company_premium_profile.features.photos_and_videos_offer" }),
        image: 'folder-image-1-image',
        description: React.createElement(FormattedMessage, { id: "company_premium_profile.features.photos_and_videos_offer_description" }),
    },
    {
        key: 3,
        name: React.createElement(FormattedMessage, { id: "company_premium_profile.features.photos_and_videos_public_profile" }),
        image: 'ux-ui-example-design',
        description: (React.createElement(FormattedMessage, { id: "company_premium_profile.features.photos_and_videos_public_profile_description" })),
    },
    {
        key: 4,
        name: React.createElement(FormattedMessage, { id: "company_premium_profile.features.highlighting_company_profile" }),
        image: 'avatar-neutral-add-users',
        description: (React.createElement(FormattedMessage, { id: "company_premium_profile.features.highlighting_company_profile_description" })),
    },
    {
        key: 5,
        name: React.createElement(FormattedMessage, { id: "company_premium_profile.features.main_page_company_logo" }),
        image: 'vip-app',
        description: React.createElement(FormattedMessage, { id: "company_premium_profile.features.main_page_company_logo_description" }),
    },
];
const PremiumCompanyProfileFeatures = () => {
    return (React.createElement(StyledFeatures, null, features.map(({ key, name, image, description }) => (React.createElement(Box, { key: key },
        React.createElement(Picture, null,
            React.createElement("img", { src: `/media/illustrations/streamline-icon-${image}.svg`, alt: "" })),
        React.createElement(Content, null,
            React.createElement(FeatureTitle, null, name),
            React.createElement("div", null, description)))))));
};
export const Picture = styled.div `
	width: 150px;
	height: 150px;
	overflow: hidden;
	align-self: center;
	& img {
		display: block;
		max-width: 100%;
	}
`;
const Box = styled.div `
	width: 100%;
	padding: 0 2em 3em;
	display: flex;
	position: relative;
	flex-direction: column;

	${up('sm')} {
		width: 50%;
	}

	${up('md')} {
		width: 33%;
	}
`;
const StyledFeatures = styled.div `
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	${up('md')} {
		justify-content: initial;
	}
`;
const Content = styled.div `
	align-self: center;
	flex: 1;
	text-align: center;
`;
const FeatureTitle = styled.div `
	color: ${({ theme }) => theme.color.brand2};
	font-size: 1.2em;
	font-weight: 700;
	margin: 0 0 0.5em;
`;
export { PremiumCompanyProfileFeatures };
