import styled from 'styled-components';
import NoteAlert from '@public/media/illustrations/streamline-icon-note-alert.svg';
export const StyledNoteAlertIcon = styled(NoteAlert) `
	width: 200px;
	height: 200px;
`;
export const CenteredHeading = styled.h1 `
	text-align: center;
	color: ${props => props.theme.color.brand1};
`;
export const MessageWrapper = styled.div `
	font-size: 1rem;
	margin-bottom: 1rem;
`;
