/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, notification } from 'antd';
import axios from 'axios';
// field components
import { Button, Panel } from '@src/components/elements';
import ReactGA from '@src/helpers/react-ga';
import gtm from '@src/helpers/gtm';
import { useHistory } from 'react-router';
import * as Api from '@src/api';
import { socket } from '@src/socket';
import { AccountErrorCode, LANGUAGE_HEADER_NAME, SocketEvents } from '@src/types';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { useAccount } from '@src/context/account-context';
import { Queries } from '@src/types/queries';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { isFeatureContactEmailEnabled } from '@src/helpers/feature-flags';
import { useAuthContextValue } from '@src/context/auth-context';
import { useQueryParameters } from '@src/helpers/use-query-parameters';
import { ConfirmProfileAsResumeModal } from './ProfileResumeModal';
import { sendResumeFormRules } from './formRules';
import { SendResumeFields } from './fields';
import { PersonalInformation } from '../../registration-candidate/PersonalInformation';
import { Passwords } from '../../registration-candidate/Passwords';
import { RedText, RequiredWrapper, SubmitButtonWrapper } from './fields/SendResumeFields.style';
export var RequiredFields;
(function (RequiredFields) {
    RequiredFields["job"] = "job";
    RequiredFields["school"] = "school";
})(RequiredFields || (RequiredFields = {}));
const successfullySentNotification = (intl) => {
    notification.success({
        message: intl.formatMessage({
            id: 'offer_detail.send_resume_form.thanks_for_your_offer_interest',
        }),
        placement: 'bottomRight',
    });
};
const successfullySentAndRegisteredNotification = (intl) => {
    notification.success({
        message: intl.formatMessage({
            id: 'offer_detail.send_resume_form.thanks_for_your_offer_interest_and_registration',
        }),
        placement: 'bottomRight',
    });
};
const failNotification = (intl) => {
    notification.error({
        message: intl.formatMessage({
            id: 'profile.resume.error',
        }),
        description: intl.formatMessage({
            id: 'profile.resume.something_went_wrong',
        }),
        placement: 'bottomRight',
    });
};
const SendResumeForm = ({ offer, sendResumeButtonRef }) => {
    const intl = useIntl();
    const rules = React.useMemo(() => sendResumeFormRules(intl), [sendResumeFormRules]);
    const [form] = Form.useForm();
    const history = useHistory();
    const [resumeFileList, setResumeFileList] = React.useState([]);
    const [motivationalLetterFileList, setMotivationalLetterFileList] = React.useState([]);
    const account = useAccount();
    const isUserLoggedIn = Boolean(account);
    const { data: myResumes } = useAppQuery(Queries.getMyResumes, Api.Account.getMyResumes, {
        enabled: isUserLoggedIn,
    });
    const { createQueryParams } = useQueryParameters();
    const [hasResumesAndWantsToUpload, setHasResumesAndWantsToUpload] = React.useState(false);
    const [sendProfileAsResume, setSendProfileAsResume] = React.useState(offer.resumeRequired === 1 && isUserLoggedIn);
    const [hasAlreadySentApplication, setHasAlreadySentApplication] = React.useState(false);
    const [wantSendApplicationAgain, setWantSendApplicationAgain] = React.useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
    const [profileEmptyFields, setProfileEmptyFields] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const SSRFetchClient = useSSRFetchClient();
    const { checkAuthentication } = useAuthContextValue();
    React.useEffect(() => {
        if (myResumes === null || myResumes === void 0 ? void 0 : myResumes.length) {
            const defaultResume = myResumes.find(r => r.default);
            form.setFieldsValue({ savedResumes: (defaultResume === null || defaultResume === void 0 ? void 0 : defaultResume.id) || 0 });
        }
    }, [myResumes]);
    function checkRequiredFields(allProfileEmptyFields) {
        const isJobEmpty = allProfileEmptyFields.includes(RequiredFields.job);
        const isSchoolEmpty = allProfileEmptyFields.includes(RequiredFields.school);
        return isJobEmpty && isSchoolEmpty;
    }
    const hasEmptyRequiredFields = React.useMemo(() => {
        return checkRequiredFields(profileEmptyFields);
    }, [profileEmptyFields]);
    async function uploadGeneratedResume(file) {
        const cdnResourceType = 'raw';
        const cdnCloudname = process.env.CLOUDINARY_NAME;
        const url = `https://api.cloudinary.com/v1_1/${cdnCloudname}/${cdnResourceType}/upload`;
        const cdnUploadPresent = process.env.CLOUDINARY_UPLOAD_PRESET;
        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        uploadFormData.append('upload_preset', cdnUploadPresent);
        uploadFormData.append('folder', `${process.env.ENVIRONMENT}/user_documents/${account.id}`);
        uploadFormData.append('publicId', `${file.name}_${new Date().getTime()}`);
        const response = await axios.post(url, uploadFormData, {
            transformRequest: (data, headers) => {
                // eslint-disable-next-line no-param-reassign
                delete headers.common[LANGUAGE_HEADER_NAME];
                return data;
            },
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return {
            url: response.data.secure_url,
            publicId: response.data.public_id,
        };
    }
    const onFormSubmit = async (sendResumeForm) => {
        try {
            setIsLoading(true);
            let sendResumeResponse;
            if (sendProfileAsResume) {
                let applicantProfilePDF;
                let resumeUrl = null;
                if ((offer.sendApplicationToContactPerson && isFeatureContactEmailEnabled(offer.company_id)) ||
                    offer.graftonId) {
                    const applicantProfile = await Api.Profile.getMyProfile();
                    const { pdf } = await import('@react-pdf/renderer');
                    const { ApplicantProfilePDF } = await import('@src/components/pages/profile/modules/resume/PDF/ApplicantProfilePDF');
                    applicantProfilePDF = await pdf(React.createElement(ApplicantProfilePDF, { applicantProfile: applicantProfile })).toBlob();
                    if (offer.graftonId) {
                        const { url } = await uploadGeneratedResume(new File([applicantProfilePDF], `${account === null || account === void 0 ? void 0 : account.firstName}_${account === null || account === void 0 ? void 0 : account.lastName}-${new Date().getTime()}.pdf`));
                        resumeUrl = url;
                    }
                }
                sendResumeResponse = await Api.Profile.sendProfileAsResume(Object.assign({ offerId: offer.id, profileUuid: account === null || account === void 0 ? void 0 : account.uuid }, sendResumeForm), applicantProfilePDF, resumeUrl);
            }
            else {
                sendResumeResponse = await Api.Profile.sendResume(Object.assign({ offerId: offer.id }, sendResumeForm));
            }
            const { account: createdAccount } = sendResumeResponse.data;
            const { savedOffer } = await Api.SavedOffer.getSavedOfferById(offer.id);
            if (savedOffer) {
                ReactGA.event({
                    category: 'Applicant',
                    action: 'Application to saved offer',
                    label: 'Applicant sent application for saved offer',
                });
                gtm.dataLayer({
                    event: 'applicant_applied_to_saved_offer',
                    category: 'applicant',
                });
                await Api.SavedOffer.deleteSavedOffer(offer.id);
            }
            // @ts-ignore
            // eslint-disable-next-line no-undef
            fbq('track', 'SubmitApplication');
            gtm.dataLayer({
                event: 'applicant_response_submitted',
                category: 'applicant',
            });
            socket.emit(SocketEvents.ACCOUNT_AUTHENTICATED, createdAccount);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            if (sendResumeResponse.status === 200) {
                setIsLoading(false);
                history.pushLocalized('/ucet');
                successfullySentNotification(intl);
            }
            else if (sendResumeResponse.status === 201) {
                setIsLoading(false);
                history.pushLocalized('/ucet');
                successfullySentAndRegisteredNotification(intl);
            }
            else {
                failNotification(intl);
            }
        }
        catch (err) {
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
            // eslint-disable-next-line no-console
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    };
    const loginWithButton = async () => {
        try {
            await checkAuthentication();
        }
        catch (err) {
            if (err !== AccountErrorCode.login_canceled) {
                notification.error({
                    message: intl.formatMessage({
                        id: 'general.error',
                    }),
                    description: intl.formatMessage({
                        id: 'general.something_went_wrong',
                    }),
                });
                console.error(err);
            }
        }
    };
    React.useEffect(() => {
        if (account) {
            form.setFieldsValue({
                first_name: account.firstName,
                last_name: account.lastName,
                email: account.email,
            });
        }
    }, [account]);
    const changeSendProfileAsResume = React.useCallback((newSendProfileAsResume) => {
        setSendProfileAsResume(newSendProfileAsResume);
        form.setFieldsValue({ sendProfileResume: newSendProfileAsResume });
    }, [form, account, offer.resumeRequired]);
    React.useEffect(() => {
        if (account) {
            changeSendProfileAsResume(offer.resumeRequired === 1 && isUserLoggedIn);
        }
    }, [account, changeSendProfileAsResume]);
    async function init() {
        try {
            const offerApplication = await Api.OfferApplication.getOfferApplicationByOfferId(offer.id);
            if (offerApplication) {
                setHasAlreadySentApplication(true);
            }
        }
        catch (err) {
            // do nothing
        }
    }
    React.useEffect(() => {
        init();
        setIsInitialized(true);
    }, []);
    const onSubmitClick = async () => {
        form.validateFields()
            .then(async () => {
            setIsLoading(true);
            if (account === null || account === void 0 ? void 0 : account.email) {
                const profileEmptyFieldsResponse = await Api.Profile.getEmptyResumeFields();
                setProfileEmptyFields(profileEmptyFieldsResponse);
                const hasActualEmptyRequiredFields = checkRequiredFields(profileEmptyFieldsResponse);
                if (hasActualEmptyRequiredFields) {
                    setShowConfirmationModal(true);
                }
                else {
                    form.submit();
                }
            }
            setIsLoading(false);
        })
            .catch(() => { });
    };
    const isCTARedirect = Boolean(offer.redirectUrl);
    const redirectWithUTMParams = React.useMemo(() => {
        var _a;
        const qp = createQueryParams({
            utm_source: 'pracuj_sk',
            utm_medium: 'job_post',
            utm_campaign: 'pracuj_sk',
        });
        return ((_a = offer.redirectUrl) === null || _a === void 0 ? void 0 : _a.includes('?')) ? `${offer.redirectUrl}&${qp}` : `${offer.redirectUrl}?${qp}`;
    }, [isCTARedirect]);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement("div", { id: "send-resume" },
        React.createElement(Panel, null,
            React.createElement("div", null,
                isCTARedirect && (React.createElement("div", { className: "flex-col flex-center" },
                    React.createElement("h2", { className: "m-b-l" },
                        React.createElement(FormattedMessage, { id: "offer_detail.details.cta_redirect.notice" })),
                    React.createElement("a", { href: redirectWithUTMParams, target: "_blank", rel: "noreferrer noopenner" },
                        React.createElement(Button, { className: "m-t-l" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.cta_redirect.cta" }))))),
                !isCTARedirect && (React.createElement(React.Fragment, null,
                    isUserLoggedIn && (!hasAlreadySentApplication || wantSendApplicationAgain) && (React.createElement("h1", { className: "form__title" },
                        React.createElement(FormattedMessage, { id: offer.resumeRequired
                                ? 'offer_detail.send_resume_form.send_resume'
                                : 'offer_detail.send_resume_form.send_application' }))),
                    !isUserLoggedIn && (React.createElement("h1", { className: "form__title" },
                        React.createElement(FormattedMessage, { id: offer.resumeRequired
                                ? 'offer_detail.send_resume_form.send_resume_and_create_account'
                                : 'offer_detail.send_resume_form.send_application_and_create_account' }))),
                    hasAlreadySentApplication && !wantSendApplicationAgain && (React.createElement("div", { className: "flex-col flex-center" },
                        React.createElement("h1", { className: "m-b-l" },
                            React.createElement(FormattedMessage, { id: "general.notice" })),
                        React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.send_twice" }),
                        React.createElement(Button, { className: "m-t-l", onClick: () => setWantSendApplicationAgain(true) },
                            React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.wanna_send_twice" })))),
                    !isUserLoggedIn && (React.createElement("div", { className: "p-none m-b-s font-bold" },
                        React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.already_registered" }),
                        React.createElement(Button, { className: "text-underline font-bold", size: "small", type: "text", onClick: () => loginWithButton() },
                            React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.already_registered_button" })))),
                    (!hasAlreadySentApplication || wantSendApplicationAgain) && (React.createElement(Form, { form: form, onFinish: onFormSubmit, className: "form form--login", name: "sendResumeForm", layout: "vertical" },
                        !isUserLoggedIn && (React.createElement(React.Fragment, null,
                            React.createElement(PersonalInformation, { rules: rules, intl: intl }),
                            React.createElement(Passwords, { rules: rules, intl: intl }))),
                        React.createElement(SendResumeFields, { rules: rules, offer: offer, myResumes: myResumes || [], isUserLoggedIn: isUserLoggedIn, hasResumesAndWantsToUpload: hasResumesAndWantsToUpload, setHasResumesAndWantsToUpload: setHasResumesAndWantsToUpload, sendProfileAsResume: sendProfileAsResume, setSendProfileAsResume: changeSendProfileAsResume, resumeFileList: resumeFileList, motivationalLetterFileList: motivationalLetterFileList, setMotivationalLetterFileList: setMotivationalLetterFileList, setResumeFileList: setResumeFileList }),
                        !sendProfileAsResume && (React.createElement("div", { ref: sendResumeButtonRef, className: "form__submit", "data-cy": "form-submit" },
                            React.createElement(Button, { htmlType: "submit", color: "brand", loading: isLoading }, intl.formatMessage({
                                id: 'offer_detail.send_resume_form.send',
                            })))),
                        sendProfileAsResume && isUserLoggedIn && (React.createElement(SubmitButtonWrapper, { ref: sendResumeButtonRef, "data-cy": "form-submit" },
                            React.createElement(Button, { onClick: onSubmitClick, color: "brand", loading: isLoading },
                                React.createElement("span", { className: "m-l-xs" },
                                    React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.send" }))))),
                        React.createElement(RequiredWrapper, null,
                            React.createElement(RedText, null, "*"),
                            React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.required_fields" })))))))),
        React.createElement(ConfirmProfileAsResumeModal, { visible: showConfirmationModal, setShowConfirmationModal: setShowConfirmationModal, profileEmptyFields: profileEmptyFields, setProfileEmptyFields: setProfileEmptyFields, hasEmptyRequiredFields: hasEmptyRequiredFields, okButtonProps: { form: 'sendResumeForm', key: 'submit', htmlType: 'submit' } })));
};
export { SendResumeForm };
