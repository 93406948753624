import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OAuthType } from '@src/types/auth';
import { AccountType } from '@src/types';
import { useHistory } from 'react-router-dom';
import { Button, Holder } from '@src/components/elements';
import Separator from 'components/modules/global/separator';
import LoginForm from 'components/modules/login-form';
import { FacebookLoginButton } from '@src/components/elements/FacebookLoginButton';
import { GoogleLoginButton } from '@src/components/elements/GoogleLoginButton';
import { LinkedInLoginButton } from '@src/components/elements/LinkedInLoginButton';
import { notification, Row, Col, Spin } from 'antd';
import * as Api from '@src/api';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { useLogin } from '@src/hooks/useLogin';
function Login() {
    const intl = useIntl();
    const { login, isLoading: isLoginLoading, error: loginError } = useLogin();
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const SSRFetchClient = useSSRFetchClient();
    const onSuccessfulCodeAcquisition = async (code) => {
        try {
            const user = await Api.Auth.linkedinAuthentication(code);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            if (user.account_type_id === AccountType.company) {
                // @ts-ignore
                history.pushLocalized('/admin');
            }
            else {
                // @ts-ignore
                history.pushLocalized('/ucet');
            }
        }
        catch (error) {
            notification.error({
                message: intl.formatMessage({
                    id: 'login.error',
                }),
                description: intl.formatMessage({
                    id: 'login.something_went_wrong',
                }),
            });
        }
    };
    React.useEffect(() => {
        const { code: codeForAccessToken, state: obtainedLinkedinStateString, error } = history.getQuery();
        if (obtainedLinkedinStateString) {
            const initialLinkedinStateString = window.localStorage.getItem('pracuj_linkedin_state_string');
            if (initialLinkedinStateString === obtainedLinkedinStateString) {
                if (codeForAccessToken !== undefined) {
                    setIsLoading(true);
                    onSuccessfulCodeAcquisition(codeForAccessToken);
                }
                else if (error) {
                    notification.warning({
                        message: intl.formatMessage({
                            id: 'general.notification',
                        }),
                        description: intl.formatMessage({
                            id: 'login.error_user_cancelled_login',
                        }),
                    });
                }
            }
            else {
                notification.error({
                    message: intl.formatMessage({
                        id: 'general.error',
                    }),
                    description: intl.formatMessage({
                        id: 'login.error_unauthorized',
                    }),
                });
            }
            window.localStorage.removeItem('pracuj_linkedin_state_string');
        }
    }, []);
    return (React.createElement(Spin, { size: "large", spinning: isLoading },
        React.createElement(Holder, { marginVertical: true, "data-cy": "login" },
            React.createElement(Row, { justify: "center", gutter: [16, 16] },
                React.createElement(Col, { xs: { span: 24 }, md: { span: 12 }, xl: { span: 10 } },
                    React.createElement("div", { className: "panel", style: { height: '100%' }, "data-cy": "1-panel" },
                        React.createElement("h2", { className: "headline headline--1" },
                            React.createElement(FormattedMessage, { id: "login.login" })),
                        React.createElement(LoginForm, { onSubmit: values => {
                                login(values);
                            }, loginError: loginError, isLoading: isLoginLoading }))),
                React.createElement(Col, { xs: { span: 24 }, md: { span: 12 }, xl: { span: 10 } },
                    React.createElement("div", { className: "panel", style: { height: '100%' }, "data-cy": "2-panel" },
                        React.createElement("div", { className: "login__registration" },
                            React.createElement("div", { className: "login__registration-text" },
                                React.createElement("h2", { className: "headline headline--1" },
                                    React.createElement(FormattedMessage, { id: "login.registration" })),
                                React.createElement("p", null,
                                    React.createElement(FormattedMessage, { id: "login.dont_have_account_yet" }))),
                            React.createElement("div", { className: "flex-center" },
                                React.createElement(Button, { type: "primary", link: "/registracia", size: "middle", "data-cy": "company-register-button" },
                                    React.createElement(FormattedMessage, { id: "login.i_am_empolyer" }))),
                            React.createElement(Separator, { text: intl.formatMessage({
                                    id: 'login.or',
                                }) }),
                            React.createElement("div", { className: "flex-col align-center" },
                                React.createElement("div", { className: "m-b-l" },
                                    React.createElement(Button, { type: "primary", link: "/registracia-uchadzac", size: "middle", "data-cy": "student-register-button" },
                                        React.createElement(FormattedMessage, { id: "login.i_am_applicant" }))),
                                React.createElement("div", { className: "flex-col" },
                                    React.createElement(FacebookLoginButton, { type: OAuthType.registration }),
                                    React.createElement(GoogleLoginButton, { type: OAuthType.registration }),
                                    React.createElement(LinkedInLoginButton, { type: OAuthType.registration }))))))))));
}
export { Login };
