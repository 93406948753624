import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import { ProductId, SubscriptionPeriods } from '@src/types';
import { getProductPricing } from '@src/shared/get-product-pricing';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { darken } from 'polished';
import { PremiumIcon } from '@src/components/elements';
const SubscriptionSelectionCard = ({ subscriptionPeriod, showPricesWithVat, selectedSubscriptionPeriod, setSelectedSubscriptionPeriod, }) => {
    const { totalPriceWithoutVat, discountRelative, productPriceWithoutVat, productPrice, totalPrice } = getProductPricing({
        productId: ProductId.companyPremium,
        productCount: subscriptionPeriod,
    });
    const theme = useTheme();
    const costPerMonth = showPricesWithVat ? productPrice : productPriceWithoutVat;
    const total = showPricesWithVat ? totalPrice : totalPriceWithoutVat;
    const subscriptionUntil = dayjs().add(subscriptionPeriod, 'month').format('DD.MM.YYYY');
    const isSelected = selectedSubscriptionPeriod === subscriptionPeriod;
    return (React.createElement(SubscriptionCard, { subscriptionPeriod: subscriptionPeriod, onClick: () => setSelectedSubscriptionPeriod(subscriptionPeriod), isSelected: isSelected },
        React.createElement(ContentWrapper, null,
            React.createElement("div", { className: "flex-col justify-center align-center" },
                React.createElement(IconWrapper, { isSelected: isSelected },
                    React.createElement(PremiumIcon, { width: 32, height: 32, color: theme.color.white })),
                React.createElement(SubscriptionPeriodTitle, null,
                    React.createElement(FormattedMessage, { id: "general.month.pluralized", values: { count: subscriptionPeriod } }))),
            React.createElement(DateSection, { isSelected: isSelected },
                React.createElement(CalendarOutlined, null),
                React.createElement(FormattedMessage, { id: "admin.subscription.until", values: { subscriptionUntil } })),
            React.createElement("div", { className: "flex-row align-baseline" },
                React.createElement(CostPerMonth, null,
                    React.createElement(FormattedNumber, { value: costPerMonth, style: "currency", currency: "EUR" })),
                React.createElement(GrayText, null,
                    React.createElement(FormattedMessage, { id: "admin.subscription.per_month" })))),
        React.createElement(CardFooter, null,
            discountRelative > 0 && (React.createElement("div", { className: "flex-row align-center justify-center" },
                React.createElement(GrayText, null,
                    React.createElement(FormattedMessage, { id: "admin.subscription.your_discount" })),
                React.createElement(Discount, null,
                    "~",
                    React.createElement(FormattedNumber, { value: discountRelative, style: "unit", unit: "percent" })))),
            React.createElement(GrayText, null,
                React.createElement("div", { className: "flex-row align-baseline justify-center" },
                    React.createElement(FormattedMessage, { id: "admin.subscription.total" }),
                    React.createElement(FormattedNumber, { value: total, style: "currency", currency: "EUR" }))))));
};
const SubscriptionCard = styled.div `
	width: 250px;
	height: 250px;
	border-radius: ${({ theme }) => theme.borderRadius.large};
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 1rem 0.5rem;
	cursor: pointer;
	justify-content: space-between;
	transition: 0.15s;
	${({ theme, isSelected }) => isSelected
    ? css `
					border: 3px solid ${darken(0.15, theme.color.gold)};
			  `
    : css `
					border: 3px solid ${theme.color.gray5};
			  `}
	${({ subscriptionPeriod, theme }) => {
    if (subscriptionPeriod === SubscriptionPeriods.OneMonthPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${darken(0.1, theme.color.brand2)};
				:hover {
					background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
						${darken(0.15, theme.color.brand2)};
				}
			`;
    }
    if (subscriptionPeriod === SubscriptionPeriods.SixMonthsPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${darken(0.1, theme.color.brand1)};
				:hover {
					background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
						${darken(0.15, theme.color.brand1)};
				}
			`;
    }
    if (subscriptionPeriod === SubscriptionPeriods.TwelveMonthsPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${theme.color.brandDarkBlue};
				:hover {
					background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
						${darken(0.05, theme.color.brandDarkBlue)};
				}
			`;
    }
}}
`;
const Discount = styled.span `
	margin-left: 0.5rem;
	font-size: 1.75rem;
	font-weight: bold;
	color: ${({ theme }) => theme.color.success};
`;
const SubscriptionPeriodTitle = styled.h3 `
	font-weight: bold;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.color.white};
`;
const IconWrapper = styled.div `
	${({ isSelected, theme }) => isSelected &&
    css `
			svg {
				color: ${darken(0.15, theme.color.gold)};
			}
		`}
`;
const DateSection = styled.div `
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: bold;
	justify-content: center;
	color: ${({ theme }) => theme.color.white};
	${({ isSelected, theme }) => isSelected &&
    css `
			color: ${darken(0.15, theme.color.gold)};
		`}
`;
const CostPerMonth = styled.div `
	margin-right: 0.5rem;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.color.white};
`;
const GrayText = styled.div `
	color: ${({ theme }) => theme.color.gray4};
`;
const CardFooter = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 0.5rem;
`;
const ContentWrapper = styled.div `
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	align-items: center;
`;
export { SubscriptionSelectionCard };
