import styled, { css } from 'styled-components';
export const AssetsContainer = styled.div `
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	${props => props.shouldBeScrollable &&
    css `
			max-height: 400px;
			overflow-y: auto;
			padding: 0.125rem;
		`}
`;
export const SpinnerContainer = styled.div `
	display: flex;
	justify-content: center;
	width: 100%;
`;
