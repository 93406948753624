import { client as axios } from './clients/axios';
export var AddressSuggestionsTypes;
(function (AddressSuggestionsTypes) {
    AddressSuggestionsTypes["Addresses"] = "addresses";
    AddressSuggestionsTypes["Cities"] = "cities";
})(AddressSuggestionsTypes || (AddressSuggestionsTypes = {}));
export async function getAddressSuggestions(searchValue, country) {
    const response = await axios.get(`/0/address/suggestions`, {
        params: { value: searchValue, country },
    });
    return response.data;
}
export async function getAddressDetail(googlePlaceId) {
    const response = await axios.get('/0/address/details', {
        params: {
            googlePlaceId,
        },
    });
    return response.data;
}
