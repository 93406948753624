import * as Api from '@src/api';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
import { SocketEvents } from '@src/types';
import { notification } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useSocket } from './socket-context';
const AccountContext = React.createContext({
    account: null,
    isAccountLoading: false,
    refetchAccount: (async () => { }),
});
export const AccountContextProvider = ({ children }) => {
    var _a;
    const intl = useIntl();
    const history = useHistory();
    const socket = useSocket();
    const { data: account, isLoading: isAccountLoading, refetch } = useSSRFetch(SSRFetchConfig.account.key, []);
    const { mutateAsync: verify } = useAppMutation(Api.Account.verifyAccount, {
        onSuccess: data => {
            notification.success({
                message: intl.formatMessage({ id: 'general.notice' }),
                description: intl.formatMessage({ id: 'account.verification.success' }),
            });
            socket.emit(SocketEvents.ACCOUNT_VERIFIED, data);
        },
    });
    React.useEffect(() => {
        // Email Verification Token === evt
        if (history === null || history === void 0 ? void 0 : history.getQuery().evt) {
            verify({ verificationToken: history.getQuery().evt });
        }
    }, [(_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.pathname]);
    return (React.createElement(AccountContext.Provider, { value: { account, isAccountLoading, refetchAccount: refetch } }, children));
};
export const useAccount = () => {
    const context = React.useContext(AccountContext);
    if (!context) {
        throw Error('AccountContext hook can be used only inside AccountContextProvider');
    }
    return context.account;
};
export const useAccountContext = () => {
    const context = React.useContext(AccountContext);
    if (!context) {
        throw Error('AccountContext hook can be used only inside AccountContextProvider');
    }
    return context;
};
