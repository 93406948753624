import { client as axios } from './clients/axios';
export async function getMyAssets(assetType) {
    const params = {};
    if (assetType) {
        params.type = assetType;
    }
    const response = await axios.get('/0/asset', { params });
    return response.data;
}
export async function saveUploadedImageOrDocumentAsset({ uploadingAsset, url, cloudinaryPublicId, }) {
    const response = await axios.post('/0/asset/image-or-document', {
        type: uploadingAsset.type,
        filename: uploadingAsset.file.name,
        size: uploadingAsset.file.size,
        url,
        cloudinaryPublicId,
    });
    return response.data;
}
export async function softDeleteAssets(assetIds) {
    await axios.delete('/0/asset', { params: { ids: assetIds } });
}
export async function getUrlForVideoUpload() {
    const response = await axios.get('/0/asset/video-upload-url');
    return response.data;
}
export async function saveUploadedVideoAsset({ uploadingAsset, muxDirectUploadId, }) {
    const response = await axios.post('/0/asset/video', {
        filename: uploadingAsset.file.name,
        size: uploadingAsset.file.size,
        muxDirectUploadId,
    });
    return response.data;
}
export async function waitForVideoToBeProcessed(muxAssetId) {
    await axios.get('/0/asset/wait-for-video', { params: { muxAssetId } });
}
export async function waitForVideoMp4VersionToBeProcessedAndGetDownloadUrl(muxAssetId) {
    const response = await axios.get('/0/asset/wait-for-video-mp4', { params: { muxAssetId } });
    return response.data.downloadUrl;
}
