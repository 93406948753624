import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { Button, Holder } from '@src/components/elements';
import { CompaniesListPreview } from '@src/components/modules/global/CompaniesList/CompaniesListPreview';
import { OfferPaymentType } from '@src/types';
import { RiCopperCoinLine, RiUserStarLine } from 'react-icons/ri';
import { BoxFree } from './BoxFree';
import { BoxCredits } from './BoxCredits';
import { Testimonials } from './Testimonials';
import { Features } from './Features';
import { BoxPayPerApplication } from './BoxPayPerApplication';
const Companies = () => {
    const theme = useTheme();
    const [activePaymentType, setActivePaymentType] = useState(OfferPaymentType.PAY_WITH_OFFER_CREDITS);
    const togglePaymentType = () => {
        setActivePaymentType(activePaymentType === OfferPaymentType.PAY_WITH_OFFER_CREDITS
            ? OfferPaymentType.PAY_PER_APPLICATION
            : OfferPaymentType.PAY_WITH_OFFER_CREDITS);
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "companies.headline" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.subheadline_1" })),
                    React.createElement(Button, { style: {
                            background: theme.color.brand3,
                            borderColor: theme.color.brand3,
                            color: theme.color.white,
                            marginTop: '1rem',
                        }, link: "/recruitment" },
                        React.createElement(FormattedMessage, { id: "general.recruitment_label_button" }))))),
        React.createElement(Content, null,
            React.createElement(PaymentTypeWrapper, null,
                React.createElement(PaymentTypeHeading, { className: "text-center m-b-m" },
                    React.createElement(FormattedMessage, { id: "admin.offer_form.payment_type_do_you_wish_to_pay" })),
                React.createElement(Switch, { onClick: togglePaymentType },
                    React.createElement(SwitchOption, { active: activePaymentType === OfferPaymentType.PAY_WITH_OFFER_CREDITS },
                        React.createElement(RiCopperCoinLine, { size: "16px" }),
                        React.createElement(FormattedMessage, { id: "admin.offer_form.payment_type_do_you_wish_to_pay_with_credits" })),
                    React.createElement(SwitchOption, { active: activePaymentType === OfferPaymentType.PAY_PER_APPLICATION },
                        React.createElement(RiUserStarLine, { size: "16px" }),
                        React.createElement(FormattedMessage, { id: "admin.offer_form.payment_type_do_you_wish_to_pay_per_resume" })),
                    React.createElement(SwitchToggle, { align: activePaymentType === OfferPaymentType.PAY_PER_APPLICATION ? 'right' : 'left' }))),
            React.createElement(Holder, null,
                React.createElement(Boxes, null,
                    activePaymentType === OfferPaymentType.PAY_WITH_OFFER_CREDITS && (React.createElement(React.Fragment, null,
                        React.createElement(BoxFree, null),
                        React.createElement(BoxCredits, null))),
                    activePaymentType === OfferPaymentType.PAY_PER_APPLICATION && React.createElement(BoxPayPerApplication, null))),
            React.createElement(CompaniesListPreview, null),
            React.createElement(FeaturesWrapper, { id: "features" },
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.features.headilne" })),
                    React.createElement(Features, null))),
            React.createElement(TestimonialsWrapper, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.testimonials.headilne" })),
                    React.createElement(Testimonials, null))),
            React.createElement(BottomCTA, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.results.headilne" })),
                    React.createElement(FreeCredit, null,
                        React.createElement(FreeCredit1, null,
                            React.createElement(FormattedMessage, { id: "companies.results.try" }),
                            ":"),
                        React.createElement(FreeCredit2, null,
                            React.createElement(FormattedMessage, { values: { count: 1 }, id: "general.credits.pluralized" })),
                        React.createElement(Button, { style: {
                                background: theme.color.success,
                                borderColor: theme.color.success,
                                color: theme.color.white,
                            }, link: "/registracia" },
                            React.createElement(ButtonText, null,
                                React.createElement(FormattedMessage, { id: "companies.free.create_account_free" }))),
                        React.createElement(FreeCredit3, null,
                            React.createElement(FormattedMessage, { id: "companies.free.no_hidden_fees" }))))))));
};
const PaymentTypeHeading = styled.h3 `
	color: ${({ theme }) => theme.color.white};
`;
const PaymentTypeWrapper = styled.div `
	position: absolute;
	margin-top: -390px;
	text-align: center;

	${up('sm')} {
		margin-top: -450px;
	}

	${up('md')} {
		margin-top: -480px;
	}
`;
const Switch = styled.div `
	min-width: 400px;
	display: flex;
	cursor: pointer;
	position: relative;
	background: ${({ theme }) => theme.color.white};
	border: 3px solid ${({ theme }) => theme.color.white};
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
	border-radius: 2rem;

	${down('md')} {
		min-width: 300;
	}
`;
const SwitchToggle = styled.div `
	position: absolute;
	background: ${({ theme }) => theme.color.brand1};
	border-radius: 2rem;
	height: 100%;
	width: 50%;
	transition: 0.3s ease-in-out;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

	${({ align }) => align === 'left'
    ? css `
					left: 0;
			  `
    : css `
					left: 50%;
			  `}
`;
const SwitchOption = styled.div `
	transition: 0.3s;
	padding: 0.5rem 1rem;
	color: ${({ theme }) => theme.color.brand1};
	z-index: 1;
	text-align: center;
	width: 50%;
	text-transform: uppercase;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	${({ active }) => active
    ? css `
					color: ${({ theme }) => theme.color.white};
			  `
    : css `
					color: ${({ theme }) => theme.color.brand1};
			  `}
`;
export const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: ${({ theme }) => theme.color.gray2};
`;
export const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 3.5em 2em 0;

	${up('sm')} {
		padding: 2.5em 2em 0;
	}

	${up('md')} {
		padding: 5em 2em 0;
	}

	& > * {
		color: ${props => props.theme.color.white};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
	}
`;
export const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 900px;
	position: relative;
	background: url('/media/images/hero-company3.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 0;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.95;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
`;
export const Boxes = styled.div `
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: flex-start;
	margin-top: -280px;
	flex-direction: column;

	${up('sm')} {
		margin-top: -340px;
	}

	${up('md')} {
		justify-content: center;
		flex-direction: row;
		margin-top: -370px;
	}
`;
export const Content = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const TestimonialsWrapper = styled.div `
	width: 100%;

	padding: 5em 0;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const FeaturesWrapper = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0 2em;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const BottomCTA = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0;
	text-align: center;

	p strong {
		color: ${props => props.theme.color.brand2};
	}

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
export const FreeCredit = styled.div `
	width: 100%;
	padding: 0 0 2em 0;
	text-align: center;
`;
export const FreeCredit1 = styled.div `
	width: 100%;
	padding: 1em 3em 0;
	font-weight: 700;
`;
export const FreeCredit2 = styled.div `
	font-size: 2em;
	font-weight: 700;
	padding: 0.3em 1em 1em;
	color: ${props => props.theme.color.brand3};
`;
export const FreeCredit3 = styled.div `
	width: 100%;
	font-weight: 400;
	font-size: 0.9em;
	padding: 1em 3em 0;
	color: ${props => props.theme.color.gray6};
`;
export const ButtonText = styled.div ``;
export { Companies };
