export var AnalyticsType;
(function (AnalyticsType) {
    AnalyticsType["applications_per_offer"] = "applications_per_offer";
    AnalyticsType["offer_applications"] = "offer_applications";
    AnalyticsType["day_offer_applications"] = "day_offer_applications";
    AnalyticsType["applications_per_day"] = "applications_per_day";
    AnalyticsType["accumulated_company_subscribers"] = "accumulated_company_subscribers";
})(AnalyticsType || (AnalyticsType = {}));
export var AnalyticsTableKeys;
(function (AnalyticsTableKeys) {
    AnalyticsTableKeys["id"] = "id";
    AnalyticsTableKeys["name"] = "name";
    AnalyticsTableKeys["stage"] = "stage";
    AnalyticsTableKeys["email"] = "email";
    AnalyticsTableKeys["telephone"] = "telephone";
    AnalyticsTableKeys["offerTitle"] = "title";
    AnalyticsTableKeys["offerApplicationsCount"] = "offerApplicationsCount";
    AnalyticsTableKeys["offerDeadline"] = "deadline";
    AnalyticsTableKeys["offerFirstPublishedAt"] = "firstPublishedAt";
    AnalyticsTableKeys["offerApplicationCreatedAt"] = "offerApplicationCreatedAt";
    AnalyticsTableKeys["day"] = "day";
    AnalyticsTableKeys["subscribersCount"] = "subscribersCount";
})(AnalyticsTableKeys || (AnalyticsTableKeys = {}));
