import styled from 'styled-components';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { up } from 'styled-breakpoints';
const { Panel: CollapsePanel } = Collapse;
export const FiltersTitle = styled.h3 `
	color: ${({ theme }) => theme.color.brand4};
	padding: 0;
	margin-bottom: 0;
	font-size: 24px;
`;
export const CollapseIcon = styled(DownOutlined) `
	rotate: ${({ rotate }) => rotate};
	transition: 0.2s;

	svg {
		width: 20px;
		height: 20px;
	}
`;
export const CollapseWrapper = styled(Collapse) `
	.ant-collapse-header {
		padding: 0 16px 0 0 !important;
	}

	${up('lg')} {
		.ant-collapse-header {
			display: none !important;
		}
	}
`;
export const CollapsePanelWrapper = styled(CollapsePanel) `
	.ant-collapse-content-box {
		padding: 0;
	}
`;
export const ToggleFilterContentButton = styled.button `
	padding: 0.5rem;
	color: ${({ theme }) => theme.color.brand1};
	border-radius: 0.3rem;
	text-decoration: underline;
	font-weight: bold;
	font-size: 0.9rem;
	border: none;
	width: 100%;
	text-align: center;
	cursor: pointer;

	:focus {
		color: ${({ theme }) => theme.color.brand1};
		background: none;
		text-decoration: underline;
	}
	:hover {
		color: ${({ theme }) => theme.color.brand4};
		border-color: transparent;
		background: rgba(0, 0, 0, 0.018);
	}
`;
export const ExpandText = styled.div `
	right: 0 !important;
	display: flex !important;
	align-items: center;
	color: ${({ theme }) => theme.color.gray6};
`;
