import { Alert, Button, Panel } from '@src/components/elements';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import LoadingWrapper from '@src/components/modules/global/loading-wrapper';
import * as Api from '@src/api';
import { PaymentInfoKeys, ProductId } from '@src/types';
import { PaymentInformationTable } from './PaymentInformationTable';
const StyledInfoAlert = styled(Alert) `
	margin: 2.5rem 0;
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
	color: rgba(0, 0, 0, 0.85);
	.anticon {
		color: #1890ff;
	}
`;
function getProductIdFromInvoice(invoice) {
    if (!invoice) {
        return null;
    }
    if (invoice.company_payment_credit_id) {
        return ProductId.offerCredits;
    }
    if (invoice.company_payment_offer_promotion_id) {
        return ProductId.offerPromotion;
    }
    if (invoice.company_payment_subscription_id) {
        return ProductId.companyPremium;
    }
    throw new Error('Unknown product id');
}
export function PaymentInformation({ invoiceId }) {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [invoice, setInvoice] = React.useState(null);
    const intl = useIntl();
    const paymentInfo = {
        [PaymentInfoKeys.iban]: invoice === null || invoice === void 0 ? void 0 : invoice.iban,
        [PaymentInfoKeys.variable_symbol]: invoice === null || invoice === void 0 ? void 0 : invoice.variable_symbol,
        [PaymentInfoKeys.payment_due]: invoice === null || invoice === void 0 ? void 0 : invoice.payment_due,
        [PaymentInfoKeys.total_sum]: parseFloat(invoice === null || invoice === void 0 ? void 0 : invoice.amount_paid).toFixed(2),
    };
    const init = async () => {
        try {
            const invoiceResponse = await Api.Invoice.getInvoiceById(invoiceId);
            setInvoice(invoiceResponse);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsInitialized(true);
        }
    };
    React.useEffect(() => {
        init();
    }, []);
    const getOrderMessage = () => {
        const productId = getProductIdFromInvoice(invoice);
        if (productId === ProductId.offerCredits) {
            return intl.formatMessage({ id: 'admin.payments.order_info.credits' });
        }
        if (productId === ProductId.offerPromotion) {
            return intl.formatMessage({ id: 'admin.payments.order_info.offer_promotion' });
        }
        if (productId === ProductId.companyPremium) {
            return intl.formatMessage({ id: 'admin.payments.order_info.subscription' });
        }
    };
    if (!invoice) {
        return null;
    }
    return (React.createElement(Panel, null,
        React.createElement(LoadingWrapper, { isLoading: !isInitialized },
            React.createElement("h2", null,
                React.createElement(FormattedMessage, { id: "admin.payments.order_number", values: {
                        orderNumber: invoice.number,
                    } })),
            React.createElement(StyledInfoAlert, { type: "info", showIcon: true, message: getOrderMessage() }),
            React.createElement(PaymentInformationTable, { paymentInfo: paymentInfo }),
            React.createElement("div", { className: "flex-center m-t-l" },
                React.createElement(Button, { type: "ghost", onClick: () => window.open(invoice.url, 'blank'), size: "middle" }, invoice.is_proforma ? (React.createElement(FormattedMessage, { id: "admin.payments.show_proforma" })) : (React.createElement(FormattedMessage, { id: "admin.payments.show_real_invoice" })))))));
}
