import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@src/components/elements';
import { DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
const StyledDeleteOutlined = styled(DeleteOutlined) `
	color: red;
	font-size: 2rem;
	margin-bottom: 1rem;
`;
const SubText = styled.div `
	margin-top: 1rem;
	color: ${props => props.theme.color.gray7};
	font-size: 0.75rem;
`;
export function DeleteSelectedAssetsModal({ isOpen, onOk, onCancel, selectedAssetIdsCount }) {
    return (React.createElement(Modal, { visible: isOpen, onOk: onOk, onCancel: onCancel, footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: onCancel, size: "middle", type: "ghost" },
                React.createElement(FormattedMessage, { id: "general.cancel" })),
            React.createElement(Button, { onClick: onOk, danger: true, size: "middle", type: "primary" },
                React.createElement(FormattedMessage, { id: "general.delete" }))) },
        React.createElement("div", { className: "m-m flex-center flex-col font-size-m" },
            React.createElement(StyledDeleteOutlined, null),
            React.createElement(FormattedMessage, { id: "assets.delete.confirm", values: { selectedAssetIdsCount } }),
            React.createElement(SubText, null,
                React.createElement(FormattedMessage, { id: "assets.delete.subtext" })))));
}
