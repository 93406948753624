import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { notification } from 'antd';
import { Panel, Button, Separator } from '@src/components/elements';
import * as Api from 'api';
import { NotificationTypesTable } from './NotificationTypesTable';
function NotificationsSection() {
    const [notificationTypesSettings, setNotificationTypesSettings] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const intl = useIntl();
    function showSuccessSaveNotification() {
        notification.success({
            message: intl.formatMessage({ id: 'general.successfuly_saved' }),
            placement: 'bottomRight',
        });
    }
    function showErrorNotification() {
        notification.error({
            message: intl.formatMessage({ id: 'general.something_went_wrong' }),
            placement: 'bottomRight',
        });
    }
    async function fetchAndSetNotificationTypesSettings() {
        setIsLoading(true);
        try {
            const settings = await Api.Account.getMyNotificationTypeSettings();
            setNotificationTypesSettings(settings);
        }
        catch (err) {
            showErrorNotification();
        }
        finally {
            setIsLoading(false);
        }
    }
    async function saveNotificationTypesSettings(settings) {
        if (!settings) {
            return;
        }
        setIsLoading(true);
        try {
            await Api.Account.updateMyNotificationTypeSettings(settings);
            showSuccessSaveNotification();
        }
        catch (err) {
            showErrorNotification();
        }
        finally {
            setIsLoading(false);
        }
    }
    const setTypePropertyValue = (value, property, notificationType) => {
        setNotificationTypesSettings(currentSettings => {
            const currentSettingsCopy = JSON.parse(JSON.stringify(currentSettings));
            currentSettingsCopy[notificationType][property] = value;
            if (property === 'is_enabled' && value === false) {
                currentSettingsCopy[notificationType].should_send_email = false;
            }
            if (property === 'should_send_email' && value === true) {
                currentSettingsCopy[notificationType].is_enabled = true;
            }
            return currentSettingsCopy;
        });
    };
    const turnOffAllNotifications = () => {
        const settingsWithAllNotificationsTurnedOff = JSON.parse(JSON.stringify(notificationTypesSettings));
        Object.keys(settingsWithAllNotificationsTurnedOff).forEach(k => {
            settingsWithAllNotificationsTurnedOff[k].is_enabled = false;
            settingsWithAllNotificationsTurnedOff[k].should_send_email = false;
        });
        setNotificationTypesSettings(settingsWithAllNotificationsTurnedOff);
        saveNotificationTypesSettings(settingsWithAllNotificationsTurnedOff);
    };
    React.useEffect(() => {
        fetchAndSetNotificationTypesSettings();
    }, []);
    return (React.createElement(Panel, null,
        React.createElement(NotificationTypesTable, { notificationTypesSettings: notificationTypesSettings, isLoading: isLoading, onCheckboxChange: setTypePropertyValue }),
        React.createElement("div", { className: "flex-center m-xl" },
            React.createElement(Button, { color: "brand", onClick: () => saveNotificationTypesSettings(notificationTypesSettings), loading: isLoading },
                React.createElement("span", { className: "m-l-xs" },
                    React.createElement(FormattedMessage, { id: "general.buttons.saveChanges" })))),
        React.createElement(Separator, null),
        React.createElement("div", { className: "flex-center m-l" },
            React.createElement(Button, { type: "default", onClick: turnOffAllNotifications, loading: isLoading },
                React.createElement("span", { className: "m-l-xs" },
                    React.createElement(FormattedMessage, { id: "global.notification.unsubscribeAll" }))))));
}
export { NotificationsSection };
