import { QuestionMark } from '@src/components/elements';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { QuestionMarkWrapper, RedText } from './SendResumeFields.style';
export function PersonalAgreementField({ agreementRules, setIsPersonalAgreementVisible }) {
    return (React.createElement("div", { className: "flex-row" },
        React.createElement(Form.Item, { name: "agreement", rules: agreementRules, valuePropName: "checked" },
            React.createElement(Checkbox, null,
                React.createElement(RedText, null, "*"),
                React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.agreement_with_personal_information" }))),
        React.createElement(QuestionMarkWrapper, null,
            React.createElement(QuestionMark, { onClick: () => setIsPersonalAgreementVisible(true) }))));
}
