import React from 'react';
import { useIntl } from 'react-intl';
import { useIntersection } from 'react-use';
import { StickyButton } from '@src/components/elements/StickyButton/StickyButton';
const ReactToOfferButton = ({ sendResumeButtonRef }) => {
    const intl = useIntl();
    const intersection = useIntersection(sendResumeButtonRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.01,
    });
    const shouldBeActive = !(((intersection === null || intersection === void 0 ? void 0 : intersection.intersectionRatio) || 0) > 0);
    return (React.createElement(StickyButton, { onClick: () => {
            var _a;
            (_a = sendResumeButtonRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }, shape: "round", placement: "bottom-left", type: "success", 
        // I want to hide the button if it's intersecting the form
        // and leave the showing logic up to the element component if not
        isActive: shouldBeActive ? undefined : shouldBeActive }, intl.formatMessage({ id: 'offer_detail.react_to_offer_button' })));
};
export { ReactToOfferButton };
