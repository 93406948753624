import { Badge, Button } from '@src/components/elements';
import styled from 'styled-components';
import { GlobalOutlined } from '@ant-design/icons';
import { darken, transparentize } from 'polished';
import { up } from 'styled-breakpoints';
export const Header = styled.div `
	position: relative;
	width: 100%;
	height: 28rem;
	min-height: 100%;
	background: url(${props => props.background}) no-repeat 50%;
	background-size: cover;
	object-fit: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const TitleImageOverlay = styled.div `
	position: absolute;
	color: ${props => props.theme.color.white};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: ${props => props.theme.fontSizes.m};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 1;
	z-index: 1;
`;
export const FullHeaderWrapper = styled.div `
	position: relative;
	max-width: ${({ theme }) => theme.breakpoints.xl};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	height: 100%;
	width: 100%;
	padding: 0 1rem;
`;
export const HeaderEntryWrapper = styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	margin-bottom: 0.5rem;
	text-align: left;
	svg {
		min-width: 1rem;
	}
	${up('sm')} {
		margin-bottom: 0.5rem;
	}
	${up('md')} {
		margin-bottom: 0;
		justify-content: start;
	}
`;
export const HeaderTextWrapper = styled.div `
	z-index: inherit;
	color: ${props => props.theme.color.white};
	${up('md')} {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	${up('lg')} {
		flex-direction: row;
	}
`;
export const HeaderContent = styled.div `
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	z-index: 2;
	margin-top: 0;
	${up('md')} {
		position: absolute;
		left: 1rem;
		bottom: ${({ hasImage }) => (hasImage ? 0 : '1rem')};
		display: flex;
		flex-direction: row;
		height: unset;
		justify-content: flex-end;
	}
`;
export const ImageBlock = styled.div `
	border: 1px solid ${({ theme }) => theme.color.gray8};
	background: ${({ theme }) => theme.color.white};
	z-index: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
	margin: 0 0 0.5rem;
	& > * {
		padding: 4px;
		background: white;
		max-width: 100%;
		max-height: 100%;
	}

	svg {
		width: 70%;
		height: 70%;
		color: ${({ theme }) => theme.color.gray4};
	}
	${up('md')} {
		margin: 0 1rem 1rem 0;
	}
`;
export const EditButton = styled(Button) `
	color: ${({ theme }) => theme.color.white};
	border: 1px solid ${({ theme }) => theme.color.white};
	z-index: inherit;

	:hover {
		background-color: ${props => darken(0.3, props.theme.color.white)};
	}´

`;
export const SubSectionButton = styled.div `
	height: 100%;
	display: flex;
	align-items: center;
	border-bottom: 0.25rem solid ${props => (props.isActive ? props.theme.color.brand3 : 'transparent')};
	transition: 0.15s;
	white-space: nowrap;
	color: ${({ isActive, theme }) => (isActive ? theme.color.brand3 : transparentize(0.2, theme.color.gray8))};
	font-weight: bold;
	font-size: 1rem;
	text-transform: uppercase;
	margin: 0 0.5rem;
	padding: 0.5rem 0 0.25rem;
	:hover {
		color: ${props => darken(0.2, props.theme.color.brand3)};
		cursor: pointer;
	}
	${up('md')} {
		padding: 1rem 0.25rem 0.75rem;
		margin: 0 0.5rem 0 0;
	}
`;
export const SubNavigation = styled.div `
	transition: all 0.15s ease;
	position: sticky;
	top: ${({ isMenuSticked }) => (isMenuSticked ? '5rem' : '0rem')};
	z-index: 999;
	width: 100%;
	background: ${({ theme }) => theme.color.gray3};
	${up('md')} {
		justify-content: center;
		position: unset;
	}
`;
export const StyledWebIcon = styled(GlobalOutlined) `
	svg {
		width: 0.875rem;
		height: 0.875rem;
	}
`;
export const SubContentPagesNavigationWrapper = styled.div `
	display: flex;
	overflow-x: auto;
	max-width: ${({ theme }) => theme.breakpoints.xl};
	width: 100%;
`;
export const HeaderTextInfo = styled.div `
	text-align: center;
	${up('md')} {
		text-align: unset;
	}
`;
export const EditButtonWrapper = styled.div `
	padding: 1rem 1rem 0;
	width: 100%;
	display: flex;
	justify-content: end;
	z-index: 2;
	max-width: ${({ theme }) => theme.breakpoints.xl};
`;
export const EntryText = styled.div `
	font-size: 0.75rem;

	${up('sm')} {
		font-size: 1rem;
	}
`;
export const HeaderTitle = styled.div `
	font-weight: bold;
	font-size: 1.25rem;
	color: ${({ theme }) => theme.color.white};
	margin-bottom: 0.5rem;
	${up('sm')} {
		font-size: 2rem;
	}
`;
export const SubNavigationContentWrapper = styled.div `
	max-width: ${({ theme }) => theme.breakpoints.xl};
	padding: 0 1rem;
	margin: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const MenuHeaderTitle = styled.div `
	font-weight: bold;
	font-size: 1rem;
	margin: 0 0.5rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: keep-all;
	overflow: hidden;
	color: ${({ theme }) => theme.color.brand3};
`;
export const SubscribersWrapper = styled.div `
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
`;
export const SubscribersText = styled.div `
	font-size: 1rem;
	color: ${({ theme }) => theme.color.brand3};
`;
export const CompanyWebsiteLink = styled.a `
	&,
	&:link,
	&:visited,
	&:focus {
		color: ${props => props.theme.color.white};
		text-decoration: underline;
	}
`;
export const StyledBadge = styled(Badge) `
	position: relative;
	font-size: unset;
	line-height: unset;
	color: unset;
`;
export const CompanySubscriptionHeader = styled.div `
	transition: all 0.2s ease;
	z-index: 999;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	background: ${props => props.theme.color.brand5};
	top: ${({ isMenuSticked }) => (isMenuSticked ? '5rem' : 0)};
	height: 3rem;
	width: 100%;
	padding: 0 1.5rem;
`;
export const CircleIconWrapper = styled.div `
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.5rem 0 0;
	padding: 0.5rem;
	border: 1px solid;
	width: 1.5rem;
	height: 1.5rem;
`;
export const BannerWrapper = styled.div `
	max-width: ${({ theme }) => theme.breakpoints.xl};
	width: 100%;
	z-index: 1;
`;
export const BannerContentWrapper = styled.div `
	padding: 1rem 1rem 0;
	width: 100%;

	${up('md')} {
		width: 37.5%;
		max-width: 37.5%;
		margin-left: auto;
	}
	${up('lg')} {
		width: 25%;
		max-width: 25%;
		margin-left: auto;
	}
`;
