import React from 'react';
import { SelectTag } from '@src/components/elements';
import { StyledTreeSelect } from './TreeSelect.style';
function TreeSelect(props) {
    return React.createElement(StyledTreeSelect, Object.assign({}, getDefaultProps(), props));
}
TreeSelect.TreeNode = StyledTreeSelect.TreeNode;
TreeSelect.SHOW_PARENT = StyledTreeSelect.SHOW_PARENT;
TreeSelect.SHOW_ALL = StyledTreeSelect.SHOW_ALL;
TreeSelect.SHOW_CHILD = StyledTreeSelect.SHOW_CHILD;
const getDefaultProps = () => {
    return {
        tagRender: tagProps => React.createElement(SelectTag, Object.assign({}, tagProps)),
    };
};
export { TreeSelect };
