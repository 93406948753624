import React from 'react';
import { isSameWeek, isSameYear, isToday, isYesterday } from 'date-fns';
import { FormattedTime, FormattedMessage, FormattedDate } from 'react-intl';
import { Time } from './Timestamp.style';
function Timestamp({ now = new Date(), date, className }) {
    if (isToday(date)) {
        return (React.createElement(Time, { className: className },
            React.createElement(FormattedMessage, { id: "general.date.today" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    if (isYesterday(date)) {
        return (React.createElement(Time, { className: className },
            React.createElement(FormattedMessage, { id: "general.date.yesterday" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    if (isSameWeek(now, date, { weekStartsOn: 1 })) {
        return (React.createElement(Time, { className: className },
            React.createElement(FormattedDate, { value: date, weekday: "long" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    return (React.createElement("time", { className: className },
        React.createElement(FormattedDate, { value: date, year: isSameYear(now, date) ? undefined : 'numeric', month: "long", day: "numeric", weekday: undefined })));
}
export { Timestamp };
