import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useIsFirstRender } from '@src/hooks/useIsFirstRender';
import { getConfig } from '@src/config';
import { SalaryRate } from '@src/types';
import { FilterSeparator, FilterTitle } from './filter/Filter.style';
import { useOffersFilterContextValue } from '../../offer-filter-context';
import { StyledSlider, InputMinMax, SalaryRateButtons } from './SalaryRangeFilter.style';
const min = 0;
var ChangeType;
(function (ChangeType) {
    ChangeType["MIN"] = "min";
    ChangeType["MAX"] = "max";
})(ChangeType || (ChangeType = {}));
const steps = {
    default: 10,
    [SalaryRate.hourly]: 0.1,
};
export const SalaryRangeFilter = () => {
    const config = getConfig();
    const intl = useIntl();
    const isFirstRender = useIsFirstRender();
    const { salaryFilter, setSalaryFilter, getHighestSalary } = useOffersFilterContextValue();
    const [maxBoundary, setMaxBoundary] = React.useState(salaryFilter.maxRange);
    const [localMaxRange, setLocalMaxRange] = React.useState(salaryFilter.maxRange);
    const [localMinRange, setLocalMinRange] = React.useState(salaryFilter.minRange);
    const sliderRef = React.useRef(null);
    const [localSalaryRate, setLocalSalaryRate] = React.useState(salaryFilter.rate);
    const [isContextChange, setIsContextChange] = React.useState(false);
    const radioOptions = config.salaryRates.map(rate => ({
        label: intl.formatMessage({ id: `offer.filter.${rate.value}` }),
        value: rate.value,
    }));
    const setLocalAndContextStates = (highestSalary) => {
        setMaxBoundary(highestSalary);
        if (salaryFilter.rate) {
            setLocalMinRange(0);
        }
        if (!isFirstRender) {
            setSalaryFilter({
                highestSalary,
                rate: localSalaryRate,
                minRange: salaryFilter.rate ? 0 : localMinRange,
                maxRange: localMaxRange !== maxBoundary && !salaryFilter.rate ? localMaxRange : highestSalary,
            });
        }
        if (localMaxRange !== maxBoundary && !salaryFilter.rate) {
            return;
        }
        setLocalMaxRange(highestSalary);
    };
    const handleSalaryRateChange = async () => {
        const highestSalary = await getHighestSalary(localSalaryRate);
        if (highestSalary) {
            setLocalAndContextStates(highestSalary);
        }
    };
    React.useEffect(() => {
        if (!isContextChange && !isFirstRender) {
            handleSalaryRateChange();
        }
        else {
            setIsContextChange(false);
        }
    }, [localSalaryRate]);
    React.useEffect(() => {
        // here we handle outside changes to context state and load them to local state
        const didContextRateChange = salaryFilter.rate !== localSalaryRate;
        // this handle use-case for init load states, when salaryFilter is off & we need to set max value on local
        const didContextHighestSalaryChange = salaryFilter.highestSalary !== maxBoundary;
        if (didContextRateChange || didContextHighestSalaryChange) {
            if (didContextRateChange) {
                setIsContextChange(true);
            }
            setLocalSalaryRate(salaryFilter.rate);
            setLocalMinRange(salaryFilter.minRange);
            setLocalMaxRange(salaryFilter.maxRange);
            setMaxBoundary(salaryFilter.highestSalary);
        }
        else {
            setIsContextChange(false);
        }
    }, [salaryFilter.rate, salaryFilter.highestSalary]);
    React.useEffect(() => {
        setLocalMinRange(salaryFilter.minRange || 0);
    }, [salaryFilter.minRange]);
    const onSliderChange = (value) => {
        setLocalMinRange(value[0]);
        setLocalMaxRange(value[1]);
    };
    function checkNewMinMaxOrderCorrectness(inputValue, changingInputType) {
        if (changingInputType === ChangeType.MIN) {
            const isNewValueLowerThenMax = inputValue < localMaxRange;
            if (isNewValueLowerThenMax) {
                return true;
            }
            setLocalMinRange(localMaxRange);
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { minRange: localMaxRange }));
        }
        if (changingInputType === ChangeType.MAX) {
            const isNewValueHigherThenMin = inputValue > localMinRange;
            if (isNewValueHigherThenMin) {
                return true;
            }
            setLocalMaxRange(localMinRange);
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { maxRange: localMinRange }));
        }
        return false;
    }
    const changeInputValue = (value, type) => {
        const isValueNaN = Number.isNaN(value);
        const localInputValue = type === ChangeType.MAX ? localMaxRange : localMinRange;
        const hasValueChanged = value !== localInputValue;
        if (!hasValueChanged || isValueNaN) {
            return;
        }
        if (!localSalaryRate) {
            setLocalSalaryRate(SalaryRate.monthly);
        }
        const numberValue = Number(value);
        if (numberValue <= 0 && type === ChangeType.MAX) {
            setLocalMaxRange(maxBoundary);
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { maxRange: maxBoundary }));
            return;
        }
        const areMinMaxValuesInRightOrder = checkNewMinMaxOrderCorrectness(numberValue, type);
        if (type === ChangeType.MAX && areMinMaxValuesInRightOrder) {
            const newMaxValue = numberValue <= maxBoundary && numberValue !== 0 ? numberValue : maxBoundary;
            const roundedNewMaxValue = salaryFilter.rate === SalaryRate.hourly
                ? Number(newMaxValue.toFixed(2))
                : Number(newMaxValue.toFixed(0));
            setLocalMaxRange(roundedNewMaxValue);
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { maxRange: roundedNewMaxValue }));
        }
        if (type === ChangeType.MIN && areMinMaxValuesInRightOrder) {
            const roundedNeMinValue = salaryFilter.rate === SalaryRate.hourly
                ? Number(numberValue.toFixed(2))
                : Number(numberValue.toFixed(0));
            setLocalMinRange(roundedNeMinValue);
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { minRange: roundedNeMinValue }));
        }
    };
    const onSliderRelease = value => {
        if (!localSalaryRate) {
            setLocalSalaryRate(SalaryRate.monthly);
        }
        else {
            setSalaryFilter(Object.assign(Object.assign({}, salaryFilter), { minRange: value[0], maxRange: value[1] }));
        }
        ;
        sliderRef.current.blur();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterTitle, null,
            React.createElement(FormattedMessage, { id: "offers.filter.salary_rate_title" })),
        React.createElement(FilterSeparator, null),
        React.createElement("div", { className: "m-b-l" },
            React.createElement("div", { className: "flex-center" },
                React.createElement(SalaryRateButtons, { options: radioOptions, activeButton: localSalaryRate, setActiveValue: value => setLocalSalaryRate(value), size: "middle" })),
            React.createElement(StyledSlider, { range: true, min: min, max: maxBoundary, value: [localMinRange, localMaxRange], onChange: onSliderChange, step: salaryFilter.rate === SalaryRate.hourly ? steps[salaryFilter.rate] : steps.default, onAfterChange: onSliderRelease, ref: sliderRef, tooltipVisible: false }),
            React.createElement("div", { className: "flex-row align-center justify-space-between" },
                React.createElement("div", { className: "flex-col" },
                    React.createElement("div", null, intl.formatMessage({ id: 'minimum' })),
                    React.createElement(InputMinMax, { className: "m-r-s", decimalSeparator: ",", placeholder: `${min}`, value: localMinRange === min ? NaN : localMinRange, onBlur: e => changeInputValue(Number(e.target.value) <= min ? min : Number(e.target.value), ChangeType.MIN), onPressEnter: e => e.target.blur(), min: min, max: maxBoundary, addonAfter: "\u20AC", precision: salaryFilter.rate === SalaryRate.hourly ? 2 : 0, controls: false })),
                React.createElement("span", { className: "m-t-m" }, "-"),
                React.createElement("div", { className: "flex-col" },
                    React.createElement("div", null, intl.formatMessage({ id: 'maximum' })),
                    React.createElement(InputMinMax, { decimalSeparator: ",", placeholder: intl.formatMessage({
                            id: 'offer.filter.unlimited',
                        }), value: localMaxRange >= maxBoundary ? NaN : localMaxRange, onBlur: e => changeInputValue(Number(e.target.value) >= maxBoundary ? maxBoundary : Number(e.target.value), ChangeType.MAX), onPressEnter: e => e.target.blur(), min: min, precision: salaryFilter.rate === SalaryRate.hourly ? 2 : 0, max: maxBoundary, addonAfter: "\u20AC", controls: false }))))));
};
