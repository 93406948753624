import React from 'react';
import { FormattedMessage } from 'react-intl';
import { translateType } from '@src/components/modules/global/OfferDetail/OfferDetails.model';
import { TextSection } from '@src/components/modules/global/OfferDetail/OfferDetails.style';
export const getOfferContractDuration = ({ isIndefinite, durationMin, durationMax, durationType, intl, }) => {
    if (typeof isIndefinite !== 'boolean' && typeof isIndefinite !== 'number') {
        return null;
    }
    const showExactDuration = durationMin && durationMax && durationMin === durationMax;
    const showMinDuration = durationMin && !durationMax;
    if (isIndefinite) {
        return (React.createElement(TextSection, null,
            React.createElement("strong", null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.contract_duration" })),
            ' ',
            React.createElement(FormattedMessage, { id: "offer_detail.details.indefinite_contract_duration" })));
    }
    if (showExactDuration) {
        return (React.createElement(TextSection, null,
            React.createElement("strong", null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.contract_duration" })),
            ' ',
            durationMin,
            " ",
            translateType(durationMin, durationType, intl)));
    }
    if (showMinDuration) {
        return (React.createElement(TextSection, null,
            React.createElement(FormattedMessage, { id: "offer_detail.details.contract_duration_minimal", values: {
                    strong: chunks => React.createElement("strong", null, chunks),
                } }),
            durationMin,
            " ",
            translateType(durationMin, durationType, intl)));
    }
    return (React.createElement(TextSection, null,
        React.createElement("strong", null,
            React.createElement(FormattedMessage, { id: "offer_detail.details.contract_duration" })),
        ' ',
        durationMin,
        " - ",
        durationMax,
        " ",
        translateType(durationMax, durationType, intl)));
};
