import styled, { css } from 'styled-components';
export const PDFDocumentContainer = styled.div `
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 0 3%;
	${props => props.showGrayBackground &&
    css `
			background-color: ${props.theme.color.gray9};
		`}

	.react-pdf__Document {
		width: 100%;
		canvas {
			width: 100% !important;
			height: auto !important;
			margin-bottom: 3%;
		}
		.react-pdf__Page__textContent {
			display: none !important;
		}
		.react-pdf__message {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`;
