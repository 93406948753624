/* eslint-disable import/no-duplicates */
import axios from 'axios';
import config from '@src/config';
import _mergeWith from 'lodash/mergeWith';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import { transformCasing } from './transformCasing';
const client = axios.create({
    baseURL: config.api,
    withCredentials: true,
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    },
});
client.interceptors.response.use((response) => {
    const { data } = response;
    if (data && typeof data === 'object' && data) {
        const mergedData = _mergeWith(transformCasing(data, _snakeCase), transformCasing(data, _camelCase));
        return Object.assign(Object.assign({}, response), { data: mergedData });
    }
    return response;
}, error => {
    return Promise.reject(error);
});
export { client };
