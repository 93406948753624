import React from 'react';
import * as Api from '@src/api';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { SpecificSearchSelect } from './SpecificSearchSelect';
export function SpecificSearchSelectWrapper({ selectedValues, setSelectedValues, isTagCountInLimit, handleSelect, onChange, }) {
    const intl = useIntl();
    const [suggestedCompanies, setSuggestedCompanies] = React.useState([]);
    const { mutateAsync: suggestionsRefetch, isLoading: isFetching } = useAppMutation(Api.Offers.getSearchSuggestions, {
        onSuccess: data => {
            const { companySuggestions } = data.suggestions;
            setSuggestedCompanies(companySuggestions);
        },
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        },
    });
    const getSearchSuggestions = (searchQuery) => {
        if (!searchQuery) {
            emptySuggestions();
            return;
        }
        suggestionsRefetch(searchQuery);
    };
    const emptySuggestions = () => {
        setSuggestedCompanies([]);
    };
    const defaultHandleSelect = (option) => {
        setSelectedValues([...(selectedValues || []), option]);
    };
    const defaultHandleDeselect = ({ label, value }) => {
        const newSelectedValues = (selectedValues || []).filter(({ label: itemLabel, value: itemValue }) => itemLabel !== label && itemValue !== value);
        setSelectedValues(newSelectedValues);
    };
    return (React.createElement(SpecificSearchSelect, { selectedValues: selectedValues, onChange: onChange, getSearchSuggestions: getSearchSuggestions, isLoading: isFetching, emptySuggestions: emptySuggestions, suggestedCompanies: suggestedCompanies, isTagCountInLimit: isTagCountInLimit, handleSelect: handleSelect || defaultHandleSelect, handleDeselect: defaultHandleDeselect }));
}
