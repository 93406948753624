import { Link } from '@src/components/elements';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
export function SendProfileResumeCheckbox({ sendProfileAsResume, setSendProfileAsResume }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { name: "sendProfileResume", initialValue: sendProfileAsResume, valuePropName: "checked" },
            React.createElement(Checkbox, { onChange: e => {
                    setSendProfileAsResume(e.target.checked);
                } },
                React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.profile_resume", values: {
                        Link: chunks => React.createElement(Link, { to: "/ucet/profil" }, chunks),
                    } })))));
}
