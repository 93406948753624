import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getLocalizedPath, getLanguageFromPathname } from '@src/shared/localized-path';
import { useShouldRedirectToAccountIfLoggedIn } from '@src/hooks/use-redirect-to-account-if-logged-in';
import { animateScroll } from 'react-scroll';
const routesToCheckRedirect = [
    '/login',
    '/registracia',
    '/registration',
    '/registracia-uchadzac',
    '/registration-candidate',
    '/prihlasenie',
];
function Link(props) {
    const location = useLocation();
    const language = getLanguageFromPathname(location.pathname);
    const accountDestinationPath = useShouldRedirectToAccountIfLoggedIn(props.to);
    if (props.to) {
        let destinationPath = props.to;
        if (routesToCheckRedirect.includes(props.to)) {
            destinationPath = accountDestinationPath;
        }
        return (React.createElement(RouterLink, { onClick: location.pathname === getLocalizedPath(destinationPath, language)
                ? () => {
                    animateScroll.scrollToTop();
                    return false;
                }
                : undefined, className: props.className, to: getLocalizedPath(destinationPath, language) }, props.children));
    }
    return (React.createElement("a", { className: props.className, onClick: props.onClick, href: props.href }, props.children));
}
export { Link };
