import * as Api from '@src/api';
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
export const getRequiredEmailRule = (intl) => [
    {
        required: true,
        message: intl.formatMessage({
            id: 'offer_detail.sync_validation.fill_email_field',
        }),
    },
];
export const getEmailFormatRule = (intl) => [
    {
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
        message: intl.formatMessage({
            id: 'offer_detail.sync_validation.fill_valid_email',
        }),
    },
];
export const getEmailExistenceRule = (intl) => [
    {
        validator: async (_, value) => new Promise((resolve, reject) => {
            failIfUserExists(value)
                .then(() => {
                resolve(null);
            })
                .catch(() => reject(new Error(intl.formatMessage({
                id: 'profile.async_validation.this_email_is_taken',
            }))));
        }),
    },
];
export const getEmailRules = (intl) => [...getRequiredEmailRule(intl), ...getEmailFormatRule(intl)];
export const getEmailRulesWithAsync = (intl) => [...getEmailExistenceRule(intl), ...getEmailRules(intl)];
