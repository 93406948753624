import React from 'react';
const OfferPromotionContext = React.createContext({
    showPromotionModal: false,
    setShowPromotionModal: () => false,
    promotingOffer: {},
    setPromotingOffer: () => null,
    isPromotedAutomatically: false,
    setIsPromotedAutomatically: () => null,
});
export const useOfferPromotionContext = () => {
    const [showPromotionModal, setShowPromotionModal] = React.useState(false);
    const [promotingOffer, setPromotingOffer] = React.useState(null);
    const [isPromotedAutomatically, setIsPromotedAutomatically] = React.useState(false);
    return {
        OfferPromotionContextProvider: OfferPromotionContext.Provider,
        value: {
            showPromotionModal,
            setShowPromotionModal: (b) => {
                setShowPromotionModal(b);
            },
            promotingOffer,
            setPromotingOffer: (o) => {
                setPromotingOffer(o);
            },
            isPromotedAutomatically,
            setIsPromotedAutomatically: (v) => {
                setIsPromotedAutomatically(v);
            },
        },
    };
};
export const useOfferPromotionContextValue = () => {
    const context = React.useContext(OfferPromotionContext);
    return context;
};
