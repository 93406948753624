import React from 'react';
import { Icon } from '@src/components/elements';
import SlugOffer from 'shared/slug-offer';
import { useSalaryFormatter } from '@src/hooks/useSalaryFormatter';
import { FormattedMessage } from 'react-intl';
import { CrownOutlined } from '@ant-design/icons';
import { Heading, Info, TagText, Item, SimilarOfferBox, Title, StyledTag, CustomPanel } from './SuggestingOffers.style';
function SuggestingOffers({ offers, boxTitle, arePromotedOffers }) {
    const { formatSalary } = useSalaryFormatter();
    return (React.createElement(CustomPanel, { arePromotedOffers: arePromotedOffers },
        React.createElement(SimilarOfferBox, null,
            React.createElement(Heading, { arePromotedOffers: arePromotedOffers },
                arePromotedOffers && React.createElement(CrownOutlined, { className: "m-r-s" }),
                boxTitle),
            offers.map(({ id, title, address, salaryRate, salaryAmountFrom, salaryAmountTo, salaryCurrency, isPaid, salaryType, }) => {
                const slug = new SlugOffer({ text: title });
                return (React.createElement(Item, { key: `offer-suggestion-company-${id}`, to: `/ponuka/${slug}-${id}${arePromotedOffers ? '?isPromoted=true' : ''}` },
                    React.createElement(Title, null, title),
                    React.createElement(Info, null,
                        React.createElement(StyledTag, { icon: React.createElement(Icon, { type: "maps-pin" }), color: "default" },
                            address && React.createElement(TagText, null, address.city),
                            !address && (React.createElement(TagText, null,
                                React.createElement(FormattedMessage, { id: "offer_detail.similar_offers.location", defaultMessage: "Remote" })))),
                        Boolean(isPaid) && (React.createElement(StyledTag, { icon: React.createElement(Icon, { type: "money-bag-euro" }), color: "default" },
                            React.createElement(TagText, null, formatSalary({
                                rate: salaryRate,
                                amountFrom: salaryAmountFrom,
                                amountTo: salaryAmountTo,
                                currency: salaryCurrency,
                                salaryType,
                            })))))));
            }))));
}
export { SuggestingOffers };
