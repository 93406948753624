import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
export function useAppQuery(queryKey, queryFn, options) {
    const intl = useIntl();
    return useQuery(queryKey, queryFn, Object.assign({ onError: (options === null || options === void 0 ? void 0 : options.onError) ? options === null || options === void 0 ? void 0 : options.onError : error => {
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        } }, options));
}
