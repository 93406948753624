// import { transparentize } from 'polished'
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Icon, Button } from '../index';
function FacebookShareButton({ withText, className }) {
    const FBShare = () => {
        if (typeof FB !== 'undefined') {
            FB === null || FB === void 0 ? void 0 : FB.ui({
                // @ts-ignore
                method: 'share',
                href: window.location.href,
            }, null);
        }
    };
    return (React.createElement(FacebookButton, { className: className, size: "middle", onClick: FBShare },
        React.createElement(Icon, { type: "social-facebook", className: withText ? 'm-r-s' : undefined }),
        withText && React.createElement(FormattedMessage, { id: "share" })));
}
const FacebookButton = styled(Button) `
	background: ${({ theme }) => theme.color.facebook};
	border-color: ${({ theme }) => theme.color.facebook};

	&:hover {
		background: ${({ theme }) => theme.color.facebook};
	}

	svg {
		width: 16px;
		height: 16px;
	}
`;
export { FacebookShareButton };
