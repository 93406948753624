export function getLocationTitleWithCount(title, count, isAtLeastOneLocationSelected, isSelected, isParentSelected) {
    if (!count || isSelected || isParentSelected) {
        return title;
    }
    if (isAtLeastOneLocationSelected) {
        return `${title} (+${count})`;
    }
    return `${title} (${count})`;
}
export function getAllParentIdsByNodeId(nodeId, treeNodes, parentIds = []) {
    for (const treeNode of treeNodes) {
        if (treeNode.id === nodeId) {
            return parentIds;
        }
        if (treeNode.sublocations) {
            return getAllParentIdsByNodeId(nodeId, treeNode.sublocations, [...parentIds, treeNode.id]);
        }
    }
    return parentIds;
}
export function buildLocationTree(currentLocations, selectedLocations, intl, isAtLeastOneLocationSelected, allLocations) {
    return currentLocations
        .map(location => {
        var _a, _b;
        const allParentIds = getAllParentIdsByNodeId(location.id, allLocations);
        const isParentSelected = allParentIds.some(parentId => selectedLocations.includes(parentId));
        const result = {
            title: getLocationTitleWithCount(intl.formatMessage({
                id: `${location.translation_key}`,
            }), location.count, isAtLeastOneLocationSelected, isParentSelected),
            count: location.count,
            value: location.id,
            disabled: !location.count &&
                !selectedLocations.includes(location.id) &&
                !((_a = location.sublocations) === null || _a === void 0 ? void 0 : _a.find(l => selectedLocations.includes(l.id))),
        };
        if ((_b = location.sublocations) === null || _b === void 0 ? void 0 : _b.length) {
            result.children = buildLocationTree(location.sublocations, selectedLocations, intl, isAtLeastOneLocationSelected, allLocations);
        }
        return result;
    })
        .sort((a, b) => {
        if (a.disabled && !b.disabled) {
            return 1;
        }
        if (!a.disabled && b.disabled) {
            return -1;
        }
        return 0;
    });
}
