import React from 'react';
import { Button, Icon, Panel } from '@src/components/elements';
import { FormattedMessage, useIntl } from 'react-intl';
import { Parser } from 'html-to-react';
import { parseISO } from 'date-fns';
import Timestamp from 'components/modules/global/timestamp';
import { useSalaryFormatter } from '@src/hooks/useSalaryFormatter';
import { useConfig } from '@src/hooks/useConfig';
import { ToggleSaveOfferButton } from '@src/components/modules/global/ToggleSaveOfferButton/ToggleSaveOfferButton';
import styled from 'styled-components';
import { FacebookShareButton } from '@src/components/elements/FacebookShareButton';
import { down } from 'styled-breakpoints';
import { transparentize } from 'polished';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { getOfferContractDuration } from '@src/helpers/getOfferContractDuration';
import { useQueryParameters } from '@src/helpers/use-query-parameters';
import { translateWorkingTimeType } from './OfferDetails.model';
import { OfferNotActive } from './OfferNotActive';
import { Headline, HeadlineSubtitle, MainHeadline, Section, SkillTagsWrapper, TagsWrapper, TagWrapper, TextSection, } from './OfferDetails.style';
import { RequiredLanguages } from './OfferDetailsRequiredLanguages';
import { RequiredDriverLicences } from './OfferDetailsDriverLicences';
import { OfferDetailsSkillTag } from './OfferDetailsSkillTag';
import { WorkingTimeRangeHours } from './OfferDetailsWorkingTimeRangeHours';
import { OfferDetailsTitleGallery } from './offer-details-title-gallery';
import { OfferDetailsGallery } from './offer-details-gallery';
const TitleWrapper = styled.div `
	width: 100%;
	display: flex;
	justify-content: space-between;

	${down('md')} {
		flex-direction: column-reverse;
	}
`;
const InterestedButton = styled(Button) `
	margin-right: 0.5rem;
	background: ${({ theme }) => theme.color.success};
	border: 1px solid ${({ theme }) => theme.color.success};

	&:hover,
	&:focus {
		background: ${({ theme }) => transparentize(0.1, theme.color.success)};
		border: 1px solid ${({ theme }) => theme.color.success};
	}

	svg {
		width: 16px;
		height: 16px;
	}
`;
const CTAButtonsGroup = styled.div `
	display: flex;
	margin-left: 1rem;

	${down('md')} {
		margin-bottom: 1rem;
		margin-left: 0;
	}
`;
const htmlToReactParser = new Parser();
export function removeTagsAndSpaces(input) {
    return input.replace(/<[^>]*>?/g, '').replace(/\s/g, '');
}
function getTurnUpDate(intl, createdAt, turnUpDate) {
    if (!turnUpDate) {
        return null;
    }
    const isTurnUpInPast = Date.parse(createdAt) > Date.parse(turnUpDate);
    return isTurnUpInPast ? (intl.formatMessage({ id: 'offer_detail.details.asap' })) : (React.createElement(Timestamp, { value: parseISO(turnUpDate) }));
}
function OfferDetails({ offer, showOfferCta }) {
    var _a;
    const intl = useIntl();
    const config = useConfig();
    const { createQueryParams } = useQueryParameters();
    const { formatSalary } = useSalaryFormatter();
    const isBelowSm = useBreakpoint(down('sm'));
    const contractDuration = getOfferContractDuration({
        isIndefinite: (_a = offer.isIndefinite) !== null && _a !== void 0 ? _a : null,
        durationMin: offer.durationMin,
        durationMax: offer.durationMax,
        durationType: offer.durationType,
        intl,
    });
    const turnUpDate = getTurnUpDate(intl, offer.createdAt, offer.turnUpDate);
    const contractTypeName = offer.contractTypeId
        ? config.contractTypes.find(({ value }) => value === offer.contractTypeId).label
        : null;
    const offerTypeName = offer.offerTypeId ? config.offerTypes.find(({ id }) => id === offer.offerTypeId).name : null;
    const showSalaryInfo = offer.isPaid &&
        typeof offer.salaryAmountFrom === 'number' &&
        offer.salaryAmountFrom >= 0 &&
        offer.salaryCurrency &&
        offer.salaryRate;
    const { offerId, workingTime, skillTags, skillTagsRates, driverLicences, languages, required, areSkillsRated, timezone, isHomeOffice, galleries, redirectUrl, } = offer;
    const requiredOthers = required ? removeTagsAndSpaces(required) : null;
    const hasAtLeastOneRequiredField = [skillTags, driverLicences, languages, requiredOthers].some(x => Boolean(x === null || x === void 0 ? void 0 : x.length));
    const hasWorkingTimeSet = workingTime ? Boolean(Object.values(workingTime).filter(value => value).length) : false;
    const shouldShowOtherRequirementsHeading = [skillTags, driverLicences, languages].some(x => Boolean(x === null || x === void 0 ? void 0 : x.length));
    const titleGallery = (galleries === null || galleries === void 0 ? void 0 : galleries.find(gal => gal.isTitleGallery)) || null;
    const nonTitleGalleries = (galleries === null || galleries === void 0 ? void 0 : galleries.filter(gal => Boolean(gal.isTitleGallery) === false)) || null;
    const redirectWithUTMParams = React.useMemo(() => {
        var _a;
        const qp = createQueryParams({
            utm_source: 'pracuj_sk',
            utm_medium: 'job_post',
            utm_campaign: 'pracuj_sk',
        });
        return ((_a = offer.redirectUrl) === null || _a === void 0 ? void 0 : _a.includes('?')) ? `${offer.redirectUrl}&${qp}` : `${offer.redirectUrl}?${qp}`;
    }, [redirectUrl]);
    return (React.createElement(Panel, { className: "m-b-m" },
        React.createElement(TitleWrapper, null,
            offer.title && React.createElement(MainHeadline, null, offer.title),
            offerId && (React.createElement(CTAButtonsGroup, null, offer.isActive && showOfferCta && (React.createElement(React.Fragment, null,
                redirectUrl && (React.createElement("a", { href: redirectWithUTMParams, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(InterestedButton, { size: "middle" },
                        React.createElement(Icon, { type: "send" }),
                        intl.formatMessage({
                            id: 'offer_detail.details.i_am_interested',
                        })))),
                !redirectUrl && (React.createElement(InterestedButton, { size: "middle", onClick: () => {
                        var _a;
                        // @ts-ignore
                        // eslint-disable-next-line no-restricted-globals
                        scrollTo(document.body, (_a = document.getElementById('send-resume')) === null || _a === void 0 ? void 0 : _a.offsetTop);
                    } },
                    React.createElement(Icon, { type: "send" }),
                    intl.formatMessage({
                        id: 'offer_detail.details.i_am_interested',
                    }))),
                React.createElement(FacebookShareButton, { className: "m-r-s" }),
                React.createElement(ToggleSaveOfferButton, { offerId: offerId })))))),
        offer.description && removeTagsAndSpaces(offer.description) && (React.createElement(Section, { isBlank: true },
            React.createElement(TextSection, null, htmlToReactParser.parse(offer.description)))),
        Boolean(titleGallery) && (React.createElement(React.Fragment, null,
            isBelowSm && React.createElement(OfferDetailsGallery, { shouldHideTitle: true, gallery: titleGallery }),
            !isBelowSm && (React.createElement("div", { className: "m-b-m" },
                React.createElement(OfferDetailsTitleGallery, { assets: titleGallery.assets }))))),
        showOfferCta && !offer.isActive && React.createElement(OfferNotActive, null),
        React.createElement(Section, null,
            React.createElement(TextSection, null, offer.deadline && (React.createElement(React.Fragment, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.deadline" })),
                ' ',
                React.createElement(Timestamp, { value: offer.deadline })))),
            turnUpDate && (React.createElement(TextSection, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.turn_up_date" })),
                ' ',
                turnUpDate)),
            contractDuration,
            offer.contractTypeId && (React.createElement(TextSection, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.contract_type" })),
                ' ',
                contractTypeName)),
            !isHomeOffice && offer.formattedAddress && (React.createElement("div", null,
                React.createElement(TextSection, null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "offer_detail.details.address" })),
                    ' ',
                    offer.formattedAddress))),
            isHomeOffice && (React.createElement(TextSection, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.location" })),
                ' ',
                React.createElement(FormattedMessage, { id: "offer_detail.details.remote_location" }))),
            offerTypeName && (React.createElement(TextSection, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.offer_type_name" })),
                ' ',
                offerTypeName))),
        workingTime && hasWorkingTimeSet && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.working_time_information" })),
            React.createElement("div", { className: "flex-col m-t-m" },
                (workingTime.timeFrom || workingTime.timeTo) && (React.createElement(TextSection, { flex: true },
                    React.createElement("strong", { className: "m-r-xs" },
                        React.createElement(FormattedMessage, { id: "offer_detail.details.working_time" })),
                    ' ',
                    React.createElement(WorkingTimeRangeHours, { timeFrom: workingTime.timeFrom, timeTo: workingTime.timeTo, timezone: timezone, isRemote: isHomeOffice }))),
                workingTime.timeGrant && (React.createElement(TextSection, { flex: true },
                    React.createElement("strong", { className: "m-r-xs" },
                        React.createElement(FormattedMessage, { id: "offer_detail.details.time_grant" })),
                    ' ',
                    React.createElement("span", { className: "flex-row" },
                        workingTime.timeGrant,
                        React.createElement(FormattedMessage, { id: "offer_detail.details.hours_day" })))),
                workingTime.timeType && (React.createElement(TextSection, null,
                    React.createElement("strong", { className: "m-r-xs" },
                        React.createElement(FormattedMessage, { id: "offer_detail.details.working_time_type" })),
                    ' ',
                    translateWorkingTimeType({ type: workingTime.timeType, intl }))),
                workingTime.additionalInformation && (React.createElement(TextSection, null,
                    React.createElement("strong", { className: "m-r-xs" },
                        React.createElement(FormattedMessage, { id: "offer_detail.details.additional_information" })),
                    ' ',
                    workingTime.additionalInformation))))),
        offer.jobAreas && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.focus" })),
            React.createElement("div", null,
                React.createElement(TagsWrapper, null, offer.jobAreas.map(jobArea => (React.createElement(TagWrapper, { key: jobArea.id },
                    React.createElement(FormattedMessage, { id: jobArea.translationKey || jobArea.translation_key })))))))),
        hasAtLeastOneRequiredField && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.candidate_requirements" })),
            Boolean(skillTags === null || skillTags === void 0 ? void 0 : skillTags.length) && (React.createElement(SkillTagsWrapper, null,
                React.createElement(OfferDetailsSkillTag, { skillTags: skillTags, skillTagsRates: skillTagsRates, areSkillsRated: 
                    // we need to check if this value is false from form or null and then fallback on skillTagsRates.length
                    areSkillsRated === false
                        ? areSkillsRated
                        : areSkillsRated || Boolean(skillTagsRates === null || skillTagsRates === void 0 ? void 0 : skillTagsRates.length) }))),
            Boolean(languages === null || languages === void 0 ? void 0 : languages.length) && (React.createElement(React.Fragment, null,
                React.createElement(HeadlineSubtitle, null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.required_languages" })),
                React.createElement(RequiredLanguages, { languages: languages }))),
            Boolean(driverLicences === null || driverLicences === void 0 ? void 0 : driverLicences.length) && (React.createElement(React.Fragment, null,
                React.createElement(HeadlineSubtitle, null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.required_driver_licences" })),
                React.createElement(RequiredDriverLicences, { driverLicences: driverLicences }))),
            Boolean(requiredOthers === null || requiredOthers === void 0 ? void 0 : requiredOthers.length) && (React.createElement(React.Fragment, null,
                shouldShowOtherRequirementsHeading && (React.createElement(HeadlineSubtitle, null,
                    React.createElement(FormattedMessage, { id: "offer_detail.details.other_requirements" }))),
                React.createElement(TextSection, null, htmlToReactParser.parse(required)))))),
        offer.niceToHave && removeTagsAndSpaces(offer.niceToHave) && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.advantage_for_candidate" })),
            React.createElement(TextSection, null, htmlToReactParser.parse(offer.niceToHave)))),
        offer.benefits && removeTagsAndSpaces(offer.benefits) && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.benefits_offer" })),
            React.createElement(TextSection, null, htmlToReactParser.parse(offer.benefits)))),
        showSalaryInfo && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.reward_amount" })),
            React.createElement(TextSection, null,
                formatSalary({
                    rate: offer.salaryRate,
                    amountFrom: offer.salaryAmountFrom,
                    amountTo: offer.salaryAmountTo,
                    currency: offer.salaryCurrency,
                    salaryType: offer.salaryType,
                }),
                React.createElement("br", null),
                offer.salaryDescription && removeTagsAndSpaces(offer.salaryDescription) && (React.createElement(TextSection, null, htmlToReactParser.parse(offer.salaryDescription)))))),
        Boolean(nonTitleGalleries === null || nonTitleGalleries === void 0 ? void 0 : nonTitleGalleries.length) && (React.createElement(Section, null,
            React.createElement(Headline, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.galleries" })),
            nonTitleGalleries.map(gallery => (React.createElement(OfferDetailsGallery, { key: gallery.uuid, gallery: gallery })))))));
}
export { OfferDetails };
