import React from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { StyledAssetUpload } from './AssetUpload.style';
export function AssetUpload({ enqueueAssetFileForUpload }) {
    return (React.createElement(StyledAssetUpload, { listType: "picture-card", showUploadList: false, customRequest: options => enqueueAssetFileForUpload(options.file), multiple: true },
        React.createElement("div", null,
            React.createElement(FileAddOutlined, { style: { fontSize: 32 } }),
            React.createElement("div", { className: "m-t-s" },
                React.createElement(FormattedMessage, { id: "assets.buttons.upload.long" })))));
}
