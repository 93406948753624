import React from 'react';
import { CompanyPremiumProfileContentType } from '@src/types';
import { Head } from '@src/components/elements';
import { useParams } from 'react-router';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import striptags from 'striptags';
import { CompanyProfileHeader } from './company-profile-header';
import { CompanyProfileMainContent } from './company-profile-main-content';
import { CompanyProfileOffersContent } from './company-profile-offers-content/CompanyProfileOffersContent';
import { CompanyProfileMainPage } from './CompanyProfile.style';
const CompanyProfile = ({ company }) => {
    const params = useParams();
    const contentType = Object.values(CompanyPremiumProfileContentType).includes(params.content)
        ? params.content
        : CompanyPremiumProfileContentType.profile;
    const companyImageUrl = getCdnImageUrl({
        shouldOptimizeQuality: false,
        file: `web/company/${company.titleImageUrl}`,
        transformation: `/w_1200,h_630,c_fill,g_faces,q_auto:best`,
    });
    const metaOfferDescription = striptags(company.description);
    return (React.createElement(CompanyProfileMainPage, null,
        React.createElement(Head, { title: company.name, description: metaOfferDescription, imageUrl: company.titleImageUrl ? companyImageUrl : undefined }),
        React.createElement(CompanyProfileHeader, { contentTypeToShow: contentType, company: company }),
        contentType === CompanyPremiumProfileContentType.profile && (React.createElement(CompanyProfileMainContent, { company: company })),
        contentType === CompanyPremiumProfileContentType.offers && (React.createElement(CompanyProfileOffersContent, { company: company }))));
};
export { CompanyProfile };
