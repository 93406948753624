import React from 'react';
import { notification } from 'antd';
import * as Api from '@src/api';
import { useIntl } from 'react-intl';
import { useAccount } from './account-context';
const NotificationContext = React.createContext({
    notifications: [],
    getNotifications: async () => { },
    loadMoreNotifications: async () => { },
    unseenNotificationsCount: 0,
    newNotificationsCount: 0,
    pageStats: {},
});
export const useNotificationContextProvider = () => {
    const intl = useIntl();
    const account = useAccount();
    const [notifications, setNotifications] = React.useState([]);
    const unseenNotificationsCount = React.useMemo(() => notifications.filter(n => !n.seen_at).length, [notifications]);
    const [newNotificationsCount, setNewNotificationsCount] = React.useState(0);
    const [pageStats, setPageStats] = React.useState({
        page: 0,
        total: 0,
        pageCount: 0,
    });
    const getNotifications = async () => {
        try {
            if (!account) {
                return;
            }
            const freshNotificationsWithPageStats = await Api.Account.getMyNotifications();
            setNotifications(freshNotificationsWithPageStats.notifications);
            setNewNotificationsCount(freshNotificationsWithPageStats.notifications.length);
            setPageStats({
                page: freshNotificationsWithPageStats.pagination.page,
                total: freshNotificationsWithPageStats.pagination.rowCount,
                pageCount: freshNotificationsWithPageStats.pagination.pageCount,
            });
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
    };
    const loadMoreNotifications = async () => {
        try {
            const oldNotificationsWithPageStats = await Api.Account.getMyNotifications(pageStats.page + 1);
            setNotifications([...notifications, ...oldNotificationsWithPageStats.notifications]);
            setNewNotificationsCount(oldNotificationsWithPageStats.notifications.length);
            setPageStats({
                page: oldNotificationsWithPageStats.pagination.page,
                total: oldNotificationsWithPageStats.pagination.rowCount,
                pageCount: oldNotificationsWithPageStats.pagination.pageCount,
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    return {
        NotificationContextProvider: NotificationContext.Provider,
        value: {
            notifications,
            unseenNotificationsCount,
            newNotificationsCount,
            pageStats,
            getNotifications,
            loadMoreNotifications,
        },
    };
};
export const useNotificationContextValue = () => {
    const context = React.useContext(NotificationContext);
    return context;
};
