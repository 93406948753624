import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { Button } from '@src/components/elements';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from '@src/api/company';
import { roundToClosestValue } from '@src/helpers/roundToClosestValue';
import { Box, BoxBottom, BoxHead, BoxMain, ButtonText, FreeCredit, FreeCredit1, FreeCredit2, FreeCredit3, FreeCreditFeatures, Introduction, MoreDetails, } from './BoxFree.styles';
const BoxFree = () => {
    const theme = useTheme();
    const { data: companiesCount } = useAppQuery(Queries.getCompaniesCount, () => Api.getAllCompaniesCount());
    return (React.createElement(Box, null,
        React.createElement(BoxHead, null,
            React.createElement(FormattedMessage, { id: "companies.free.headline", values: {
                    strong: chunks => React.createElement("strong", null, chunks),
                } })),
        React.createElement(BoxMain, null,
            React.createElement(Introduction, null,
                React.createElement(FormattedMessage, { id: "companies.free.subheadline", values: {
                        count: React.createElement("strong", null,
                            roundToClosestValue(companiesCount, 50),
                            "+"),
                        span: chunks => React.createElement("strong", null, chunks),
                    } })),
            React.createElement(FreeCredit, null,
                React.createElement(FreeCredit1, null,
                    React.createElement(FormattedMessage, { id: "companies.free.you_get_free" }),
                    ":"),
                React.createElement(FreeCredit2, null,
                    React.createElement(FormattedMessage, { values: { count: 1 }, id: "general.credits.pluralized" })),
                React.createElement(Button, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: "/registracia" },
                    React.createElement(ButtonText, null,
                        React.createElement(FormattedMessage, { id: "companies.free.create_account_free" }))),
                React.createElement(FreeCredit3, null,
                    React.createElement(FormattedMessage, { id: "companies.free.no_hidden_fees" })))),
        React.createElement(BoxBottom, null,
            React.createElement(FreeCreditFeatures, null,
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_1" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_2" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_3" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_4" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_5" })))),
            React.createElement(MoreDetails, { to: "features", smooth: true },
                React.createElement(FormattedMessage, { id: "companies.free.more_details" })))));
};
export { BoxFree };
