import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OAuthType } from '@src/types/auth';
function FacebookLoginButton({ type }) {
    React.useEffect(() => {
        var _a;
        if (typeof document !== 'undefined' && typeof FB !== 'undefined') {
            (_a = FB === null || FB === void 0 ? void 0 : FB.XFBML) === null || _a === void 0 ? void 0 : _a.parse();
        }
    }, []);
    const text = type === OAuthType.registration ? (React.createElement(FormattedMessage, { id: "facebook.registration", defaultMessage: "Registrova\u0165 cez Facebook" })) : (React.createElement(FormattedMessage, { id: "facebook.login", defaultMessage: "Prihl\u00E1si\u0165 cez Facebook" }));
    return (React.createElement("div", { className: "fb-login-button", "data-size": "medium", "data-scope": "public_profile, email", "data-button-type": "login_with", "data-layout": "rounded", "data-auto-logout-link": "false", "data-use-continue-as": "false", "data-width": "" }, text));
}
export { FacebookLoginButton };
