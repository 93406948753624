import { SearchFilterOptionGroup } from '@src/types';
import React from 'react';
import { SpecificSearchSelectWrapper, } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelectWrapper';
import { useOffersFilterContextValue } from '@src/components/pages/offers/offer-filter-context';
import { SearchFilterWrapper } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelect.style';
import { SearchOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { tagOptions } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelect';
import { LocationFilter } from './filters/LocationFilter';
import { SelectWrapper, SearchIcon, SearchBarItem, ButtonWrapper, Search, SearchBar, SearchBarItemTitle, AlertMaxTagCount, ForcedPlaceholder, } from './OfferSearchFilterWrapper.style';
const OfferSearchFilterWrapper = () => {
    var _a;
    const intl = useIntl();
    const [showLocationPlaceholder, setShowLocationPlaceholder] = React.useState(true);
    const { setSearchCompaniesSelected, setSearchKeywordsSelected, searchCompaniesSelected, searchKeywordsSelected, offersFiltersSSR, areOffersLoading, areFiltersLoading, } = useOffersFilterContextValue();
    const isTagCountInLimit = searchCompaniesSelected.length + searchKeywordsSelected.length < tagOptions.maxTagCount;
    const handleSelect = ({ group, label, value }) => {
        if (group === SearchFilterOptionGroup.company) {
            const newContextValue = { id: Number(value), name: label };
            const isValueAlreadySelected = searchCompaniesSelected.find(({ id, name }) => newContextValue.id === id && newContextValue.name === name);
            if (!isValueAlreadySelected) {
                setSearchCompaniesSelected([...searchCompaniesSelected, newContextValue]);
            }
        }
        else if (group === SearchFilterOptionGroup.keyword) {
            const isValueAlreadySelected = searchKeywordsSelected.find(keyword => keyword === label);
            if (!isValueAlreadySelected) {
                setSearchKeywordsSelected([...searchKeywordsSelected, label]);
            }
        }
    };
    return (React.createElement(SearchFilterWrapper, { isTagCountInLimit: isTagCountInLimit },
        React.createElement(SearchBar, null,
            React.createElement(SearchBarItem, null,
                React.createElement(SearchBarItemTitle, null,
                    React.createElement(FormattedMessage, { id: "global.specific_search.title" })),
                React.createElement(SelectWrapper, null,
                    React.createElement(SearchIcon, null),
                    React.createElement(SpecificSearchSelectWrapper, { selectedValues: [], isTagCountInLimit: isTagCountInLimit, handleSelect: handleSelect })),
                !isTagCountInLimit && (React.createElement(AlertMaxTagCount, { message: intl.formatMessage({ id: 'offers.max_tag_count_alert' }), type: "warning", showIcon: true }))),
            React.createElement(SearchBarItem, { hasNegativeMargin: true },
                React.createElement(SearchBarItemTitle, null,
                    React.createElement(FormattedMessage, { id: "global.location.title" })),
                React.createElement(SelectWrapper, null,
                    React.createElement(LocationFilter, { locationFilterItems: (_a = offersFiltersSSR === null || offersFiltersSSR === void 0 ? void 0 : offersFiltersSSR.location) !== null && _a !== void 0 ? _a : {}, isLoading: areFiltersLoading || areOffersLoading, setShowLocationPlaceholder: setShowLocationPlaceholder }),
                    showLocationPlaceholder && (React.createElement(ForcedPlaceholder, { className: "ant-select-selection-placeholder" },
                        React.createElement(FormattedMessage, { id: "global.location.placeholder" })))))),
        React.createElement(Search, null,
            React.createElement(ButtonWrapper, { type: "success", onClick: () => setSearchCompaniesSelected([...searchCompaniesSelected]) },
                React.createElement(SearchOutlined, { className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "search" })))));
};
export { OfferSearchFilterWrapper };
