import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Col, Row, notification } from 'antd';
import { Button, Panel, Alert, Link } from '@src/components/elements';
import actions from '@src/actions';
import { CouponResponses, ProductId } from '@src/types';
import { useConfig } from '@src/hooks/useConfig';
import * as Api from '@src/api';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import { useAdminContextValue } from '@src/components/pages/admin/admin-context';
import { getHasCompanyActivePremiumCompanyProfileSubscription } from '@src/helpers/premium-subscription';
import { useQueryClient } from 'react-query';
import { AdminOrderPayments } from './AdminOrderPayments';
import { AdminOrderSummary } from './AdminOrderSummary';
import { AdminOrderBillingProfile } from './AdminOrderBillingProfile';
import { AdminOrderProducts } from './AdminOrderProducts';
import { AdminOrderCoupon } from './AdminOrderCoupon';
import { AdminOrderErrorAlert } from './AdminOrderErrorAlert';
const AdminOrder = ({ productId = ProductId.offerCredits, amount = 1, offerId, onOrderSuccess, setWasCouponApplied, companyOutOfContext, }) => {
    var _a, _b, _c;
    const [couponInputValue, setCouponInputValue] = React.useState('');
    const [appliedCouponCode, setAppliedCouponCode] = React.useState(null);
    const [isCouponLoading, setIsCouponLoading] = React.useState(false);
    const [isOrderLoading, setIsOrderLoading] = React.useState(false);
    const [discount, setDiscount] = React.useState(0);
    const dispatch = useDispatch();
    const config = useConfig();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { companyInfo, setIsCompanyPremiumActivatedDialogOpen } = useAdminContextValue();
    const { companyPaymentSubscriptions, payment_method_token: companyPaymentMethodToken } = companyOutOfContext || companyInfo;
    const [selectedBillingProfile, setSelectedBillingProfile] = React.useState();
    const [billingProfiles, setBillingProfiles] = React.useState([]);
    const hasAbroadBillingProfile = (selectedBillingProfile === null || selectedBillingProfile === void 0 ? void 0 : selectedBillingProfile.country) !== config.countryCode;
    const isAllowedToMakeThePurchase = !hasAbroadBillingProfile || (hasAbroadBillingProfile && (selectedBillingProfile === null || selectedBillingProfile === void 0 ? void 0 : selectedBillingProfile.tinVat));
    const hasActiveSubscription = getHasCompanyActivePremiumCompanyProfileSubscription(companyPaymentSubscriptions);
    const orderParams = React.useMemo(() => ({
        productAmounts: [
            {
                productId,
                amount,
            },
        ],
        appliedCouponCode,
        hasActiveSubscription,
        offerId,
    }), [productId, amount, appliedCouponCode]);
    const { data: orderContent, error, isFetching: isOrderContentFetching, } = useAppQuery([Queries.getOrder, orderParams], () => Api.Admin.getOrderContent({ orderParams }), {
        keepPreviousData: true,
    });
    const getBillingProfiles = async () => {
        try {
            const { allBillingProfilesById } = await Api.CompanyBillingProfile.getBillingProfiles();
            setBillingProfiles(allBillingProfilesById);
            const defaultBillingProfile = allBillingProfilesById.find(profile => profile.isDefault);
            setSelectedBillingProfile(defaultBillingProfile);
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };
    const validateCoupon = async (couponCode = couponInputValue) => {
        var _a, _b;
        try {
            setIsCouponLoading(true);
            const productIds = orderContent ? orderContent.products.map(({ id }) => id) : [];
            const coupon = await Api.Admin.validateCoupon(couponCode, productIds, offerId);
            setWasCouponApplied === null || setWasCouponApplied === void 0 ? void 0 : setWasCouponApplied(true);
            if (orderContent && (coupon.freeCreditsCount || coupon.freeMonthsPeriod || coupon.freeOfferPromotion)) {
                await Api.Admin.applyCoupon(couponCode, productIds, offerId);
                if (coupon.productId === ProductId.offerCredits) {
                    history.pushLocalized('/admin');
                    dispatch(actions.adminToggleCreditsReplenishedDialog(true));
                }
                if (coupon.productId === ProductId.offerPromotion) {
                    onOrderSuccess === null || onOrderSuccess === void 0 ? void 0 : onOrderSuccess();
                    history.pushLocalized('/admin');
                    notification.success({
                        message: intl.formatMessage({ id: 'admin.order.coupon.offer_promotion_activated' }),
                    });
                }
                if (coupon.productId === ProductId.companyPremium) {
                    onOrderSuccess === null || onOrderSuccess === void 0 ? void 0 : onOrderSuccess();
                    history.pushLocalized('/admin');
                    setIsCompanyPremiumActivatedDialogOpen(true);
                    queryClient.invalidateQueries(Queries.getCompany);
                }
                return;
            }
            setDiscount(coupon.discountPercentage);
            setAppliedCouponCode(couponCode);
            setCouponInputValue('');
            notification.success({
                message: intl.formatMessage({ id: 'admin.order.coupon.applied' }),
            });
        }
        catch (err) {
            const errorCode = (_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.code;
            switch (errorCode) {
                case CouponResponses.ALREADY_USED:
                    notification.error({
                        message: intl.formatMessage({ id: 'admin.order.coupon.already_used' }),
                    });
                    break;
                case CouponResponses.EXPIRED:
                    notification.error({
                        message: intl.formatMessage({ id: 'admin.order.coupon.expired' }),
                    });
                    break;
                case CouponResponses.NOT_APPLICABLE:
                    notification.error({
                        message: intl.formatMessage({ id: 'admin.order.coupon.not_applicable' }),
                    });
                    break;
                default:
                    notification.error({
                        message: intl.formatMessage({ id: 'admin.order.coupon.invalid_code' }),
                    });
            }
        }
        finally {
            setIsCouponLoading(false);
        }
    };
    const init = async () => {
        dispatch(actions.myCompanyInfoInitStart());
        getBillingProfiles();
    };
    React.useEffect(() => {
        const { discountCode } = history.getQuery();
        if (discountCode) {
            setCouponInputValue(discountCode);
            validateCoupon(discountCode);
        }
    }, [location.search]);
    React.useEffect(() => {
        init();
    }, []);
    const errorCode = ((_c = (_b = (_a = error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.code) || null;
    return (React.createElement(Panel, null,
        errorCode && React.createElement(AdminOrderErrorAlert, { code: errorCode }),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { className: "flex-col", xs: 24, md: 14 },
                React.createElement(AdminOrderProducts, { orderContent: orderContent }),
                React.createElement(AdminOrderCoupon, { isApplyDisabled: !orderContent || isOrderLoading || isOrderContentFetching, appliedCouponCode: appliedCouponCode, isCouponLoading: isCouponLoading, setAppliedCouponCode: setAppliedCouponCode, setCouponInputValue: setCouponInputValue, validateCoupon: validateCoupon, couponInputValue: couponInputValue, setDiscount: setDiscount })),
            React.createElement(Col, { className: "flex-1", xs: 24, md: 10 },
                React.createElement(AdminOrderBillingProfile, { billingProfiles: billingProfiles, selectedBillingProfile: selectedBillingProfile, setSelectedBillingProfile: setSelectedBillingProfile })),
            React.createElement(Col, { xs: 24 },
                React.createElement(AdminOrderSummary, { orderContent: orderContent, discount: discount, hasAbroadBillingProfile: hasAbroadBillingProfile, loading: isOrderContentFetching }))),
        isAllowedToMakeThePurchase && discount !== 100 && (React.createElement(AdminOrderPayments, { orderContent: orderContent, orderParams: orderParams, selectedBillingProfile: selectedBillingProfile, isOrderLoading: isOrderLoading || isOrderContentFetching, appliedCouponCode: appliedCouponCode, setIsOrderLoading: setIsOrderLoading, offerId: offerId, onOrderSuccess: onOrderSuccess, companyPaymentMethodToken: companyPaymentMethodToken })),
        !isAllowedToMakeThePurchase && (React.createElement(Panel, { className: "m-t-m" },
            React.createElement("div", { className: "flex-col flex-center" },
                React.createElement(Alert, { className: "m-b-m", type: "warning", message: React.createElement("div", { className: "flex-col" },
                        React.createElement(FormattedMessage, { id: "admin.payments.missing_tin_vat" }),
                        billingProfiles.length > 1 && (React.createElement(FormattedMessage, { id: "admin.payments.change_billing_profile" }))) }),
                React.createElement(Link, { to: `/admin/platby/fakturacny-profil/${selectedBillingProfile === null || selectedBillingProfile === void 0 ? void 0 : selectedBillingProfile.id}` },
                    React.createElement(Button, { size: "middle" },
                        React.createElement(FormattedMessage, { id: "admin.payments.go_to_billing_profile" }))))))));
};
export { AdminOrder };
