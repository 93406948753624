import { client as axios } from './clients/axios';
export async function getAccount() {
    return (await axios.get('/0/auth-check', {
        validateStatus: status => status >= 200 && status <= 309,
    })).data;
}
export async function putAccount(account) {
    return (await axios.put('/0/account', account)).data;
}
export async function getMyResumes() {
    return (await axios.get('/0/account-resume')).data;
}
export async function getAccountExistsByEmail(email) {
    try {
        const response = await axios.get('/0/account', { params: { email } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getMyNotifications(page) {
    try {
        const response = await axios.get('/0/account/notifications', {
            params: {
                page,
            },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function patchNotification(uuid, data) {
    try {
        const response = await axios.patch(`/0/account/notifications/${uuid}`, data);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function watchAllNotifications() {
    try {
        const response = await axios.put('/0/account/notifications/watch-all');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getMyNotificationTypeSettings() {
    const response = await axios.get('/0/account/notifications/settings');
    return response.data;
}
export async function updateMyNotificationTypeSettings(notificationTypesSettings) {
    if (!notificationTypesSettings) {
        return;
    }
    await axios.put('/0/account/notifications/settings', notificationTypesSettings);
}
export async function markOnboardingAsCompleted() {
    await axios.patch('/0/account/onboarding');
}
export async function resetPassword(token, password) {
    const response = await axios.put(`/0/reset-password?token=${token}`, { password });
    return response.data;
}
export async function resendVerificationEmail() {
    return (await axios.post('/0/resend-verification-email', {})).data;
}
export async function verifyAccount({ verificationToken }) {
    return (await axios.post(`/0/verification?token=${verificationToken}`, {})).data;
}
export async function disableProfileProgress(accountId) {
    return (await axios.patch('/0/account/profile-progress', accountId)).data;
}
export async function getAllAccountsCount() {
    return (await axios.get('/0/account/count-all')).data;
}
