// Applying auto quality adjustment transformation will achieve smaller file size
// Details: https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
// Applying any transformation results in using other default optimizations
// Details: https://cloudinary.com/documentation/image_optimization#default_optimizations
function getTransformationUrlPart(shouldOptimizeQuality, originalTransformation) {
    if (!originalTransformation && !shouldOptimizeQuality) {
        return '';
    }
    if (!originalTransformation && shouldOptimizeQuality) {
        return '/q_auto';
    }
    if (originalTransformation && !shouldOptimizeQuality) {
        return originalTransformation;
    }
    return `/${originalTransformation},q_auto`;
}
export function getCdnImageUrl({ transformation, file, shouldOptimizeQuality = true, shouldIncludeEnvironmentPart = true, addWatermarkedLogo = false, }) {
    const baseUrl = 'https://res.cloudinary.com';
    const cloudnamePart = `/${process.env.CLOUDINARY_NAME}`;
    const transformationPart = getTransformationUrlPart(shouldOptimizeQuality, transformation);
    const environmentPart = shouldIncludeEnvironmentPart ? `/${process.env.ENVIRONMENT}` : '';
    // bump this if you need to invalidate cached assets
    const versionPart = '/v1636215802';
    // replace ':' -> '/' due to dummy images
    const filePart = file ? `/${file.replace(':', '/')}` : '';
    const watermarkedLogoLayerPart = addWatermarkedLogo
        ? '/l_pracuj_logo_watermark_wqn8zn/fl_layer_apply,g_south_east,x_20,y_20,fl_relative,w_0.08'
        : '';
    // return `${baseUrl}${transformationResult}${version}${environment}${fileResult}`
    return `${baseUrl}${cloudnamePart}/image/upload${transformationPart}${watermarkedLogoLayerPart}${versionPart}${environmentPart}${filePart}`;
}
