export var OfferTypeId;
(function (OfferTypeId) {
    OfferTypeId[OfferTypeId["internship"] = 1] = "internship";
    OfferTypeId[OfferTypeId["permanent"] = 2] = "permanent";
    OfferTypeId[OfferTypeId["volunteering"] = 3] = "volunteering";
    OfferTypeId[OfferTypeId["temporaryJob"] = 4] = "temporaryJob";
    OfferTypeId[OfferTypeId["workAndTravel"] = 5] = "workAndTravel";
    OfferTypeId[OfferTypeId["contractor"] = 6] = "contractor";
})(OfferTypeId || (OfferTypeId = {}));
const offerTypeMap = {
    sk: {
        staz: OfferTypeId.internship,
        praca: OfferTypeId.permanent,
        dobrovolnictvo: OfferTypeId.volunteering,
        brigada: OfferTypeId.temporaryJob,
        'work-and-travel': OfferTypeId.workAndTravel,
        zivnost: OfferTypeId.contractor,
    },
    en: {
        internship: OfferTypeId.internship,
        permanent: OfferTypeId.permanent,
        volunteering: OfferTypeId.volunteering,
        'temporary-job': OfferTypeId.temporaryJob,
        'work-and-travel': OfferTypeId.workAndTravel,
        contractor: OfferTypeId.contractor,
    },
    uk: {
        internship: OfferTypeId.internship,
        permanent: OfferTypeId.permanent,
        volunteering: OfferTypeId.volunteering,
        'temporary-job': OfferTypeId.temporaryJob,
        'work-and-travel': OfferTypeId.workAndTravel,
        contractor: OfferTypeId.contractor,
    },
};
const WorkingTimeTypeMap = {
    sk: {
        flexible: 'Flexibilná',
        fix: 'Fixná',
    },
    en: {
        flexible: 'Flexible',
        fix: 'Fix',
    },
    uk: {
        flexible: 'Flexible',
        fix: 'Fix',
    },
};
export var SendType;
(function (SendType) {
    SendType["rejected"] = "rejected";
    SendType["rejected_and_accepted"] = "rejected_and_accepted";
})(SendType || (SendType = {}));
var OfferContractDurationType;
(function (OfferContractDurationType) {
    OfferContractDurationType["day"] = "day";
    OfferContractDurationType["week"] = "week";
    OfferContractDurationType["month"] = "month";
    OfferContractDurationType["year"] = "year";
})(OfferContractDurationType || (OfferContractDurationType = {}));
var EOfferType;
(function (EOfferType) {
    EOfferType[EOfferType["internship"] = 1] = "internship";
    EOfferType[EOfferType["permanent"] = 2] = "permanent";
    EOfferType[EOfferType["volunteering"] = 3] = "volunteering";
    EOfferType[EOfferType["temporary_job"] = 4] = "temporary_job";
    EOfferType[EOfferType["work_and_travel"] = 5] = "work_and_travel";
    EOfferType[EOfferType["contractor"] = 6] = "contractor";
})(EOfferType || (EOfferType = {}));
var EContractType;
(function (EContractType) {
    EContractType[EContractType["full_time"] = 1] = "full_time";
    EContractType[EContractType["part_time"] = 2] = "part_time";
})(EContractType || (EContractType = {}));
var HourBase;
(function (HourBase) {
    HourBase[HourBase["full_time"] = 160] = "full_time";
    HourBase[HourBase["part_time"] = 80] = "part_time";
})(HourBase || (HourBase = {}));
const hourBaseByOfferTypeMap = {
    [EOfferType.permanent]: {
        [EContractType.full_time]: HourBase.full_time,
        [EContractType.part_time]: HourBase.part_time,
    },
    [EOfferType.internship]: HourBase.part_time,
    [EOfferType.temporary_job]: HourBase.part_time,
    [EOfferType.contractor]: HourBase.full_time,
    [EOfferType.work_and_travel]: HourBase.full_time,
};
var SalaryRate;
(function (SalaryRate) {
    SalaryRate["hourly"] = "hourly";
    SalaryRate["monthly"] = "monthly";
    SalaryRate["once"] = "once";
})(SalaryRate || (SalaryRate = {}));
export var SalaryCurrency;
(function (SalaryCurrency) {
    SalaryCurrency["euro"] = "\u20AC";
})(SalaryCurrency || (SalaryCurrency = {}));
export var EOfferStep;
(function (EOfferStep) {
    EOfferStep[EOfferStep["PAYMENT_TYPE"] = 0] = "PAYMENT_TYPE";
    EOfferStep[EOfferStep["BASIC_INFO"] = 1] = "BASIC_INFO";
    EOfferStep[EOfferStep["ASSETS"] = 2] = "ASSETS";
    EOfferStep[EOfferStep["PREVIEW"] = 3] = "PREVIEW";
})(EOfferStep || (EOfferStep = {}));
export var OfferPaymentType;
(function (OfferPaymentType) {
    OfferPaymentType["PAY_WITH_OFFER_CREDITS"] = "PAY_WITH_OFFER_CREDITS";
    OfferPaymentType["PAY_PER_APPLICATION"] = "PAY_PER_APPLICATION";
})(OfferPaymentType || (OfferPaymentType = {}));
export var OfferAction;
(function (OfferAction) {
    OfferAction["edit"] = "edit";
    OfferAction["create"] = "create";
})(OfferAction || (OfferAction = {}));
export var SalaryType;
(function (SalaryType) {
    SalaryType["net"] = "net";
    SalaryType["gross"] = "gross";
})(SalaryType || (SalaryType = {}));
export var EOfferPaymentTypeFormField;
(function (EOfferPaymentTypeFormField) {
    EOfferPaymentTypeFormField["paymentType"] = "paymentType";
    EOfferPaymentTypeFormField["billingProfileId"] = "billingProfileId";
})(EOfferPaymentTypeFormField || (EOfferPaymentTypeFormField = {}));
export var EOfferBasicInfoFormField;
(function (EOfferBasicInfoFormField) {
    EOfferBasicInfoFormField["title"] = "title";
    EOfferBasicInfoFormField["redirectUrl"] = "redirectUrl";
    EOfferBasicInfoFormField["offerTypeId"] = "offerTypeId";
    EOfferBasicInfoFormField["homeOffice"] = "homeOffice";
    EOfferBasicInfoFormField["jobArea"] = "jobArea";
    EOfferBasicInfoFormField["description"] = "description";
    EOfferBasicInfoFormField["required"] = "required";
    EOfferBasicInfoFormField["niceToHave"] = "niceToHave";
    EOfferBasicInfoFormField["deadline"] = "deadline";
    EOfferBasicInfoFormField["isPaid"] = "isPaid";
    EOfferBasicInfoFormField["benefits"] = "benefits";
    EOfferBasicInfoFormField["durationMin"] = "durationMin";
    EOfferBasicInfoFormField["durationMax"] = "durationMax";
    EOfferBasicInfoFormField["durationType"] = "durationType";
    EOfferBasicInfoFormField["contractTypeId"] = "contractTypeId";
    EOfferBasicInfoFormField["salaryRate"] = "salaryRate";
    EOfferBasicInfoFormField["salaryAmountFrom"] = "salaryAmountFrom";
    EOfferBasicInfoFormField["salaryAmountTo"] = "salaryAmountTo";
    EOfferBasicInfoFormField["salaryCurrency"] = "salaryCurrency";
    EOfferBasicInfoFormField["salaryDescription"] = "salaryDescription";
    EOfferBasicInfoFormField["salaryType"] = "salaryType";
    EOfferBasicInfoFormField["resumeRequired"] = "resumeRequired";
    EOfferBasicInfoFormField["motivationalRequired"] = "motivationalRequired";
    EOfferBasicInfoFormField["address"] = "address";
    EOfferBasicInfoFormField["contact_email"] = "contact_email";
    EOfferBasicInfoFormField["turnUpDate"] = "turnUpDate";
    EOfferBasicInfoFormField["turnUpDateAsap"] = "turnUpDateAsap";
    EOfferBasicInfoFormField["languages"] = "languages";
    EOfferBasicInfoFormField["languageCode"] = "languageCode";
    EOfferBasicInfoFormField["languageLevel"] = "languageLevel";
    EOfferBasicInfoFormField["driverLicences"] = "driverLicences";
    EOfferBasicInfoFormField["driverLicence"] = "driverLicence";
    EOfferBasicInfoFormField["mileage"] = "mileage";
    EOfferBasicInfoFormField["workingTimeFrom"] = "workingTimeFrom";
    EOfferBasicInfoFormField["workingTimeTo"] = "workingTimeTo";
    EOfferBasicInfoFormField["workingTimeHours"] = "workingTimeHours";
    EOfferBasicInfoFormField["workingTimeType"] = "workingTimeType";
    EOfferBasicInfoFormField["workingTimeInfo"] = "workingTimeInfo";
    EOfferBasicInfoFormField["skillTags"] = "skillTags";
    EOfferBasicInfoFormField["rateSkillTagsSwitch"] = "rateSkillTagsSwitch";
    EOfferBasicInfoFormField["skillTagsRates"] = "skillTagsRates";
    EOfferBasicInfoFormField["timezone"] = "timezone";
    EOfferBasicInfoFormField["isIndefinite"] = "isIndefinite";
})(EOfferBasicInfoFormField || (EOfferBasicInfoFormField = {}));
export var EOfferAssetsFormField;
(function (EOfferAssetsFormField) {
    EOfferAssetsFormField["galleries"] = "galleries";
})(EOfferAssetsFormField || (EOfferAssetsFormField = {}));
export var EOfferGalleryKey;
(function (EOfferGalleryKey) {
    EOfferGalleryKey["title"] = "title";
    EOfferGalleryKey["isTitleGallery"] = "isTitleGallery";
    EOfferGalleryKey["uuid"] = "uuid";
    EOfferGalleryKey["assets"] = "assets";
})(EOfferGalleryKey || (EOfferGalleryKey = {}));
export { offerTypeMap, EOfferType, EContractType, SalaryRate, HourBase, OfferContractDurationType, hourBaseByOfferTypeMap, WorkingTimeTypeMap, };
export var SearchFilterOptionGroup;
(function (SearchFilterOptionGroup) {
    SearchFilterOptionGroup["company"] = "company";
    SearchFilterOptionGroup["skillTag"] = "skillTag";
    SearchFilterOptionGroup["keyword"] = "keyword";
})(SearchFilterOptionGroup || (SearchFilterOptionGroup = {}));
export var WorkingTimeType;
(function (WorkingTimeType) {
    WorkingTimeType["FIX"] = "fix";
    WorkingTimeType["FLEXIBLE"] = "flexible";
})(WorkingTimeType || (WorkingTimeType = {}));
export var ContractDurationType;
(function (ContractDurationType) {
    ContractDurationType["definite"] = "definite";
    ContractDurationType["indefinite"] = "indefinite";
})(ContractDurationType || (ContractDurationType = {}));
