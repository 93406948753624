import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Col, Row, notification } from 'antd';
import { history } from '@src/app/index';
import { OfferNotificationSubscriptionForm } from '@src/components/modules/forms/offer-notification-subscription-form';
import { Holder, Link } from '@src/components/elements';
import LoadingWrapper from '@src/components/modules/global/loading-wrapper';
import Button from '@src/components/modules/global/button';
import * as Api from '@src/api';
const OfferNotificationSubscriptionPage = () => {
    const [subscription, setSubscription] = React.useState(null);
    const [subscriptionWasJustDeleted, setSubscriptionWasJustDeleted] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const intl = useIntl();
    const showErrorNotification = () => {
        notification.error({
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.error',
                defaultMessage: 'Chyba',
            }),
            description: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.something_went_wrong',
                defaultMessage: 'Niekde nastala chyba.',
            }),
        });
    };
    const initialize = async () => {
        var _a;
        setIsLoading(true);
        try {
            const token = history.getQuery().onst;
            const fetchedSubscription = await Api.OfferNotificationSubscription.getSubscription(token);
            setSubscription(fetchedSubscription);
        }
        catch (err) {
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) !== 404) {
                showErrorNotification();
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    React.useEffect(() => {
        initialize();
    }, []);
    const handleDelete = () => {
        setSubscription(null);
        setSubscriptionWasJustDeleted(true);
    };
    return (React.createElement(LoadingWrapper, { isLoading: isLoading }, subscription ? (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24 },
                React.createElement("h1", { className: "headline headline--1 headline--line-behind" },
                    React.createElement(FormattedMessage, { id: "offer_notification_subscription.new_subscription", defaultMessage: "Odoberanie nov\u00FDch pon\u00FAk" }))),
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: "panel" },
                    React.createElement(OfferNotificationSubscriptionForm, { subscription: subscription, showEmailField: true, onSubmit: initialize, onDelete: handleDelete })))))) : (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, className: "text-center" },
                React.createElement("h1", { className: "headline headline--1 headline--line-behind" }, subscriptionWasJustDeleted
                    ? intl.formatMessage({
                        id: 'offer_notification_subscription.subscription_successfully_deleted',
                        defaultMessage: 'Odoberanie úspešne zmazané',
                    })
                    : intl.formatMessage({
                        id: 'offer_notification_subscription.subscription_not_found',
                        defaultMessage: 'Odoberanie nebolo nájdené',
                    })),
                React.createElement("div", { className: "page-not-found__content" },
                    React.createElement("p", null, subscriptionWasJustDeleted
                        ? intl.formatMessage({
                            id: 'offer_notification_subscription.subscription_deletion_was_successful',
                            defaultMessage: 'Vymazanie odberu prebehlo úspešne',
                        })
                        : intl.formatMessage({
                            id: 'offer_notification_subscription.we_are_sorry_but',
                            defaultMessage: 'Ľutujeme, ale hľadaný odber nebol nájdený',
                        }))),
                React.createElement(Link, { to: "/" },
                    React.createElement(Button, { color: "brand", text: intl.formatMessage({
                            id: 'offer_notification_subscription.continue_to_home_page',
                            defaultMessage: 'Pokračovať na hlavnú stránku',
                        }) }))))))));
};
export { OfferNotificationSubscriptionPage };
