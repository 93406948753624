import React from 'react';
import { DropzoneContainer, DropzoneContent, DropzoneOverlay, DropzoneOverlayText, StyledCloudUpload, } from './FileDropzone.style';
export function FileDropzone({ onDrop, children }) {
    const [isDraggingOverBody, setIsDraggingOverBody] = React.useState(false);
    // This counter is used to accurately detect when user is hovering over body.
    // Otherwise dragleave event is fired when cursor leave any child element. This behaviour is causing dropzone to flicker
    // Details: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
    const dragEnterLeaveCounter = React.useRef(0);
    const handleDropOnDropzone = (event) => {
        event.preventDefault();
        onDrop(Array.from(event.dataTransfer.files));
        setIsDraggingOverBody(false);
    };
    React.useEffect(() => {
        // This function must be called on dragover event.
        // Otherwise drop event wont be triggered: Probably bug.
        // Details: https://stackoverflow.com/questions/21339924/drop-event-not-firing-in-chrome
        const handleDragoverBody = (event) => {
            event.preventDefault();
        };
        const handleDragEnterBody = () => {
            setIsDraggingOverBody(true);
            dragEnterLeaveCounter.current += 1;
        };
        const handleDragleaveBody = () => {
            dragEnterLeaveCounter.current -= 1;
            if (dragEnterLeaveCounter.current === 0) {
                setIsDraggingOverBody(false);
            }
        };
        const handleDropBodyOnBody = () => {
            setIsDraggingOverBody(false);
        };
        document.body.addEventListener('dragover', handleDragoverBody);
        document.body.addEventListener('dragenter', handleDragEnterBody);
        document.body.addEventListener('dragleave', handleDragleaveBody);
        document.body.addEventListener('drop', handleDropBodyOnBody);
        return () => {
            document.body.removeEventListener('dragover', handleDragoverBody);
            document.body.removeEventListener('dragenter', handleDragEnterBody);
            document.body.removeEventListener('dragleave', handleDragleaveBody);
            document.body.removeEventListener('drop', handleDropBodyOnBody);
        };
    }, []);
    return (
    // @ts-ignore
    React.createElement(DropzoneContainer, { onDrop: handleDropOnDropzone },
        React.createElement(DropzoneOverlay, { isDraggingOverBody: isDraggingOverBody },
            React.createElement(DropzoneOverlayText, null, "Nahra\u0165 s\u00FAbory"),
            React.createElement(StyledCloudUpload, null)),
        React.createElement(DropzoneContent, { isDraggingOverBody: isDraggingOverBody }, children)));
}
