import { FileUpload } from '@src/components/elements';
import { Form } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
export function UploadResumeField({ addResumeRules, resumeFileList, setResumeFileList }) {
    const intl = useIntl();
    return (React.createElement(Form.Item, { rules: addResumeRules, label: intl.formatMessage({
            id: 'offer_detail.send_resume_form.resume',
        }), name: "resume" },
        React.createElement(FileUpload, { name: "resume", accept: "*", maxFileSize: 2, multiple: false, wholeFileList: resumeFileList, setFileList: setResumeFileList })));
}
