import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Spinner } from '@src/components/elements';
import { FilterCount } from './Filter.style';
const Filter = ({ filterItem, loading, areCurrentFiltersNotEmpty, isSelected, isDisabled, toggleFilter, }) => {
    return (React.createElement("div", { className: "flex-row m-s align-center" },
        React.createElement(Checkbox, { type: "checkbox", checked: isSelected, onChange: () => toggleFilter(filterItem.id), disabled: !isSelected && isDisabled },
            React.createElement("div", { className: "m-l-s word-break-keep-all" },
                Boolean(filterItem.translationKey) && React.createElement(FormattedMessage, { id: `${filterItem.translationKey}` }),
                Boolean(!filterItem.translationKey) && filterItem.name,
                !loading && !isSelected && (React.createElement(FilterCount, null,
                    Boolean(filterItem.count) && (React.createElement(React.Fragment, null,
                        areCurrentFiltersNotEmpty && ` (+${filterItem.count})`,
                        !areCurrentFiltersNotEmpty && ` (${filterItem.count})`)),
                    !filterItem.count && ` (0)`)),
                loading && React.createElement(Spinner, { size: "small", delay: 0, className: "m-l-xs flex-inline align-center" })))));
};
export { Filter };
