import React from 'react';
import { OfferFilterType } from '@src/types';
import { useHistory } from 'react-router';
import { tagOptions } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { SearchFilterWrapper } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelect.style';
import { LocationSelect } from '@src/components/modules/forms/offer-notification-subscription-form/LocationSelect';
import { formatSearchSelectValues } from '@src/helpers/format-search-select-values';
import { SpecificSearchSelectWrapper, } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelectWrapper';
import { MainFilterSearchWrapper, SelectWrapper, SearchIcon, LocationPinIcon, ButtonWrapper, Search, SearchBarItem, SearchBar, SearchBarItemTitle, AlertMaxTagCount, } from './HomeSearchWrapper.style';
const HomeSearchWrapper = () => {
    const history = useHistory();
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedLocations, setSelectedLocations] = React.useState([]);
    const onSearch = () => {
        const { companyIds, keywords } = formatSearchSelectValues(selectedValues);
        let queryString = '';
        const paramsToUrl = {
            [OfferFilterType.location]: selectedLocations.length !== 0 ? selectedLocations : null,
            [OfferFilterType.searchCompaniesSelected]: companyIds.length !== 0 ? companyIds : null,
            [OfferFilterType.searchKeywordsSelected]: keywords.length !== 0 ? keywords : null,
        };
        Object.keys(paramsToUrl).forEach(key => {
            const value = paramsToUrl[key];
            if (value !== undefined && value !== null) {
                const valueString = encodeURIComponent(value);
                queryString += queryString ? `&${key}=${valueString}` : `${key}=${valueString}`;
            }
        });
        history.pushLocalized(`/ponuky?${queryString}`);
    };
    const intl = useIntl();
    const isTagCountInLimit = selectedValues.length < tagOptions.maxTagCount;
    return (React.createElement(MainFilterSearchWrapper, null,
        React.createElement(SearchFilterWrapper, { isTagCountInLimit: isTagCountInLimit },
            React.createElement(SearchBar, null,
                React.createElement(SearchBarItem, null,
                    React.createElement(SearchBarItemTitle, null,
                        React.createElement(FormattedMessage, { id: "global.specific_search.title" })),
                    React.createElement(SelectWrapper, null,
                        React.createElement(SearchIcon, null),
                        React.createElement(SpecificSearchSelectWrapper, { selectedValues: selectedValues, setSelectedValues: setSelectedValues, isTagCountInLimit: isTagCountInLimit })),
                    !isTagCountInLimit && (React.createElement(AlertMaxTagCount, { message: intl.formatMessage({ id: 'offers.max_tag_count_alert' }), type: "warning", showIcon: true }))),
                React.createElement(SearchBarItem, { hasNegativeMargin: true },
                    React.createElement(SearchBarItemTitle, null,
                        React.createElement(FormattedMessage, { id: "global.location.title" })),
                    React.createElement(SelectWrapper, null,
                        React.createElement(LocationPinIcon, { type: "pin-location" }),
                        React.createElement(LocationSelect, { onChange: setSelectedLocations, placeholder: intl.formatMessage({ id: 'global.location.placeholder' }) })))),
            React.createElement(Search, null,
                React.createElement(ButtonWrapper, { type: "success", onClick: onSearch },
                    React.createElement(SearchOutlined, { className: "m-r-s" }),
                    React.createElement(FormattedMessage, { id: "search" }))))));
};
export { HomeSearchWrapper };
