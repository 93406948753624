import React from 'react';
import * as uuid from 'uuid';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '@src/hooks/useConfig';
import moment from 'moment';
import { Skeleton } from 'antd';
import { NotificationItem } from '@src/components/modules/navigation/NotificationItem/NotificationItem';
import * as Api from '@src/api';
import { NotificationsWrapper, NoNotificationsMessage } from './Notifications.style';
const SkeletonItem = () => (React.createElement("div", { className: "p-m" },
    React.createElement(Skeleton, { avatar: { shape: 'square' }, paragraph: { rows: 3 }, title: false, active: true })));
function Notifications({ notifications = [], refetchNotifications, isLoading, skeletonCount }) {
    const config = useConfig();
    const markNotificationAsClicked = React.useCallback(async (notificationId, wasClicked) => {
        try {
            if (!wasClicked) {
                await Api.Account.patchNotification(notificationId, {
                    clicked_at: moment().format(config.datetimeFormat),
                });
                refetchNotifications();
            }
        }
        catch (err) {
            console.error(err);
        }
    }, [refetchNotifications]);
    return (React.createElement(NotificationsWrapper, null,
        notifications.length === 0 && (React.createElement(NoNotificationsMessage, null,
            React.createElement(FormattedMessage, { id: "global.notification.no_notifications" }))),
        notifications.map(item => (React.createElement(NotificationItem, { key: item.uuid, notification: item, onClick: () => markNotificationAsClicked(item.uuid, Boolean(item.clicked_at)) }))),
        isLoading && [...Array(skeletonCount)].map(() => React.createElement(SkeletonItem, { key: uuid.v4() }))));
}
export { Notifications };
