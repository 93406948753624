import { Avatar, Button } from '@src/components/elements';
import { useAccount } from '@src/context/account-context';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
export const ProfileBoxWrapper = styled.div `
	display: flex;
	flex-direction: column;
	text-transform: none;
	font-weight: normal;
`;
export const Top = styled.button `
	display: flex;
	cursor: pointer;
	border: 0;
	padding: 0;
	overflow: hidden;
	text-align: left;
	background: ${props => props.theme.color.white};
	border-radius: ${props => props.theme.borderRadius.base};
	transition: all 0.1s ease;
	border: 1px solid ${props => props.theme.color.white};

	&:hover {
		background: ${props => props.theme.color.gray2};
		border: 1px solid ${props => props.theme.color.gray3};
	}
`;
export const AvatarWrapper = styled.div `
	margin: 0.5rem 1rem 0.5rem 0.5rem;
	border-radius: ${props => props.theme.borderRadius.base};
	display: flex;
`;
export const ContentWrapper = styled.div `
	padding: 0 0.5rem 0 0;
	display: flex;
	flex-direction: column;
	align-self: center;
	overflow: hidden;
`;
export const Name = styled.div `
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.3;
	color: ${props => props.theme.color.brand1};
	${props => props.marginTop &&
    css `
			margin-top: 0.5rem;
		`}
`;
export const Email = styled.div `
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${props => props.theme.color.gray6};
`;
export const Company = styled.div `
	margin: 0.5rem 0;
	line-height: 1.3;
	color: ${props => props.theme.color.gray7};
`;
export const Bottom = styled.div `
	margin-top: 0.5rem;
	display: flex;
	gap: 0.5rem;
	align-self: flex-end;
	justify-content: space-between;
`;
function ProfileBox({ onLogoutClick, onAccountClick, image, name, email, company, companyUrl }) {
    const theme = useTheme();
    const account = useAccount();
    return (React.createElement(ProfileBoxWrapper, null,
        React.createElement(Top, { onClick: onAccountClick },
            React.createElement(AvatarWrapper, null,
                React.createElement(StyledAvatar, { account: account, shape: "square", size: 70, src: image, color: theme.color.gray4, contain: true })),
            React.createElement(ContentWrapper, null,
                React.createElement(Name, { marginTop: !!company }, name),
                React.createElement(Email, { title: email }, email),
                company && React.createElement(Company, null, company))),
        React.createElement(Bottom, null,
            company && (React.createElement(Button, { size: "small", type: "link", link: `/firma/${companyUrl}` },
                React.createElement(FormattedMessage, { id: "general.profile_public_company" }))),
            React.createElement(Button, { size: "small", danger: true, type: "primary", onClick: onLogoutClick },
                React.createElement(FormattedMessage, { id: "general.log_off" })))));
}
const StyledAvatar = styled(Avatar) `
	&& svg {
		width: 4rem;
		height: 4rem;
	}
`;
export { ProfileBox };
