import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Slider } from 'antd';
import { transparentize } from 'polished';
import { up } from 'styled-breakpoints';
import { Link, Switch } from '@src/components/elements';
import { getProductPricing } from '@src/shared/get-product-pricing';
import config from '@src/config';
import { ProductId } from '@src/types';
import Big from 'big.js';
const maxCVCount = 20;
export const BoxPayPerApplication = () => {
    const intl = useIntl();
    const [withVAT, setWithVAT] = React.useState(false);
    const [CVcount, setCVCount] = React.useState(10);
    const [CVPricing, setCVPricing] = React.useState(null);
    React.useEffect(() => {
        setCVPricing(getProductPricing({ productCount: CVcount, productId: ProductId.offerCV }));
    }, [CVcount]);
    const marks = {
        1: '1',
        5: '5',
        10: '10',
        20: (React.createElement(NonBreakableText, null, intl.formatMessage({ id: 'companies.max_cvs_and_more' }, { amount: 20 }))),
    };
    return (React.createElement(Box, null,
        React.createElement(BoxHead, null,
            React.createElement(FormattedMessage, { id: "companies.cvs_headline" })),
        React.createElement(SliderWrapper, null,
            React.createElement(StyledSlider, { min: 1, max: 20, marks: marks, value: CVcount, onChange: value => setCVCount(value), dark: true })),
        CVPricing && (React.createElement(Introduction, null,
            React.createElement(PricingCalculation, null,
                React.createElement(PricingCalculationBox, null,
                    React.createElement("dl", null,
                        React.createElement("dt", null,
                            React.createElement(FormattedMessage, { id: "companies.cvs_price_single" })),
                        React.createElement("dd", null,
                            React.createElement(FormattedNumber, { value: withVAT ? CVPricing.productPrice : CVPricing.productPriceWithoutVat, style: "currency", currency: "EUR" }))))),
            React.createElement(PricingTotal, null,
                React.createElement(SmallTextBox, null,
                    React.createElement(FormattedMessage, { id: "companies.credits.total" }),
                    ' ',
                    React.createElement(FormattedNumber, { value: withVAT ? CVPricing.totalPrice : CVPricing.totalPriceWithoutVat, style: "currency", currency: "EUR" }),
                    CVcount === maxCVCount && (React.createElement(Badge, null, intl.formatMessage({ id: 'companies.cvs_limit' })))),
                React.createElement(MaxPriceNote, null, intl.formatMessage({ id: 'companies.cvs_max_price' }, {
                    maxPrice: intl.formatNumber(new Big(99).times(withVAT ? 1.2 : 1).toNumber(), {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                    }),
                }))),
            React.createElement(VATSelector, null,
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "companies.credits.show_prices_with_vat" }),
                    ' ',
                    React.createElement(FormattedNumber, { value: config.pricing.VAT, style: "unit", unit: "percent" })),
                React.createElement(Switch, { checked: withVAT, onChange: checked => setWithVAT(checked) })))),
        React.createElement(ContactSales, null,
            React.createElement(FormattedMessage, { id: "companies.credits.want_more" }),
            React.createElement(ContactLink, { to: "/kontakt" },
                React.createElement(FormattedMessage, { id: "companies.credits.contact_us" })))));
};
const Badge = styled.span `
	background: ${props => props.theme.color.success};
	color: ${props => props.theme.color.white};
	padding: 0.125rem 0.75rem;
	font-size: 13px;
	margin-left: 0.5rem;
	border-radius: 1rem;
`;
const NonBreakableText = styled.div `
	white-space: pre;
`;
const StyledSlider = styled(Slider) `
	.ant-slider-dot {
		height: 16px;
		width: 16px;
		margin-left: -8px;
		top: -4px;
		border: 4px solid ${props => props.theme.color.gray5};
		box-shadow: 0px 0px 0px 3px ${props => props.theme.color.white};
	}
	.ant-slider-dot-active {
		background-color: ${props => props.theme.color.white};
		border: 4px solid ${props => props.theme.color.success};
		box-shadow: 0px 0px 0px 3px ${props => props.theme.color.white};
	}
	.ant-slider-handle {
		height: 25px;
		width: 25px;
		margin-top: -9px;
		background-color: ${props => props.theme.color.success};
		border: solid 2px ${props => props.theme.color.white};
	}
	&:hover .ant-slider-handle:not(.ant-tooltip-open) {
		border-color: ${props => props.theme.color.white};
	}
	.ant-slider-rail {
		height: 8px;
		background-color: ${props => props.theme.color.gray5};
	}
	.ant-slider-track {
		height: 8px;
		background-color: ${props => props.theme.color.success};
	}
	&:hover .ant-slider-track {
		background-color: ${props => props.theme.color.success};
	}
	.ant-slider-mark {
		top: 29px;
		font-size: 18px;
	}
	.ant-slider-mark-text {
		color: ${props => props.theme.color.gray5};
	}
	.ant-slider-mark-text-active {
		color: ${props => props.theme.color.success};
	}

	${props => props.dark
    ? `
			.ant-slider-dot-active {
				background-color: ${props.theme.color.success};
				border: 4px solid ${props.theme.color.white};
				box-shadow: none;
			}
			.ant-slider-dot {
				border: 4px solid ${props.theme.color.gray1};
				box-shadow: none;
			}
			.ant-slider-rail {
				background-color: ${props.theme.color.gray1};
			}
			.ant-slider-mark-text {
				color: ${props.theme.color.white};
			}
			`
    : ``}
`;
const Box = styled.div `
	width: 100%;
	min-height: 300px;
	position: relative;
	background: ${props => props.theme.color.brand1};
	color: ${props => props.theme.color.white};
	border-radius: 0 0 4px 4px;
	box-shadow: 0 15px 35px rgb(50 50 66 / 30%), 0 5px 15px rgb(0 0 0 / 7%);
	overflow: hidden;

	${up('md')} {
		border-radius: 4px;
		width: 50%;
	}
`;
const BoxHead = styled.div `
	padding: 1.5em 3em 1.2em;
	text-align: center;
	background: ${props => transparentize(0.7, props.theme.color.brand4)};

	font-weight: 400;
	font-size: 1.3em;
	color: ${props => props.theme.color.white};

	strong {
		font-weight: 700;
		text-transform: uppercase;
		color: ${props => props.theme.color.gray2};
	}
`;
const SmallTextBox = styled.div `
	text-align: center;
	color: ${props => transparentize(0.1, props.theme.color.white)};
	font-size: 1em;
	padding: 0 2em 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	small {
		color: ${props => transparentize(0.4, props.theme.color.white)};
		font-size: 0.9em;
	}
`;
const SliderWrapper = styled.div `
	padding: 1.1em 5em 1.5em;
	text-align: center;
`;
const ContactSales = styled.div `
	width: 100%;
	padding: 2em 3em 1.8em;
	text-align: center;
	color: ${props => transparentize(0.3, props.theme.color.white)};
	background: ${props => transparentize(0.85, props.theme.color.black)};
`;
const Introduction = styled.div `
	text-align: center;
	color: ${props => props.theme.color.gray6};
	font-size: 1.2em;
	padding: 2em 2em 0;
`;
const PricingCalculation = styled.div `
	display: flex;
	justify-content: center;
	padding: 0;
`;
const PricingCalculationBox = styled.div `
	display: flex;
	width: 50%;
	justify-content: center;

	dl {
		margin: 0;
	}
	dt {
		color: ${props => transparentize(0.5, props.theme.color.white)};
		font-size: 0.9em;
	}
	dd {
		color: ${props => props.theme.color.white};
		font-size: 2em;
	}
`;
const PricingTotal = styled.div `
	border-top: 1px solid ${props => transparentize(0.85, props.theme.color.white)};
	padding: 1.5em 0 0;
	width: 100%;
	justify-content: center;
`;
const VATSelector = styled.div `
	text-align: center;
	color: ${props => transparentize(0.5, props.theme.color.white)};
	font-size: 0.9em;
	padding: 2em 3em 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	& > * + * {
		margin-left: 0.7em;
	}
`;
const ContactLink = styled(Link) `
	display: block;
	margin: 0.5em auto 0;
	text-decoration: underline;
	&:link,
	&:visited,
	&:focus {
		color: ${props => props.theme.color.white};
	}
`;
const MaxPriceNote = styled.div `
	color: ${props => transparentize(0.5, props.theme.color.white)};
	font-size: 0.9em;
`;
