import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { CompanyInfoEditFormField } from '@src/types';
import { Col, Form, Input, notification, Row } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import * as uuid from 'uuid';
import * as Api from '@src/api';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { ImageUpload } from '@src/components/elements';
import { checkImageSize } from '@src/components/pages/admin/modules/company-info-edit/form-fields/UploadFields';
import { companyInfoEditFormRules } from '@src/components/pages/admin/modules/company-info-edit/companyInfoEditFormRules';
import { AddressFields } from '@src/components/modules/global/AddressFields';
function getImageThumb(pictureUrl) {
    return getCdnImageUrl({
        file: `web/company/${pictureUrl}`,
        transformation: 'w_140,h_140,c_limit',
    });
}
const CompanyProfileHeaderEditForm = React.forwardRef(({ setIsEditModalOpen, company }, ref) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const { invalidateSSRContext } = useSSRFetchClient();
    // @ts-ignore
    const rules = companyInfoEditFormRules(intl, company);
    const { mutateAsync: updateCompanyProfileInfo } = useAppMutation(Api.CompanyPremiumProfile.setCompanyPremiumProfileHeaderInfo, {
        onSuccess: () => {
            notification.success({
                message: intl.formatMessage({
                    id: 'general.warning',
                }),
                description: intl.formatMessage({
                    id: 'general.successfuly_saved',
                }),
            });
            invalidateSSRContext(SSRFetchConfig.companyProfile.key);
        },
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        },
    });
    const [imageFile, setImageFile] = React.useState([]);
    const [titleFile, setTitleFile] = React.useState([]);
    const initialValues = React.useMemo(() => ({
        [CompanyInfoEditFormField.image]: company.image,
        [CompanyInfoEditFormField.title_image]: company.titleImageUrl,
        [CompanyInfoEditFormField.address]: company.address,
        [CompanyInfoEditFormField.website]: company.website,
    }), [company]);
    const initImages = () => {
        const { image } = company;
        const titleImage = company.titleImageUrl;
        if (image) {
            const imageThumb = getImageThumb(image);
            setImageFile([{ thumbUrl: imageThumb, uid: uuid.v4() }]);
        }
        if (titleImage) {
            const titleImageThumb = getImageThumb(titleImage);
            setTitleFile([{ thumbUrl: titleImageThumb, uid: uuid.v4() }]);
        }
    };
    React.useEffect(() => {
        initImages();
    }, [company.image, company.titleImageUrl]);
    const validateFormFields = async () => {
        await form.validateFields();
        return true;
    };
    const submitForm = async () => {
        var _a, _b;
        const validationPassed = await validateFormFields();
        if (!validationPassed) {
            return;
        }
        const formValues = form.getFieldsValue();
        const shouldDeleteTitleImage = Boolean(initialValues.title_image && titleFile.length === 0);
        const shouldDeleteCompanyImage = Boolean(initialValues.image && imageFile.length === 0);
        const shouldSetCompanyImage = Boolean(imageFile.length === 1);
        const shouldSetTitleImage = Boolean(titleFile.length === 1);
        await updateCompanyProfileInfo({
            companyPremiumProfileInfo: formValues,
            shouldDeleteTitleImage,
            shouldDeleteCompanyImage,
            shouldSetCompanyImage,
            shouldSetTitleImage,
            companyImage: (_a = imageFile[0]) === null || _a === void 0 ? void 0 : _a.originFileObj,
            companyTitleImage: (_b = titleFile[0]) === null || _b === void 0 ? void 0 : _b.originFileObj,
        });
        setIsEditModalOpen(false);
    };
    React.useImperativeHandle(ref, () => ({ submitForm }));
    if (!company) {
        return null;
    }
    return (React.createElement(Form, { form: form, layout: "vertical", scrollToFirstError: true, initialValues: initialValues },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'company.image',
                    }), name: CompanyInfoEditFormField.image },
                    React.createElement(ImageUpload, { name: "image", accept: "image/*", aspect: 1, cropSize: { width: 600, height: 600 }, beforeUpload: e => checkImageSize(e, 1, intl), wholeFileList: imageFile, setFileList: setImageFile, modalTitle: `${intl.formatMessage({
                            id: 'admin.company_premium_profile.upload_modal_title',
                        })} (1:1, max. 1MB)` }))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'company.title_image',
                    }), name: CompanyInfoEditFormField.title_image },
                    React.createElement(ImageUpload, { name: "title_image_url", accept: "image/*", aspect: 5 / 1, beforeUpload: e => checkImageSize(e, 2, intl), wholeFileList: titleFile, setFileList: setTitleFile, modalTitle: `${intl.formatMessage({
                            id: 'admin.company_premium_profile.upload_modal_title',
                        })} (5:1, max. 2MB)` })))),
        React.createElement(Col, { xs: 24, md: 12 },
            React.createElement(AddressFields, { baseName: "address", form: form, countryLabel: intl.formatMessage({ id: 'general.country' }), formattedAddressLabel: intl.formatMessage({ id: 'company.address' }) })),
        React.createElement(Col, { xs: 24, md: 12 },
            React.createElement(Form.Item, { label: intl.formatMessage({
                    id: 'admin.company_premium_profile.company_web_page',
                }), name: CompanyInfoEditFormField.website, rules: rules.websiteRules },
                React.createElement(Input, null)))));
});
export default CompanyProfileHeaderEditForm;
