var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/require-default-props */
import React from 'react';
import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';
import { transparentize } from 'polished';
import { Link } from '../Link';
const defaultProps = {
    size: 'large',
    type: 'primary',
    textStyle: 'default',
};
function Button(props) {
    const _a = Object.assign(Object.assign({}, defaultProps), props), { children, link, type, size, ref, textStyle } = _a, otherProps = __rest(_a, ["children", "link", "type", "size", "ref", "textStyle"]);
    if (type === 'secondary' || props.type === 'secondary') {
        return (React.createElement(StyledSecondaryButtonWrapper, { size: size, textStyle: textStyle, ref: ref, disabled: props.disabled },
            React.createElement(AntdButton, Object.assign({ type: "default", size: size }, otherProps), children)));
    }
    if (type === 'success' || props.type === 'success') {
        return (React.createElement(SuccessButton, Object.assign({ size: size, textStyle: textStyle, ref: ref }, otherProps), children));
    }
    if (link) {
        return (React.createElement(StyledLink, { to: link, ref: ref },
            React.createElement(AntdButton, Object.assign({ type: props.type || 'default', size: size }, otherProps), children)));
    }
    return (React.createElement(AntdButton, Object.assign({ ref: ref, type: type, size: size }, otherProps), children));
}
const StyledLink = styled(Link) `
	width: fit-content;
	height: fit-content;
`;
const StyledSecondaryButtonWrapper = styled.div `
	.ant-btn {
		border: none;
		background: ${({ theme }) => theme.color.gray2};
		color: ${({ theme, disabled }) => (disabled ? theme.color.gray6 : theme.color.brand1)};
		font-size: 14px;
		transition: all ${({ theme }) => theme.transition.basic};
		${({ textStyle }) => textStyle === 'upper-case-bold' &&
    css `
				font-weight: bold;
				text-transform: uppercase;
			`}
		${({ size }) => !size &&
    css `
				height: 2rem;
			`}

		&:hover {
			color: ${({ theme, disabled }) => (disabled ? theme.color.gray6 : theme.color.brand1)};
			background: ${({ theme, disabled }) => (disabled ? theme.color.gray2 : theme.color.gray4)};
		}
	}
`;
const SuccessButton = styled(Button) `
	background: ${({ theme }) => theme.color.success};
	border: 1px solid ${({ theme }) => theme.color.success};

	&:hover,
	&:focus {
		background: ${({ theme }) => transparentize(0.1, theme.color.success)};
		border: 1px solid ${({ theme }) => theme.color.success};
	}
`;
export { Button };
