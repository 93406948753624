import styled from 'styled-components';
import { EyeOutlined, PlayCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button } from '@src/components/elements';
import ListIcon from '@public/media/icons/streamlinehq-picture-stack.svg';
import { up } from 'styled-breakpoints';
export const GalleryCardsContainer = styled.div `
	display: grid;
	grid-template-columns: minmax(100px, 1fr);
	justify-items: center;
	gap: 1rem;

	${up('sm')} {
		grid-template-columns: repeat(2, minmax(100px, 1fr));
	}
`;
export const GalleryCardAssetImageWrapper = styled.div `
	width: 100%;
	height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	img {
		height: 100%;
		width: 100%;
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}
`;
export const AddGalleryButton = styled(Button) `
	width: 100%;
	height: 250px;
	font-size: 1.25rem;
	background: url('/media/images/background-gradient.jpg') 50% 50%;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid transparent;
		background: url('/media/images/background-gradient.jpg') 50% 0%;
	}
`;
export const StyledPlusOutlined = styled(PlusOutlined) `
	svg {
		width: 2rem;
		height: 2rem;
	}
`;
export const StyledPlayCircleOutlined = styled(PlayCircleFilled) `
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	svg {
		${up('xs')} {
			width: 4rem;
			height: 4rem;
		}
		${up('sm')} {
			width: 3rem;
			height: 3rem;
		}
		${up('lg')} {
			width: 4rem;
			height: 4rem;
		}
	}
`;
export const MultipleAssetsIcon = styled(ListIcon) `
	z-index: 4;
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
`;
export const GalleryCardTitle = styled.div `
	position: absolute;
	left: 0;
	bottom: 0;
	color: ${props => props.theme.color.white};
	font-size: 1rem;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: keep-all;
	overflow: hidden;
	width: 100%;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
	padding: 2rem 1rem 1rem;
	transition: 0.3s;
`;
export const GalleryCard = styled.div `
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: 0.3s ease-in-out;

	svg {
		color: ${props => props.theme.color.white};
	}

	&:hover {
		${GalleryCardTitle} {
			padding-top: 4rem;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
		}
	}
`;
export const DetailIcon = styled(EyeOutlined) `
	width: 3rem;
	height: 3rem;
	padding: 1rem;
	border-radius: 4rem;
	background: rgb(80, 80, 80);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.3s;
	transform: scale(0.5);
`;
export const StyledDivOverlay = styled.div `
	position: absolute;
	color: ${props => props.theme.color.white};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: ${props => props.theme.fontSizes.m};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;

	&:hover {
		${DetailIcon} {
			opacity: 1;
			transform: scale(1);
		}
	}
`;
