import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import A from '@public/media/illustrations/driverLicences/A.svg';
import A1 from '@public/media/illustrations/driverLicences/A1.svg';
import A2 from '@public/media/illustrations/driverLicences/A2.svg';
import AM from '@public/media/illustrations/driverLicences/AM.svg';
import B from '@public/media/illustrations/driverLicences/B.svg';
import B1 from '@public/media/illustrations/driverLicences/B1.svg';
import BE from '@public/media/illustrations/driverLicences/BE.svg';
import C from '@public/media/illustrations/driverLicences/C.svg';
import C1 from '@public/media/illustrations/driverLicences/C1.svg';
import C1E from '@public/media/illustrations/driverLicences/C1E.svg';
import CE from '@public/media/illustrations/driverLicences/CE.svg';
import D from '@public/media/illustrations/driverLicences/D.svg';
import D1 from '@public/media/illustrations/driverLicences/D1.svg';
import D1E from '@public/media/illustrations/driverLicences/D1E.svg';
import DE from '@public/media/illustrations/driverLicences/DE.svg';
import T from '@public/media/illustrations/driverLicences/T.svg';
const RowDiv = styled.div `
	display: flex;
	width: 100%;
	max-width: 300px;
	justify-content: space-between;
	margin-bottom: 0.5rem;
`;
const TitleWrapper = styled.span `
	color: ${({ theme }) => theme.color.gray6};
	margin-bottom: 0.5rem;
	width: 80px;
	${({ alignEnd }) => alignEnd &&
    css `
			text-align: end;
		`}
`;
const DriverLicenceWithIcon = styled.div `
	display: flex;
	flex-direction: row;
	width: 30%;
	justify-content: space-between;
	align-items: center;
`;
const SvgWrapper = styled.div `
	display: flex;
	flex-direction: row;
	margin-left: 0.25rem;

	svg {
		width: 3rem;
		height: 1.5rem;
	}
`;
const driverLicenceIcons = {
    AM,
    A1,
    A2,
    A,
    B1,
    B,
    BE,
    C1,
    C1E,
    C,
    CE,
    D1,
    D1E,
    D,
    DE,
    T,
};
export const RequiredDriverLicences = ({ driverLicences }) => {
    const showMileageTitle = driverLicences === null || driverLicences === void 0 ? void 0 : driverLicences.some(licence => licence.mileage);
    return (React.createElement(React.Fragment, null,
        React.createElement(RowDiv, null,
            React.createElement(TitleWrapper, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.licence_type" })),
            Boolean(showMileageTitle) && (React.createElement(TitleWrapper, { alignEnd: true },
                React.createElement(FormattedMessage, { id: "offer_detail.details.mileage" })))),
        (driverLicences || []).map((licence, i) => {
            const { mileage } = licence;
            const driverLicence = licence.driverLicence || licence.driving_licence_type;
            const Icon = driverLicenceIcons[driverLicence];
            return (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement(RowDiv, { key: `${i}-${driverLicence}` },
                React.createElement(DriverLicenceWithIcon, null,
                    driverLicence,
                    React.createElement(SvgWrapper, null,
                        React.createElement(Icon, null))),
                Boolean(mileage) && React.createElement("div", null, mileage)));
        })));
};
