var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
const ButtonsWrap = styled.div `
	display: flex;
	flex-wrap: wrap;

	&& {
		.ant-btn {
			border-radius: 0px;
			margin: 0.25rem 0;
			margin-left: -1px;
		}
		/* .ant-btn:not(:first-child) {
			margin-left: -1px;
		} */
		.ant-btn:first-child {
			border-radius: 4px 0 0 4px;
		}
		.ant-btn:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
`;
export function SwitchButtonsGroup(props) {
    const { options, activeButton, defaultActiveButton, setActiveValue, onChange } = props, otherProps = __rest(props, ["options", "activeButton", "defaultActiveButton", "setActiveValue", "onChange"]);
    const active = activeButton || defaultActiveButton;
    return (React.createElement(ButtonsWrap, { className: otherProps.className }, options.map(option => {
        const buttonType = active === option.value ? 'primary' : 'default';
        return (React.createElement(Button, Object.assign({ key: option.value, onClick: () => {
                setActiveValue === null || setActiveValue === void 0 ? void 0 : setActiveValue(active === option.value ? null : option.value);
                onChange === null || onChange === void 0 ? void 0 : onChange(active === option.value ? null : option.value);
            }, type: buttonType }, otherProps), option.label));
    })));
}
