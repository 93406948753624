import React from 'react';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { AssetDetailImageContainer, AssetDetailImg } from './AssetDetailImage.style';
export function AssetDetailImage({ asset }) {
    const assetImageUrl = getCdnImageUrl({
        file: asset.cloudinaryPublicId,
        shouldIncludeEnvironmentPart: false,
        addWatermarkedLogo: true,
    });
    return (React.createElement(AssetDetailImageContainer, null,
        React.createElement(AssetDetailImg, { src: assetImageUrl, alt: "asset-detail-img" })));
}
