import config from '@src/config';
import { client as axios } from './clients/axios';
export async function getAllSkillTags() {
    const response = await axios.get(`${config.api}/0/skill-tag/all`);
    return response.data;
}
export async function getAutoCompleteSkillTags(searchedText, selectedOptions) {
    const response = await axios.get(`${config.api}/0/skill-tag/search`, { params: { searchedText, selectedOptions } });
    return response.data;
}
export async function getSingleSkillTag(tagName) {
    const response = await axios.get(`${config.api}/0/skill-tag/${tagName}`);
    return response.data;
}
export async function getSkillTagsSuggestions({ searchText, selectedOptionsIds }) {
    const response = await axios.get(`${config.api}/0/skill-tag/suggestions`, {
        params: { searchText, selectedOptionsIds },
    });
    return response.data;
}
