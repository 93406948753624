import { Form, Input } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
export function SendMessageField({ messageRules }) {
    const intl = useIntl();
    return (React.createElement(Form.Item, { name: "message", label: intl.formatMessage({
            id: 'offer_detail.send_resume_form.message',
        }), rules: messageRules },
        React.createElement(Input.TextArea, { rows: 4 })));
}
