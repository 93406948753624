import styled, { css } from 'styled-components';
export const UserNotifications = styled.div `
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 2rem;
	height: 2.5rem;
	z-index: 0;

	& > a > svg {
		opacity: 0.4;
		width: 20px;
		height: 20px;
		transition: all 0.2s ease;
	}
	&& > a {
		display: flex;
		align-items: flex-start;
		color: ${props => props.theme.color.white};
		margin: 0.5rem 0.5rem 0;
		width: 100%;
		height: 2rem;
		z-index: 2;

		& > svg {
			color: ${props => props.theme.color.white};
		}
	}
	&&:hover {
		&& > a > svg {
			opacity: 1;
			transform: scale(1.1);
		}
	}

	${props => props.active &&
    css `
			&:after {
				content: '';
				display: block;
				width: 1.5rem;
				height: 1.5rem;
				border-radius: 50%;
				border: 4px solid ${props.theme.color.white};
				position: absolute;
				top: 0.3rem;
				margin: auto;
				animation: keyframes-notification-pulse 3s ease 0s infinite;
				z-index: 1;
			}
			& > a > svg {
				animation: keyframes-notification-pulse-icon 3s ease 0s infinite;
			}
			@keyframes keyframes-notification-pulse {
				0% {
					opacity: 1;
					transform: scale(0);
				}
				80% {
					opacity: 0;
					transform: scale(2);
				}
				100% {
					opacity: 0;
					transform: scale(3);
				}
			}
			@keyframes keyframes-notification-pulse-icon {
				0% {
					transform: scale(1);
				}
				3% {
					transform: scale(1);
				}
				10% {
					transform: scale(1.3);
				}
				15% {
					transform: scale(1);
				}
				100% {
					transform: scale(1);
				}
			}
			& > a > svg {
				z-index: 2;
				opacity: 1;
			}
		`};
`;
