import { client as axios } from './clients/axios';
export async function getBillingProfiles() {
    try {
        const response = await axios.get(`/0/company-billing-profile`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getBillingProfileById(billingProfileId) {
    try {
        const response = await axios.get(`/0/company-billing-profile/edit-billing-info`, {
            params: { billingProfileId },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function createBillingProfile(billingProfileData) {
    try {
        const response = await axios.post(`/0/company-billing-profile`, { billingProfileData });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function editBillingProfile(billingProfileId, billingProfileData) {
    try {
        const response = await axios.patch(`/0/company-billing-profile`, { billingProfileId, billingProfileData });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function setDefaultBillingProfile(billingProfileId) {
    try {
        const response = await axios.patch(`/0/company-billing-profile/set-default-billing-profile`, {
            billingProfileId,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function deleteBillingProfile(billingProfileId) {
    try {
        const response = await axios.delete(`/0/company-billing-profile/${billingProfileId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
