import { Modal } from '@src/components/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuthContextValue } from '@src/context/auth-context';
import * as Api from '@src/api';
import { AccountErrorCode } from '@src/types';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import LoginForm from '../login-form';
export function AccountAuthenticationModal() {
    const { isModalOpen, setIsModalOpen, authModalPromise } = useAuthContextValue();
    const [loginError, setLoginError] = React.useState(null);
    const SSRFetchClient = useSSRFetchClient();
    const onSubmit = React.useCallback(async (data) => {
        try {
            const account = await Api.Auth.login({ email: data.email, password: data.password });
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            authModalPromise.resolve(account);
            setIsModalOpen(false);
        }
        catch (err) {
            setLoginError(err);
        }
    }, [authModalPromise]);
    const onOAuthSuccess = React.useCallback((account) => {
        authModalPromise.resolve(account);
        setIsModalOpen(false);
    }, [authModalPromise]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { centered: true, visible: isModalOpen, footer: null, onCancel: () => {
                authModalPromise.reject(AccountErrorCode.login_canceled);
                setIsModalOpen(false);
            } },
            React.createElement("h2", { className: "text-center m-b-l" },
                React.createElement(FormattedMessage, { id: "login.login" })),
            React.createElement(LoginForm, { loginError: loginError, onSubmit: onSubmit, showApplicantRegistrationButton: true, showCompanyRegistrationButton: false, onOAuthSuccess: onOAuthSuccess }))));
}
