/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Icon } from '@src/components/elements';
import { FormattedMessage, useIntl } from 'react-intl';
import { CrownOutlined, ExclamationCircleOutlined, HomeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import SlugOffer from 'shared/slug-offer';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { CompanyImage } from '@src/components/modules/global/CompanyImage';
import { useSalaryFormatter } from '@src/hooks/useSalaryFormatter';
import { useConfig } from '@src/hooks/useConfig';
import { ToggleSaveOfferButton } from '@src/components/modules/global/ToggleSaveOfferButton/ToggleSaveOfferButton';
import { getOfferTypeTagIcon } from '@src/helpers/get-offer-type-icon';
import { useOffersFilterContextValue } from '../offer-filter-context';
import { CompanyWrapper, ContentWrapper, IconWrapper, ImageWrapper, InfoTag, LinkWrapper, OfferBottomCol, OfferBox, OfferFooter, OfferIntoTagGroup, OfferTags, PromotedBadge, StyledTag, StyledTimestamp, Title, } from './Offer.style';
const Offer = ({ values }) => {
    const intl = useIntl();
    const config = useConfig();
    const { formatSalary } = useSalaryFormatter();
    // TODO get right casing (snake to camel)
    const { address, salary_rate, salary_amount_from, salary_amount_to, salary_currency, updated_at, id, is_paid, title, company, address_id, offer_type_id, isPromoted, salaryType, } = values;
    const { salaryFilter } = useOffersFilterContextValue();
    const slug = new SlugOffer({
        text: title,
        suffix: id,
    });
    const isRemote = Boolean(address_id) === false;
    const offerTypeText = React.useMemo(() => { var _a; return (_a = config.offerTypes.find(offerType => Number(offerType.id) === offer_type_id)) === null || _a === void 0 ? void 0 : _a.name; }, [offer_type_id]);
    const salaryString = formatSalary({
        rate: salary_rate,
        amountFrom: salary_amount_from,
        amountTo: salary_amount_to,
        currency: salary_currency,
        salaryType,
    });
    const isCalculatedOffer = salaryFilter.rate && salary_rate !== salaryFilter.rate;
    const promotionId = isPromoted ? `?isPromoted=true` : '';
    return (React.createElement(LinkWrapper, { to: `/ponuka/${slug}${promotionId}` },
        React.createElement(OfferBox, { isPromoted: isPromoted },
            isPromoted && (React.createElement(PromotedBadge, null,
                React.createElement(CrownOutlined, { className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "offers.offer.promoted" }))),
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24 },
                    company && company.image && (React.createElement(ImageWrapper, null,
                        React.createElement(CompanyImage, { name: company.name, imageUrl: getCdnImageUrl({
                                file: `web/company/${company.image}`,
                                transformation: 'w_300,h_120,c_limit',
                            }) }))),
                    React.createElement(Title, null, title),
                    React.createElement(CompanyWrapper, null, company && company.name),
                    React.createElement(OfferIntoTagGroup, null,
                        React.createElement(InfoTag, null,
                            React.createElement(Icon, { className: "icon", type: "maps-pin" }),
                            !isRemote && address && React.createElement("span", null, address.city),
                            isRemote && React.createElement("span", null, "Remote")),
                        is_paid && (React.createElement(InfoTag, null,
                            React.createElement(Icon, { className: "icon", type: "money-bag-euro" }),
                            React.createElement("span", null, salaryString)))))),
            React.createElement(OfferFooter, null,
                React.createElement(OfferTags, null,
                    isRemote && (React.createElement(StyledTag, { color: "magenta", icon: React.createElement(HomeOutlined, null) }, intl.formatMessage({
                        id: 'offers.offer.remote',
                    }))),
                    React.createElement(StyledTag, { color: "geekblue", icon: React.createElement(IconWrapper, null, getOfferTypeTagIcon(offer_type_id)) },
                        React.createElement("span", { className: "m-l-s" }, offerTypeText)),
                    isCalculatedOffer && (React.createElement(StyledTag, { color: "warning", icon: React.createElement(ExclamationCircleOutlined, null) },
                        React.createElement(FormattedMessage, { id: "offers.offer.calculated" })))),
                React.createElement(OfferBottomCol, null,
                    React.createElement(ContentWrapper, null,
                        React.createElement(StyledTimestamp, { value: updated_at, type: "relative" }),
                        React.createElement(ToggleSaveOfferButton, { offerId: id })))))));
};
export { Offer };
