import React from 'react';
import { NotificationType } from '@src/types';
import BrowserPageText from '@public/media/icons/streamline-icon-browser-page-text.svg';
import SingleNeutralActionsAdd from '@public/media/icons/streamline-icon-single-neutral-actions-add.svg';
import NotesPaperText from '@public/media/icons/streamline-icon-notes-paper-text.svg';
import Calendar3 from '@public/media/icons/streamline-icon-calendar-3.svg';
import OfficeEmployee from '@public/media/icons/streamline-icon-office-employee.svg';
import View1 from '@public/media/icons/streamline-icon-view-1.svg';
import SeoSearch from '@public/media/icons/streamline-icon-seo-search.svg';
import InformationCircle from '@public/media/icons/streamline-icon-information-circle.svg';
import CalendarClock from '@public/media/icons/streamline-icon-calendar-clock.svg';
import styled, { css } from 'styled-components';
import { theme } from '@src/styles/theme';
import { Icon } from '@src/components/elements';
// this component is used above the ThemeProvider, so we have to access theme directly through import
const ImageOrIcon = styled.span `
	svg {
		color: ${theme.color.gray6};
		transform: scale(0.9);
		width: 24px;
		${props => props.isNew &&
    css `
				color: ${theme.color.brand1};
			`}
	}
`;
function getNotificationIcon(notificationType) {
    switch (notificationType) {
        case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION:
            return React.createElement(BrowserPageText, { viewBox: "0 0 24 24" });
        case NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION:
            return React.createElement(BrowserPageText, { viewBox: "0 0 24 24" });
        case NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION:
            return React.createElement(SingleNeutralActionsAdd, { viewBox: "0 0 24 24" });
        case NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION:
            return React.createElement(SeoSearch, { viewBox: "0 0 24 24" });
        case NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION:
            return React.createElement(InformationCircle, { viewBox: "0 0 24 24" });
        case NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION:
            return React.createElement(NotesPaperText, { viewBox: "0 0 24 24" });
        case NotificationType.CREDITS_EXPIRATION_NOTIFICATION:
            return React.createElement(Calendar3, { viewBox: "0 0 24 24" });
        case NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION:
            return React.createElement(OfficeEmployee, { viewBox: "0 0 24 24" });
        case NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION:
            return React.createElement(View1, { viewBox: "0 0 24 24" });
        case NotificationType.CREDITS_PAID_NOTIFICATION:
            return React.createElement(Icon, { type: "bank", width: 24 });
        case NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION:
            return React.createElement(CalendarClock, { viewBox: "0 0 24 24" });
        default:
            return null;
    }
}
function NotificationImageOrIcon(props) {
    return (React.createElement(ImageOrIcon, { isNew: props.isNew === undefined ? true : props.isNew },
        props.imageUrl && React.createElement("img", { src: props.imageUrl, alt: "Notification" }),
        !props.imageUrl && getNotificationIcon(props.notificationType)));
}
export { NotificationImageOrIcon };
