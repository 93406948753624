import { Alert as AntAlert } from 'antd';
import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
export const Alert = styled(AntAlert) `
	${({ type }) => type === 'info' &&
    css `
			background-color: #e6f7ff;
			border: 1px solid #91d5ff;
			color: rgba(0, 0, 0, 0.85);

			.anticon {
				color: #1890ff;
			}
		`}

	${down('sm')} {
		flex-wrap: wrap;

		.anticon {
			width: 24px;
		}

		.ant-alert-content {
			width: calc(100% - 24px);
		}

		.ant-alert-action {
			margin: 1rem 0 0.5rem 38px;
			width: 100%;
		}
	}
`;
