import * as Api from '@src/api';
import { notification } from 'antd';
import { CandidateRegistrationFormField } from '@src/types';
async function failIfUserExists(email) {
    try {
        await Api.Account.getAccountExistsByEmail(email);
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
export const registrationCandidateFormRules = intl => {
    const firstNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.first_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?={}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_first_name',
            }),
        },
    ];
    const lastNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.last_name',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^0-9_~`!@#$%^&*(),.;<>?{}\[\]\\\/]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_last_name',
            }),
        },
    ];
    const passwordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.password',
            }),
        },
        {
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_length_password',
            }),
        },
        {
            pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_password',
            }),
        },
    ];
    const repeatPasswordRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration_student.sync_validation.fill_repeate_password_field',
            }),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (value && getFieldValue(CandidateRegistrationFormField.password) === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({
                    id: 'registration.sync_validation.passwordRepeat',
                })));
            },
        }),
    ];
    const agreementRules = [
        {
            validator: (_, value) => {
                return value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'registration.sync_validation.protection',
                    })));
            },
        },
    ];
    const emailRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.email',
            }),
        },
        {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.invalid_email',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfUserExists(value)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => {
                    notification.warning({
                        message: intl.formatMessage({
                            id: 'registration.async_validation.email_taken',
                        }),
                        description: intl.formatMessage({
                            id: 'registration_student.async_validation.email_used',
                        }),
                    });
                    reject(new Error(intl.formatMessage({
                        id: 'registration.async_validation.email_taken',
                    })));
                });
            }),
        },
    ];
    const telephoneRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.telephone',
            }),
        },
        {
            max: 14,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_long_telephone',
            }),
        },
        {
            min: 9,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_short_telephone',
            }),
        },
        {
            pattern: /^[^A-Za-z_~`!@#$%^&*(),.;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_title_after',
            }),
        },
    ];
    return {
        firstNameRules,
        lastNameRules,
        emailRules,
        passwordRules,
        repeatPasswordRules,
        agreementRules,
        telephoneRules,
    };
};
