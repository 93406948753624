import styled from 'styled-components';
export const AssetDetailImageContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: ${props => props.theme.color.gray9};
`;
export const AssetDetailImg = styled.img `
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
`;
