import { Button, Panel, Spinner, Tag } from '@src/components/elements';
import { useConfig } from '@src/hooks/useConfig';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useTheme } from 'styled-components';
import { TotalPriceSmall, TotalPrice } from './AdminOrder.style';
const AdminOrderSummary = ({ orderContent, hasAbroadBillingProfile, loading, discount }) => {
    const theme = useTheme();
    const config = useConfig();
    return (React.createElement(Panel, null,
        discount === 100 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex-col align-center m-b-l" },
                React.createElement(Tag, { color: theme.color.success },
                    React.createElement(FormattedMessage, { id: "admin.order.discount", values: { discount } }))),
            React.createElement("div", { className: "flex-row align-center justify-center font-size-l font-bold p-b-m" },
                React.createElement(FormattedMessage, { id: "admin.order.full_amount" }),
                ' ',
                orderContent && !loading && (React.createElement(TotalPrice, null,
                    React.createElement(FormattedNumber, { value: orderContent.totalPrice, style: "currency", currency: "EUR" }))),
                loading && (React.createElement("div", { className: "flex-col align-center m-s" },
                    React.createElement(Spinner, { size: "large" }))),
                React.createElement(FormattedMessage, { id: "admin.order.vat_included" })),
            React.createElement("div", { className: "flex-row align-center justify-center font-size-l font-bold" },
                React.createElement(FormattedMessage, { id: "admin.order.full_discount", values: {
                        styled: chunk => React.createElement(TotalPrice, null, chunk),
                    } })),
            React.createElement("div", { className: "flex-row align-center justify-center font-size-m m-t-s m-b-l" },
                React.createElement(FormattedMessage, { id: "admin.order.no_needed_payment" })),
            React.createElement("div", { className: "flex-row justify-center" },
                React.createElement(Button, null,
                    React.createElement(FormattedMessage, { id: "admin.order.buy_with_full_discount" }))))),
        discount !== 100 && (React.createElement(React.Fragment, null,
            Boolean(discount) && (React.createElement("div", { className: "flex-col align-center m-b-m" },
                React.createElement(Tag, { color: theme.color.success },
                    React.createElement(FormattedMessage, { id: "admin.order.discount", values: { discount } })),
                React.createElement("div", { className: "m-t-s" }, orderContent && (React.createElement("div", { className: "flex-row align-baseline" },
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "admin.order.original_price" }),
                        ' ',
                        React.createElement(FormattedNumber, { value: hasAbroadBillingProfile
                                ? orderContent.totalPriceBeforeCouponDiscountWithoutVat
                                : orderContent.totalPriceBeforeCouponDiscount, style: "currency", currency: "EUR" })),
                    !hasAbroadBillingProfile && (React.createElement(TotalPriceSmall, null,
                        "(",
                        React.createElement(FormattedNumber, { value: orderContent.totalPriceBeforeCouponDiscountWithoutVat, style: "currency", currency: "EUR" }),
                        ' ',
                        React.createElement(FormattedMessage, { id: "admin.order.vat_excluded" }),
                        ")"))))))),
            !hasAbroadBillingProfile && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex-row align-center justify-center font-size-l font-bold p-b-l" },
                    React.createElement(FormattedMessage, { id: "admin.order.full_amount" }),
                    ' ',
                    orderContent && !loading && (React.createElement(TotalPrice, null,
                        React.createElement(FormattedNumber, { value: orderContent.totalPrice, style: "currency", currency: "EUR" }))),
                    loading && (React.createElement("div", { className: "flex-col align-center m-s" },
                        React.createElement(Spinner, { delay: 500, size: "large" }))),
                    React.createElement(FormattedMessage, { id: "admin.order.vat_included" }),
                    orderContent && !loading && (React.createElement(TotalPriceSmall, null,
                        "(",
                        React.createElement(FormattedNumber, { value: orderContent.totalPriceWithoutVat, style: "currency", currency: "EUR" }),
                        ' ',
                        React.createElement(FormattedMessage, { id: "admin.order.vat_excluded" }),
                        ")"))),
                React.createElement("div", { className: "order__billing-info" },
                    React.createElement("div", { className: "order__autobilling" },
                        React.createElement(FormattedMessage, { id: "admin.order.note_vat", values: {
                                vatPercent: config.pricing.VAT,
                            } }))))),
            hasAbroadBillingProfile && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex-row align-center justify-center font-size-l font-bold p-b-l" },
                    React.createElement(FormattedMessage, { id: "admin.order.full_amount" }),
                    orderContent && !loading && (React.createElement(TotalPrice, null,
                        React.createElement(FormattedNumber, { value: orderContent.totalPriceWithoutVat, style: "currency", currency: "EUR" }))),
                    loading && (React.createElement("div", { className: "flex-col align-center m-s" },
                        React.createElement(Spinner, { delay: 500, size: "large" }))),
                    React.createElement(FormattedMessage, { id: "admin.order.vat_excluded" })),
                React.createElement("div", { className: "order__billing-info" },
                    React.createElement("div", { className: "order__autobilling" },
                        React.createElement(FormattedMessage, { id: "admin.order.note_selfpay_vat" })))))))));
};
export { AdminOrderSummary };
