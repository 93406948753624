import { RichText } from '@src/components/elements';
import { CompanyInfoEditFormField, } from '@src/types';
import { Col, Form, Input, notification, Row } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Api from '@src/api';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { companyInfoEditFormRules } from '@src/components/pages/admin/modules/company-info-edit/companyInfoEditFormRules';
import { useConfig } from '@src/hooks/useConfig';
import { onSocialInputChange } from '@src/helpers/remove-social-url-prefix';
import { useHistory } from 'react-router';
const CompanyProfileMainContentEditForm = React.forwardRef(({ setIsModalOpen, setIsSubmitting, company }, ref) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const { invalidateSSRContext } = useSSRFetchClient();
    const config = useConfig();
    const history = useHistory();
    // @ts-ignore
    const rules = companyInfoEditFormRules(intl, company);
    const { mutateAsync: updateCompanyProfileInfo } = useAppMutation(Api.CompanyPremiumProfile.setCompanyPremiumProfileMainInfo, {
        onSuccess: () => {
            notification.success({
                message: intl.formatMessage({
                    id: 'general.warning',
                }),
                description: intl.formatMessage({
                    id: 'general.successfuly_saved',
                }),
            });
        },
    });
    const initialValues = React.useMemo(() => ({
        [CompanyInfoEditFormField.area]: company === null || company === void 0 ? void 0 : company.area,
        [CompanyInfoEditFormField.employees]: company === null || company === void 0 ? void 0 : company.employees,
        [CompanyInfoEditFormField.cin]: company === null || company === void 0 ? void 0 : company.cin,
        [CompanyInfoEditFormField.companyUrl]: company === null || company === void 0 ? void 0 : company.companyUrl,
        [CompanyInfoEditFormField.facebook_url]: company === null || company === void 0 ? void 0 : company.facebookUrl,
        [CompanyInfoEditFormField.linkedin_url]: company === null || company === void 0 ? void 0 : company.linkedinUrl,
        [CompanyInfoEditFormField.twitter_url]: company === null || company === void 0 ? void 0 : company.twitterUrl,
        [CompanyInfoEditFormField.youtube_url]: company === null || company === void 0 ? void 0 : company.youtubeUrl,
        [CompanyInfoEditFormField.instagram_url]: company === null || company === void 0 ? void 0 : company.instagramUrl,
        [CompanyInfoEditFormField.description]: company === null || company === void 0 ? void 0 : company.description,
    }), [company]);
    const submitForm = async () => {
        try {
            setIsSubmitting(true);
            const formValues = form.getFieldsValue();
            await updateCompanyProfileInfo(formValues);
            invalidateSSRContext(SSRFetchConfig.companyProfile.key);
            setIsModalOpen(false);
            if (formValues.companyUrl !== company.companyUrl) {
                history.pushLocalized(`/firma/${formValues.companyUrl}/profile`);
            }
        }
        catch (err) {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
        finally {
            setIsSubmitting(false);
        }
    };
    React.useImperativeHandle(ref, () => ({ submitForm }));
    if (!company) {
        return null;
    }
    return (React.createElement(Form, { form: form, layout: "vertical", scrollToFirstError: true, initialValues: initialValues },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'company.area',
                    }), name: CompanyInfoEditFormField.area, rules: rules.areaRules },
                    React.createElement(Input, null))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'company.employees',
                    }), name: CompanyInfoEditFormField.employees, rules: rules.employeeRules },
                    React.createElement(Input, null))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'company.ico',
                    }), name: CompanyInfoEditFormField.cin, rules: rules.cinRules },
                    React.createElement(Input, null))),
            React.createElement(Col, null,
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.company_url',
                    }), name: CompanyInfoEditFormField.companyUrl, rules: rules.companyUrlRules },
                    React.createElement(Input, { addonBefore: intl.formatMessage({
                            id: 'registration.registration_form.company_url_host',
                        }) })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'general.facebook_url',
                    }), name: CompanyInfoEditFormField.facebook_url, rules: rules.socialLinkRules },
                    React.createElement(Input, { addonBefore: config.socialBaseUrl.facebook.replace('https://www.', ''), onChange: e => onSocialInputChange(e.target.value, 'facebookUrl', form.setFieldsValue) }))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'general.linkedin_url',
                    }), name: CompanyInfoEditFormField.linkedin_url, rules: rules.socialLinkRules },
                    React.createElement(Input, { addonBefore: config.socialBaseUrl.linkedinCompany.replace('https://www.', ''), onChange: e => onSocialInputChange(e.target.value, 'linkedinUrl', form.setFieldsValue, true) }))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'general.twitter_url',
                    }), name: CompanyInfoEditFormField.twitter_url, rules: rules.socialLinkRules },
                    React.createElement(Input, { addonBefore: config.socialBaseUrl.twitter.replace('https://www.', ''), onChange: e => onSocialInputChange(e.target.value, 'twitterUrl', form.setFieldsValue) }))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'general.youtube_url',
                    }), name: CompanyInfoEditFormField.youtube_url, rules: rules.socialLinkRules },
                    React.createElement(Input, { addonBefore: config.socialBaseUrl.youtube.replace('https://www.', ''), onChange: e => onSocialInputChange(e.target.value, 'youtubeUrl', form.setFieldsValue) }))),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'general.instagram_url',
                    }), name: CompanyInfoEditFormField.instagram_url, rules: rules.socialLinkRules },
                    React.createElement(Input, { addonBefore: config.socialBaseUrl.instagram.replace('https://www.', ''), onChange: e => onSocialInputChange(e.target.value, 'instagramUrl', form.setFieldsValue) })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24 },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'admin.company_premium_profile.company_description',
                    }), name: CompanyInfoEditFormField.description, rules: rules.descriptionRules },
                    React.createElement(RichText.RichTextEditorAnt, null))))));
});
export default CompanyProfileMainContentEditForm;
