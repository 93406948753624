import { Form } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, QuestionMark } from '@src/components/elements';
import { CompanyRegistrationFormField } from '@src/types';
import { QuestionMarkWrapper, RedText } from './RegistrationForm.style';
function ProtectionCheckbox({ agreementRules, setShowDataProcessingModal, }) {
    const [isInitialized, setIsInitialized] = React.useState(false);
    React.useEffect(() => {
        setIsInitialized(true);
    }, []);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement("div", { className: "flex-row" },
        React.createElement(Form.Item, { name: CompanyRegistrationFormField.protection, valuePropName: "checked", rules: agreementRules },
            React.createElement(Checkbox, null,
                React.createElement(RedText, null, "*"),
                React.createElement(FormattedMessage, { id: "registration.registration_form.protection" }))),
        React.createElement(QuestionMarkWrapper, null,
            React.createElement(QuestionMark, { onClick: () => setShowDataProcessingModal(true) }))));
}
export { ProtectionCheckbox };
