var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { StyledPanel } from './Panel.style';
const Panel = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(StyledPanel, Object.assign({}, props, { ref: el => { var _a; return (_a = props.measureRef) === null || _a === void 0 ? void 0 : _a.call(props, el); } }), children));
};
export default Panel;
