import { Modal, Input, Button } from '@src/components/elements';
import React from 'react';
import { notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Api from '@src/api';
import { AssetLibrary } from '@src/components/modules/AssetLibrary';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import styled from 'styled-components';
const EditCompanyAssetGalleryModal = ({ assetGalleryToEdit, selectedAssetGalleryAssetIds, setAssetGalleryToEdit, }) => {
    const assetGalleryRef = React.useRef(null);
    const [titleValue, setTitleValue] = React.useState('');
    const intl = useIntl();
    const { invalidateSSRContext } = useSSRFetchClient();
    const { mutateAsync: updateCompanyAssetGallery } = useAppMutation(Api.CompanyAssetGallery.updateCompanyAssetGallery, {
        onSuccess: () => {
            notification.success({
                message: intl.formatMessage({
                    id: 'general.warning',
                }),
                description: intl.formatMessage({
                    id: 'general.successfuly_saved',
                }),
            });
        },
    });
    const onSubmit = async () => {
        var _a;
        try {
            const assetIds = (_a = assetGalleryRef.current) === null || _a === void 0 ? void 0 : _a.getSelectedAssets().map(({ id }) => id);
            if (assetGalleryToEdit && assetIds && assetIds.length) {
                await updateCompanyAssetGallery({
                    galleryTitle: titleValue,
                    galleryId: assetGalleryToEdit.id,
                    assetIds,
                });
                invalidateSSRContext(SSRFetchConfig.companyProfile.key);
                setAssetGalleryToEdit(null);
                return;
            }
            notification.warning({
                message: intl.formatMessage({
                    id: 'admin.company_premium_profile.no_file_selected',
                }),
                description: intl.formatMessage({
                    id: 'admin.company_premium_profile.choose_at_least_one_file_gallery',
                }),
            });
        }
        catch (err) {
            console.error(err);
            notification.error({
                message: intl.formatMessage({ id: 'general.error' }),
                description: intl.formatMessage({ id: 'general.something_went_wrong' }),
            });
        }
    };
    React.useEffect(() => {
        if (assetGalleryRef.current) {
            assetGalleryRef.current.setSelectedAssetIds(selectedAssetGalleryAssetIds);
        }
    }, [selectedAssetGalleryAssetIds]);
    React.useEffect(() => {
        setTitleValue((assetGalleryToEdit === null || assetGalleryToEdit === void 0 ? void 0 : assetGalleryToEdit.title) || '');
    }, [assetGalleryToEdit]);
    return (React.createElement(EditAssetsModal, { title: React.createElement("div", { className: "flex-row justify-space-between gap-m align-center" },
            React.createElement("div", null, intl.formatMessage({
                id: 'admin.company_premium_profile.edit_gallery',
            })),
            React.createElement("div", { className: "flex-row gap-m" },
                React.createElement(Button, { type: "default", size: "middle", onClick: () => setAssetGalleryToEdit(null) }, intl.formatMessage({ id: 'general.cancel' })),
                React.createElement(Button, { size: "middle", onClick: () => onSubmit() }, intl.formatMessage({ id: 'general.save' })))), visible: Boolean(assetGalleryToEdit), forceRender: true, footer: null },
        React.createElement("div", { className: "m-b-l" },
            React.createElement("div", { className: "m-b-xs" },
                React.createElement(FormattedMessage, { id: "admin.company_premium_profile.gallery_name" })),
            React.createElement(Input, { value: titleValue, onChange: e => setTitleValue(e.target.value), placeholder: intl.formatMessage({
                    id: 'admin.company_premium_profile.gallery_name_placeholder',
                }), maxLength: 100 })),
        React.createElement("div", null,
            React.createElement("div", { className: "m-b-xs" },
                React.createElement(FormattedMessage, { id: "admin.company_premium_profile.media" })),
            React.createElement(AssetLibrary, { ref: assetGalleryRef, areDocumentsDisabled: true, isInSelectMode: true, showSelectedAssetOrder: true }))));
};
const EditAssetsModal = styled(Modal) `
	width: 100vw !important;
	max-width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;

	.ant-modal-close {
		display: none;
	}

	.ant-modal-header {
		position: sticky;
		border-radius: 0;
		position: 0;
		top: 0;
		z-index: 3;
	}
	.ant-modal-body {
		border-radius: 0;
	}
	.ant-modal-content {
		height: 100%;
	}
`;
export { EditCompanyAssetGalleryModal };
