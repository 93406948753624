var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form, notification } from 'antd';
import { useIntl } from 'react-intl';
import { Select } from '@src/components/elements';
import * as Api from '@src/api';
import { normalizeStr } from '@src/helpers/normalize-string';
const { Option } = Select;
const SelectFieldWrapper = (props) => {
    var _a, _b;
    const { jobAreas, useIdAsValue, value, onChange, defaultValue, intl } = props, otherProps = __rest(props, ["jobAreas", "useIdAsValue", "value", "onChange", "defaultValue", "intl"]);
    const onSelectChange = (ids) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(ids.map(id => jobAreas.find(jobArea => jobArea.id === id)));
    };
    return (React.createElement(Select, Object.assign({ mode: "multiple", showArrow: true, optionFilterProp: "children", value: useIdAsValue ? value : (_a = value) === null || _a === void 0 ? void 0 : _a.map(jobArea => jobArea.id), defaultValue: useIdAsValue ? defaultValue : (_b = defaultValue) === null || _b === void 0 ? void 0 : _b.map(jobArea => jobArea.id), filterOption: (input, option) => normalizeStr(option.children).indexOf(normalizeStr(input)) >= 0, onChange: useIdAsValue ? onChange : onSelectChange, placeholder: intl.formatMessage({ id: 'global.job_area.placeholder' }) }, otherProps), jobAreas.map(jobArea => {
        // we need to pass string as children to option for using filterOption on Select search
        // if we pass <FormattedMessage.../> as children, search does not work
        const jobAreaName = intl.formatMessage({
            id: jobArea.translation_key,
        });
        return (React.createElement(Option, { key: jobArea.id, value: jobArea.id }, jobAreaName));
    })));
};
export function JobAreaSelectField(props) {
    const { rules, useIdAsValue = true, label, name, dependencies } = props, otherProps = __rest(props, ["rules", "useIdAsValue", "label", "name", "dependencies"]);
    const intl = useIntl();
    const [jobAreas, setJobAreas] = React.useState([]);
    const init = async () => {
        try {
            const jobAreasResponse = await Api.JobArea.getOfferJobAreas();
            setJobAreas(jobAreasResponse);
        }
        catch (err) {
            console.error(err);
            notification.error({
                message: intl.formatMessage({ id: 'general.error' }),
                description: intl.formatMessage({ id: 'general.something_went_wrong' }),
            });
        }
    };
    React.useEffect(() => {
        init();
    }, []);
    return (React.createElement(Form.Item, { label: label, name: name, rules: rules, dependencies: dependencies },
        React.createElement(SelectFieldWrapper, Object.assign({ useIdAsValue: useIdAsValue, jobAreas: jobAreas, intl: intl }, otherProps))));
}
