export var OfferFilterType;
(function (OfferFilterType) {
    OfferFilterType["type"] = "selectedTypeIds";
    OfferFilterType["location"] = "selectedLocations";
    OfferFilterType["jobArea"] = "selectedJobAreaIds";
    OfferFilterType["salaryMin"] = "salaryMin";
    OfferFilterType["salaryMax"] = "salaryMax";
    OfferFilterType["salaryRate"] = "salaryRate";
    OfferFilterType["searchCompaniesSelected"] = "selectedCompaniesIds";
    OfferFilterType["searchKeywordsSelected"] = "selectedKeywords";
    OfferFilterType["searchSkillTagsSelected"] = "selectedSkillTags";
})(OfferFilterType || (OfferFilterType = {}));
