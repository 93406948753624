import React from 'react';
import braintree from 'braintree-web';
import moment from 'moment';
import * as Api from '@src/api';
export function useBraintree() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [braintree3DSecureInstance, setBraintree3DSecureInstance] = React.useState(null);
    const [braintreeDataCollector, setBraintreeDataCollector] = React.useState(null);
    const [hostedFieldsInstance, setHostedFieldsInstance] = React.useState(null);
    const initBraintreeClient = React.useCallback(async (clientToken) => {
        try {
            const createdBraintreeInstance = await braintree.client.create({
                authorization: clientToken,
            });
            const createdBraintree3DSecureInstance = await braintree.threeDSecure.create({
                client: createdBraintreeInstance,
                version: 2,
            });
            const createdDataCollector = await braintree.dataCollector.create({
                client: createdBraintreeInstance,
            });
            setBraintreeDataCollector(createdDataCollector);
            setBraintree3DSecureInstance(createdBraintree3DSecureInstance);
            return {
                braintreeClient: createdBraintreeInstance,
                braintree3DSecure: createdBraintree3DSecureInstance,
                braintreeDataCollectorInstance: createdDataCollector,
            };
        }
        catch (err) {
            console.error(err);
        }
    }, []);
    const initHostedFields = async (clientToken) => {
        if (hostedFieldsInstance) {
            return hostedFieldsInstance;
        }
        try {
            const createdHostedFieldsInstance = await braintree.hostedFields.create({
                authorization: clientToken,
                styles: {
                    input: {
                        height: '2rem',
                        border: '1px solid black',
                        'font-size': '14px',
                    },
                    'input.invalid': {
                        color: 'red',
                    },
                    'input.valid': {
                        color: 'green',
                    },
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '1111 1111 1111 1111',
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: '123',
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: moment().format('MM / YYYY'),
                    },
                },
            });
            setHostedFieldsInstance(createdHostedFieldsInstance);
            return createdHostedFieldsInstance;
        }
        catch (err) {
            console.error(err);
        }
    };
    const initCheckout = async (shouldInitHostedFields) => {
        setIsLoading(true);
        const { clientToken } = await Api.Admin.getClientToken();
        const { braintreeClient, braintree3DSecure, braintreeDataCollectorInstance } = (await initBraintreeClient(clientToken));
        let braintreeHostedFields;
        if (shouldInitHostedFields) {
            braintreeHostedFields = await initHostedFields(clientToken);
        }
        setIsLoading(false);
        return {
            braintreeHostedFields,
            braintreeClient,
            braintree3DSecure,
            braintreeDataCollectorInstance,
        };
    };
    return {
        isBraintreeLoading: isLoading,
        initCheckout,
        hostedFieldsInstance,
        setHostedFieldsInstance,
        braintree3DSecureInstance,
        setBraintree3DSecureInstance,
        braintreeDataCollector,
        setBraintreeDataCollector,
    };
}
