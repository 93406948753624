import { Modal } from '@src/components/elements';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import CompanyProfileMainContentEditForm from './CompanyProfileMainContentEditForm';
const CompanyProfileMainContentEditModal = ({ isEditModalOpen, setIsEditModalOpen, company }) => {
    const companyProfileHeaderFormRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const intl = useIntl();
    const theme = useTheme();
    return (React.createElement(Modal, { title: intl.formatMessage({ id: 'admin.company_premium_profile.edit_company_profile_body' }), visible: isEditModalOpen, width: theme.modalWidth.largeModal, onOk: () => { var _a; return (_a = companyProfileHeaderFormRef.current) === null || _a === void 0 ? void 0 : _a.submitForm(); }, okButtonProps: {
            loading: isSubmitting,
        }, onCancel: () => setIsEditModalOpen(false) },
        React.createElement(CompanyProfileMainContentEditForm, { setIsModalOpen: setIsEditModalOpen, setIsSubmitting: setIsSubmitting, company: company, ref: companyProfileHeaderFormRef })));
};
export { CompanyProfileMainContentEditModal };
