import styled, { css } from 'styled-components';
import Add from '@public/media/icons/streamline-icon-add.svg';
import { Button } from '@src/components/elements';
import { transparentize } from 'polished';
const mediaFromLaptop = '@media screen and (max-width: 1024px)';
export const CompanyNumbers = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
`;
export const CompanyNumbersTitle = styled.div `
	color: ${({ theme }) => theme.color.gray6};
	font-size: 1rem;
`;
export const CompanyName = styled.h2 `
	margin: 0;
	margin-bottom: 2rem;
	font-size: 1.75rem;
	color: ${({ theme }) => theme.color.brand1};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	& > a:hover {
		text-decoration: underline;
	}
`;
export const AddIcon = styled(Add) `
	path {
		width: 100%;
		height: 100%;
	}
`;
export const ImageBlockWrapper = styled.div `
	display: flex;
	position: relative;

	${mediaFromLaptop} {
		flex-direction: column;
	}
`;
export const ProfileBoxBody = styled.div `
	padding: 1rem;
	position: relative;
	padding-top: 75px;
`;
export const Header = styled.div `
	width: 100%;
	height: 90px;
	background: black url(${props => props.background}) no-repeat 50%;
	background-size: cover;
	border-radius: 4px 4px 0 0;

	${({ isInAdmin }) => isInAdmin &&
    css `
			&:hover {
				cursor: pointer;
			}
		`}
`;
export const ImageBlock = styled.div `
	position: absolute;
	border: 1px solid ${({ theme }) => theme.color.gray4};
	background: white;
	z-index: 1;
	top: -50px;
	left: calc(50% - 50px);
	width: 100px;
	height: 100px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;

	${({ isInAdmin }) => isInAdmin &&
    css `
			&:hover {
				cursor: pointer;
			}
		`}

	& > * {
		padding: 4px;
		background: white;
		max-width: 100%;
		max-height: 100%;
	}

	svg {
		width: 70%;
		height: 70%;
		color: ${({ theme }) => theme.color.gray4};
	}
`;
export const PrimaryColorWrapper = styled.span `
	color: ${({ theme }) => theme.color.brand1};
`;
export const CompanyNumberBoxDiv = styled.div `
	flex: 1;
	display: flex;
	flex-direction: column;
	min-width: 0;
	align-items: center;
	margin-top: auto;
`;
export const EditCompanyButton = styled(Button) `
	width: 100%;
	justify-content: center;
	margin-bottom: 0.25rem;
`;
export const PremiumTextWrapper = styled.div `
	color: ${({ theme }) => theme.color.gray6};
`;
export const PremiumIconWrapper = styled.div `
	margin-right: 0.5rem;
	background: ${({ theme }) => transparentize(0.8, theme.color.brand1)};
	width: 1.5rem;
	height: 1.5rem;
	max-width: 1.5rem;
	max-height: 1.5rem;
	min-width: 1.5rem;
	min-height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.color.brand1};
	border-radius: 1rem;
	color: ${({ theme }) => theme.color.brand1};
`;
