import config from '@src/config';
import axios from 'axios';
const client = axios.create({
    baseURL: config.api,
    withCredentials: true,
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    },
});
export async function getPostsCount() {
    try {
        const response = await client.get('/0/cms/magazine-posts-count');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getPosts(currentPage) {
    try {
        const response = await client.post('/0/cms/magazine-posts', {
            skip: (currentPage - 1) * config.magazine.postsPerPage,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getPost(options) {
    try {
        const response = await client.post('/0/cms/magazine-post', options);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
