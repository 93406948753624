import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { history } from '@src/app/index';
import DeprecatedButton from 'components/modules/global/button';
function OfferNotActive() {
    const intl = useIntl();
    return (React.createElement("div", { className: "panel panel--payment u-text-center" },
        React.createElement("h4", { className: "offer-detail__cta-not-active__text" },
            React.createElement(FormattedMessage, { id: "offer_detail.details.this_offer_is_not_available", defaultMessage: "Bohu\u017Eia\u013E, t\u00E1to ponuka u\u017E nie je dostupn\u00E1." })),
        React.createElement(DeprecatedButton, { text: intl.formatMessage({
                id: 'offer_detail.details.go_to_offers',
                defaultMessage: 'Prejsť na ponuky',
            }), 
            // @ts-ignore
            onClick: () => history.pushLocalized('/ponuky') })));
}
export { OfferNotActive };
