import styled from 'styled-components';
export const PopupHeader = styled.img `
	display: block;
	border-radius: 4px;
	max-width: 100%;
	height: auto;
`;
export const MainContent = styled.div `
	margin: 2rem 0 0;
	text-align: center;

	h1 {
		font-size: 0.8rem;
		color: ${props => props.theme.color.brand1};

		strong {
			font-weight: normal;
			margin: 0.3rem 0 0;
			font-size: 2rem;
			display: block;
			color: ${props => props.theme.color.brand2};
		}

		em {
			font-weight: bold;
			font-style: normal;
		}
	}

	.main {
		padding: 1em;
		color: ${props => props.theme.color.black};
		margin: 1rem 0 0;
		font-size: 1rem;
		em {
			font-weight: bold;
			font-style: normal;
		}
	}

	.confirm {
		padding: 1em;
	}

	.note {
		padding: 1em;
		color: ${props => props.theme.color.gray7};
		margin: 1rem 0 0;
		font-size: 0.9rem;
		border-radius: ${props => props.theme.borderRadius.base};
		background: ${props => props.theme.color.gray2};

		strong {
			color: ${props => props.theme.color.gray8};
		}
	}
`;
