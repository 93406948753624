var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import React from 'react';
import styled, { css } from 'styled-components';
const CompanyPremiumElementWrapper = (_a) => {
    var { children, shouldBeHiddenIfPremiumIsActive = true } = _a, otherProps = __rest(_a, ["children", "shouldBeHiddenIfPremiumIsActive"]);
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const { hasActivePremiumSubscription } = otherProps;
    const onClickDefaultHandler = () => {
        setIsPremiumCompanyProfileSubscriptionModalOpen(true);
    };
    if (shouldBeHiddenIfPremiumIsActive) {
        return null;
    }
    return (React.createElement(StyledDiv, Object.assign({}, (!hasActivePremiumSubscription && { onClick: onClickDefaultHandler }), otherProps), children));
};
const StyledDiv = styled.div `
	width: 100%;
	height: 100%;
	${({ hasActivePremiumSubscription, onClick }) => (!hasActivePremiumSubscription || onClick) &&
    css `
			cursor: pointer;
		`}
`;
export { CompanyPremiumElementWrapper };
