import React from 'react';
import { Progress } from '@src/components/elements';
import { UploadingAssetCardContainer, UploadingAssetCardContent, UploadingAssetCardFooter, UploadingAssetCardFooterText, } from './UploadingAssetCard.style';
export function UploadingAssetCard({ uploadingAsset }) {
    return (React.createElement(UploadingAssetCardContainer, null,
        React.createElement(UploadingAssetCardContent, null,
            React.createElement(Progress, { type: "circle", percent: uploadingAsset.progressPercentage })),
        React.createElement(UploadingAssetCardFooter, null,
            React.createElement(UploadingAssetCardFooterText, null, uploadingAsset.file.name))));
}
