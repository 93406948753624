import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from '@src/components/elements';
import { BillingProfilesSelect } from './AdminOrder.style';
const { Option } = Select;
const AdminOrderBillingProfile = ({ billingProfiles, selectedBillingProfile, setSelectedBillingProfile }) => {
    return (React.createElement("div", { className: "order__company" },
        React.createElement("h4", { className: "order__company-header" },
            React.createElement(FormattedMessage, { id: "admin.order.choose_billing_profile", defaultMessage: "Vyberte faktura\u010Dn\u00FD profil" })),
        React.createElement(BillingProfilesSelect, { className: "m-b-m", value: selectedBillingProfile === null || selectedBillingProfile === void 0 ? void 0 : selectedBillingProfile.id, onChange: profileId => {
                const newBillingProfile = billingProfiles.find(profile => profile.id === profileId);
                if (newBillingProfile) {
                    setSelectedBillingProfile(newBillingProfile);
                }
            } }, billingProfiles.length &&
            billingProfiles.map(profile => (React.createElement(Option, { key: profile.id, value: profile.id }, profile.name)))),
        selectedBillingProfile && (React.createElement("h4", { className: "order__company-header" },
            React.createElement(FormattedMessage, { id: "admin.order.billing_informations", defaultMessage: "Faktura\u010Dn\u00E9 \u00FAdaje" }))),
        selectedBillingProfile && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "order__company-group" },
                React.createElement("div", null, selectedBillingProfile.name),
                React.createElement("div", null, selectedBillingProfile.street),
                React.createElement("div", null,
                    selectedBillingProfile.postalCode,
                    "\u00A0",
                    selectedBillingProfile.city)),
            React.createElement("div", { className: "order__company-group" },
                React.createElement("div", null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "admin.order.cin", defaultMessage: "I\u010CO" })),
                    ' ',
                    selectedBillingProfile.cin),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "admin.order.tin", defaultMessage: "DI\u010C" })),
                    ' ',
                    selectedBillingProfile.tin),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "admin.order.tin_vat", defaultMessage: "I\u010C DPH" })),
                    ' ',
                    selectedBillingProfile.tinVat))))));
};
export { AdminOrderBillingProfile };
