import React from 'react';
import * as Api from '@src/api';
import { useMutation, useQueryClient } from 'react-query';
import { Queries } from '@src/types/queries';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { useAccount } from './account-context';
const SavedOffersContext = React.createContext({
    savedOffers: [],
    isFetching: false,
    isDeleting: false,
    isCreating: false,
    removeSavedOffer: () => { },
    createSavedOffer: () => { },
});
export const useSavedOffersContextProvider = () => {
    const account = useAccount();
    const { data: savedOffers, isLoading: isFetching, refetch, } = useAppQuery(Queries.getSavedOffers, () => (account ? Api.SavedOffer.getSavedOffers() : []));
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { mutateAsync: createSavedOffer, isLoading: isCreating } = useMutation((offerId) => Api.SavedOffer.createSavedOffer(offerId), {
        onSuccess: isOfferAlreadySaved => {
            queryClient.invalidateQueries(Queries.getSavedOffers);
            if (isOfferAlreadySaved) {
                notification.warning({
                    message: intl.formatMessage({
                        id: 'general.warning',
                    }),
                    description: intl.formatMessage({
                        id: 'offers.saved_offers.offer_already_saved',
                    }),
                });
                return;
            }
            notification.success({
                message: intl.formatMessage({
                    id: 'general.notification',
                }),
                description: intl.formatMessage({
                    id: 'offers.saved_offers.offer_saved',
                }),
            });
        },
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        },
    });
    const { mutateAsync: removeSavedOffer, isLoading: isDeleting } = useMutation((offerId) => Api.SavedOffer.deleteSavedOffer(offerId), {
        onSuccess: () => {
            queryClient.invalidateQueries(Queries.getSavedOffers);
            notification.success({
                message: intl.formatMessage({
                    id: 'general.notification',
                }),
                description: intl.formatMessage({
                    id: 'offers.saved_offers.offer_removed',
                }),
            });
        },
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        },
    });
    React.useEffect(() => {
        refetch();
    }, [account]);
    return {
        SavedOffersContextProvider: SavedOffersContext.Provider,
        value: {
            savedOffers: savedOffers || [],
            isFetching,
            removeSavedOffer,
            isDeleting,
            createSavedOffer,
            isCreating,
        },
    };
};
export const useSavedOffersContextValue = () => {
    const context = React.useContext(SavedOffersContext);
    return context;
};
