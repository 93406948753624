import React from 'react';
import { Row, Col } from 'antd';
import { Holder, Panel, Title } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
import { useAccount } from '@src/context/account-context';
import { useHistory } from 'react-router';
import { RegistrationCandidateForm } from './RegistrationCandidateForm';
export function RegistrationCandidate() {
    const history = useHistory();
    const user = useAccount();
    const isUserLoggedIn = Boolean(user);
    React.useEffect(() => {
        if (isUserLoggedIn) {
            history.pushLocalized('/registracia-prihlaseny-pouzivatel');
        }
    }, [isUserLoggedIn]);
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, lg: { span: 12 } },
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "registration_student.registration" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, lg: { span: 12 } },
                React.createElement(Panel, null,
                    React.createElement(RegistrationCandidateForm, null))))));
}
