import { Button } from '@src/components/elements';
import { PaymentMethod } from '@src/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { PaymentInformation } from '../payment-information/PaymentInformation';
const StepConfirmation = ({ invoiceId, paymentMethod }) => {
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    return (React.createElement(React.Fragment, null,
        paymentMethod === PaymentMethod.bank_transfer && React.createElement(PaymentInformation, { invoiceId: invoiceId }),
        paymentMethod !== PaymentMethod.bank_transfer && (React.createElement("div", { className: "flex-col justify-center align-center p-l" },
            React.createElement("div", { className: "m-b-l" },
                React.createElement("img", { style: { width: '160px' }, src: "/media/illustrations/streamline-icon-gems-money-finance.svg", alt: "" })),
            React.createElement("div", { className: "font-size-l" },
                React.createElement(FormattedMessage, { id: "admin.subscription.premium_company_profile_activated_successfully" })))),
        React.createElement("div", { className: "flex-center m-t-l" },
            React.createElement(Button, { size: "middle", onClick: () => setIsPremiumCompanyProfileSubscriptionModalOpen(false) },
                React.createElement(FormattedMessage, { id: "general.close" })))));
};
export { StepConfirmation };
