import * as Api from '@src/api';
import { every, isEmpty, omit } from 'lodash';
export function getValidationRules({ intl, subscriptionToken, formValues }) {
    const emailValidationRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.email',
            }),
        },
        {
            type: 'email',
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.enter_valid_email',
            }),
        },
        {
            validator: async (_, value) => {
                if (!value || !subscriptionToken) {
                    return;
                }
                const emailIsTaken = await Api.OfferNotificationSubscription.isEmailTaken(value, subscriptionToken);
                if (emailIsTaken) {
                    throw new Error(intl.formatMessage({
                        id: 'forms.offer_notification_subscription_form.validation_schema.email.taken',
                    }));
                }
            },
        },
    ];
    const subscriptionFilterRules = [
        () => {
            let valuesWithoutEmail = formValues;
            if (formValues && 'email' in formValues) {
                valuesWithoutEmail = omit(formValues, ['email']);
            }
            const areAllFieldsEmpty = every(valuesWithoutEmail, isEmpty);
            return {
                required: areAllFieldsEmpty,
                message: intl.formatMessage({ id: 'offers.offer_notification_subscription_form.fill_at_least_on' }),
            };
        },
    ];
    const salaryMaxValidationRules = [
        {
            type: 'number',
            message: intl.formatMessage({
                id: 'admin.offer_validation.number_has_wrong_format',
            }),
        },
        ({ getFieldValue }) => ({
            validator: (_, value) => {
                const minValue = getFieldValue('salaryMin');
                const isSalaryRateSelected = Boolean(getFieldValue('salaryRate'));
                return !isSalaryRateSelected ||
                    (isSalaryRateSelected && value >= minValue) ||
                    (isSalaryRateSelected && (!value || !minValue))
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({
                        id: 'offers.offer_notification_subscription_form.wrong_maximum_value',
                    })));
            },
        }),
    ];
    return {
        emailValidationRules,
        subscriptionFilterRules,
        salaryMaxValidationRules,
    };
}
