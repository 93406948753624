import React from 'react';
import { useAuthContextValue } from '@src/context/auth-context';
import { notification } from 'antd';
import { AccountErrorCode } from '@src/types/account';
import { Button } from '@src/components/elements';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfig } from '@src/hooks/useConfig';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { useAccount } from '@src/context/account-context';
import { useSavedOffersContextValue } from '@src/context/saved-offer-context';
import { SaveButtonText } from './ToggleSaveOfferButton.style';
function ToggleSaveOfferButton({ offerId }) {
    const { savedOffers, removeSavedOffer, createSavedOffer } = useSavedOffersContextValue();
    const isOfferSaved = savedOffers.map(({ offerId: id }) => id).includes(offerId);
    const theme = useTheme();
    const { checkAuthentication } = useAuthContextValue();
    const intl = useIntl();
    const config = useConfig();
    const account = useAccount();
    const isInAdmin = (account === null || account === void 0 ? void 0 : account.accountTypeId) === config.accountType.company;
    const toggleSaveOffer = async () => {
        try {
            const authenticatedAccount = await checkAuthentication();
            if (authenticatedAccount && authenticatedAccount.account_type_id === 1) {
                notification.warning({
                    message: intl.formatMessage({
                        id: 'general.warning',
                    }),
                    description: intl.formatMessage({
                        id: 'general.action_not_allowed_for_company_account',
                    }),
                });
                return;
            }
            if (isOfferSaved) {
                removeSavedOffer(offerId);
            }
            else {
                createSavedOffer(offerId);
            }
        }
        catch (err) {
            if (err !== AccountErrorCode.login_canceled) {
                notification.error({
                    message: intl.formatMessage({
                        id: 'general.error',
                    }),
                    description: intl.formatMessage({
                        id: 'general.something_went_wrong',
                    }),
                });
                console.error(err);
            }
        }
    };
    return (React.createElement(React.Fragment, null, !isInAdmin && (React.createElement(React.Fragment, null,
        React.createElement(Button, { size: "middle", onClick: e => {
                e.preventDefault();
                toggleSaveOffer();
            }, type: "secondary" }, isOfferSaved ? (React.createElement(HeartFilled, { style: { color: theme.color.error } })) : (React.createElement("div", { className: "flex-center" },
            React.createElement(HeartOutlined, null),
            React.createElement(SaveButtonText, null,
                React.createElement(FormattedMessage, { id: "general.save" })))))))));
}
export { ToggleSaveOfferButton };
