import React from 'react';
import { Radio } from 'antd';
import styled, { createGlobalStyle, css } from 'styled-components';
export function RadioGroupBox({ boxes, children, defaultValue, buttonStyle, onChange, size = 'middle', value, disabled, }) {
    return (React.createElement("div", { className: "rgb-ant-wrapper" },
        React.createElement(RadioGroupBoxAntOverride, { isDisabled: disabled }),
        React.createElement(Radio.Group, { disabled: disabled, value: value, defaultValue: defaultValue, buttonStyle: buttonStyle, onChange: onChange },
            boxes.map(box => (React.createElement(Box, { value: box.value, key: box.value, size: size },
                React.createElement(BoxContentWrapper, null,
                    box.icon,
                    box.label)))),
            children)));
}
const Box = styled(Radio.Button) `
	${({ size }) => {
    switch (size) {
        case 'large':
            return css `
					width: 120px;
					height: 120px;
					padding: 0.8rem 0.5rem;
				`;
        case 'middle':
            return css `
					width: 60px;
					height: 60px;
					padding: 0.125rem;
				`;
        case 'small':
            return css `
					width: 24px;
					height: 24px;
					padding: 0.125rem;
				`;
        default:
            return '';
    }
}}
	text-align: center;
	font-size: 12px;
	line-height: 12px;
	svg {
		max-width: 60px;
		max-height: 60px;
		margin-bottom: 1rem;
	}
	.anticon {
		width: 60px;
		height: 60px;
		margin-bottom: 1rem;
	}
`;
const BoxContentWrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
const RadioGroupBoxAntOverride = createGlobalStyle `
	.rgb-ant-wrapper{
		display: flex;
		.ant-radio-group {
			display: flex;
			gap: 1rem;
			justify-content: center;
			flex-wrap: wrap;
		}
		.ant-radio-button-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 2px solid ${({ theme }) => theme.color.gray5};
			box-shadow: none !important;

			&:hover {
				${({ isDisabled }) => !isDisabled &&
    css `
						border: 2px solid ${({ theme }) => theme.color.brand1};
					`}
			}
			&::before {
				content: none;
			}
			.ant-radio-button,
			.ant-radio-button-checked
			{
				border: none;
			}
		}
		.ant-radio-button-wrapper-checked {
			border-radius: 8px;
			outline: none;
			border: 2px solid ${({ theme }) => theme.color.brand1} !important;
			box-shadow: none;

			
			&:hover {
				${({ isDisabled }) => !isDisabled &&
    css `
						border: 2px solid ${({ theme }) => theme.color.brand1};
					`}
			}
		}
	}
`;
