import { Button, Link, Switch } from '@src/components/elements';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { useConfig } from '@src/hooks/useConfig';
import { SubscriptionPeriods } from '@src/types';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { useHistory } from 'react-router';
import { SubscriptionSelectionCard } from './SubscriptionSelectionCard';
import { EPremiumCompanySubscriptionModalSteps } from './PremiumCompanyProfileSubscriptionModal';
const StepPremiumCompanyProfile = ({ setCurrentStep, selectedSubscriptionPeriod, setSelectedSubscriptionPeriod, }) => {
    const [showPricesWithVat, setShowPricesWithVat] = React.useState(false);
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const config = useConfig();
    const history = useHistory();
    return (React.createElement(PremiumCompanyProfileStepContent, null,
        React.createElement("h2", null,
            React.createElement(FormattedMessage, { id: "admin.premium_company_profile" })),
        React.createElement(GreyTextWrapper, null,
            React.createElement(FormattedMessage, { id: "admin.premium_profile_modal_description" })),
        React.createElement(PeriodSelectionCardsContainer, null,
            React.createElement(SubscriptionSelectionCard, { subscriptionPeriod: SubscriptionPeriods.OneMonthPeriod, showPricesWithVat: showPricesWithVat, selectedSubscriptionPeriod: selectedSubscriptionPeriod, setSelectedSubscriptionPeriod: setSelectedSubscriptionPeriod }),
            React.createElement(SubscriptionSelectionCard, { subscriptionPeriod: SubscriptionPeriods.SixMonthsPeriod, showPricesWithVat: showPricesWithVat, selectedSubscriptionPeriod: selectedSubscriptionPeriod, setSelectedSubscriptionPeriod: setSelectedSubscriptionPeriod }),
            React.createElement(SubscriptionSelectionCard, { subscriptionPeriod: SubscriptionPeriods.TwelveMonthsPeriod, showPricesWithVat: showPricesWithVat, selectedSubscriptionPeriod: selectedSubscriptionPeriod, setSelectedSubscriptionPeriod: setSelectedSubscriptionPeriod })),
        React.createElement("div", { className: "flex-center m-t-l" },
            React.createElement("span", { className: "m-r-s" },
                React.createElement(FormattedMessage, { id: "companies.credits.show_prices_with_vat" }),
                ' ',
                React.createElement(FormattedNumber, { value: config.pricing.VAT, style: "unit", unit: "percent" })),
            React.createElement(Switch, { checked: showPricesWithVat, onChange: checked => setShowPricesWithVat(checked) })),
        React.createElement(GreyTextWrapper, null,
            React.createElement(SubscriptionFeaturesTableLink, { onClick: () => {
                    history.pushLocalized('/premium-firemny-profil#features');
                    setIsPremiumCompanyProfileSubscriptionModalOpen(false);
                } },
                React.createElement(FormattedMessage, { id: "admin.subscription.features_table" }))),
        React.createElement("div", { className: "flex-center m-t-l" },
            React.createElement(Button, { className: "m-s", size: "middle", type: "ghost", onClick: () => setIsPremiumCompanyProfileSubscriptionModalOpen(false) },
                React.createElement(FormattedMessage, { id: "global.cancel" })),
            React.createElement(Button, { className: "m-s", size: "middle", onClick: () => setCurrentStep(EPremiumCompanySubscriptionModalSteps.CHECKOUT) },
                React.createElement(FormattedMessage, { id: "admin.subscription.confirm_and_checkout" })))));
};
const PeriodSelectionCardsContainer = styled.div `
	display: flex;
	justify-content: center;
	gap: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	flex-wrap: wrap;
`;
const PremiumCompanyProfileStepContent = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1rem 0;
	align-items: center;
`;
const GreyTextWrapper = styled.div `
	color: ${({ theme }) => theme.color.gray7};
	margin-top: 1rem;
	text-align: center;
`;
const SubscriptionFeaturesTableLink = styled(Link) `
	&,
	&:link,
	&:visited,
	&:focus {
		color: ${({ theme }) => theme.color.gray7};
		text-decoration: underline;
	}
`;
export { StepPremiumCompanyProfile };
