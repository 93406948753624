import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Spinner } from '@src/components/elements';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
import styled from 'styled-components';
import { CompanyPage } from './Company';
import CompanyProfile from '../admin/modules/company-profile';
const PublicCompanyProfilePage = () => {
    const [subscribersCount, setSubscribersCount] = React.useState();
    const params = useParams();
    const history = useHistory();
    const { data: company, error, isLoading, } = useSSRFetch(SSRFetchConfig.companyProfile.key, params.companyUniqueUrl, [
        subscribersCount,
        params.companyUniqueUrl,
    ]);
    React.useEffect(() => {
        if (error) {
            history.pushLocalized('/error');
        }
    }, [error]);
    React.useEffect(() => {
        var _a, _b;
        if (((_a = company === null || company === void 0 ? void 0 : company.subscribers) === null || _a === void 0 ? void 0 : _a.length) !== subscribersCount) {
            setSubscribersCount((_b = company === null || company === void 0 ? void 0 : company.subscribers) === null || _b === void 0 ? void 0 : _b.length);
        }
    }, [company]);
    if (isLoading || !company) {
        return (React.createElement("div", { className: "width-100 flex-row justify-center" },
            React.createElement(CustomSpinner, null)));
    }
    if (company.companyPremiumSettings && company.companyPremiumSettings.hasPremiumProfileEnabled) {
        return React.createElement(CompanyProfile, { company: company });
    }
    return React.createElement(CompanyPage, { company: company, subscribersCount: subscribersCount });
};
const CustomSpinner = styled(Spinner) `
	align-items: center;
	height: 500px;
	display: flex;
	justify-content: center;

	.ant-spin-dot {
		font-size: 5rem;
	}
`;
export { PublicCompanyProfilePage };
