import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Form, Input, notification, Row } from 'antd';
// field components
import { Button } from '@src/components/elements';
import * as Api from '@src/api';
import { useHistory } from 'react-router';
export function ResetPasswordForm() {
    const intl = useIntl();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const onFinish = async (values) => {
        try {
            setLoading(true);
            // @ts-ignore
            const { token } = history.getQuery();
            const resetPasswordResponse = await Api.Account.resetPassword(token, values.password);
            if (resetPasswordResponse.status === 200) {
                const redirectPath = resetPasswordResponse.type === 1 ? '/admin' : '/ucet';
                // @ts-ignore
                history.pushLocalized(redirectPath);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
        finally {
            setLoading(false);
        }
    };
    return (React.createElement(Form, { form: form, layout: "vertical", onFinish: onFinish },
        React.createElement("div", { className: "form__row" },
            React.createElement(Row, { gutter: [16, 0] },
                React.createElement(Col, { xs: { span: 24 } },
                    React.createElement(Form.Item, { label: intl.formatMessage({
                            id: 'reset_password.reset_password.password',
                        }), name: "password", rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'registration.sync_validation.password',
                                }),
                            },
                            {
                                min: 8,
                                message: intl.formatMessage({
                                    id: 'registration.sync_validation.restricted_length_password',
                                }),
                            },
                            {
                                pattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g,
                                message: intl.formatMessage({
                                    id: 'registration.sync_validation.restricted_symbols_password',
                                }),
                            },
                        ], validateTrigger: "onBlur" },
                        React.createElement(Input.Password, null))),
                React.createElement(Col, { xs: { span: 24 } },
                    React.createElement(Form.Item, { label: intl.formatMessage({
                            id: 'reset_password.reset_password.passwordRepeat',
                        }), name: "passwordRepeat", rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'registration.sync_validation.fill_repeate_password_field',
                                }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value && getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(intl.formatMessage({
                                        id: 'registration.sync_validation.passwordRepeat',
                                    })));
                                },
                            }),
                        ], validateTrigger: "onBlur" },
                        React.createElement(Input.Password, null))))),
        React.createElement("div", { className: "form__submit" },
            React.createElement(Button, { htmlType: "submit", color: "brand", loading: loading },
                React.createElement(FormattedMessage, { id: "reset_password.reset_password.password_recovery" })))));
}
