import React from 'react';
import { Form, AutoComplete, Alert, notification } from 'antd';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import _omitBy from 'lodash/omitBy';
import * as Api from '@src/api';
import { Input, Checkbox, Button, QuestionMark } from '@src/components/elements';
import { DataProcessingOrNewsletterModal } from '@src/components/modules/global/DataProcessingOrNewsletterModal';
import SlugOffer from '@src/shared/slug-offer';
import { SearchOutlined } from '@ant-design/icons';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { CompanyRegistrationFormField } from '@src/types';
import { registrationFormRules } from './registrationFormRules';
import { ProtectionCheckbox } from './ProtectionCheckbox';
import { CompanyInformationForm } from './CompanyInformationForm';
import { PersonalInformationForm } from './PersonalInformationForm';
import { LoginCredentialsForm } from './LoginCredentialsForm';
import { QuestionMarkWrapper, RedText, RequiredWrapper, SubmitButtonWrapper } from './RegistrationForm.style';
const emptyCompanyInfoFields = {
    [CompanyRegistrationFormField.street_address]: '',
    [CompanyRegistrationFormField.city]: '',
    [CompanyRegistrationFormField.postal_code]: '',
    [CompanyRegistrationFormField.cin]: '',
    [CompanyRegistrationFormField.tin]: '',
    [CompanyRegistrationFormField.tin_vat]: '',
    [CompanyRegistrationFormField.description]: null,
    [CompanyRegistrationFormField.website]: '',
    [CompanyRegistrationFormField.area]: '',
    [CompanyRegistrationFormField.employees]: '',
};
export function RegistrationForm({ intl }) {
    const [form] = Form.useForm();
    const history = useHistory();
    const [isInvitationUsed, setIsInvitationUsed] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [companyInfo, setCompanyInfo] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    // @ts-ignore
    const cit = history.getQuery && history.getQuery().cit;
    const wasInvited = Boolean(cit);
    const autoCompleteRef = React.useRef(null);
    const [showDataProcessingModal, setShowDataProcessingModal] = React.useState(false);
    const [showNewsletterModal, setShowNewsletterModal] = React.useState(false);
    const [invalidAddressError, setInvalidAddressError] = React.useState(false);
    const SSRFetchClient = useSSRFetchClient();
    const hasCompanyFromFinstat = Object.keys(companyInfo).length > 0 && !companyInfo.id;
    const isCompanyRegistered = Boolean(Object.keys(companyInfo).length > 0 && companyInfo.id);
    const wasNotInvitedOrInvitationWasUsed = !wasInvited || isInvitationUsed;
    const wasInvitedAndInvitationUsed = wasInvited && isInvitationUsed;
    const rules = registrationFormRules(intl);
    const { companyNameRules, agreementRules } = rules;
    const companyInfoInit = async () => {
        try {
            const company = await Api.Registration.getCompanyByInvitationToken(cit);
            form.setFieldsValue(company);
            setCompanyInfo(company);
        }
        catch (err) {
            setIsInvitationUsed(true);
        }
    };
    React.useEffect(() => {
        if (wasInvited) {
            companyInfoInit();
        }
    }, []);
    const onSearchChange = async (companyName) => {
        if (!companyName) {
            return;
        }
        try {
            const suggestions = await Api.Registration.getCompaniesSuggestions(companyName);
            setOptions(suggestions.map(suggestion => ({ value: suggestion.label, cin: suggestion.cin })));
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
    };
    const debouncedOnSearchChange = debounce(onSearchChange, 300);
    const loadCompanyInfo = async (value, option) => {
        try {
            if (hasCompanyFromFinstat) {
                setCompanyInfo({});
            }
            const parameterKey = option.cin ? 'cin' : 'name';
            const parameterValue = option.cin || option.value;
            const companyInfoResponse = await Api.Registration.getCompanyInfo(parameterKey, parameterValue);
            form.setFieldsValue(companyInfoResponse);
            setCompanyInfo(companyInfoResponse);
            // @ts-ignore
            autoCompleteRef.current.blur();
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
                duration: 15,
            });
        }
    };
    const onSubmit = async (companyForm) => {
        var _a;
        try {
            setInvalidAddressError(false);
            setLoading(true);
            let companyIdOrForm = companyInfo.id ? Object.assign(Object.assign({}, companyForm), companyInfo) : companyForm;
            const companyUrl = companyIdOrForm.company_url || new SlugOffer({ text: companyIdOrForm.name }).result;
            companyIdOrForm.company_url = companyUrl;
            companyIdOrForm = _omitBy(companyIdOrForm, val => val === '');
            const registrationResponse = await Api.Registration.registerCompany(cit, companyIdOrForm);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            if (registrationResponse.status === 201) {
                // @ts-ignore
                history.pushLocalized('/admin');
                notification.success({
                    message: intl.formatMessage({
                        id: 'general.warning',
                    }),
                    description: intl.formatMessage({
                        id: 'registration.registration_form.registration_success',
                    }),
                });
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                setInvalidAddressError(true);
            }
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
        finally {
            setLoading(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, layout: "vertical", onFinish: onSubmit, autoComplete: "off", scrollToFirstError: {
                behavior: actions => {
                    if (actions.length) {
                        const [{ el, top, left }] = actions;
                        el.scrollTop = top - 200;
                        el.scrollLeft = left;
                    }
                },
            } },
            wasInvitedAndInvitationUsed && (React.createElement(Alert, { message: intl.formatMessage({ id: 'general.warning' }), description: React.createElement(FormattedMessage, { id: "registration.registration_form.invitation_already_used" }), type: "warning", showIcon: true, closable: true, className: "m-b-l" })),
            isCompanyRegistered && !wasInvited && (React.createElement(Alert, { message: intl.formatMessage({ id: 'general.warning' }), description: React.createElement(FormattedMessage, { id: "registration.registration_form.company_already_registered", values: {
                        companyName: companyInfo.name,
                        strong: chunks => React.createElement("strong", null, chunks),
                    } }), type: "warning", showIcon: true, className: "m-b-l" })),
            React.createElement(Form.Item, { label: intl.formatMessage({
                    id: 'registration.registration_form.name',
                }), name: CompanyRegistrationFormField.name, rules: companyNameRules },
                React.createElement(AutoComplete, { ref: autoCompleteRef, onSearch: debouncedOnSearchChange, options: options, onSelect: loadCompanyInfo, onChange: value => {
                        const option = options.find(o => o.value === value);
                        form.setFieldsValue(emptyCompanyInfoFields);
                        setCompanyInfo({});
                        if (option) {
                            loadCompanyInfo(value, option);
                        }
                    }, notFoundContent: intl.formatMessage({
                        id: 'registration.registration_form.no_results',
                    }), disabled: !wasNotInvitedOrInvitationWasUsed && form.getFieldValue(CompanyRegistrationFormField.name), allowClear: true },
                    React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: intl.formatMessage({
                            id: 'forms.offer_notification_subscription_form.render_select.write',
                        }) }))),
            wasNotInvitedOrInvitationWasUsed && !isCompanyRegistered && (React.createElement(CompanyInformationForm, { intl: intl, invalidAddressError: invalidAddressError, isCompanyRegistered: isCompanyRegistered, companyInfo: companyInfo, rules: rules })),
            React.createElement(PersonalInformationForm, { intl: intl, rules: rules }),
            React.createElement(LoginCredentialsForm, { intl: intl, rules: rules, form: form, wasNotInvitedOrInvitationWasUsed: wasNotInvitedOrInvitationWasUsed }),
            React.createElement(ProtectionCheckbox, { agreementRules: agreementRules, setShowDataProcessingModal: setShowDataProcessingModal }),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Form.Item, { name: CompanyRegistrationFormField.newsletter, valuePropName: "checked" },
                    React.createElement(Checkbox, null,
                        React.createElement(FormattedMessage, { id: "registration.registration_form.newsletter" }))),
                React.createElement(QuestionMarkWrapper, null,
                    React.createElement(QuestionMark, { onClick: () => setShowNewsletterModal(true) }))),
            React.createElement(SubmitButtonWrapper, { "data-cy": "form-submit" },
                React.createElement(Button, { color: "brand", htmlType: "submit", loading: loading },
                    React.createElement("span", { className: "m-l-xxs" },
                        React.createElement(FormattedMessage, { id: "registration.registration_form.register" })))),
            React.createElement(RequiredWrapper, null,
                React.createElement(RedText, null, "*"),
                React.createElement(FormattedMessage, { id: "registration.registration_form.required" }))),
        React.createElement(DataProcessingOrNewsletterModal, { visible: showDataProcessingModal, setVisible: setShowDataProcessingModal, textId: "registration.registration_form.data_processing" }),
        React.createElement(DataProcessingOrNewsletterModal, { visible: showNewsletterModal, setVisible: setShowNewsletterModal, textId: "registration.registration_form.email_processing" })));
}
