import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import getCmsAsset from '@src/helpers/get-cms-asset';
import { up } from 'styled-breakpoints';
import { useLocation } from 'react-router-dom';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
export const StyledTestimonials = styled.div ``;
export const Box = styled.div `
	width: 100%;
	display: flex;
	padding: 0;
	position: relative;
	flex-direction: column;

	${up('md')} {
		flex-direction: row;
	}

	& + & {
		padding-top: 4em;
	}
`;
export const Picture = styled.div `
	width: 200px;
	height: 200px;
	margin: 0 0 2em;
	border-radius: 100%;
	overflow: hidden;
	align-self: center;

	${up('md')} {
		margin: 0 2em;
	}

	& img {
		display: block;
		max-width: 100%;
	}
`;
export const CommentWrapper = styled.div `
	align-self: center;
	flex: 1;
	padding: 0 2em;
	text-align: center;

	${up('md')} {
		text-align: left;
	}
`;
export const Comment = styled.div `
	font-size: 1.3em;
	font-weight: 400;
	color: ${props => props.theme.color.gray7};
`;
export const Author = styled.div `
	margin-top: 1em;
	font-size: 1.3em;
	color: ${props => props.theme.color.gray6};

	strong {
		color: ${props => props.theme.color.brand3};
	}

	a {
		text-decoration: underline;
	}
`;
const Testimonials = () => {
    const intl = useIntl();
    const location = useLocation();
    const { data: testimonials } = useSSRFetch(SSRFetchConfig.testimonials.key, intl.locale, [
        location.pathname,
    ]);
    return (React.createElement(StyledTestimonials, null, testimonials &&
        testimonials.entries.map(item => {
            return (React.createElement(Box, { key: item.name },
                React.createElement(Picture, null,
                    React.createElement("img", { src: getCmsAsset(item.image), alt: "" })),
                React.createElement(CommentWrapper, null,
                    React.createElement(Comment, null,
                        "\u201E",
                        item.comment,
                        "\u201C"),
                    React.createElement(Author, null,
                        React.createElement("strong", null, item.name),
                        ", ",
                        item.role,
                        React.createElement("br", null),
                        React.createElement("a", { href: item.url, target: "_blank", rel: "noreferrer" }, item.company)))));
        })));
};
export { Testimonials };
