/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@src/components/elements';
import { ABROAD, REMOTE } from '@src/types';
import { LocationSelect } from '@src/components/modules/forms/offer-notification-subscription-form/LocationSelect';
import styled from 'styled-components';
import { useOffersFilterContextValue } from '@src/components/pages/offers/offer-filter-context';
import { buildLocationTree, getLocationTitleWithCount } from './LocationFilter.model';
function LocationFilter({ locationFilterItems, isLoading, setShowLocationPlaceholder }) {
    const intl = useIntl();
    const { locationFiltersSelected, setLocationFiltersSelected } = useOffersFilterContextValue();
    const isAtLeastOneLocationSelected = locationFiltersSelected.length > 0;
    const isAbroadSelected = locationFiltersSelected.includes(ABROAD);
    const isRemoteSelected = locationFiltersSelected.includes(REMOTE);
    const locationTreeData = React.useMemo(() => [
        {
            title: getLocationTitleWithCount(intl.formatMessage({ id: 'location.abroad' }), (locationFilterItems === null || locationFilterItems === void 0 ? void 0 : locationFilterItems.abroadOfferCount) || 0, isAtLeastOneLocationSelected, isAbroadSelected),
            count: locationFilterItems.abroadOfferCount,
            value: ABROAD,
            disabled: locationFilterItems.abroadOfferCount === 0 && !locationFiltersSelected.includes(ABROAD),
        },
        {
            title: getLocationTitleWithCount(intl.formatMessage({ id: 'location.remote' }), (locationFilterItems === null || locationFilterItems === void 0 ? void 0 : locationFilterItems.remoteOfferCount) || 0, isAtLeastOneLocationSelected, isRemoteSelected),
            count: locationFilterItems.remoteOfferCount,
            value: REMOTE,
            disabled: locationFilterItems.remoteOfferCount === 0 && !locationFiltersSelected.includes(REMOTE),
        },
        ...buildLocationTree(locationFilterItems.locations || [], locationFiltersSelected, intl, isAtLeastOneLocationSelected, locationFilterItems.locations || []),
    ], [
        locationFilterItems,
        isAtLeastOneLocationSelected,
        locationFiltersSelected,
        isAbroadSelected,
        isRemoteSelected,
        intl,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconStyled, { type: "pin-location" }),
        React.createElement(LocationSelect, { value: locationFiltersSelected, onChange: setLocationFiltersSelected, locationTree: locationTreeData, isLoading: isLoading, setShowLocationPlaceholder: setShowLocationPlaceholder })));
}
const IconStyled = styled(Icon) `
	position: absolute;
	z-index: 2;
	top: 30%;
	left: 8px;

	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
export { LocationFilter };
