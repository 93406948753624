import * as Api from '@src/api/auth';
import { useAccountContext } from '@src/context/account-context';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useAppMutation } from './useAppMutation';
import { useConfig } from './useConfig';
export const useLogin = (shouldStayOnSameUrl) => {
    const intl = useIntl();
    const history = useHistory();
    const config = useConfig();
    const { refetchAccount } = useAccountContext();
    const { mutateAsync: login, isLoading, error, isError, } = useAppMutation(Api.login, {
        onSuccess: async (data) => {
            await refetchAccount();
            notification.success({
                message: intl.formatMessage({ id: 'general.notice' }),
                description: intl.formatMessage({ id: 'login.successfully_logged' }),
            });
            if (history.location.search === '?ref=back') {
                history.goBack();
            }
            else {
                let landingUrl = null;
                // @ts-ignore
                if (data.accountTypeId === config.accountType.company) {
                    landingUrl = '/admin';
                }
                else {
                    landingUrl = shouldStayOnSameUrl ? history.location.pathname : '/ucet';
                }
                history.pushLocalized(landingUrl);
            }
            socket.emit(SocketEvents.ACCOUNT_AUTHENTICATED, data);
        },
        onError: (err) => {
            const { status } = err.response;
            if (status === 429) {
                notification.error({
                    message: intl.formatMessage({ id: 'global.notification.attack' }),
                    description: intl.formatMessage({ id: 'global.notification.attack_description' }),
                });
            }
        },
    });
    return { login, isLoading, error, isError };
};
