import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { CloseModalButton, StyledModal } from './DetailsAssetsModal.style';
import { AssetsModalCarousel } from './AssetsModalCarousel';
export function DetailsAssetsModal({ isOpen, assets, initialAssetId, closeModal }) {
    return (React.createElement(StyledModal, { visible: isOpen, destroyOnClose: true, modalRender: () => (React.createElement(React.Fragment, null,
            React.createElement(CloseModalButton, { onClick: closeModal },
                React.createElement(CloseOutlined, null)),
            React.createElement(AssetsModalCarousel, { assets: assets, initialAssetId: initialAssetId, closeModal: closeModal }))) },
        React.createElement(React.Fragment, null)));
}
