import React, { Suspense } from 'react';
import loadable from '@loadable/component';
const ReactPdfDocument = loadable(() => import('react-pdf'), {
    resolveComponent: components => components.Document,
});
const ReactPdfPage = loadable(() => import('react-pdf'), {
    resolveComponent: components => components.Page,
});
export function Document(props) {
    return (React.createElement(Suspense, { fallback: React.createElement("div", null) },
        React.createElement(ReactPdfDocument, Object.assign({}, props))));
}
export function Page(props) {
    return (React.createElement(Suspense, { fallback: React.createElement("div", null) },
        React.createElement(ReactPdfPage, Object.assign({}, props))));
}
