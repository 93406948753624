import { Avatar as AntAvatar } from 'antd';
import styled, { css } from 'styled-components';
export const Avatar = styled(AntAvatar) `
	background: ${({ theme }) => theme.color.gray2};
	${props => props.$contain &&
    css `
			background: transparent;

			& > img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		`}
`;
