/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Tag, Button, Icon } from '@src/components/elements';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClearOutlined } from '@ant-design/icons';
import { ABROAD, REMOTE } from '@src/types';
import OfficialBuilding3 from '@public/media/icons/streamline-icon-official-building-3.svg';
import CloseQuote from '@public/media/icons/streamline-icon-close-quote.svg';
import { useOffersFilterContextValue } from '@src/components/pages/offers/offer-filter-context';
import { roundSalary } from '@src/helpers/roundSalaryToTen';
const FilterTag = styled(Tag) `
	display: inline-flex;
	line-height: 2rem;
	font-size: 1rem;
	align-items: center;
	margin-bottom: 0.5rem;

	.ant-tag-close-icon {
		margin-left: 0.75rem;

		& > svg {
			width: 16px;
			height: 16px;
		}
	}
`;
const IconStyled = styled(Icon) `
	z-index: 2;
	top: 30%;
	left: 8px;

	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
const ClearFiltersButton = styled(Button) `
	text-decoration: underline;
`;
const OfferCounts = styled.div `
	margin: 0.75rem 0;
	color: ${({ theme }) => theme.color.gray6};
`;
const TagContainer = styled.div `
	display: inline-flex;
	flex-wrap: wrap;
`;
const FilterTags = ({ offerCounts }) => {
    const offerFilterContextValue = useOffersFilterContextValue();
    const intl = useIntl();
    const { offersFiltersSSR, typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected, searchCompaniesSelected, searchKeywordsSelected, salaryFilter, setTypeFiltersSelected, setLocationFiltersSelected, setJobAreaFiltersSelected, setSearchCompaniesSelected, setSearchKeywordsSelected, setSalaryFilter, highestSalary, changePage, highestGlobalSalary, } = offerFilterContextValue;
    const [showClearFiltersButton, setShowClearFiltersButton] = React.useState(false);
    const { offersCount, allOffersCount } = offerCounts;
    const isShownMaxNumberOfOffers = offersCount === allOffersCount;
    const isTypeFilterActive = Boolean(typeFiltersSelected && typeFiltersSelected.length);
    const isJobAreaFilterActive = Boolean(jobAreaFiltersSelected && jobAreaFiltersSelected.length);
    const isLocationFilterActive = Boolean(locationFiltersSelected && locationFiltersSelected.length);
    const isSalaryFilterActive = Boolean(salaryFilter.rate);
    const isCompanyFilterActive = Boolean(searchCompaniesSelected && searchCompaniesSelected.length);
    const isKeywordFilterActive = Boolean(searchKeywordsSelected && searchKeywordsSelected.length);
    const removeTypeIdFromFilter = (typeIdToRemove) => {
        const newtypeFiltersSelected = typeFiltersSelected.filter(typeId => typeIdToRemove !== typeId);
        setTypeFiltersSelected(newtypeFiltersSelected);
    };
    const removeJobAreaIdFromFilter = (jobAreaIdToRemove) => {
        const newJobAreaIds = jobAreaFiltersSelected.filter(jobAreaId => jobAreaId !== jobAreaIdToRemove);
        setJobAreaFiltersSelected(newJobAreaIds);
    };
    const removeLocationFromFilter = (locationToRemove) => {
        const newlocationFiltersSelected = locationFiltersSelected.filter(loc => loc !== locationToRemove);
        setLocationFiltersSelected(newlocationFiltersSelected);
    };
    const removeCompanyFromFilter = (companyToRemove) => {
        const newCompanyFiltersSelected = searchCompaniesSelected.filter(co => co.id !== companyToRemove);
        setSearchCompaniesSelected(newCompanyFiltersSelected);
    };
    const removeKeywordFromFilter = (keywordToRemove) => {
        const newKeywordFiltersSelected = searchKeywordsSelected.filter(kw => kw !== keywordToRemove);
        setSearchKeywordsSelected(newKeywordFiltersSelected);
    };
    const getLocationTranslationKeyById = (locationId, currentSublocations) => {
        for (const location of currentSublocations) {
            if (location.id === locationId) {
                return location.translation_key;
            }
            if (location.sublocations) {
                const foundKey = getLocationTranslationKeyById(locationId, location.sublocations);
                if (foundKey) {
                    return foundKey;
                }
            }
        }
    };
    const SalaryTagText = () => {
        const isMaxValueChanged = salaryFilter.maxRange !== salaryFilter.highestSalary;
        const isMinValueChanged = salaryFilter.minRange !== 0;
        const minRangeText = intl.formatNumber(salaryFilter.minRange, {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        });
        const maxRangeText = intl.formatNumber(salaryFilter.maxRange, {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        });
        const salaryRateLowercased = intl
            .formatMessage({
            id: `offer.filter.${salaryFilter.rate}`,
        })
            .toLowerCase();
        let rangeText;
        if (isMaxValueChanged && isMinValueChanged) {
            rangeText = `: ${minRangeText} - ${maxRangeText} / ${salaryRateLowercased}`;
        }
        else if (isMaxValueChanged && !isMinValueChanged) {
            rangeText = `: ${intl.formatMessage({ id: 'offers.filter.to' })} ${maxRangeText} / ${salaryRateLowercased}`;
        }
        else if (!isMaxValueChanged && isMinValueChanged) {
            rangeText = `: ${intl.formatMessage({
                id: 'offers.filter.from',
            })} ${minRangeText} / ${salaryRateLowercased}`;
        }
        else {
            rangeText = `: ${salaryRateLowercased}`;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(FormattedMessage, { id: "offers.filter.salary_rate" }),
            rangeText));
    };
    React.useEffect(() => {
        const filtersCount = typeFiltersSelected.length +
            locationFiltersSelected.length +
            jobAreaFiltersSelected.length +
            Number(isSalaryFilterActive) +
            searchKeywordsSelected.length +
            searchCompaniesSelected.length;
        setShowClearFiltersButton(filtersCount >= 1);
    }, [
        typeFiltersSelected,
        locationFiltersSelected,
        jobAreaFiltersSelected,
        searchKeywordsSelected,
        searchCompaniesSelected,
        salaryFilter,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `flex-row align-center ${showClearFiltersButton ? 'justify-space-between' : 'justify-end'}` },
            showClearFiltersButton && (React.createElement(ClearFiltersButton, { onClick: () => {
                    setSalaryFilter({
                        minRange: 0,
                        maxRange: highestGlobalSalary,
                        highestSalary: highestGlobalSalary,
                        rate: null,
                    });
                    setTypeFiltersSelected([]);
                    setJobAreaFiltersSelected([]);
                    setLocationFiltersSelected([]);
                    setSearchCompaniesSelected([]);
                    setSearchKeywordsSelected([]);
                    changePage(1);
                }, type: "text", icon: React.createElement(ClearOutlined, { className: "m-r-s" }) },
                React.createElement(FormattedMessage, { id: "offers.clear_selected_filters" }))),
            React.createElement(OfferCounts, null,
                isShownMaxNumberOfOffers && (React.createElement(FormattedMessage, { values: {
                        allOffersCount,
                    }, id: "offers.showing_all_offers_count" })),
                !isShownMaxNumberOfOffers && (React.createElement(FormattedMessage, { values: {
                        offersCount,
                        allOffersCount,
                    }, id: "offers.showing_offers_count" })))),
        React.createElement(TagContainer, null,
            isCompanyFilterActive &&
                searchCompaniesSelected.map(selectedCompany => (React.createElement(FilterTag, { key: selectedCompany.id, closable: true, onClose: () => removeCompanyFromFilter(selectedCompany.id) },
                    React.createElement(React.Fragment, null,
                        React.createElement(OfficialBuilding3, { width: 16, className: "m-r-s" }),
                        selectedCompany.name)))),
            isKeywordFilterActive &&
                searchKeywordsSelected.map(selectedKeyword => (React.createElement(FilterTag, { key: selectedKeyword, closable: true, onClose: () => removeKeywordFromFilter(selectedKeyword) },
                    React.createElement(React.Fragment, null,
                        React.createElement(CloseQuote, { width: 16, className: "m-r-s" }),
                        `"${selectedKeyword}"`)))),
            offersFiltersSSR && (React.createElement(React.Fragment, null,
                isTypeFilterActive &&
                    typeFiltersSelected.map(selectedTypeId => {
                        var _a;
                        return (React.createElement(FilterTag, { key: selectedTypeId, closable: true, onClose: () => removeTypeIdFromFilter(selectedTypeId) },
                            React.createElement(FormattedMessage, { id: (_a = offersFiltersSSR.type.find(offerFilter => offerFilter.id === selectedTypeId)) === null || _a === void 0 ? void 0 : _a.translationKey })));
                    }),
                isLocationFilterActive &&
                    locationFiltersSelected.map(location => (React.createElement(FilterTag, { key: location, closable: true, onClose: () => removeLocationFromFilter(location) },
                        React.createElement(IconStyled, { type: "pin-location" }),
                        location === ABROAD && React.createElement(FormattedMessage, { id: "location.abroad" }),
                        location === REMOTE && React.createElement(FormattedMessage, { id: "location.remote" }),
                        location !== REMOTE && location !== ABROAD && (React.createElement(FormattedMessage, { id: getLocationTranslationKeyById(location, offersFiltersSSR.location.locations) }))))),
                isJobAreaFilterActive &&
                    jobAreaFiltersSelected.map(jobAreaId => {
                        var _a;
                        return (React.createElement(FilterTag, { key: jobAreaId, closable: true, onClose: () => removeJobAreaIdFromFilter(jobAreaId) },
                            React.createElement(FormattedMessage, { id: (_a = offersFiltersSSR.jobArea.find(offerFilter => offerFilter.id === jobAreaId)) === null || _a === void 0 ? void 0 : _a.translationKey })));
                    }),
                isSalaryFilterActive && (React.createElement(FilterTag, { key: salaryFilter.rate, closable: true, onClose: () => setSalaryFilter({
                        rate: null,
                        minRange: 0,
                        maxRange: roundSalary(highestSalary),
                        highestSalary: roundSalary(highestSalary),
                    }) },
                    React.createElement(SalaryTagText, null))))))));
};
export { FilterTags };
