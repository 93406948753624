export const configFrontendEN = {
    api: process.env.API,
    adSenseClientId: 'ca-pub-2352455228582816',
    cookieConsentDuration: 365,
    dateDisplayFormat: 'DD. MM. YYYY',
    analyticsDateFormat: 'D.M.YYYY',
    environment: process.env.ENVIRONMENT,
    sizeBreakpointXs: 568,
    sizeBreakpointSm: 768,
    sizeBreakpointMd: 1024,
    sizeBreakpointLg: 1280,
    transitionUiSlow: 667,
    socialBaseUrl: {
        facebook: 'https://www.facebook.com/',
        linkedinCompany: 'https://www.linkedin.com/company/',
        linkedin: 'https://www.linkedin.com/in/',
        instagram: 'https://www.instagram.com/',
        twitter: 'https://www.twitter.com/',
        youtube: 'https://www.youtube.com/channel/',
    },
    accountType: {
        company: 1,
        student: 2,
    },
    offerTypes: [
        { name: 'Internship', id: 1 },
        { name: 'Permanent job', id: 2 },
        { name: 'Volunteering', id: 3 },
        { name: 'Temporary job', id: 4 },
        { name: 'Work & travel', id: 5 },
        { name: 'Contractor', id: 6 },
    ],
    locations: [
        { name: 'Slovakia', id: 1 },
        { name: 'Banska Bystrica region', id: 2 },
        { name: 'Bratislava region', id: 3 },
        { name: 'Kosice region', id: 4 },
        { name: 'Nitra region', id: 5 },
        { name: 'Presov region', id: 6 },
        { name: 'Trencin region', id: 7 },
        { name: 'Trnava region', id: 8 },
        { name: 'Zilina region', id: 9 },
        { name: 'Bratislava', id: 10 },
        { name: 'Kosice', id: 11 },
        { name: 'Lucenec district', id: 12 },
        { name: 'Rimavska Sobota district', id: 13 },
        { name: 'Galanta district', id: 14 },
        { name: 'Bardejov district', id: 15 },
        { name: 'Kezmarok district', id: 16 },
        { name: 'Turcianske Teplice district', id: 17 },
        { name: 'Presov district', id: 18 },
        { name: 'Trencin district', id: 19 },
        { name: 'Humenne district', id: 20 },
        { name: 'Nitra district', id: 21 },
        { name: 'Nove Zamky district', id: 22 },
        { name: 'Topolcany district', id: 23 },
        { name: 'Roznava district', id: 24 },
        { name: 'Spisska Nova Ves district', id: 25 },
        { name: 'Vranov nad Toplou district', id: 26 },
        { name: 'Namestovo district', id: 27 },
        { name: 'Zvolen district', id: 28 },
        { name: 'Dunajska Streda district', id: 29 },
        { name: 'Trebisov district', id: 30 },
        { name: 'Kosice - surroundings', id: 31 },
        { name: 'Brezno district', id: 32 },
        { name: 'Banska Stiavnica district', id: 33 },
        { name: 'Banska Bystrica district', id: 34 },
        { name: 'Pezinok district', id: 35 },
        { name: 'Michalovce district', id: 36 },
        { name: 'Komarno district', id: 37 },
        { name: 'Sabinov district', id: 38 },
        { name: 'Levice district', id: 39 },
        { name: 'Levoca district', id: 40 },
        { name: 'Velky Krtis district', id: 41 },
        { name: 'Stropkov district', id: 42 },
        { name: 'Piestany district', id: 43 },
        { name: 'Banovce nad Bebravou district', id: 44 },
        { name: 'Ziar nad Hronom district', id: 45 },
        { name: 'Sobrance district', id: 46 },
        { name: 'Poprad district', id: 47 },
        { name: 'Nové Mesto nad Váhom district', id: 48 },
        { name: 'Zilina district', id: 49 },
        { name: 'Martin district', id: 50 },
        { name: 'Snina district', id: 51 },
        { name: 'Zlate Moravce district', id: 52 },
        { name: 'Svidnik district', id: 53 },
        { name: 'Puchov district', id: 54 },
        { name: 'Liptovsky Mikulas district', id: 55 },
        { name: 'Senec district', id: 56 },
        { name: 'Ruzomberok district', id: 57 },
        { name: 'Trnava district', id: 58 },
        { name: 'Senica district', id: 59 },
        { name: 'Povazska Bystrica district', id: 60 },
        { name: 'Ilava district', id: 61 },
        { name: 'Prievidza district', id: 62 },
        { name: 'Hlohovec district', id: 63 },
        { name: 'Malacky district', id: 64 },
        { name: 'Partizanske district', id: 65 },
        { name: 'Bratislava V district', id: 66 },
        { name: 'Bratislava IV district', id: 67 },
        { name: 'Bratislava III district', id: 68 },
        { name: 'Bratislava II district', id: 69 },
        { name: 'Bratislava I district', id: 70 },
        { name: 'Zarnovica district', id: 71 },
        { name: 'Medzilaborce district', id: 72 },
        { name: 'Myjava district', id: 73 },
        { name: 'Poltar district', id: 74 },
        { name: 'Tvrdosin district', id: 75 },
        { name: 'Skalica district', id: 76 },
        { name: 'Bytca district', id: 77 },
        { name: 'Dolny Kubin district', id: 78 },
        { name: 'Krupina district', id: 79 },
        { name: 'Cadca district', id: 80 },
        { name: 'Stara Lubovna district', id: 81 },
        { name: 'Revuca district', id: 82 },
        { name: 'Detva district', id: 83 },
        { name: 'Sala district', id: 84 },
        { name: 'Kysucke Nove Mesto district', id: 85 },
        { name: 'Gelnica district', id: 86 },
        { name: 'Košice IV district', id: 87 },
        { name: 'Košice III district', id: 88 },
        { name: 'Košice I district', id: 89 },
        { name: 'Košice II district', id: 90 },
    ],
    contractTypes: [
        { value: 1, label: 'Full-time' },
        { value: 2, label: 'Part-time' },
    ],
    salaryRates: [
        { value: 'hourly', label: 'Hourly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'once', label: 'Once' },
    ],
    salaryCurrencies: [{ value: '€', label: '€' }],
    durationTypes: [
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },
    ],
    languages: [
        { code: 'sk', value: 'Slovak' },
        { code: 'en', value: 'English' },
        { code: 'fr', value: 'French' },
        { code: 'cs', value: 'Czech' },
        { code: 'de', value: 'German' },
        { code: 'pl', value: 'Polish' },
        { code: 'es', value: 'Spanish' },
        { code: 'pt', value: 'Portugese' },
        { code: 'zh', value: 'Chinese' },
        { code: 'uk', value: 'Ukrainian' },
        { code: 'ru', value: 'Russian' },
        { code: 'hu', value: 'Hungarian' },
    ],
    monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    universities: [
        'Akadémia ozbrojených síl generála Milana Rastislava Štefánika',
        'Akadémia Policajného zboru',
        'Akadémia umení v Banskej Bystrici',
        'Bratislavská medzinárodná škola liberálnych štúdií',
        'Ekonomická univerzita v Bratislave',
        'Hudobná a umelecká akadémia Jána Albrechta - Banská Štiavnica, s. r. o., odborná vysoká škola',
        'Katolícka univerzita v Ružomberku',
        'Paneurópska vysoká škola',
        'Prešovská univerzita v Prešove',
        'Slovenská poľnohospodárska univerzita v Nitre',
        'Slovenská technická univerzita v Bratislave',
        'Slovenská zdravotnícka univerzita v Bratislave',
        'Stredoeurópska vysoká škola v Skalici',
        'Technická univerzita v Košiciach',
        'Technická univerzita vo Zvolene',
        'Trenčianska univerzita Alexandra Dubčeka v Trenčíne',
        'Trnavská univerzita v Trnave',
        'Univerzita J. Selyeho',
        'Univerzita Komenského v Bratislave',
        'Univerzita Konštantína Filozofa v Nitre',
        'Univerzita Mateja Bela v Banskej Bystrici',
        'Univerzita Pavla Jozefa Šafárika v Košiciach',
        'Univerzita sv. Cyrila a Metoda v Trnave',
        'Univerzita veterinárskeho lekárstva a&nbsp;farmácie v Košiciach',
        'Vysoká škola bezpečnostného manažérstva v Košiciach',
        'Vysoká škola Danubius',
        'Vysoká škola DTI',
        'Vysoká škola ekonómie a manažmentu verejnej správy v Bratislave',
        'Vysoká škola manažmentu',
        'Vysoká škola medzinárodného podnikania ISM Slovakia v Prešove',
        'Vysoká škola medzinárodných a veřejných vztahů Praha, o. p. s.',
        'Vysoká škola múzických umení v Bratislave',
        'Vysoká škola výtvarných umení v Bratislave',
        'Vysoká škola zdravotníctva a sociálnej práce sv. Alžbety v Bratislave, n. o.',
        'Žilinská univerzita v Žiline',
    ],
    universitySections: [
        'architektúra a urbanizmus',
        'bezpečnostné vedy',
        'biológia',
        'biotechnológie',
        'doprava',
        'drevárstvo',
        'ekologické a environmentálne vedy',
        'ekonómia a manažment',
        'elektrotechnika',
        'farmácia',
        'filológia',
        'filozofia',
        'fyzika',
        'geodézia a kartografia',
        'historické vedy',
        'chémia',
        'chemické inžinierstvo a technológie',
        'informatika',
        'kybernetika',
        'lesníctvo',
        'logopédia a liečebná pedagogika',
        'matematika',
        'mediálne a komunikačné štúdiá',
        'obrana a vojenstvo',
        'ošetrovateľstvo',
        'politické vedy',
        'poľnohospodárstvo a krajinárstvo',
        'potravinárstvo',
        'pôrodná asistencia',
        'právo',
        'priestorové plánovanie',
        'psychológia',
        'sociálna práca',
        'sociológia a sociálna antropológia',
        'stavebníctvo',
        'strojárstvo',
        'teológia',
        'učiteľstvo a pedagogické vedy',
        'umenie',
        'vedy o športe',
        'vedy o umení a kultúre',
        'vedy o Zemi',
        'verejné zdravotníctvo',
        'veterinárske lekárstvo',
        'všeobecné lekárstvo',
        'zdravotnícke vedy',
        'získavanie a spracovanie zemských zdrojov',
        'zubné lekárstvo',
    ],
};
