import React from 'react';
import { Link, Button } from '@src/components/elements';
import { Form, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { FacebookLoginButton } from '@src/components/elements/FacebookLoginButton';
import { GoogleLoginButton } from '@src/components/elements/GoogleLoginButton';
import { LinkedInLoginButton } from '@src/components/elements/LinkedInLoginButton';
import { OAuthType } from '@src/types/auth';
import { useRoute } from '@src/hooks/use-route';
import styled from 'styled-components';
const StyledLink = styled(Link) `
	&:link,
	&:visited,
	&:focus {
		color: ${props => props.theme.color.white};
		text-decoration: underline;
	}
`;
const LoginForm = props => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const route = useRoute();
    const shouldStayOnPage = (route === null || route === void 0 ? void 0 : route.id) !== '/prihlasenie';
    const { onSubmit, loginError, onOAuthSuccess, showApplicantRegistrationButton, showCompanyRegistrationButton, isLoading, } = props;
    const shouldShowRegistrationLink = showApplicantRegistrationButton || showCompanyRegistrationButton;
    return (React.createElement("div", { id: "login-form" },
        loginError && (React.createElement("div", { className: "form__errors" },
            React.createElement("p", { className: "form__errors-item" },
                React.createElement(FormattedMessage, { id: "login_form.wrong_email_or_password" })),
            React.createElement("p", { className: "form__errors-item" },
                React.createElement(FormattedMessage, { id: "login_form.dont_have_login_email", values: {
                        Link: chunks => React.createElement(StyledLink, { to: "/registracia-uchadzac" }, chunks),
                    } })))),
        React.createElement(Form, { onFinish: onSubmit, form: form, layout: "vertical", className: "form form--login" },
            React.createElement(Form.Item, { rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.email',
                        }),
                    },
                    {
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.invalid_email',
                        }),
                    },
                ], name: "email", label: intl.formatMessage({
                    id: 'login_form.email',
                }) },
                React.createElement(Input, { type: "email", placeholder: "name@email.com" })),
            React.createElement(Form.Item, { rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.password',
                        }),
                    },
                ], name: "password", label: intl.formatMessage({
                    id: 'login_form.password',
                }) },
                React.createElement(Input.Password, { autoComplete: "new-password", placeholder: "password" })),
            React.createElement("div", { className: "flex-row justify-space-between" },
                React.createElement(Link, { className: "link link--subtle", to: "/obnovenie-hesla-email", "data-cy": "password-reset-button" },
                    React.createElement(FormattedMessage, { id: "login_form.forgot_password" })),
                shouldShowRegistrationLink && (React.createElement("div", { className: "flex-col" },
                    showApplicantRegistrationButton && (React.createElement(Link, { className: "link link--subtle", to: "/registracia-uchadzac" },
                        React.createElement(FormattedMessage, { id: "general.registration_candidate" }))),
                    showCompanyRegistrationButton && (React.createElement(Link, { className: "link link--subtle", to: "/registracia" },
                        React.createElement(FormattedMessage, { id: "general.registration_company" })))))),
            React.createElement("div", { className: "form__submit" },
                React.createElement("div", { className: "flex-col" },
                    React.createElement("div", { className: "m-b-l" },
                        React.createElement(Button, { type: "primary", htmlType: "submit", size: "middle", "data-cy": "login-button", loading: isLoading },
                            React.createElement(FormattedMessage, { id: "login_form.login" }))),
                    React.createElement("div", { className: "flex-col" },
                        React.createElement(FacebookLoginButton, { type: OAuthType.login }),
                        React.createElement(GoogleLoginButton, { type: OAuthType.login, shouldStayOnPage: shouldStayOnPage, onLoginSuccess: onOAuthSuccess }),
                        React.createElement(LinkedInLoginButton, { type: OAuthType.login })))))));
};
export default LoginForm;
