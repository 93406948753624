import { Button, Table } from '@src/components/elements';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PremiumDiamond from '@public/media/icons/streamline-icon-diamond-money-payments-finance.svg';
import { transparentize } from 'polished';
import { useHistory, useLocation } from 'react-router';
import { useAccount } from '@src/context/account-context';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
const PremiumCompanyProfileComparisonTable = () => {
    const intl = useIntl();
    const history = useHistory();
    const [profileFeaturesTableRef, setProfileFeaturesTableRef] = React.useState(null);
    const location = useLocation();
    const account = useAccount();
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const isSmOrLowerLayout = useBreakpoint(down('sm'));
    const paymentsTableScroll = isSmOrLowerLayout ? { x: 400 } : undefined;
    React.useEffect(() => {
        if (location.hash === '#features' && profileFeaturesTableRef) {
            profileFeaturesTableRef.scrollIntoView({ behavior: 'smooth' });
        }
    }, [profileFeaturesTableRef, location]);
    const columns = React.useMemo(() => [
        {
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            render: (value) => {
                return React.createElement(FeatureName, null, value);
            },
        },
        {
            title: (React.createElement(BasicProfileHeader, null,
                React.createElement(FormattedMessage, { id: "company_premium_profile.free_profile" }))),
            dataIndex: 'availableForFreeProfile',
            key: 'availableForFreeProfile',
            align: 'center',
            width: '30%',
            render: (_, { isActionButton, availableForFreeProfile, }) => {
                if (isActionButton) {
                    if (!account || !(account === null || account === void 0 ? void 0 : account.companyId)) {
                        return (React.createElement(ColumnButton, { type: "secondary", shape: "round", link: account ? '/registracia-prihlaseny-pouzivatel' : '/registracia' },
                            React.createElement(FormattedMessage, { id: "company_premium_profile.try_for_free" })));
                    }
                    return null;
                }
                return availableForFreeProfile ? React.createElement(StyledCheckmark, null) : React.createElement(StyledX, null);
            },
        },
        {
            title: (React.createElement(PremiumProfileHeader, null,
                React.createElement(FormattedMessage, { id: "company_premium_profile.premium_profile" }),
                React.createElement(PremiumIconWrapper, null,
                    React.createElement(PremiumDiamond, { width: 14, height: 14 })))),
            dataIndex: ['availableForPremiumProfile', 'isActionButton'],
            key: 'availableForPremiumProfile',
            align: 'center',
            width: '30%',
            render: (_, { isActionButton, availableForPremiumProfile, }) => {
                if (isActionButton) {
                    if (!account || !(account === null || account === void 0 ? void 0 : account.companyId)) {
                        return (React.createElement(ColumnButton, { type: "primary", shape: "round", link: account ? '/registracia-prihlaseny-pouzivatel' : '/registracia' },
                            React.createElement(FormattedMessage, { id: "general.registration_company" })));
                    }
                    return (React.createElement(ColumnButton, { type: "primary", shape: "round", onClick: () => setIsPremiumCompanyProfileSubscriptionModalOpen(true) },
                        React.createElement(FormattedMessage, { id: "company_premium_profile.buy_subscription" })));
                }
                return availableForPremiumProfile ? React.createElement(StyledCheckmark, null) : React.createElement(StyledX, null);
            },
        },
    ], [history]);
    const data = React.useMemo(() => [
        {
            key: '1',
            name: intl.formatMessage({ id: 'company_premium_profile.features.unlimited_company_users' }),
            availableForFreeProfile: true,
            availableForPremiumProfile: true,
        },
        {
            key: '2',
            name: intl.formatMessage({ id: 'company_premium_profile.features.candidate_management' }),
            availableForFreeProfile: true,
            availableForPremiumProfile: true,
        },
        {
            key: '3',
            name: intl.formatMessage({ id: 'company_premium_profile.features.online_video_interviews' }),
            availableForFreeProfile: true,
            availableForPremiumProfile: true,
        },
        {
            key: '4',
            name: intl.formatMessage({ id: 'company_premium_profile.features.public_company_profile' }),
            availableForFreeProfile: true,
            availableForPremiumProfile: true,
        },
        {
            key: '5',
            name: intl.formatMessage({ id: 'company_premium_profile.features.analytics' }),
            availableForFreeProfile: false,
            availableForPremiumProfile: true,
        },
        {
            key: '6',
            name: intl.formatMessage({ id: 'company_premium_profile.features.photos_and_videos_offer' }),
            availableForFreeProfile: false,
            availableForPremiumProfile: true,
        },
        {
            key: '7',
            name: intl.formatMessage({ id: 'company_premium_profile.features.photos_and_videos_public_profile' }),
            availableForFreeProfile: false,
            availableForPremiumProfile: true,
        },
        {
            key: '8',
            name: intl.formatMessage({ id: 'company_premium_profile.features.highlighting_company_profile' }),
            availableForFreeProfile: false,
            availableForPremiumProfile: true,
        },
        {
            key: '9',
            name: intl.formatMessage({ id: 'company_premium_profile.features.main_page_company_logo' }),
            availableForFreeProfile: false,
            availableForPremiumProfile: true,
        },
        {
            key: '10',
            isActionButton: true,
        },
    ], [intl]);
    return (React.createElement("div", { ref: ref => setProfileFeaturesTableRef(ref), className: "m-b-xl m-t-xl" },
        React.createElement(StyledTable, { pagination: false, columns: columns, dataSource: data, scroll: paymentsTableScroll })));
};
const PremiumIconWrapper = styled.div `
	margin-left: 0.5rem;
	background: ${({ theme }) => transparentize(0.8, theme.color.gold)};
	width: 1.5rem;
	height: 1.5rem;
	max-width: 1.5rem;
	max-height: 1.5rem;
	min-width: 1.5rem;
	min-height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.color.gold};
	border-radius: 1rem;
	color: ${({ theme }) => theme.color.gold};
`;
const StyledTable = styled(Table) `
	thead > tr > th:last-child {
		background-color: ${({ theme }) => theme.color.brandDarkBlue};
	}
	,
	thead > tr > th {
		background-color: ${({ theme }) => theme.color.gray3};
	}
`;
const StyledCheckmark = styled(CheckOutlined) `
	color: ${({ theme }) => theme.color.success};
	padding: 0.5rem;
	border-radius: 1rem;
	background: ${({ theme }) => transparentize(0.9, theme.color.success)};
`;
const StyledX = styled(CloseOutlined) `
	color: ${({ theme }) => theme.color.error};
	padding: 0.5rem;
	border-radius: 1rem;
	background: ${({ theme }) => transparentize(0.9, theme.color.error)};
`;
const FeatureName = styled.div `
	font-size: 1rem;
`;
const BasicProfileHeader = styled.div `
	color: ${({ theme }) => theme.color.black};
`;
const PremiumProfileHeader = styled.div `
	color: ${({ theme }) => theme.color.white};
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ColumnButton = styled(Button) `
	white-space: normal;
	word-break: keep-all;
	&& {
		height: auto;
	}
`;
export { PremiumCompanyProfileComparisonTable };
