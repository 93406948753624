import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { Button, Holder } from '@src/components/elements';
import config from '@src/config';
import { useAccount } from '@src/context/account-context';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from '@src/api';
import { roundToClosestValue } from '@src/helpers/roundToClosestValue';
import { BigButton, ButtonWrapper, Note, Content, Headline, Hero, Wrapper, FeaturesWrapper, ButtonText, BottomCTA, } from './Candidate.style';
import { Features } from './Features';
const Candidate = () => {
    const theme = useTheme();
    const account = useAccount();
    const isUserAdmin = (account === null || account === void 0 ? void 0 : account.accountTypeId) === config.accountType.company;
    const { data: companiesCount } = useAppQuery(Queries.getCompaniesCount, () => Api.Company.getAllCompaniesCount());
    const { data: offersCount } = useAppQuery(Queries.getOffersCount, () => Api.Offers.getAllOffersCount());
    const { data: offerApplicationsCount } = useAppQuery(Queries.getResponsesCount, () => Api.OfferApplication.getAllOfferApplicationsCount());
    return (React.createElement(Wrapper, null,
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "candidate.headline" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "candidate.subheadline_1" }))))),
        React.createElement(Content, null,
            React.createElement(FeaturesWrapper, { id: "features" },
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.features.headilne" })),
                    React.createElement(Features, null),
                    React.createElement(BigButton, { style: {
                            background: theme.color.success,
                            borderColor: theme.color.success,
                            color: theme.color.white,
                        }, link: "/ponuky" },
                        React.createElement(ButtonText, null,
                            React.createElement(FormattedMessage, { id: "home.hero.cta_offers_short" }))))),
            React.createElement(BottomCTA, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "candidate.results" })),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "companies.results.subheadilne", values: {
                                companies: React.createElement("strong", null,
                                    roundToClosestValue(companiesCount, 50),
                                    "+"),
                                offers: React.createElement("strong", null,
                                    roundToClosestValue(offersCount, 50),
                                    "+"),
                                responses: React.createElement("strong", null,
                                    roundToClosestValue(offerApplicationsCount, 100),
                                    "+"),
                            } })),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { style: {
                                background: theme.color.success,
                                borderColor: theme.color.success,
                                color: theme.color.white,
                            }, link: isUserAdmin ? '/registracia-prihlaseny-pouzivatel' : '/registracia-uchadzac' },
                            React.createElement(ButtonText, null,
                                React.createElement(FormattedMessage, { id: "candidate.free.create_account_free" }))),
                        React.createElement(Note, null,
                            React.createElement(FormattedMessage, { id: "candidate.free.no_hidden_fees" }))))))));
};
export { Candidate };
