import React from 'react';
import styled from 'styled-components';
export const Headline = styled.div `
	text-align: left;
	flex: 0 0 auto;
	& > * {
		color: ${({ theme }) => theme.color.brand1};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
`;
const Container = styled.div `
	padding: 2em 1em 0;
`;
const Header = styled.div `
	margin-bottom: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
	flex-wrap: wrap;
`;
const Actions = styled.div `
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	flex: 0 0 auto;
	justify-content: flex-end;
`;
const Description = styled.div `
	color: ${({ theme }) => theme.color.black};
	font-size: 1rem;
	margin-bottom: 2rem;
`;
const AdminPageContainer = ({ title, description, actions, children }) => {
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Headline, null,
                React.createElement("h1", null, title)),
            actions && React.createElement(Actions, null, actions)),
        description && React.createElement(Description, null, description),
        React.createElement(React.Fragment, null, children)));
};
export { AdminPageContainer };
