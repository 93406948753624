import { FileUpload } from '@src/components/elements';
import { Form } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
export function UploadMotivationalLetterField({ motivationalLetterRules, motivationalLetterFileList, setMotivationalLetterFileList, }) {
    const intl = useIntl();
    return (React.createElement(Form.Item, { label: intl.formatMessage({
            id: 'offer_detail.send_resume_form.motivational_letter',
        }), name: "motivationalLetter", "data-cy": "motivation-letter", rules: motivationalLetterRules },
        React.createElement(FileUpload, { name: "motivationalLetter", accept: "*", maxFileSize: 2, multiple: false, wholeFileList: motivationalLetterFileList, setFileList: setMotivationalLetterFileList })));
}
