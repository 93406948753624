import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import * as Api from '@src/api';
import * as uuid from 'uuid';
import { Button } from '@src/components/elements';
import { useAuthContextValue } from '@src/context/auth-context';
import { useIntl } from 'react-intl';
import { useAccount } from '@src/context/account-context';
import { Avatar } from 'antd';
import IconCheck from '@public/media/icons/streamline-icon-check.svg';
import IconAdd from '@public/media/icons/streamline-icon-add.svg';
import { BottomGroup } from './CompanySubscriptionBlock.style';
export function CompanySubscriptionBlock({ subscribers, companyId }) {
    const [subscribersLocal, setSubscribersLocal] = React.useState(subscribers);
    const [isLoading, setIsLoading] = React.useState(false);
    const user = useAccount();
    const shouldDisableSubscribeButton = (user === null || user === void 0 ? void 0 : user.companyId) === companyId;
    const { checkAuthentication } = useAuthContextValue();
    const isSubscribedToCompany = React.useMemo(() => Boolean(subscribersLocal === null || subscribersLocal === void 0 ? void 0 : subscribersLocal.find(c => c.account_id === (user === null || user === void 0 ? void 0 : user.id))), [subscribersLocal]);
    const intl = useIntl();
    const toggleSubscription = async () => {
        try {
            setIsLoading(true);
            await checkAuthentication();
            await Api.Company.toggleCompanySubscription(companyId);
            if (isSubscribedToCompany) {
                setSubscribersLocal(subscribersLocal.filter(s => s.account_id !== (user === null || user === void 0 ? void 0 : user.id)));
            }
            else {
                setSubscribersLocal([...subscribersLocal, { account_id: user === null || user === void 0 ? void 0 : user.id, company_id: companyId }]);
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    };
    return (React.createElement(BottomGroup, null,
        React.createElement("div", null, subscribersLocal && subscribersLocal.length > 0 && (React.createElement(Avatar.Group, { maxCount: 1, size: "small", maxStyle: { color: '#f56a00', backgroundColor: '#fde3cf' } }, subscribersLocal === null || subscribersLocal === void 0 ? void 0 : subscribersLocal.map((_, index) => (React.createElement(Avatar, { style: { backgroundColor: ['#f56a00'][index] }, icon: React.createElement(UserOutlined, null), key: uuid.v4() })))))),
        React.createElement(Button, { className: "m-l-s", loading: isLoading, size: "small", shape: "round", onClick: () => toggleSubscription(), disabled: shouldDisableSubscribeButton, icon: isSubscribedToCompany ? (React.createElement(IconCheck, { className: "m-r-xs", width: 10 })) : (React.createElement(IconAdd, { className: "m-r-xs", width: 10 })) },
            !isSubscribedToCompany && (React.createElement("span", null, intl.formatMessage({ id: 'companies_list.subscription.subscribe' }))),
            isSubscribedToCompany && (React.createElement("span", null, intl.formatMessage({ id: 'companies_list.subscription.unsubscribe' }))))));
}
