var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/require-default-props */
import React from 'react';
import { AccountSex, AccountType } from '@src/types';
import SingleMan from '@public/media/icons/streamline-icon-single-man.svg';
import SingleWoman from '@public/media/icons/streamline-icon-single-woman.svg';
import SingleNeutral from '@public/media/icons/streamline-icon-single-neutral.svg';
import IconBuildings from '@public/media/icons/streamline-icon-buildings-1.svg';
import { useTheme } from 'styled-components';
import { Avatar as StyledAvatar } from './Avatar.style';
function getFallbackAvatarIcon({ account, color }) {
    if ((account === null || account === void 0 ? void 0 : account.accountTypeId) === AccountType.applicant) {
        switch (account.sex) {
            case AccountSex.man:
                return React.createElement(SingleMan, { fill: color });
            case AccountSex.woman:
                return React.createElement(SingleWoman, { fill: color });
            default:
                return React.createElement(SingleNeutral, { fill: color });
        }
    }
    if ((account === null || account === void 0 ? void 0 : account.accountTypeId) === AccountType.company) {
        return React.createElement(IconBuildings, { fill: color });
    }
    return React.createElement(SingleNeutral, { fill: color });
}
function Avatar(props) {
    const theme = useTheme();
    const { className, src, color = theme.color.gray4, account, size = 'large', shape = 'circle', contain = undefined } = props, otherProps = __rest(props, ["className", "src", "color", "account", "size", "shape", "contain"]);
    return (React.createElement(StyledAvatar, Object.assign({ src: src, size: size, shape: shape, className: className, "$contain": contain, icon: getFallbackAvatarIcon({ account, color }) }, otherProps)));
}
export { Avatar };
