import { Button, Icon, Link, Separator } from '@src/components/elements';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
export const OfferLink = styled(Link) `
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${up('sm')} {
		flex-direction: row;
	}
`;
export const OffersTitle = styled.div `
	text-align: center;
	width: 100%;
	padding: 1rem 1rem 0 1rem;
	font-size: 2rem;
	color: ${({ theme }) => theme.color.brand1};
`;
export const OfferLinkWrapper = styled.div `
	padding: 1rem;
	position: relative;
	border: 1px solid ${({ theme }) => theme.color.gray4};
	transition: all 0.2s ease;
	cursor: pointer;

	&:hover {
		background: ${({ theme }) => theme.color.gray3};
	}
`;
export const OffersContainer = styled.div `
	padding: 1rem 1rem;
	display: grid;
	grid-template-columns: repeat(1, minmax(240px, 1fr));
	gap: 1.5rem;
`;
export const OfferPropsText = styled.div `
	font-size: 0.75rem;
	color: ${({ theme }) => theme.color.gray8};
`;
export const StyledSeparator = styled(Separator) `
	margin: 1rem 0;
`;
export const MapIcon = styled(Icon) `
	width: 1rem;
	min-width: 1rem;
	margin-right: 0.5rem;
`;
export const OfferTypeIconWrapper = styled.span `
	margin-right: 0.5rem;
	display: flex;
	svg {
		color: ${({ theme }) => theme.color.brand1};
		width: 1rem;
		min-width: 1rem;
		g {
			stroke: ${({ theme }) => theme.color.brand1};
		}
		path {
			stroke: ${({ theme }) => theme.color.brand1};
		}
	}
`;
export const MoneyBagIcon = styled(Icon) `
	width: 1rem;
	min-width: 1rem;
	margin-right: 0.5rem;
`;
export const DetailButton = styled(Button) ``;
export const ButtonWrapper = styled.div `
	padding-bottom: 0.5rem;
	height: 100%;
	display: flex;
	align-items: end;
	justify-content: center;
	${up('md')} {
		padding-bottom: 0;
	}
`;
export const OfferPropsWrapper = styled.div `
	display: flex;
	flex-direction: column;
	${up('md')} {
		flex-direction: row;
	}
`;
export const OfferMain = styled.div `
	display: flex;
	flex-direction: column;
`;
export const OfferPropItem = styled.div `
	display: flex;
	align-content: center;
	margin-bottom: 0.5rem;

	${up('md')} {
		margin: 0;
		margin-right: 1rem;
	}
`;
export const OfferTitle = styled.div `
	font-weight: bold;
	font-size: 1rem;
	margin-bottom: 0.5rem;
`;
export const OffersSubPageContent = styled.div `
	max-width: ${({ theme }) => theme.breakpoints.xl};
	margin: auto;
`;
