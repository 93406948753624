import { client as axios } from './clients/axios';
export async function getSubscription(token) {
    const response = await axios.get(`/0/offer-notification-subscription${token ? `?onst=${token}` : ''}`);
    return response.data;
}
export async function createSubscription(subscriptionData) {
    await axios.post('/0/offer-notification-subscription', subscriptionData);
}
export async function updateSubscription(subscriptionData, token) {
    await axios.put(`/0/offer-notification-subscription?onst=${token}`, subscriptionData);
}
export async function isEmailTaken(email, token) {
    const tokenQueryPart = token ? `&onst=${token}` : '';
    const response = await axios.get(`/0/offer-notification-subscription/is-email-taken?email=${email}${tokenQueryPart}`);
    return response.data;
}
export async function deleteSubscription(token) {
    await axios.delete(`/0/offer-notification-subscription?onst=${token}`);
}
