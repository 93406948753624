import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Parser } from 'html-to-react';
import { useLocation } from 'react-router';
import striptags from 'striptags';
import getSocialImageUrl from '@src/backend/helpers/get-social-image-url';
import { useConfig } from '@src/hooks/useConfig';
import { Button, Head, Holder, Separator } from '@src/components/elements';
import IconAdd from '@public/media/icons/streamline-icon-add.svg';
import IconCheck from '@public/media/icons/streamline-icon-check.svg';
import IconMapsPin from '@public/media/icons/streamline-icon-maps-pin-1.svg';
import IconBriefcase from '@public/media/icons/streamline-icon-briefcase.svg';
import IconFolderOpen from '@public/media/icons/streamline-icon-folder-open.svg';
import IconMultipleUsers from '@public/media/icons/streamline-icon-multiple-users-1.svg';
import IconNetworkBrowser from '@public/media/icons/streamline-icon-network-browser.svg';
import IconBuildings from '@public/media/icons/streamline-icon-buildings-1.svg';
import { useSalaryFormatter } from '@src/hooks/useSalaryFormatter';
import SlugOffer from '@src/shared/slug-offer';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { useCompanyProfileBoxInfo } from '@src/components/modules/global/CompanyProfileBox/use-company-profile-box-info';
import { useAccount } from '@src/context/account-context';
import { getLanguageFromPathname } from '@src/shared/localized-path';
import { Main, Header, Body, CompanyBlock, CompanyInfo, MoreInfo, SocialButtons, BodyContent, SubText, Description, IconWrapper, CompanyContent, AddressBox, Position, Heading, TotalCount, StyledPanel, CompanyMainPanel, NumbersTitle, CompanyNumbers, SocialIconFacebook, SocialIconLinkedIn, SocialIconInstagram, SocialIconTwitter, SocialIconYoutube, Subheading, ImageBlock, } from './Company.style';
const htmlToReactParser = new Parser();
function CompanyPage({ company, subscribersCount, }) {
    var _a, _b, _c;
    const account = useAccount();
    const location = useLocation();
    const locale = getLanguageFromPathname(location.pathname);
    const { formatSalary } = useSalaryFormatter();
    const intl = useIntl();
    const config = useConfig();
    const { isAccountSubscribed, toggleSubscription, isSubscriptionLoading } = useCompanyProfileBoxInfo(company);
    if (!company) {
        return null;
    }
    const activeOffers = (_a = company.offers) === null || _a === void 0 ? void 0 : _a.filter(o => Boolean(o.isActive));
    const publishedOffers = (_b = company.offers) === null || _b === void 0 ? void 0 : _b.filter(o => Boolean(o.firstPublishedAt));
    const companyActiveOffers = activeOffers === null || activeOffers === void 0 ? void 0 : activeOffers.map(({ id, title, address, offerTypeId, salaryRate, salaryType, salaryAmountFrom, salaryAmountTo, salaryCurrency, isPaid, }) => {
        var _a;
        const salaryString = salaryAmountFrom && isPaid
            ? formatSalary({
                rate: salaryRate,
                amountFrom: salaryAmountFrom,
                amountTo: salaryAmountTo,
                currency: salaryCurrency,
                salaryType,
            })
            : '';
        return {
            key: id,
            position: title,
            salary: salaryString,
            type: (_a = config.offerTypes.find(type => type.id === offerTypeId)) === null || _a === void 0 ? void 0 : _a.name,
            city: (address === null || address === void 0 ? void 0 : address.city) || intl.formatMessage({ id: 'offer_detail.details.remote_location' }),
            link: `/ponuka/${new SlugOffer({ text: title }).result}-${id}`,
        };
    });
    const imageBackgroundUrl = company.titleImageUrl
        ? getCdnImageUrl({
            file: `web/company/${company.titleImageUrl}`,
            transformation: 'w_1172,h_200,c_limit',
        })
        : '/media/images/background-gradient.jpg';
    const hasAtLeastOneSocial = [
        company.facebookUrl,
        company.linkedinUrl,
        company.instagramUrl,
        company.twitterUrl,
        company.youtubeUrl,
    ].some(Boolean);
    const companyImageUrl = getSocialImageUrl({
        companyImage: company.image,
        locale,
    });
    const companyDescriptionMeta = striptags(company.description).substring(0, 250);
    const isUserFromCompany = (account === null || account === void 0 ? void 0 : account.companyId) === company.id;
    return (React.createElement(Main, null,
        React.createElement(Head, { title: `${company.name} | Pracuj.sk`, description: companyDescriptionMeta, imageUrl: companyImageUrl }),
        React.createElement(Holder, { marginVertical: true },
            React.createElement(Header, { className: "flex-center", background: imageBackgroundUrl }),
            React.createElement(Body, null,
                React.createElement(ImageBlock, null,
                    company.image && (React.createElement("img", { src: getCdnImageUrl({
                            file: `web/company/${company.image}`,
                            transformation: 'w_140,h_140,c_limit',
                        }), alt: "logo" })),
                    !company.image && React.createElement(IconBuildings, { viewBox: "0 0 24 24" })),
                React.createElement(CompanyBlock, null,
                    React.createElement(CompanyMainPanel, null,
                        React.createElement(CompanyContent, null,
                            React.createElement("div", { className: "m-b-l" },
                                React.createElement(Heading, { className: "text-center m-none font-size-xxxl" }, company.name)),
                            React.createElement(CompanyNumbers, null,
                                React.createElement("div", { className: "flex-1 flex-col align-center" },
                                    React.createElement(NumbersTitle, null,
                                        React.createElement(FormattedMessage, { id: "company.profile_box.offers" })),
                                    React.createElement("div", { className: "font-size-xxxl font-bold" }, activeOffers === null || activeOffers === void 0 ? void 0 : activeOffers.length)),
                                React.createElement("div", { className: "flex-1 flex-col align-center" },
                                    React.createElement(NumbersTitle, null,
                                        React.createElement(FormattedMessage, { id: "company.profile_box.followers" })),
                                    React.createElement("div", { className: "font-size-xxxl font-bold" }, subscribersCount))),
                            isUserFromCompany && (React.createElement(Button, { size: "middle", type: "primary", link: "/admin/firemny-profil" },
                                React.createElement(FormattedMessage, { id: "profile.change_profile" }))),
                            !isUserFromCompany && (React.createElement(Button, { size: "middle", shape: "round", loading: isSubscriptionLoading, icon: isAccountSubscribed ? React.createElement(IconCheck, { width: 16 }) : React.createElement(IconAdd, { width: 16 }), onClick: toggleSubscription },
                                React.createElement("span", { className: "m-l-s" },
                                    !isAccountSubscribed && (React.createElement(FormattedMessage, { id: "company.subscription", defaultMessage: "Odber nov\u00FDch pon\u00FAk" })),
                                    isAccountSubscribed && (React.createElement(FormattedMessage, { id: "company.subscription.active" }))))),
                            React.createElement(TotalCount, null,
                                React.createElement(FormattedMessage, { id: "offers.offers_count", values: {
                                        total: publishedOffers === null || publishedOffers === void 0 ? void 0 : publishedOffers.length,
                                    } })),
                            React.createElement(AddressBox, null,
                                React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconMapsPin, { width: 24, height: 24 })),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.address" })),
                                        React.createElement(SubText, null, (_c = company.address) === null || _c === void 0 ? void 0 : _c.formattedAddress))),
                                Boolean(company.cin) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconBriefcase, { width: 24, height: 24 })),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.ico" })),
                                        React.createElement(SubText, null, company.cin)))),
                                Boolean(company.area) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconFolderOpen, { width: 24, height: 24 })),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.area" })),
                                        React.createElement(SubText, null, company.area)))),
                                Boolean(company.employees) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconMultipleUsers, { width: 24, height: 24 })),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.employees" })),
                                        React.createElement(SubText, null, company.employees)))),
                                Boolean(company.website) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconNetworkBrowser, { width: 24, height: 24 })),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.website" })),
                                        React.createElement("a", { href: company.website }, company.website))))),
                            React.createElement(MoreInfo, { className: "text-left" },
                                React.createElement(Separator, null,
                                    React.createElement(Heading, { className: "font-size-xl m-none" },
                                        React.createElement(FormattedMessage, { id: "company.about" }))),
                                React.createElement("div", { className: "font-size-s" }, htmlToReactParser.parse(company.description))),
                            hasAtLeastOneSocial && (React.createElement(CompanyInfo, null,
                                React.createElement(SocialButtons, null,
                                    React.createElement(Separator, null,
                                        React.createElement(Subheading, null,
                                            React.createElement(FormattedMessage, { id: "company.social_media" }))),
                                    React.createElement("div", { className: "flex-center" },
                                        Boolean(company.facebookUrl) && (React.createElement("a", { href: `${config.socialBaseUrl.facebook}${company.facebookUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconFacebook, { type: "social-facebook" }))),
                                        Boolean(company.linkedinUrl) && (React.createElement("a", { href: `${config.socialBaseUrl.linkedinCompany}${company.linkedinUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconLinkedIn, { type: "social-linkedin" }))),
                                        Boolean(company.instagramUrl) && (React.createElement("a", { href: `${config.socialBaseUrl.instagram}${company.instagramUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconInstagram, { type: "social-instagram" }))),
                                        Boolean(company.twitterUrl) && (React.createElement("a", { href: `${config.socialBaseUrl.twitter}${company.twitterUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconTwitter, { type: "social-twitter" }))),
                                        Boolean(company.youtubeUrl) && (React.createElement("a", { href: `${config.socialBaseUrl.youtube}${company.youtubeUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconYoutube, { type: "social-youtube" })))))))))),
                React.createElement(BodyContent, { className: "position-relative" },
                    React.createElement(Description, null,
                        React.createElement(Heading, { className: "font-size-xl" },
                            React.createElement(FormattedMessage, { id: "company.about" })),
                        htmlToReactParser.parse(company.description)),
                    (companyActiveOffers === null || companyActiveOffers === void 0 ? void 0 : companyActiveOffers.length) > 0 && (React.createElement(StyledPanel, { className: "m-t-m m-b-m" },
                        React.createElement(Heading, { className: "font-size-xl" },
                            React.createElement(FormattedMessage, { id: "company.active_offers" }),
                            ' ',
                            React.createElement("em", null,
                                "(", companyActiveOffers === null || companyActiveOffers === void 0 ? void 0 :
                                companyActiveOffers.length,
                                ")")), companyActiveOffers === null || companyActiveOffers === void 0 ? void 0 :
                        companyActiveOffers.map(item => {
                            return (React.createElement(Position, { to: item.link, key: item.key },
                                React.createElement("div", null,
                                    React.createElement("h2", null, item.position),
                                    React.createElement("div", { className: "type" }, item.type),
                                    React.createElement("div", { className: "city" }, item.city),
                                    React.createElement("div", { className: "salary" }, item.salary)),
                                React.createElement("div", { className: "detail" },
                                    React.createElement(FormattedMessage, { id: "global.detail" }),
                                    " \u279C")));
                        }))))))));
}
export { CompanyPage };
