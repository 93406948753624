import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from '@src/api/account';
import { roundToClosestValue } from '@src/helpers/roundToClosestValue';
import { Box, BoxBottom, BoxMain, Claim, Content, Picture, Quickstart, QuickstartCTA, QuickstartCTAButton, QuickstartTile, } from './BoxQuickStart.styles';
const tiles = [
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_1_headline" }),
        image: 'nomad-home-2',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_1_text" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_2_headline" }),
        image: 'workspace-imac-coffee-1',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_2_text" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_3_headline" }),
        image: 'app-launch-laptop',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_3_text" }),
    },
];
const BoxQuickstart = () => {
    const theme = useTheme();
    const { data: accountCounts } = useAppQuery(Queries.getAccountsCount, () => Api.getAllAccountsCount());
    return (React.createElement(Box, null,
        React.createElement(BoxMain, null,
            React.createElement(Quickstart, null, tiles.map(item => {
                return (React.createElement(QuickstartTile, { key: item.image },
                    React.createElement(Picture, null,
                        React.createElement("img", { src: `/media/illustrations/streamline-icon-${item.image}.svg`, alt: "" })),
                    React.createElement(Content, null,
                        React.createElement("dt", null, item.name),
                        React.createElement("dd", null, item.text))));
            })),
            React.createElement(QuickstartCTA, null,
                React.createElement(QuickstartCTAButton, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: "/prihlasenie" },
                    React.createElement(FormattedMessage, { id: "home.quickstart.cta" })))),
        React.createElement(BoxBottom, null,
            React.createElement(Claim, null,
                React.createElement(FormattedMessage, { id: "home.quickstart.subheadline", values: {
                        count: React.createElement("strong", null,
                            roundToClosestValue(accountCounts, 100),
                            "+"),
                        span: chunks => React.createElement("strong", null, chunks),
                        a: chunks => React.createElement(Link, { to: "/ponuky" }, chunks),
                    } })))));
};
export { BoxQuickstart };
