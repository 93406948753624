import { client as axios } from './clients/axios';
export async function createSavedOffer(offerId) {
    const response = await axios.post('/0/save-offer', { offerId });
    return response.data;
}
export async function getSavedOffers() {
    const response = await axios.get('/0/save-offer');
    return response.data;
}
export async function deleteSavedOffer(offerId) {
    const response = await axios.delete(`/0/save-offer/${offerId}`);
    return response.data;
}
export async function getSavedOfferById(offerId) {
    const response = await axios.get(`/0/save-offer/${offerId}`, {
        params: { offerId },
    });
    return response.data;
}
export async function deleteSavedOffers(query) {
    const response = await axios.delete(`/0/save-offer/all`, {
        params: query,
    });
    return response.data;
}
