var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { Select } from '@src/components/elements';
import { useIntl } from 'react-intl';
import * as Api from '@src/api';
import { normalizeStr } from '@src/helpers/normalize-string';
const { Option } = Select;
const SelectFieldWrapper = (props) => {
    var _a, _b;
    const intl = useIntl();
    const { offerTypes, useIdAsValue, value, onChange, defaultValue } = props, otherProps = __rest(props, ["offerTypes", "useIdAsValue", "value", "onChange", "defaultValue"]);
    const onSelectChange = (ids) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(ids.map(id => offerTypes.find(offerType => offerType.id === id)));
    };
    return (React.createElement(Select, Object.assign({ mode: "multiple", placeholder: intl.formatMessage({ id: 'global.type.placeholder' }), showArrow: true, optionFilterProp: "children", value: useIdAsValue ? value : (_a = value) === null || _a === void 0 ? void 0 : _a.map(offerType => offerType.id), defaultValue: useIdAsValue ? value : (_b = value) === null || _b === void 0 ? void 0 : _b.map(offerType => offerType.id), onChange: useIdAsValue ? onChange : onSelectChange, showSearch: true, filterOption: (input, option) => normalizeStr(option.children).indexOf(normalizeStr(input)) >= 0 }, otherProps), offerTypes.map(offerType => {
        // we need to pass string as children to option for using filterOption on Select search
        // if we pass <FormattedMessage.../> as children, search does not work
        const offerTypeName = intl.formatMessage({
            id: offerType.translation_key,
        });
        return (React.createElement(Option, { key: offerType.id, value: offerType.id }, offerTypeName));
    })));
};
export const TypeSelectField = (props) => {
    const { rules, useIdAsValue = true, label, name, dependencies } = props, otherProps = __rest(props, ["rules", "useIdAsValue", "label", "name", "dependencies"]);
    const { data: offerTypes, } = useQuery('filterTypes', async () => {
        const data = await Api.Offer.getOfferTypes();
        return data;
    });
    if (!offerTypes) {
        return null;
    }
    return (React.createElement(Form.Item, { label: label, name: name, rules: rules, dependencies: dependencies },
        React.createElement(SelectFieldWrapper, Object.assign({ useIdAsValue: useIdAsValue, offerTypes: offerTypes }, otherProps))));
};
