import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Icon, Modal } from '@src/components/elements';
import { OfferNotificationSubscriptionForm } from '@src/components/modules/forms/offer-notification-subscription-form';
import styled from 'styled-components';
const { Title } = Typography;
const OfferNotificationSubscriptionModal = ({ isOpen, setIsOpen }) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    const intl = useIntl();
    return (React.createElement(Modal, { title: React.createElement("div", { className: "flex-row align-center" },
            React.createElement(BellIcon, { type: "bell" }),
            React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_form.didnt_find_suitable_offer" })), visible: isOpen, footer: null, onCancel: closeModal, destroyOnClose: true, width: 720 },
        React.createElement(StyledInfoAlert, { message: intl.formatMessage({ id: 'offers.offer_notification_subscription_form.info_alert' }), type: "info", showIcon: true }),
        React.createElement(OfferNotificationSubscriptionForm, { showEmailField: true, onSubmit: closeModal })));
};
export const StyledTitle = styled(Title) `
	&& {
		margin-bottom: 0;
		font-weight: 400;
	}
`;
export const BellIcon = styled(Icon) `
	svg {
		width: 16px;
		height: 16px;
		stroke: black;
		margin-right: 0.5rem;
	}
`;
export const StyledInfoAlert = styled(Alert) `
	margin-bottom: 1rem;
`;
export { OfferNotificationSubscriptionModal };
