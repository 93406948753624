import { Link, Panel, Tag } from '@src/components/elements';
import styled, { css } from 'styled-components';
export const CustomPanel = styled(Panel) `
	padding: 1rem;
	margin-bottom: 1rem;
	${({ arePromotedOffers }) => arePromotedOffers &&
    css `
			border: 1px solid ${({ theme }) => theme.color.premium};
		`}
`;
export const SimilarOfferBox = styled.aside `
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const Heading = styled.h5 `
	font-size: 1.2rem;
	text-align: center;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;

	${({ arePromotedOffers }) => arePromotedOffers &&
    css `
			padding: 0.25rem 0.75rem;
			border-radius: ${({ theme }) => theme.borderRadius.base};
			background-color: ${({ theme }) => theme.color.premium};
			color: ${({ theme }) => theme.color.white};
			width: fit-content;
		`}
`;
export const Title = styled.p `
	font-size: 1rem;
	margin-bottom: 0.5rem;
	line-height: 1rem;
`;
export const Item = styled(Link) `
	border-bottom: 1px solid ${({ theme }) => theme.color.gray4};
	padding: 0.75rem;
	width: 100%;

	&:hover {
		background: ${({ theme }) => theme.color.gray1};
		transition: 0.167s;
	}
	&:last-child {
		border-bottom: none;
	}
`;
export const Info = styled.div `
	color: ${({ theme }) => theme.color.black};
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 0.8rem;
	width: 100%;
	white-space: normal;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 0.5rem;

	svg {
		height: 14px;
		width: 14px;
		min-width: 14px;
	}
`;
export const StyledTag = styled(Tag) ``;
export const TagText = styled.div `
	white-space: normal;
	word-wrap: break-word;
`;
