import { Button, Input, Panel } from '@src/components/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import CurrencyEuroBill from '@public/media/icons/streamline-icon-currency-euro-bill.svg';
import CheckCircle1 from '@public/media/icons/streamline-icon-check-circle-1.svg';
import Bin from '@public/media/icons/streamline-icon-bin.svg';
const AdminOrderCoupon = ({ isApplyDisabled, appliedCouponCode, isCouponLoading, setAppliedCouponCode, setCouponInputValue, validateCoupon, couponInputValue, setDiscount, }) => {
    const theme = useTheme();
    return (React.createElement(Panel, { className: "m-t-m p-m" },
        !appliedCouponCode && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex-row" },
                React.createElement("div", { className: "flex-col" },
                    React.createElement("div", { className: "m-b-s font-size-l" },
                        React.createElement(FormattedMessage, { id: "admin.order.coupon.use_your_coupon" }),
                        React.createElement(CurrencyEuroBill, { fill: theme.color.success, width: 40, className: "m-l-s" })),
                    React.createElement(Input, { style: { fontSize: '2rem' }, value: couponInputValue, onChange: e => { var _a; return setCouponInputValue((_a = e.target.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()); }, placeholder: "JKL23ABC", maxLength: 10 }))),
            React.createElement("div", { className: "m-t-m flex-center" },
                React.createElement(Button, { loading: isCouponLoading, disabled: isApplyDisabled, size: "middle", onClick: () => validateCoupon() },
                    React.createElement(FormattedMessage, { id: "admin.order.coupon.apply" }))))),
        Boolean(appliedCouponCode) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex-center" },
                React.createElement("div", { className: "flex-col align-center" },
                    React.createElement(CheckCircle1, { className: "m-b-s", fill: theme.color.success, width: 40 }),
                    React.createElement("div", { className: "text-center font-size-m" },
                        React.createElement(FormattedMessage, { id: "admin.order.coupon.your_coupon_was_applied" })))),
            React.createElement("div", { className: "m-t-m flex-center" },
                React.createElement(Button, { type: "text", size: "middle", onClick: () => {
                        setAppliedCouponCode(null);
                        setDiscount(0);
                    } },
                    React.createElement(Bin, { className: "m-r-xs", width: 16, fill: "gray" }),
                    React.createElement(FormattedMessage, { id: "admin.order.coupon.remove" })))))));
};
export { AdminOrderCoupon };
