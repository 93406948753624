import { Button, Modal } from '@src/components/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';
const CancelButton = ({ setVisible }) => (React.createElement(Button, { type: "default", size: "middle", onClick: () => setVisible(false) },
    React.createElement(FormattedMessage, { id: "registration.registration_form.cancel" })));
export function DataProcessingOrNewsletterModal({ visible, setVisible, textId, companyName }) {
    return (React.createElement(Modal, { visible: visible, centered: true, onCancel: () => {
            setVisible(false);
        }, footer: React.createElement(CancelButton, { setVisible: setVisible }) },
        React.createElement(FormattedMessage, { id: textId, values: {
                breakingLine: React.createElement("br", null),
                companyName,
            } })));
}
