// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { Button, Holder } from '@src/components/elements';
import { Col, Row } from 'antd';
import { BigButton, ButtonWrapper, Note, Content, Headline, Hero, Wrapper, FeaturesWrapper, ButtonText, BottomCTA, Video, } from './Recruitment.style';
import { Features } from './Features';
const Recruitment = () => {
    const theme = useTheme();
    return (React.createElement(Wrapper, null,
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "recruitment.headline" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "recruitment.subheadline_1" }))))),
        React.createElement(Content, null,
            React.createElement(FeaturesWrapper, { id: "features" },
                React.createElement(Holder, null,
                    React.createElement(Video, { playbackId: "rf01qHeA2O3bH6z9ScYRaKmJiaosUPXrPd58v8FkHTmw", streamType: "on-demand", controls: true, autoPlay: false, muted: false }),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "recruitment.features.headilne" })),
                    React.createElement(Features, null),
                    React.createElement(BigButton, { style: {
                            background: theme.color.success,
                            borderColor: theme.color.success,
                            color: theme.color.white,
                        }, link: "/kontakt?type=recruitment" },
                        React.createElement(ButtonText, null,
                            React.createElement(FormattedMessage, { id: "recruitment.cta1" }))))),
            React.createElement(BottomCTA, null,
                React.createElement(Holder, null,
                    React.createElement(Row, { gutter: 16, justify: "center" },
                        React.createElement(Col, { className: "gutter-row", span: 12 },
                            React.createElement("div", null,
                                React.createElement("h2", null,
                                    React.createElement(FormattedMessage, { id: "recruitment.no_risk" })),
                                React.createElement("p", null,
                                    React.createElement(FormattedMessage, { id: "recruitment.no_risk.subheadilne", values: {
                                            strong: chunks => React.createElement("strong", null, chunks),
                                        } })),
                                React.createElement(ButtonWrapper, null,
                                    React.createElement(Button, { style: {
                                            background: theme.color.success,
                                            borderColor: theme.color.success,
                                            color: theme.color.white,
                                        }, link: "/kontakt?type=recruitment" },
                                        React.createElement(ButtonText, null,
                                            React.createElement(FormattedMessage, { id: "general.contact_us" }))),
                                    React.createElement(Note, null,
                                        React.createElement(FormattedMessage, { id: "recruitment.no_risk.note" })))))))))));
};
export { Recruitment };
