import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Button } from '@src/components/elements';
import MuxVideo from '@mux/mux-video-react';
export const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
export const BigButton = styled(Button) `
	${up('sm')} {
		transform: scale(1.2);
	}
`;
export const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 3.5em 2em 0;

	${up('sm')} {
		padding: 2.5em 2em 0;
	}

	${up('md')} {
		padding: 5em 2em 0;
	}

	& > * {
		color: ${props => props.theme.color.white};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
	}
`;
export const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 360px;
	position: relative;
	background: url('/media/images/hero-candidate.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 0;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.95;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		transform: scaleX(-1);
	}
`;
export const Content = styled.div `
	width: 100%;
`;
export const TestimonialsWrapper = styled.div `
	width: 100%;

	padding: 5em 0;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const FeaturesWrapper = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0 5em;
	text-align: center;
	border-bottom: 1px solid ${props => props.theme.color.gray2};

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const BottomCTA = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0;
	text-align: center;

	p strong {
		color: ${props => props.theme.color.brand2};
	}

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
export const ButtonWrapper = styled.div `
	width: 100%;
	text-align: center;
`;
export const Note = styled.div `
	width: 100%;
	font-weight: 400;
	font-size: 0.9em;
	padding: 1em 3em 0;
	color: ${props => props.theme.color.gray6};
`;
export const ButtonText = styled.div ``;
export const Video = styled(MuxVideo) `
	height: 100%;
	max-width: 100%;
	width: 50em;
	border-radius: 0.8rem;
	margin-bottom: 6em;
`;
