import { Slider } from 'antd';
import { SwitchButtonsGroup, InputNumber } from '@src/components/elements';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
export const StyledSlider = styled(Slider) `
	margin: 1rem 6px;

	&& {
		.ant-slider-dot {
			height: 14px;
			width: 14px;
			top: -4px;
		}
		.ant-slider-handle {
			height: 20px;
			width: 20px;
			margin-top: -7px;
		}
		.ant-slider-rail {
			height: 6px;
		}
		.ant-slider-track {
			height: 6px;
		}
		.ant-slider-mark-text {
			margin-top: 5px;
		}
	}
`;
export const InputMinMax = styled(InputNumber) `
	width: 110px;
`;
export const SalaryRateButtons = styled(SwitchButtonsGroup) `
	${down('xs')} {
		.ant-btn {
			font-size: 13px;
			padding: 4px 12px;
		}
	}
`;
