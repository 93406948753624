import React from 'react';
import MuxVideo from '@mux-elements/mux-video-react';
import { useAccount } from '@src/context/account-context';
import styled from 'styled-components';
const AssetDetailVideoContainer = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`;
export function AssetDetailVideo({ asset }) {
    const account = useAccount();
    return (React.createElement(AssetDetailVideoContainer, null,
        React.createElement(MuxVideo, { style: { maxWidth: '100%' }, playbackId: asset.muxPublicPlaybackId, metadata: {
                video_id: asset.id,
                video_title: asset.filename,
                viewer_user_id: account === null || account === void 0 ? void 0 : account.id,
            }, 
            // @ts-ignore
            autoPlay: "muted", envKey: process.env.MUX_VIDEO_TRACK_KEY, 
            // @ts-ignore
            streamType: "on-demand", controls: true, disablePictureInPicture: true, height: "100%" })));
}
