// @ts-nocheck
import React from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import getCmsAsset from '@src/helpers/get-cms-asset';
import NotFound from '@src/components/pages/not-found';
import RenderHtml from '@src/components/elements/RenderHtml';
import Timestamp from '@src/components/modules/global/timestamp';
import { Button, Head, Holder, Title } from '@src/components/elements';
import excerptHtml from 'excerpt-html';
import { DiscussionEmbed } from 'disqus-react';
import { Helmet } from 'react-helmet';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
// import { Link } from '@src/components/elements'
const BodyWrapper = styled.div `
	font-size: 1.2em;
	margin-top: 2rem;
	& img {
		width: auto;
		max-width: 100%;
		margin: 2em auto;
		display: block;
	}
	& .imageCaption {
		margin: -1em 0 2em 0;
		text-align: center;
		font-size: 1.1em;
		opacity: 0.8;
		line-height: 1.1;
	}
	& iframe {
		width: 100%;
		margin: 2em auto;
		display: block;
		min-height: 400px;
	}
	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		padding-top: 1.8em;
		padding-bottom: 1.5em;
		font-weight: bold;
		color: ${props => props.theme.color.brand1};
	}
	& h1 {
		font-size: 1.6em;
	}
	& h2 {
		font-size: 1.5em;
	}
	& h3 {
		font-size: 1.3em;
	}
	& h4 {
		font-size: 1.1em;
	}
	& h5 {
		font-size: 1em;
	}
	& h6 {
		font-size: 1em;
	}
	& ul,
	& ol {
		padding-top: 1.8em;
		padding-bottom: 1.5em;
	}
	& blockquote {
		border-left: 4px solid ${props => props.theme.color.gray5};
		color: ${props => props.theme.color.gray7};
		font-size: 1.025em;
		padding-left: 1.125em;
		margin: 0 2em 1.333333333em 2em;
	}
	& table {
		margin: 2em 0 2em;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		max-width: 100%;
	}
	& td {
		margin: 0.3em 0.6em 0 0;
		padding: 0.5em 2em 0.5em 0;
	}
	& tr {
		border-bottom: 1px solid ${props => props.theme.color.gray2};
	}
	& a {
		text-decoration: underline;
	}
	& .partner {
		padding-top: 2em;
	}
	& .magazine-widget {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		margin-top: 3em;
		margin-bottom: 3em;
		padding: 1em 2em;
		border: 1px solid ${props => props.theme.color.gray5};
		border-radius: ${props => props.theme.borderRadius.large};
		background: ${props => props.theme.color.white};

		${up('md')} {
			width: 80%;
		}
	}
	& .magazine-title {
		font-size: 1.1em;
		font-weight: bold;
		display: inline-block;
	}
	& .magazine-article {
		.magazine-title {
			padding-top: 0.4em;
			padding-bottom: 0.4em;
		}
	}
	& .magazine-widget-headline {
		font-size: 0.9em;
		padding-top: 0.2em;
		padding-bottom: 0.3em;
		color: ${props => props.theme.color.gray8};
	}
`;
const Subheadline = styled.div `
	text-align: center;
	display: block;
	font-weight: bold;
	color: ${props => props.theme.color.gray6};
`;
export const Image = styled.img `
	margin: 2em auto;
	display: block;
	max-width: 100%;
`;
// TODO: post filter by categories
// export const CategoryLink = styled(Link)`
export const CategoryLink = styled.span `
	display: inline-block;
	margin-top: 0.5em;
	&:hover {
		text-decoration: none;
	}
	text-transform: uppercase;
`;
export const AuthorLink = styled.span `
	display: inline-block;
	margin-top: 0.5em;
	&:hover {
		text-decoration: none;
	}
	color: ${props => props.theme.color.brand3};
	text-transform: uppercase;
`;
export const DotSeparator = styled.span `
	display: inline-block;
	margin: 0 0.5em;
`;
export const ShareWrapper = styled.div `
	margin: 4em 0 4em 0;
`;
export const ShareText = styled.div `
	text-align: center;
	font-weight: bold;
	font-size: 1.3em;
`;
export const ShareCTAs = styled.div `
	display: flex;
	justify-content: center;
	margin: 2em 0 2em 0;
	flex-wrap: wrap;
`;
export const StyledButton = styled(Button) `
	font-weight: bold;
	text-transform: uppercase;
	margin: 0.5em;
	&:link,
	&:visited,
	&:focus {
		line-height: 1.7em;
		color: ${props => props.theme.color.white};
	}

	&:hover,
	&:active {
		line-height: 1.7em;
		color: ${props => props.theme.color.white};
	}

	&& {
		padding-top: 8px !important;
	}
`;
export const DiscussionWrapper = styled.div `
	padding: 2em 0 0 0;
`;
// TODO: next/previous articles
// TODO: similar articles
const Post = () => {
    const theme = useTheme();
    const location = useLocation();
    // @ts-ignore
    const { slug } = useParams();
    const { data: post, error } = useSSRFetch(SSRFetchConfig.magazinePost.key, slug);
    const currentURL = `${process.env.PUBLIC_URL}${encodeURI(location.pathname)}`;
    let ldJson;
    if (post) {
        ldJson = {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': currentURL,
            },
            headline: post.title,
            image: getCmsAsset(post.coverImage, 'large'),
            datePublished: post.date,
            dateModified: post.date,
            author: {
                '@type': 'Person',
                name: post.author || 'Pracuj.sk',
            },
            publisher: {
                '@type': 'Organization',
                name: 'Pracuj.sk',
                logo: {
                    '@type': 'ImageObject',
                    url: `${process.env.PUBLIC_URL}/media/images/logo-square.png`,
                },
            },
        };
    }
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, md: 18 },
                error && React.createElement(NotFound, null),
                post && (React.createElement(React.Fragment, null,
                    React.createElement(Helmet, null,
                        React.createElement("script", { type: "application/ld+json" }, JSON.stringify(ldJson))),
                    React.createElement(Head, { title: post.title, description: excerptHtml(post.content, { pruneLength: 300 }), imageUrl: getCmsAsset(post.coverImage, 'large') }),
                    React.createElement(Row, { justify: "center" },
                        React.createElement(Col, null,
                            React.createElement(Title, { level: 1, type: "secondary" }, post.title))),
                    React.createElement(Subheadline, null,
                        React.createElement("time", { dateTime: post.date },
                            React.createElement(Timestamp, { value: post.date, type: "humanized" })),
                        React.createElement(DotSeparator, null, "\u25AA"),
                        React.createElement(CategoryLink, null, post.category),
                        post.author && (React.createElement(React.Fragment, null,
                            React.createElement(DotSeparator, null, "\u25AA"),
                            React.createElement(AuthorLink, null, post.author)))),
                    React.createElement(Image, { src: getCmsAsset(post.coverImage, 'body'), srcSet: `${getCmsAsset(post.coverImage, 'body')} 1x, ${getCmsAsset(post.coverImage, 'body_2x')} 2x`, alt: post.title }),
                    React.createElement(BodyWrapper, null,
                        React.createElement(RenderHtml, { html: post.content, extended: true }),
                        post.partnerHostname && (React.createElement("div", { className: "partner" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "magazine.continue_to_article" }),
                                ' ',
                                React.createElement("a", { href: `${post.partnerLink}?ref=pracuj.sk`, target: "_blank", rel: "noreferrer" }, post.title),
                                ' '),
                            "[",
                            post.partnerHostname,
                            "]"))),
                    React.createElement(ShareWrapper, null,
                        React.createElement(ShareText, null,
                            React.createElement(FormattedMessage, { id: "magazine.share_message" })),
                        React.createElement(ShareCTAs, null,
                            React.createElement(StyledButton, { type: "primary", size: "large", href: `http://twitter.com/intent/tweet?url=${currentURL}`, target: "_blank", style: { background: theme.color.twitter, borderColor: theme.color.twitter } },
                                React.createElement(FormattedMessage, { id: "magazine.share_twitter" })),
                            React.createElement(StyledButton, { type: "primary", size: "large", href: `https://www.facebook.com/sharer.php?u=${currentURL}`, target: "_blank", style: { background: theme.color.facebook, borderColor: theme.color.facebook } },
                                React.createElement(FormattedMessage, { id: "magazine.share_facebook" })))),
                    React.createElement(DiscussionWrapper, null,
                        React.createElement(DiscussionEmbed, { shortname: "pracuj-sk", config: {
                                url: currentURL,
                                identifier: slug,
                                title: post.title,
                                language: 'cz_CS',
                            } }))))))));
};
export default Post;
