import React from 'react';
const PremiumCompanyProfileModalContext = React.createContext({
    isPremiumCompanyProfileSubscriptionModalOpen: false,
    setIsPremiumCompanyProfileSubscriptionModalOpen: () => { },
});
export const usePremiumCompanyProfileModalContextProvider = () => {
    const [isPremiumCompanyProfileSubscriptionModalOpen, setIsPremiumCompanyProfileSubscriptionModalOpen] = React.useState(false);
    return {
        PremiumCompanyProfileModalContextProvider: PremiumCompanyProfileModalContext.Provider,
        value: {
            isPremiumCompanyProfileSubscriptionModalOpen,
            setIsPremiumCompanyProfileSubscriptionModalOpen,
        },
    };
};
export const usePremiumCompanyProfileModalContextValue = () => {
    const context = React.useContext(PremiumCompanyProfileModalContext);
    return context;
};
