/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { Holder, Panel, Title } from '@src/components/elements';
import { RegistrationForm } from './modules/RegistrationForm';
export function Registration() {
    const intl = useIntl();
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, lg: { span: 12 } },
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "registration.employer" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, xl: { span: 18 } },
                React.createElement(Panel, null,
                    React.createElement(RegistrationForm, { intl: intl }))))));
}
