import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Row, Col } from 'antd';
import { Pagination, Holder } from '@src/components/elements';
import config from '@src/config';
import MissingContentView from '@src/components/modules/MissingContentView/MissingContentView';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
import LoadingWrapper from '@src/components/modules/global/loading-wrapper';
import Excerpt from '../excerpt';
const Homepage = () => {
    // @ts-ignore
    const { page } = useParams();
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const currentPage = page !== undefined ? parseInt(page, 10) : 1;
    const { data: posts, refetch, isLoading, } = useSSRFetch(SSRFetchConfig.magazinePosts.key, currentPage, [location.pathname, currentPage]);
    const { data: postsCount } = useSSRFetch(SSRFetchConfig.magazinePostsCount.key, undefined, [
        location.pathname,
    ]);
    useEffect(() => {
        refetch();
    }, [location.pathname]);
    const onChangePage = newPage => {
        if (newPage === 1) {
            return history.pushLocalized('/magazin');
        }
        return history.pushLocalized(`/magazin/archiv/${newPage}`);
    };
    if (Array.isArray(posts) && !posts.length) {
        return (React.createElement(MissingContentView, { heading: intl.formatMessage({
                id: 'magazine.missing_content_heading',
                defaultMessage: 'Žiadne ponuky"',
            }), message: intl.formatMessage({
                id: 'magazine.missing_content_message',
                defaultMessage: 'Bohužiaľ, momentálne nie sú k dispozícii žiadne ponuky',
            }) }));
    }
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(LoadingWrapper, { isLoading: isLoading },
            React.createElement(Row, { justify: "center" },
                React.createElement(Col, null,
                    posts &&
                        posts.map(post => {
                            return React.createElement(Excerpt, { key: post.title, post: post });
                        }),
                    posts && postsCount && postsCount > config.magazine.postsPerPage && (React.createElement(Pagination, { current: currentPage, total: postsCount, defaultPageSize: config.magazine.postsPerPage, onChange: onChangePage, showSizeChanger: false })),
                    currentPage > 1 && (React.createElement(Helmet, null,
                        React.createElement("meta", { name: "robots", content: "noindex, follow" }))))))));
};
export default Homepage;
