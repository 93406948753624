import styled from 'styled-components';
export const AssetDetailDocumentIconContainer = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	gap: 2rem;
`;
export const AssetDetailDocumentContainer = styled.div `
	width: 100%;
	height: 100%;
	border: 2px solid ${props => props.theme.color.gray9};
`;
