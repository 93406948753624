import { Panel } from '@src/components/elements';
import { BoxChosen } from '@src/components/pages/companies/BoxCredits';
import { ProductId, ProductType } from '@src/types';
import React from 'react';
import dayjs from 'dayjs';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ProductNote, OrderTitle, ProductDescription, ProductTitle } from './AdminOrder.style';
const AdminOrderProducts = ({ orderContent }) => {
    return (React.createElement(Panel, { className: "p-m" },
        React.createElement(OrderTitle, null,
            React.createElement(FormattedMessage, { id: "admin.order" })),
        orderContent &&
            orderContent.products.map(product => (React.createElement(React.Fragment, { key: product.id },
                product.translationKey && (React.createElement(React.Fragment, null,
                    React.createElement(ProductTitle, null,
                        React.createElement(FormattedMessage, { id: product.translationKey })),
                    React.createElement(ProductDescription, null,
                        React.createElement(FormattedMessage, { id: `${product.translationKey}.description` })))),
                product.productType === ProductType.credit && product.amount && (React.createElement(React.Fragment, null,
                    React.createElement(BoxChosen, null,
                        React.createElement(FormattedMessage, { values: { count: product.amount }, id: "general.credits.pluralized" })),
                    React.createElement(ProductNote, null,
                        React.createElement(FormattedMessage, { id: "general.credits.note_expiration" })))),
                product.productType === ProductType.subscription && product.amount && (React.createElement(BoxChosen, null,
                    React.createElement(FormattedMessage, { values: { count: product.amount }, id: "general.month.pluralized" }))),
                product.productType === ProductType.promotion &&
                    product.amount &&
                    product.id === ProductId.offerPromotion && (React.createElement(React.Fragment, null,
                    React.createElement(BoxChosen, null,
                        React.createElement(FormattedMessage, { values: { count: product.amount }, id: "general.day.pluralized" })),
                    React.createElement(ProductNote, null,
                        "do",
                        ' ',
                        React.createElement(FormattedDate, { value: dayjs().add(product.amount, 'day').toDate(), day: "numeric", month: "long", year: "numeric" })))))))));
};
export { AdminOrderProducts };
