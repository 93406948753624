import styled from 'styled-components';
export const DocumentContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	width: 100%;
	height: 100%;
	border-radius: inherit;
`;
export const PDFPreviewContainer = styled.div `
	width: 100%;
	height: 100%;

	.react-pdf__Document {
		height: 100%;
		.react-pdf__Page {
			height: 100%;
			display: flex;
			justify-content: center;
			background-color: ${props => props.theme.color.gray9};
			border-radius: 8px 8px 0 0;
			canvas {
				height: 100% !important;
				width: 100% !important;
				object-fit: contain;
			}
			.react-pdf__Page__textContent {
				display: none !important;
			}
		}
		.react-pdf__message {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}
`;
