import React from 'react';
import { AssetType, EOfferGalleryKey } from '@src/types';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { getMuxVideoThumbnailUrl } from '@src/shared/get-mux-video-thumbnail-url';
import { DetailsAssetsModal } from '@src/components/modules/AssetDetailModal';
import { AssetImg, GalleryAssetCardContainer, GalleryAssetsContainer } from './OfferDetailsGallery.style';
function getAssetImgUrl(asset) {
    if (asset.type === AssetType.IMAGE) {
        return getCdnImageUrl({
            file: asset.cloudinaryPublicId,
            transformation: 'w_200,c_scale',
            shouldIncludeEnvironmentPart: false,
        });
    }
    if (asset.type === AssetType.VIDEO) {
        return getMuxVideoThumbnailUrl({ muxPublicPlaybackId: asset.muxPublicPlaybackId, width: 200 });
    }
    throw new Error('Assets in offer gallery should be only images and videos');
}
function GalleryAssetCard({ asset, onClick }) {
    return (React.createElement(GalleryAssetCardContainer, { onClick: onClick },
        React.createElement(AssetImg, { src: getAssetImgUrl(asset) })));
}
export function OfferDetailsGallery({ gallery, shouldHideTitle }) {
    const [isAssetsModalOpen, setIsAssetsModalOpen] = React.useState(false);
    const [initialAssetId, setInitialAssetId] = React.useState(null);
    const openAssetsModal = (assetId) => {
        setIsAssetsModalOpen(true);
        setInitialAssetId(assetId);
    };
    const closeAssetsModal = () => {
        setIsAssetsModalOpen(false);
        setInitialAssetId(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "m-t-l m-b-l" },
            !shouldHideTitle && React.createElement("h3", null, gallery[EOfferGalleryKey.title]),
            React.createElement(GalleryAssetsContainer, null, gallery.assets.map(asset => (React.createElement(GalleryAssetCard, { key: asset.id, asset: asset, onClick: () => openAssetsModal(asset.id) }))))),
        React.createElement(DetailsAssetsModal, { isOpen: isAssetsModalOpen, assets: gallery.assets, closeModal: closeAssetsModal, initialAssetId: initialAssetId })));
}
