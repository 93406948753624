import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
export function useAppMutation(mutationFn, options) {
    const intl = useIntl();
    return useMutation(mutationFn, Object.assign(Object.assign({}, options), { onError: (options === null || options === void 0 ? void 0 : options.onError) ? options === null || options === void 0 ? void 0 : options.onError : error => {
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        } }));
}
