import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link, Holder } from '@src/components/elements';
import { useConfig } from '@src/hooks/useConfig';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from '@src/api/company';
import { roundToClosestValue } from '@src/helpers/roundToClosestValue';
import { CompaniesListGrid } from './CompaniesListGrid';
export const CompaniesListPreviewWrapper = styled.div `
	width: 100%;
	padding: 5em 0;
	margin: 0 auto;

	h2 {
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
		color: ${props => props.theme.color.brand4};
	}

	h3 {
		font-weight: 400;
		font-size: 1.5em;
		text-align: center;
		margin: 0;

		strong {
			color: ${props => props.theme.color.brand2};
		}
	}
`;
function CompaniesListPreview() {
    const config = useConfig();
    const { data: companies, isLoading } = useSSRFetch(SSRFetchConfig.companiesListPreview.key, {
        limit: config.companies.listPreviewCount,
    }, []);
    const { data: companiesCount } = useAppQuery(Queries.getCompaniesCount, () => Api.getAllCompaniesCount());
    return (React.createElement(CompaniesListPreviewWrapper, null,
        React.createElement(Holder, null,
            React.createElement("h2", null,
                React.createElement(FormattedMessage, { id: "home.clients.headilne" })),
            React.createElement(CompaniesListGrid, { companies: companies || [], isLoading: isLoading }),
            React.createElement("h3", null,
                React.createElement(FormattedMessage, { id: "companies.clients.subheadline", values: {
                        count: (React.createElement(Link, { to: "/zoznam-zamestnavatelov" },
                            React.createElement("strong", null,
                                roundToClosestValue(companiesCount, 50),
                                "+"))),
                    } })))));
}
export { CompaniesListPreview };
