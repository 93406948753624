import React from 'react';
import IconAlarmBell from '@public/media/icons/streamline-icon-alarm-bell-1.svg';
import _debounce from 'lodash/debounce';
import { SocketEvents } from '@src/types';
import * as Api from '@src/api';
import { useSocket } from '@src/context/socket-context';
import { useNotificationContextValue } from '@src/context/notification-context';
import { FormattedMessage } from 'react-intl';
import { Notifications } from './Notifications';
import { UserNotifications } from './HeaderMenuNotifications.style';
import { MenuLabelWrapper, MenuContent, MenuContentColumn, MenuContentInner, LoadMore } from './HeaderMenu.style';
export function HeaderMenuNotifications({ setActiveCategory, onCategoryLeave, isActive }) {
    const NotificationContext = useNotificationContextValue();
    const notifications = React.useMemo(() => NotificationContext.notifications, [NotificationContext.notifications]);
    const { pageStats, newNotificationsCount, loadMoreNotifications, getNotifications } = NotificationContext;
    const [mounted, setMounted] = React.useState(false);
    const notificationsColumnRef = React.useRef(null);
    const socket = useSocket();
    const [loading, setLoading] = React.useState(false);
    const hasUnreadNotifications = React.useMemo(() => notifications.some(n => !n.seen_at), [notifications]);
    const init = React.useCallback(async () => {
        try {
            setLoading(true);
            getNotifications();
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }
    }, []);
    const watchNotifications = async () => {
        try {
            if (hasUnreadNotifications) {
                await Api.Account.watchAllNotifications();
                await init();
            }
        }
        catch (err) {
            console.error(err);
        }
    };
    React.useEffect(() => {
        setMounted(true);
        socket.on(SocketEvents.NOTIFICATION_RECEIVED, init);
        init();
        return () => {
            socket.off(SocketEvents.NOTIFICATION_RECEIVED, init);
        };
    }, []);
    React.useEffect(() => {
        if (isActive) {
            watchNotifications();
        }
    }, [isActive]);
    const onScroll = e => {
        const { scrollTop, scrollHeight, clientHeight } = notificationsColumnRef.current;
        const bottomLoadingTrasholdInPixels = 20;
        if (scrollTop + clientHeight >= scrollHeight - bottomLoadingTrasholdInPixels) {
            loadMoreNotifications();
        }
        e.preventDefault();
    };
    const debouncedOnScrollLoad = _debounce(onScroll, 50);
    React.useEffect(() => {
        if (!loading && pageStats.page < pageStats.pageCount) {
            ;
            notificationsColumnRef.current.addEventListener('scroll', debouncedOnScrollLoad);
            return () => { var _a; return (_a = notificationsColumnRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', debouncedOnScrollLoad); };
        }
    }, [pageStats, loading]);
    return (React.createElement(React.Fragment, null,
        React.createElement(UserNotifications, { active: hasUnreadNotifications, onMouseEnter: () => setActiveCategory({ category: 'notifications', offset: '0' }), onMouseLeave: onCategoryLeave },
            React.createElement(MenuLabelWrapper, { to: "#", active: isActive, hasContent: Boolean(notifications.length) },
                React.createElement(IconAlarmBell, { viewBox: "0 0 24 24" })),
            React.createElement(MenuContent, { mounted: mounted, visible: isActive, userControls: true, ref: notificationsColumnRef },
                React.createElement(MenuContentColumn, null,
                    React.createElement(MenuContentInner, { width: 350 },
                        React.createElement(Notifications, { notifications: notifications, refetchNotifications: init, isLoading: loading, skeletonCount: newNotificationsCount }),
                        pageStats.pageCount > pageStats.page && !loading && (React.createElement(LoadMore, { onClick: loadMoreNotifications },
                            React.createElement(FormattedMessage, { id: "general.notifications.load_more" })))))))));
}
