import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
const SubText = styled.span `
	color: ${props => props.theme.color.gray7};
`;
export function AssetLibraryAssetCountInfo({ activeAssetCount, displayedAssetCount }) {
    return (React.createElement("div", null, activeAssetCount !== 0 && (React.createElement(React.Fragment, null,
        activeAssetCount === displayedAssetCount && (React.createElement(React.Fragment, null,
            React.createElement(SubText, null,
                React.createElement(FormattedMessage, { id: "assets.count_info.total.title" })),
            React.createElement("br", null),
            React.createElement("span", { className: "font-bold" },
                React.createElement(FormattedMessage, { values: { activeAssetCount }, id: "assets.count_info.total.count" })))),
        activeAssetCount !== displayedAssetCount && (React.createElement(React.Fragment, null,
            React.createElement(SubText, null,
                React.createElement(FormattedMessage, { values: { displayedAssetCount }, id: "assets.count_info.displayed.title" })),
            React.createElement("br", null),
            React.createElement("span", { className: "font-bold" },
                React.createElement(FormattedMessage, { values: { activeAssetCount, displayedAssetCount }, id: "assets.count_info.displayed.count" }))))))));
}
