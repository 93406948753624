import React from 'react';
import { useIntl } from 'react-intl';
import { parseISO, format } from 'date-fns';
import { Table } from '@src/components/elements';
import { PaymentInfoKeys } from '@src/types';
export function PaymentInformationTable({ paymentInfo }) {
    const intl = useIntl();
    const columns = [
        {
            key: 'description',
            dataIndex: 'description',
        },
        {
            key: 'value',
            dataIndex: 'value',
        },
    ];
    const data = Object.keys(paymentInfo).map(key => {
        let value = paymentInfo[key];
        if (key === PaymentInfoKeys.payment_due) {
            value = format(parseISO(paymentInfo[PaymentInfoKeys.payment_due].toString()), 'd.MM.yyyy');
        }
        if (key === PaymentInfoKeys.total_sum) {
            value = `${paymentInfo[key]} €`;
        }
        return {
            description: intl.formatMessage({ id: `admin.payments.${key}` }),
            value,
        };
    });
    return React.createElement(Table, { bordered: true, showHeader: false, columns: columns, dataSource: data, pagination: false, rowKey: "value" });
}
