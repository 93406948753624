import React from 'react';
import { Form, Row, Col } from 'antd';
import { Input } from '@src/components/elements';
import { CandidateRegistrationFormField } from '@src/types';
export function PersonalInformation({ rules, intl }) {
    const { firstNameRules, lastNameRules, emailRules, telephoneRules } = rules;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.first_name, rules: firstNameRules, label: intl.formatMessage({
                        id: 'registration_student.registration_student_form.first_name',
                    }) },
                    React.createElement(Input, { autoComplete: "off", placeholder: intl.formatMessage({
                            id: 'registration_student.registration_student_form.first_name.placeholder',
                        }) }))),
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.last_name, rules: lastNameRules, label: intl.formatMessage({
                        id: 'registration_student.registration_student_form.last_name',
                    }) },
                    React.createElement(Input, { autoComplete: "off", placeholder: intl.formatMessage({
                            id: 'registration_student.registration_student_form.last_name.placeholder',
                        }) })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.email, rules: emailRules, label: intl.formatMessage({
                        id: 'registration_student.registration_student_form.email',
                    }), validateTrigger: ['onBlur'] },
                    React.createElement(Input, { autoComplete: "off", placeholder: intl.formatMessage({
                            id: 'registration_student.registration_student_form.email.placeholder',
                        }), type: "email" }))),
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.telephone, rules: telephoneRules, label: intl.formatMessage({
                        id: 'registration_student.registration_student_form.phone_number',
                    }), validateTrigger: ['onBlur'] },
                    React.createElement(Input, null))))));
}
