import React from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../Modal';
function getBase64(img) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(img);
    });
}
const StyledImg = styled.img `
	width: 100%;
`;
function ImageUpload({ name, cropSize, accept, aspect, beforeUpload, wholeFileList, setFileList, modalTitle, minZoom = 0.1, }) {
    const [preview, setPreview] = React.useState({
        image: '',
        visible: false,
        title: '',
    });
    const handlePreview = async (file) => {
        var _a;
        let filePreview = '';
        if (file.thumbUrl) {
            filePreview = file.thumbUrl;
        }
        else if (!file.url && !file.preview && !filePreview) {
            filePreview = await getBase64(file.originFileObj);
        }
        setPreview({
            image: file.url || filePreview,
            visible: true,
            title: file.name || ((_a = file.url) === null || _a === void 0 ? void 0 : _a.substring(file.url.lastIndexOf('/') + 1)) || 'Image',
        });
    };
    const handleCancelPreview = () => {
        setPreview(Object.assign(Object.assign({}, preview), { visible: false }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ImgCrop, { aspect: aspect, modalTitle: modalTitle, minZoom: minZoom, grid: true, quality: 0.85, cropperProps: { cropSize, restrictPosition: false } },
            React.createElement(Upload, { beforeUpload: beforeUpload, action: file => getBase64(file), maxCount: 1, listType: "picture-card", onChange: ({ fileList }) => {
                    setFileList(fileList);
                }, onPreview: handlePreview, accept: accept, name: name, fileList: wholeFileList },
                React.createElement("div", null,
                    React.createElement(PlusOutlined, null),
                    React.createElement("div", { className: "m-t-s" },
                        React.createElement(FormattedMessage, { id: "registration.registration_form.upload", defaultMessage: "Upload" }))))),
        React.createElement(Modal, { visible: preview.visible, title: preview.title, footer: null, onCancel: handleCancelPreview },
            React.createElement(StyledImg, { alt: "preview", src: preview.image }))));
}
export { ImageUpload };
