import { darken, transparentize } from 'polished';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
export const IconWrapper = styled.div `
	display: inline-flex;
	align-items: center;
`;
export const DropdownAbsoluteWrapper = styled.div `
	z-index: 4;
	cursor: pointer;
	position: absolute;
	top: 1rem;
	left: 1rem;
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	transition: 0.15s;
	justify-content: center;
	background: ${({ theme }) => transparentize(0.4, theme.color.gray9)};
	:hover {
		background: ${({ theme }) => darken(0.4, theme.color.white)};
	}
`;
export const StopPropagationWrapper = styled.div `
	width: 2rem;
	height: 2rem;
	padding: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 100%;
		height: 100%;
		color: ${({ theme }) => theme.color.white};
	}
`;
export const StyledEllipsisOutlined = styled(EllipsisOutlined) `
	width: 100%;
	height: 100%;
`;
