/* eslint-disable react/no-danger */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useIntl } from 'react-intl';
const withoutShortcodeWrapper = (content, tagName) => {
    let result = content;
    result = content.replace(`[${tagName}]`, '');
    result = result.replace(`[/${tagName}]`, '');
    return result;
};
const shortcodeMatcher = (tagName) => new RegExp(`\\[${tagName}\\](.+?)\\[/${tagName}\\]`, 'ig');
const withoutBreaks = (content) => {
    let result = content;
    result = result.replace(new RegExp('<br>', 'ig'), '');
    result = result.replace(new RegExp('<br/>', 'ig'), '');
    result = result.replace(new RegExp('<br />', 'ig'), '');
    return result;
};
const replacedWithDiv = (content, tagName, className, prepend = '') => {
    let result = content;
    result = withoutShortcodeWrapper(result, tagName);
    return `<div class="magazine-${className}">${prepend}${result}</div>`;
};
const replacedWithLink = (content, tagName, link) => {
    let result = content;
    result = withoutShortcodeWrapper(result, tagName);
    return `<a href="${link}" class="magazine-${tagName}">${result}</a>`;
};
const addImageSource = (input) => {
    const result = input.replace(/\/storage\/uploads\/original\//g, `${process.env.CMS_URL}/storage/uploads/body/`);
    return result;
};
const addImageCaptions = (input) => {
    const replacer = match => {
        const hasDescription = match.includes('alt=');
        if (hasDescription) {
            const altTextMatch = match.match(/alt="(.[^"]+)"/g);
            const altText = altTextMatch[0].slice(5, -1);
            return `${match}<div class="imageCaption"><small>${altText}</small></div>`;
        }
        return match;
    };
    const result = input.replace(/(<\s*\/?\s*)img(\s*([^>]*)?\s*>)/gi, replacer);
    return result;
};
const addRetinaImages = (input) => {
    const replacer = match => {
        const srcOriginal = match.match(/src="(.[^"]+)"/g)[0].slice(5, -1);
        const srcRetina = srcOriginal.replace('/body/', '/body_2x/');
        const result = match.replace('/>', `srcset="${srcOriginal} 1x, ${srcRetina} 2x" />`);
        return result;
    };
    const result = input.replace(/(<\s*\/?\s*)img(\s*([^>]*)?\s*>)/gi, replacer);
    return result;
};
const processOfferShortcodes = (input) => {
    let result = input;
    const matches = result.match(shortcodeMatcher('offer'));
    if (matches) {
        result = result.replace(shortcodeMatcher('offer'), content => {
            let instanceResult = content;
            instanceResult = instanceResult.replace(shortcodeMatcher('offer'), instanceContent => replacedWithDiv(instanceContent, 'offer', 'widget'));
            instanceResult = withoutBreaks(instanceResult);
            const link = withoutShortcodeWrapper(instanceResult.match(shortcodeMatcher('link'))[0], 'link');
            instanceResult = instanceResult.replace(shortcodeMatcher('link'), '');
            instanceResult = instanceResult.replace(shortcodeMatcher('title'), instanceContent => replacedWithLink(instanceContent, 'title', link));
            instanceResult = instanceResult.replace(shortcodeMatcher('info'), instanceContent => replacedWithDiv(instanceContent, 'info', 'info'));
            return instanceResult;
        });
    }
    return result;
};
const processArticlesShortcodes = (input) => {
    const intl = useIntl();
    let result = input;
    const matches = result.match(shortcodeMatcher('articles'));
    if (matches) {
        result = result.replace(shortcodeMatcher('articles'), content => {
            let instanceResult = content;
            instanceResult = instanceResult.replace(shortcodeMatcher('articles'), instanceContent => replacedWithDiv(instanceContent, 'articles', 'widget', `<div class="magazine-widget-headline">${intl.formatMessage({
                id: 'magazine.other_articles_headline',
            })}</div>`));
            instanceResult = withoutBreaks(instanceResult);
            instanceResult = instanceResult.replace(shortcodeMatcher('article'), innerInstanceContent => {
                let innerInstanceResult = innerInstanceContent;
                innerInstanceResult = innerInstanceResult.replace(shortcodeMatcher('article'), item => replacedWithDiv(item, 'article', 'article'));
                const link = withoutShortcodeWrapper(innerInstanceResult.match(shortcodeMatcher('link'))[0], 'link');
                innerInstanceResult = innerInstanceResult.replace(shortcodeMatcher('link'), '');
                innerInstanceResult = innerInstanceResult.replace(shortcodeMatcher('title'), item => replacedWithLink(item, 'title', link));
                return innerInstanceResult;
            });
            return instanceResult;
        });
    }
    return result;
};
const RenderHtml = ({ html, cmsImagePrefix = true, extended = false }) => {
    let result;
    if (extended) {
        result = sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'br', 'iframe', 'blockquote']),
            allowedAttributes: {
                a: ['href', 'name', 'target'],
                img: ['src', 'alt'],
                iframe: ['src'],
            },
        });
    }
    else {
        result = sanitizeHtml(html);
    }
    if (cmsImagePrefix) {
        result = addImageSource(result);
    }
    result = addImageCaptions(result);
    result = addRetinaImages(result);
    result = processOfferShortcodes(result);
    result = processArticlesShortcodes(result);
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: result } });
};
export default RenderHtml;
