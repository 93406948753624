import React from 'react';
import { IntlProvider } from 'react-intl';
// ant design stuff
import { ConfigProvider, notification } from 'antd';
import dayjs from 'dayjs';
import { QueryClient, QueryClientProvider } from 'react-query';
import skSK from 'antd/es/locale/sk_SK';
import enUS from 'antd/es/locale/en_US';
import ukUA from 'antd/es/locale/uk_UA';
import weekday from 'dayjs/plugin/weekday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import { registerLocale } from 'i18n-iso-countries';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { client as axios, client as axiosInstance } from '@src/api/clients/axios';
import { useAppContextProvider } from './context/app-context';
import messages_sk from './translations/sk.json';
import messages_en from './translations/en.json';
import messages_uk from './translations/uk.json';
import { useSSRDataContextProvider } from './hooks/useSSRFetch';
import { LANGUAGE_HEADER_NAME } from './types';
import { ErrorBoundary } from './components/modules/ErrorBoundary/ErrorBoundary';
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/sk.json'));
registerLocale(require('i18n-iso-countries/langs/uk.json'));
registerLocale(require('i18n-iso-countries/langs/ru.json'));
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
});
Sentry.init({
    dsn: 'https://8724211dfc814a20903f47cfa75a6234@o437627.ingest.sentry.io/5441158',
    environment: process.env.ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});
notification.config({
    placement: 'bottomRight',
});
const messagesMap = {
    sk: messages_sk,
    en: messages_en,
    uk: messages_uk,
};
const antdLocaleMap = {
    sk: skSK,
    en: enUS,
    uk: ukUA,
};
const App = ({ children }) => {
    const { value: AppContextValue, AppContextProvider } = useAppContextProvider();
    const { value: SSRDataContextValue, SSRDataContextProvider } = useSSRDataContextProvider();
    React.useEffect(() => {
        // not every request from frontend app is using shared axios instance
        // for this reason it is required to add lang header to both instance and global axios object
        axios.defaults.headers.common[LANGUAGE_HEADER_NAME] = AppContextValue.language;
        axiosInstance.defaults.headers.common[LANGUAGE_HEADER_NAME] = AppContextValue.language;
    }, [AppContextValue.language]);
    return (React.createElement(Sentry.ErrorBoundary, null,
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(SSRDataContextProvider, { value: SSRDataContextValue },
                React.createElement(AppContextProvider, { value: AppContextValue },
                    React.createElement(ConfigProvider, { locale: antdLocaleMap[AppContextValue.language] },
                        React.createElement(IntlProvider, { locale: AppContextValue.language, messages: messagesMap[AppContextValue.language] },
                            React.createElement(ErrorBoundary, null, children))))))));
};
export default App;
