import { client as axios } from './clients/axios';
export async function getCompanyAssetGalleries(companyId) {
    const response = await axios.get('/0/company-asset-gallery', { params: companyId });
    return response.data;
}
export async function createCompanyAssetGallery({ galleryTitle, assetIds, isPrimaryGallery, }) {
    const response = await axios.post('/0/company-asset-gallery', { galleryTitle, assetIds, isPrimaryGallery });
    return response.data;
}
export async function updateCompanyAssetGallery({ galleryId, assetIds, galleryTitle, }) {
    const response = await axios.put(`/0/company-asset-gallery/${galleryId}`, {
        assetIds,
        galleryTitle,
    });
    return response.data;
}
export async function deleteCompanyAssetGallery({ galleryId }) {
    const response = await axios.delete(`/0/company-asset-gallery/${galleryId}`);
    return response.data;
}
