var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import useScrollPosition from '@react-hook/window-scroll';
export const StickyButtonWrapper = styled.button `
	padding: 0;
	margin: 0;
	border: 0;
	display: flex;
	position: fixed;
	z-index: ${props => props.theme.zIndex.stickyButton};
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 0 0px 0 3px ${props => transparentize(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	opacity: 0;
	transform: scale(0);
	transition: all 0.2s ease;

	background: ${props => props.theme.color[props.type]};
	color: ${props => (props.type === 'white' ? props.theme.color.brand3 : props.theme.color.white)};

	${props => {
    switch (props.shape) {
        case 'round':
            return css `
					width: auto;
					height: 2rem;
					border-radius: 2rem;
					padding: 0.5rem 1rem;
				`;
        case 'circle':
            return css `
					width: 3rem;
					height: 3rem;
					border-radius: 50%;
					padding: 0;
				`;
        case 'square':
            return css `
					width: auto;
					height: 2rem;
					border-radius: 0;
					padding: 0.5rem 1rem;
				`;
        default:
            return css `
					width: auto;
					height: 2rem;
					border-radius: 2rem;
					padding: 0.5rem 1rem;
				`;
    }
}}

	${props => props.isActive &&
    css `
			opacity: 1;
			transform: scale(1);
		`};

	${props => {
    switch (props.placement) {
        case 'top-left':
            return css `
					top: 0.75rem;
					left: 0.75rem;
				`;
        case 'top-right':
            return css `
					top: 0.75rem;
					right: 0.75rem;
				`;
        case 'bottom-left':
            return css `
					bottom: 0.75rem;
					left: 0.75rem;
				`;
        case 'bottom-right':
            return css `
					bottom: 0.75rem;
					right: 0.75rem;
				`;
        default:
            return css `
					bottom: 0.75rem;
					right: 0.75rem;
				`;
    }
}}
`;
const StickyButton = (_a) => {
    var { children, type = 'white', isActive } = _a, otherProps = __rest(_a, ["children", "type", "isActive"]);
    const theme = useTheme();
    const topOffset = useScrollPosition();
    return (React.createElement(StickyButtonWrapper, Object.assign({ isActive: isActive !== undefined ? isActive : topOffset > theme.size.headerHeight * 2, 
        // @ts-ignore
        type: type }, otherProps), children));
};
export { StickyButton };
