import { SkillTagSkillLevel } from '@src/types';
import { Progress } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { TagsWrapper, TagWrapper } from './OfferDetails.style';
const StyledTable = styled.table `
	width: 100%;
	max-width: 300px;
	padding: 0 1rem;
`;
const SkillLevelWrapper = styled.div `
	width: 100%;
`;
const SkillLevelText = styled.div `
	color: ${({ theme }) => theme.color.brand1};
	font-weight: bold;
	position: relative;
	width: fit-content;
	left: ${({ widthMultiplier }) => (widthMultiplier !== 1 ? 20 * widthMultiplier : 0)}%;
	transform: translateX(
		-${({ widthMultiplier }) => {
    if (widthMultiplier === 1) {
        return 0;
    }
    if (widthMultiplier === 5) {
        return 100;
    }
    return 50;
}}%
	);
`;
function mapRatesOnTagNames(skillTags, skillTagsRates) {
    const tagsWithRates = skillTags.map(tag => {
        const matchedRate = skillTagsRates.find(tagRate => tagRate.id === tag.id);
        return {
            id: tag.id,
            name: tag.name,
            value: matchedRate.value,
        };
    });
    return tagsWithRates;
}
export const OfferDetailsSkillTag = ({ skillTags, skillTagsRates, areSkillsRated }) => {
    const theme = useTheme();
    const areTagsRated = areSkillsRated && skillTagsRates && skillTagsRates.every(skillTagRate => skillTagRate.value);
    const tags = skillTagsRates && skillTagsRates.length ? mapRatesOnTagNames(skillTags, skillTagsRates) : skillTags;
    return (React.createElement(React.Fragment, null,
        areTagsRated && (React.createElement(StyledTable, null,
            React.createElement("tbody", null, tags.map(tag => (React.createElement("tr", { key: tag.id, className: "m-b-m" },
                React.createElement("td", null,
                    tag.name,
                    React.createElement(Progress, { showInfo: false, type: "line", percent: (100 / 5) * tag.value, strokeColor: theme.color.brand1 }),
                    React.createElement(SkillLevelWrapper, { widthMultiplier: tag.value },
                        React.createElement(SkillLevelText, { widthMultiplier: tag.value },
                            React.createElement(FormattedMessage, { id: `profile.resume.skill.skill_level_${Object.values(SkillTagSkillLevel)[tag.value - 1]}` })))))))))),
        !areTagsRated && (React.createElement("div", null,
            React.createElement(TagsWrapper, null, tags.map(tag => (React.createElement(TagWrapper, { key: tag.id }, tag.name))))))));
};
