import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button } from '@src/components/elements';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
export const ButtonWrapper = styled(Button) `
	&& {
		width: 100%;
		height: 100%;
		border-radius: 0;
		border: none;
		display: flex;
		justify-content: center;
	}
`;
export const SelectWrapper = styled.div `
	position: relative;
	.ant-select-selector {
		padding: 0.25rem 0.25rem 0.25rem 1.75rem;
		border-radius: 0 !important;
	}
	.ant-select-selection-placeholder {
		padding-left: 1.5rem;
	}
	.ant-tag {
		display: none;
	}
	.ant-select-selection-search {
		margin-inline-start: 7px !important;
	}
`;
export const SearchBarItem = styled.div `
	flex: 1;
	width: 50%;

	${props => props.hasNegativeMargin &&
    css `
			margin-left: -1px;
		`}

	${down('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
export const SearchIcon = styled(SearchOutlined) `
	position: absolute;
	z-index: 2;
	top: 30%;
	left: 8px;

	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
export const Search = styled.div `
	align-self: flex-start;
	margin-left: -1px;
	margin-top: 25px;
	width: max-content;

	${down('md')} {
		display: none;
	}
`;
export const SearchBar = styled.div `
	flex: 1;
	display: flex;

	${down('md')} {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;
export const SearchBarItemTitle = styled.div `
	color: ${({ theme }) => theme.color.brand1};
	font-size: 14px;
	margin: 0 0 4px 4px;
`;
export const AlertMaxTagCount = styled(Alert) `
	border-top: none;
	border-radius: 0 0 4px 4px;
`;
export const ForcedPlaceholder = styled.span `
	font-size: 14px;
	z-index: 1;
	position: absolute;
	top: 50%;
	right: 11px;
	left: 11px;
	transform: translateY(-50%);
	transition: all 0.3s;
`;
