import styled from 'styled-components';
import './PulsingDot.scss';
export const StyledPulsingDotContainer = styled.div `
	position: relative;
	top: -1px;
`;
export const StyledCircle = styled.div `
	border: 2px solid #62bd19;
	-webkit-border-radius: 2rem;
	border-radius: 2rem;
	height: 20px;
	width: 20px;
	-webkit-animation: pulsate 3s ease-out;
	animation: pulsate 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	opacity: 0;
	position: absolute;
	top: -10px;
	left: -10px;
`;
export const StyledDot = styled.div `
	width: 10px;
	height: 10px;
	background-color: #62bd19;
	margin: auto;
	border-radius: 50%;
	position: absolute;
	top: -5px;
	left: -5px;
`;
