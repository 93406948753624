import { Modal } from '@src/components/elements';
import { AssetLibrary } from '@src/components/modules/AssetLibrary';
import React from 'react';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { notification } from 'antd';
import * as Api from '@src/api';
import { useIntl } from 'react-intl';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { useTheme } from 'styled-components';
const AddAndEditPrimaryAssetGalleryModal = ({ isPrimaryAssetGalleryModalOpen, setIsPrimaryAssetGalleryModalOpen, setPrimaryAssetGalleryToEdit, assetIdToPreSelect, gallery, }) => {
    const assetGalleryRef = React.useRef(null);
    const intl = useIntl();
    const theme = useTheme();
    const { invalidateSSRContext } = useSSRFetchClient();
    const { mutateAsync: createCompanyPrimaryAssetGallery } = useAppMutation(Api.CompanyAssetGallery.createCompanyAssetGallery, {
        onSuccess: () => {
            showSuccessNotification();
        },
    });
    const { mutateAsync: updateCompanyPrimaryAssetGallery } = useAppMutation(Api.CompanyAssetGallery.updateCompanyAssetGallery, {
        onSuccess: () => {
            showSuccessNotification();
        },
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'general.error',
                }),
                description: intl.formatMessage({
                    id: 'general.something_went_wrong',
                }),
            });
        },
    });
    function showSuccessNotification() {
        notification.success({
            message: intl.formatMessage({
                id: 'general.warning',
            }),
            description: intl.formatMessage({
                id: 'general.successfuly_saved',
            }),
        });
    }
    const submitModal = async () => {
        var _a;
        const selectedAssets = (_a = assetGalleryRef.current) === null || _a === void 0 ? void 0 : _a.getSelectedAssets();
        if (!selectedAssets) {
            notification.warning({
                message: intl.formatMessage({
                    id: 'admin.company_premium_profile.no_file_selected',
                }),
                description: intl.formatMessage({
                    id: 'admin.company_premium_profile.choose_at_least_one_file_gallery',
                }),
            });
            return;
        }
        if (assetIdToPreSelect && gallery) {
            await updateCompanyPrimaryAssetGallery({ galleryId: gallery.id, assetIds: [selectedAssets[0].id] });
        }
        else {
            await createCompanyPrimaryAssetGallery({ assetIds: [selectedAssets[0].id], isPrimaryGallery: true });
        }
        invalidateSSRContext(SSRFetchConfig.companyProfile.key);
        setPrimaryAssetGalleryToEdit(null);
        setIsPrimaryAssetGalleryModalOpen(false);
    };
    React.useEffect(() => {
        if (assetIdToPreSelect && assetGalleryRef.current) {
            assetGalleryRef.current.setSelectedAssetIds([assetIdToPreSelect]);
        }
    }, [gallery]);
    return (React.createElement(Modal, { visible: isPrimaryAssetGalleryModalOpen || Boolean(gallery), onCancel: () => {
            setPrimaryAssetGalleryToEdit(null);
            setIsPrimaryAssetGalleryModalOpen(false);
        }, width: theme.modalWidth.largeModal, onOk: submitModal, forceRender: true, okText: intl.formatMessage({
            id: 'admin.company_premium_profile.confirm_primary_asset_modal',
        }), cancelText: intl.formatMessage({
            id: 'admin.company_premium_profile.close_primary_asset_modal',
        }), title: intl.formatMessage({
            id: 'admin.company_premium_profile.choose_photo_video',
        }) },
        React.createElement(AssetLibrary, { ref: assetGalleryRef, areDocumentsDisabled: true, isInSelectMode: true, allowSingleAssetSelectionOnly: true })));
};
export { AddAndEditPrimaryAssetGalleryModal };
