import React from 'react';
import { useIntl } from 'react-intl';
import { NotificationType } from '@src/types';
import { Table, Checkbox } from '@src/components/elements';
import { BellOutlined, MailOutlined } from '@ant-design/icons';
import { useAppContextValue } from '@src/context/app-context';
function NotificationTypesTable({ notificationTypesSettings, isLoading, onCheckboxChange }) {
    const intl = useIntl();
    const appContext = useAppContextValue();
    const rows = React.useMemo(() => {
        if (!notificationTypesSettings) {
            return [];
        }
        const typeSettingRows = [];
        if (NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.new_company_offer.type' }), description: intl.formatMessage({ id: 'global.notification.new_company_offer.description' }) }, notificationTypesSettings[NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION]));
        }
        if (NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.new_subscription_offer.type' }), description: intl.formatMessage({ id: 'global.notification.new_subscription_offer.description' }) }, notificationTypesSettings[NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION]));
        }
        if (NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.new_company_subscriber.type' }), description: intl.formatMessage({ id: 'global.notification.new_company_subscriber.description' }) }, notificationTypesSettings[NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION]));
        }
        if (NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.hiring_process_result.title' }), description: intl.formatMessage({ id: 'global.notification.hiring_process_result.description' }) }, notificationTypesSettings[NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION]));
        }
        if (NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.hiring_process_result.title' }), description: intl.formatMessage({ id: 'global.notification.hiring_process_closed.description' }) }, notificationTypesSettings[NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION]));
        }
        if (NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.new_offer_application.type' }), description: intl.formatMessage({ id: 'global.notification.new_offer_application.description' }) }, notificationTypesSettings[NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION]));
        }
        if (NotificationType.CREDITS_EXPIRATION_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.CREDITS_EXPIRATION_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.credits_expiration.type' }), description: intl.formatMessage({ id: 'global.notification.credits_expiration.description' }) }, notificationTypesSettings[NotificationType.CREDITS_EXPIRATION_NOTIFICATION]));
        }
        if (NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.candidate_progress.viewed.title' }), description: intl.formatMessage({ id: 'global.notification.candidate_progress.viewed.description' }) }, notificationTypesSettings[NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION]));
        }
        if (NotificationType.CREDITS_PAID_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.CREDITS_PAID_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.paid_credits.title' }), description: intl.formatMessage({ id: 'global.notification.paid_credits.table_description' }) }, notificationTypesSettings[NotificationType.CREDITS_PAID_NOTIFICATION]));
        }
        if (NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION in notificationTypesSettings) {
            typeSettingRows.push(Object.assign({ typeId: NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION, name: intl.formatMessage({ id: 'global.notification.offer_deadline.title' }), description: intl.formatMessage({ id: 'global.notification.offer_deadline.description' }) }, notificationTypesSettings[NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION]));
        }
        return typeSettingRows;
    }, [notificationTypesSettings, appContext.language]);
    const columns = React.useMemo(() => [
        {
            dataIndex: 'name',
            title: intl.formatMessage({ id: 'global.notification.type' }),
            width: 120,
        },
        {
            dataIndex: 'description',
            title: intl.formatMessage({ id: 'global.notification.description' }),
        },
        {
            dataIndex: 'is_enabled',
            title: (React.createElement("span", null,
                React.createElement("div", null, intl.formatMessage({ id: 'global.notification.web_notifications' })),
                React.createElement("div", null,
                    React.createElement(BellOutlined, null)))),
            align: 'center',
            render: (isEnabled, { typeId }) => (React.createElement(Checkbox, { checked: isEnabled, onChange: (e) => onCheckboxChange(e.target.checked, 'is_enabled', typeId) })),
            width: 120,
        },
        {
            dataIndex: 'should_send_email',
            title: (React.createElement("span", null,
                React.createElement("div", null, intl.formatMessage({ id: 'global.notification.email_notifications' })),
                React.createElement("div", null,
                    React.createElement(MailOutlined, null)))),
            align: 'center',
            render: (shouldSendEmail, { typeId }) => (React.createElement(Checkbox, { checked: shouldSendEmail, onChange: (e) => onCheckboxChange(e.target.checked, 'should_send_email', typeId) })),
            width: 120,
        },
    ], [notificationTypesSettings, appContext.language]);
    return (React.createElement(Table, { bordered: true, pagination: false, scroll: { x: 660 }, dataSource: rows, columns: columns, rowKey: "typeId", className: "m-t-m", loading: isLoading }));
}
export { NotificationTypesTable };
