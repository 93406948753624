import React from 'react';
import loadable from '@loadable/component';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import ReactGA from '@src/helpers/react-ga';
import gtm from '@src/helpers/gtm';
import { OfferAction } from '@src/types';
import * as Analytics from '@src/helpers/analytics-tracking';
import { LayoutMain } from '@src/components/layouts/Main';
import { Home } from '@src/components/pages/home';
// import { ApplicantsCalculator } from '@src/components/pages/microsite/applicants-calculator'
import { ContactUs } from '@src/components/pages/contact';
import Protection from '@src/components/pages/protection';
import TermsAndConditions from '@src/components/pages/terms-and-conditions';
import { Login } from '@src/components/pages/login';
import { Registration } from '@src/components/pages/registration';
import { RegistrationCandidate } from '@src/components/pages/registration-candidate';
import { AuthenticatedRegistrationWarning } from '@src/components/pages/authenticated-registration-warning';
import { ResetPassword } from '@src/components/pages/reset-password';
import ResetPasswordEmail from '@src/components/pages/reset-password-email';
import { OfferNotificationSubscriptionPage } from '@src/components/pages/offer-notification-subscription';
import NotFound from '@src/components/pages/not-found';
import { Candidate } from '@src/components/pages/candidate';
import { Companies } from '@src/components/pages/companies';
import { Recruitment } from '@src/components/pages/recruitment';
import { Offers } from '@src/components/pages/offers';
import Magazine from '@src/components/pages/magazine';
import MagazinePost from '@src/components/pages/magazine/post';
import MagazineHomepage from '@src/components/pages/magazine/homepage';
import { OfferDetailsPage } from '@src/components/pages/offer-detail';
import FeedbackStudent from '@src/components/pages/student-feedback';
import { PublicCompanyProfilePage } from '@src/components/pages/company';
import { theme } from '@src/styles/theme';
import featureSwitch from '@src/helpers/feature-switch';
import { NotificationsSection } from '@src/components/modules/notifications-section';
import { WelcomeFromPraxujPopup } from '@src/components/modules/WelcomeFromPraxujPopup';
import 'antd/dist/antd.less';
import { Redirect } from 'react-router';
import { useNotificationContextProvider } from '@src/context/notification-context';
import { CompaniesList } from '@src/components/pages/companies-list';
import { PremiumCompanyProfilePage } from '@src/components/pages/premium-company-profile';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
const Admin = loadable(() => import('@src/components/pages/admin'));
const AdminOffers = loadable(() => import('@src/components/pages/admin/modules/offers'));
const HiringProcess = loadable(() => import('@src/components/pages/admin/modules/hiring-process'));
const Applicants = loadable(() => import('@src/components/pages/admin/modules/applicants'));
// const AutomatizedMessages = loadable(() => import('@src/components/pages/admin/modules/automatized-messages'))
const AdminOfferCreateOrEdit = loadable(() => import('@src/components/pages/admin/modules/offer-create-or-edit'));
const AdminCredits = loadable(() => import('@src/components/pages/admin/modules/credits'));
const AdminRecruitment = loadable(() => import('@src/components/pages/admin/modules/recruitment'));
const AdminOrderPage = loadable(() => import('@src/components/pages/admin/modules/order/AdminOrderPage'));
const AdminPayments = loadable(() => import('@src/components/pages/admin/modules/payments/Payments'));
const AdminUsers = loadable(() => import('@src/components/pages/admin/modules/users/AdminUsers'));
const CompanyInfoEdit = loadable(() => import('@src/components/pages/admin/modules/company-info-edit'));
const AdminLoginInfoEdit = loadable(() => import('@src/components/pages/admin/modules/login-info-edit'));
const AdminPersonalInfo = loadable(() => import('@src/components/pages/admin/modules/personal-info'));
const BillingProfile = loadable(() => import('@src/components/pages/admin/modules/payments/billing-profile/BillingProfile'));
const AdminApi = loadable(() => import('@src/components/pages/admin/modules/api/Api'));
const PaymentInformation = loadable(() => import('@src/components/pages/admin/modules/payment-information/PaymentInformationPage'));
const Profile = loadable(() => import('@src/components/pages/profile'));
const ProfileApplicationHistory = loadable(() => import('@src/components/pages/profile/modules/ProfileApplicationHistory'));
const ProfileLoginInfoEdit = loadable(() => import('@src/components/pages/profile/modules/settings'));
const OfferNotificationSubscriptionSection = loadable(() => import('@src/components/pages/profile/modules/OfferNotificationSubscriptionSection'));
const ProfileResumes = loadable(() => import('@src/components/pages/profile/modules/Resumes'));
const ApplicantsProfile = loadable(() => import('@src/components/pages/profile/modules/resume/form'));
const ApplicantResumeWebView = loadable(() => import('@src/components/pages/profile/modules/resume/web-view'));
const SavedOffers = loadable(() => import('@src/components/pages/profile/modules/saved-offers/SavedOffers'));
const Previews = loadable(() => import('@src/components/pages/admin/modules/analytics/Previews'));
const Reports = loadable(() => import('@src/components/pages/admin/modules/analytics/Reports'));
const Report = loadable(() => import('@src/components/pages/admin/modules/analytics/Reports/Report'));
const PremiumCompanyProfile = loadable(() => import('@src/components/pages/admin/modules/premium-company-profile'));
const AssetLibraryPage = loadable(() => import('@src/components/pages/admin/modules/asset-library'));
const applicantResumePaths = {
    sk: '/ucet/profil/uprava-profilu',
    en: '/account/profile/edit-profile',
    uk: '/account/profile/edit-profile',
};
const applicantResumeWebViewPaths = {
    sk: '/ucet/profil',
    en: '/account/profile',
    uk: '/account/profile',
};
const userProfileView = {
    sk: '/admin/uchadzaci/pouzivatel/:applicantProfileUuid',
    en: '/admin/applicants/user/:applicantProfileUuid',
    uk: '/admin/applicants/user/:applicantProfileUuid',
};
// const adminAutomatizedMessagesPaths = {
// 	sk: featureSwitch('automatizedMessages') ? '/admin/automaticke-odpovede' : undefined,
// 	en: featureSwitch('automatizedMessages') ? '/account/automatized-messages' : undefined,
// }
const applicantsOverviewPaths = {
    sk: featureSwitch('applicantsOverview') ? '/admin/uchadzaci' : undefined,
    en: featureSwitch('applicantsOverview') ? '/admin/applicants' : undefined,
    uk: featureSwitch('applicantsOverview') ? '/admin/applicants' : undefined,
};
const routes = [
    {
        id: '/',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/',
            en: '/',
            uk: '/',
        },
        sitemap: true,
    },
    {
        id: '/verification',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/verification',
            en: '/verification',
            uk: '/verification',
        },
    },
    {
        id: '/offer_feedback',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/offer_feedback',
            en: '/offer_feedback',
            uk: '/offer_feedback',
        },
    },
    {
        id: '/zoznam-zamestnavatelov',
        Component: CompaniesList,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/zoznam-zamestnavatelov',
            en: '/employers-list',
            uk: '/employers-list',
        },
        sitemap: true,
    },
    {
        id: '/premium-firemny-profil',
        Component: PremiumCompanyProfilePage,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/premium-firemny-profil',
            en: '/premium-company-profile',
            uk: '/premium-company-profile',
        },
        sitemap: true,
    },
    {
        id: '/firmy',
        Component: Companies,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/firmy',
            en: '/companies',
            uk: '/companies',
        },
        sitemap: true,
    },
    {
        id: '/firma',
        Component: PublicCompanyProfilePage,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/firma/:companyUniqueUrl/:content?',
            en: '/company/:companyUniqueUrl/:content?',
            uk: '/company/:companyUniqueUrl/:content?',
        },
    },
    {
        id: '/uchadzac',
        Component: Candidate,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/uchadzac',
            en: '/candidate',
            uk: '/candidate',
        },
        sitemap: true,
    },
    {
        id: '/recruitment',
        Component: Recruitment,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/recruitment',
            en: '/recruitment',
            uk: '/recruitment',
        },
        sitemap: true,
    },
    {
        id: '/kontakt',
        Component: ContactUs,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/kontakt',
            en: '/contact',
            uk: '/contact',
        },
        sitemap: true,
    },
    {
        id: '/ochrana-osobnych-udajov',
        Component: Protection,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ochrana-osobnych-udajov',
            en: '/personal-data-protection',
            uk: '/personal-data-protection',
        },
        sitemap: true,
    },
    {
        id: '/obchodne-podmienky',
        Component: TermsAndConditions,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/obchodne-podmienky',
            en: '/terms-and-conditions',
            uk: '/terms-and-conditions',
        },
        sitemap: true,
    },
    {
        id: '/ponuky',
        Component: Offers,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ponuky/:page?',
            en: '/offers/:page?',
            uk: '/offers/:page?',
        },
    },
    {
        id: '/ponuka',
        Component: OfferDetailsPage,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ponuka/:slug',
            en: '/offer/:slug',
            uk: '/offer/:slug',
        },
    },
    {
        id: '/prihlasenie',
        Component: Login,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/prihlasenie',
            en: '/login',
            uk: '/login',
        },
        sitemap: true,
    },
    {
        id: '/registracia',
        Component: Registration,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/registracia',
            en: '/registration',
            uk: '/registration',
        },
        sitemap: true,
    },
    {
        id: '/registracia-uchadzac',
        Component: RegistrationCandidate,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/registracia-uchadzac',
            en: '/registration-candidate',
            uk: '/registration-candidate',
        },
        sitemap: true,
    },
    {
        id: '/registracia-prihlaseny-pouzivatel',
        Component: AuthenticatedRegistrationWarning,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/registracia-prihlaseny-pouzivatel',
            en: '/authenticated-registration-warning',
            uk: '/authenticated-registration-warning',
        },
    },
    {
        id: '/obnovenie-hesla',
        Component: ResetPassword,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/obnovenie-hesla',
            en: '/reset-password',
            uk: '/reset-password',
        },
    },
    {
        id: '/obnovenie-hesla-email',
        Component: ResetPasswordEmail,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/obnovenie-hesla-email',
            en: '/reset-password-email',
            uk: '/reset-password-email',
        },
    },
    {
        id: '/odber',
        Component: OfferNotificationSubscriptionPage,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/odber',
            en: '/offer-subscription',
            uk: '/offer-subscription',
        },
    },
    {
        id: '/feedback/:feedbackToken',
        Component: FeedbackStudent,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/feedback/:feedbackToken',
            en: '/feedback/:feedbackToken',
            uk: '/feedback/:feedbackToken',
        },
    },
    {
        id: '/ucet',
        Component: Profile,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ucet',
            en: '/account',
            uk: '/account',
        },
        routes: [
            {
                id: '/ucet',
                Component: ProfileApplicationHistory,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet',
                    en: '/account',
                    uk: '/account',
                },
            },
            {
                id: '/ucet/ulozene-ponuky',
                Component: SavedOffers,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet/ulozene-ponuky',
                    en: '/account/saved-offers',
                    uk: '/account/saved-offers',
                },
            },
            {
                id: '/ucet/prihlasovacie-udaje',
                Component: ProfileLoginInfoEdit,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet/prihlasovacie-udaje',
                    en: '/account/login-info',
                    uk: '/account/login-info',
                },
            },
            {
                id: '/ucet/odber',
                Component: OfferNotificationSubscriptionSection,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet/odber',
                    en: '/account/subscription',
                    uk: '/account/subscription',
                },
            },
            {
                id: '/ucet/zivotopisy',
                Component: ProfileResumes,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet/zivotopisy',
                    en: '/account/resumes',
                    uk: '/account/resumes',
                },
            },
            {
                id: '/ucet/profil',
                Component: ApplicantResumeWebView,
                props: {
                    layout: 'main',
                },
                paths: applicantResumeWebViewPaths,
            },
            {
                id: '/ucet/profil/uprava-profilu',
                Component: ApplicantsProfile,
                props: {
                    layout: 'main',
                },
                paths: applicantResumePaths,
            },
            {
                id: '/ucet/notifikacie',
                Component: NotificationsSection,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/ucet/notifikacie',
                    en: '/account/notifications',
                    uk: '/account/notifications',
                },
            },
        ],
    },
    {
        id: '/admin',
        Component: Admin,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/admin',
            en: '/admin',
            uk: '/admin',
        },
        routes: [
            {
                id: '/admin',
                Component: AdminOffers,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin',
                    en: '/admin',
                    uk: '/admin',
                },
            },
            {
                id: '/admin/vyberovy-proces',
                Component: HiringProcess,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/vyberovy-proces/:offerId?',
                    en: '/admin/hiring-process/:offerId?',
                    uk: '/admin/hiring-process/:offerId?',
                },
            },
            {
                id: '/admin/uchadzaci',
                Component: featureSwitch('applicantsOverview') ? Applicants : undefined,
                props: {
                    layout: 'main',
                },
                paths: applicantsOverviewPaths,
            },
            {
                id: '/admin/uchadzaci/pouzivatel',
                Component: ApplicantResumeWebView,
                props: {
                    layout: 'main',
                },
                paths: userProfileView,
            },
            {
                id: '/admin/kredity',
                Component: AdminCredits,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/kredity',
                    en: '/admin/credits',
                    uk: '/admin/credits',
                },
            },
            {
                id: '/admin/recruitment',
                Component: AdminRecruitment,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/recruitment',
                    en: '/admin/recruitment',
                    uk: '/admin/recruitment',
                },
            },
            {
                id: '/admin/objednavka',
                Component: AdminOrderPage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/objednavka',
                    en: '/admin/order',
                    uk: '/admin/order',
                },
            },
            {
                id: '/admin/objednavka/platobne-informacie',
                Component: PaymentInformation,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/objednavka/platobne-informacie/:invoiceId',
                    en: '/admin/order/payment-informations/:invoiceId',
                    uk: '/admin/order/payment-informations/:invoiceId',
                },
            },
            {
                id: '/admin/firemny-profil',
                Component: CompanyInfoEdit,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/firemny-profil',
                    en: '/admin/company-profile',
                    uk: '/admin/company-profile',
                },
            },
            {
                id: '/admin/platby',
                Component: AdminPayments,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/platby',
                    en: '/admin/payments',
                    uk: '/admin/payments',
                },
            },
            {
                id: '/admin/platby/fakturacny-profil',
                Component: BillingProfile,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/platby/fakturacny-profil/:billingProfileId?',
                    en: '/admin/paymets/billing-profile/:billingProfileId?',
                    uk: '/admin/paymets/billing-profile/:billingProfileId?',
                },
            },
            {
                id: '/admin/api',
                Component: AdminApi,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/api',
                    en: '/admin/api',
                    uk: '/admin/api',
                },
            },
            {
                id: '/admin/pouzivatelia',
                Component: AdminUsers,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/pouzivatelia',
                    en: '/admin/users',
                    uk: '/admin/users',
                },
            },
            {
                id: '/admin/osobne-udaje',
                Component: AdminPersonalInfo,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/osobne-udaje',
                    en: '/admin/personal-information',
                    uk: '/admin/personal-information',
                },
            },
            {
                id: '/admin/prihlasovacie-udaje',
                Component: AdminLoginInfoEdit,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/prihlasovacie-udaje',
                    en: '/admin/login-information',
                    uk: '/admin/login-information',
                },
            },
            {
                id: '/admin/pridat-ponuku',
                Component: AdminOfferCreateOrEdit,
                props: {
                    layout: 'main',
                    offerAction: OfferAction.create,
                },
                paths: {
                    sk: '/admin/pridat-ponuku',
                    en: '/admin/add-offer',
                    uk: '/admin/add-offer',
                },
            },
            {
                id: '/admin/uprava-ponuky',
                Component: AdminOfferCreateOrEdit,
                props: {
                    layout: 'main',
                    offerAction: OfferAction.edit,
                },
                paths: {
                    sk: '/admin/uprava-ponuky/:slug',
                    en: '/admin/edit-offer/:slug',
                    uk: '/admin/edit-offer/:slug',
                },
            },
            {
                id: '/admin/notifikacie',
                Component: NotificationsSection,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/notifikacie',
                    en: '/admin/notifications',
                    uk: '/admin/notifications',
                },
            },
            {
                id: '/admin/kniznica',
                Component: AssetLibraryPage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/kniznica',
                    en: '/admin/library',
                    uk: '/admin/library',
                },
            },
            // This is commented to get rid of browser warnings when featureSwitch is off (false)
            // {
            // 	id: '/admin/automaticke-odpovede',
            // 	Component: featureSwitch('automatizedMessages') ? AutomatizedMessages : undefined,
            // 	props: {
            // 		layout: 'main',
            // 	},
            // 	paths: adminAutomatizedMessagesPaths,
            // },
            {
                id: '/admin/prehlady',
                Component: Previews,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/prehlady',
                    en: '/admin/previews',
                    uk: '/admin/previews',
                },
            },
            {
                id: '/admin/reporty',
                Component: Reports,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/reporty',
                    en: '/admin/reports',
                    uk: '/admin/reports',
                },
            },
            {
                id: '/admin/reporty',
                Component: Report,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/reporty/:analyticsType',
                    en: '/admin/reports/:analyticsType',
                    uk: '/admin/reports/:analyticsType',
                },
            },
            {
                id: '/admin/premium-firemny-profil',
                Component: PremiumCompanyProfile,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/admin/premium-firemny-profil',
                    en: '/admin/premium-company-profile',
                    uk: '/admin/premium-company-profile',
                },
            },
        ],
    },
    {
        id: '/magazin',
        Component: Magazine,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/magazin',
            en: '/magazine',
            uk: '/magazine',
        },
        routes: [
            {
                id: '/magazin',
                Component: MagazineHomepage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin',
                    en: '/magazine',
                    uk: '/magazine',
                },
            },
            {
                id: '/magazin/archiv/:page',
                Component: MagazineHomepage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin/archiv/:page',
                    en: '/magazin/archive/:page',
                    uk: '/magazin/archive/:page',
                },
            },
            {
                id: '/magazin/:slug',
                Component: MagazinePost,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin/:slug',
                    en: '/magazine/:slug',
                    uk: '/magazine/:slug',
                },
            },
        ],
        sitemap: true,
    },
];
function getRoutePaths(routeObject) {
    const paths = Object.entries(routeObject.paths).map(([locale, path]) => locale !== 'sk' ? `/${locale}${path}` : path);
    const Component = routeObject.Component;
    return (React.createElement(Route, { key: paths[0], path: paths, exact: !routeObject.routes }, routeObject.routes ? (React.createElement(Component, Object.assign({}, routeObject.props), routeObject.routes.map(innerRoute => getRoutePaths(innerRoute)))) : (React.createElement(Component, Object.assign({}, routeObject.props)))));
}
function Routes() {
    const location = useLocation();
    const history = useHistory();
    const topLevelMainLayoutRoutes = routes.filter(({ props }) => props.layout === 'main');
    const micrositeLayoutRoutes = routes.filter(({ props }) => props.layout === 'microsite');
    const { value: NotificationValue, NotificationContextProvider } = useNotificationContextProvider();
    const SSRFetchClient = useSSRFetchClient();
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            // init google analytics
            ReactGA.initialize(process.env.GOOGLE_ANALYTICS);
        }
        gtm.initialize();
    }, []);
    React.useEffect(() => {
        window.scrollTo(0, 0);
        // Google analytics
        ReactGA.pageview(location.pathname + location.search);
        // inhouse tracking
        Analytics.trackPageView();
        Analytics.trackPromotedOfferView();
    }, [location.pathname]);
    React.useEffect(() => {
        const sessionId = history.getQuery().sid;
        if (sessionId) {
            Cookies.set('connect.sid', sessionId);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            history.replace(window.location.pathname);
        }
    }, []);
    return (React.createElement(Switch, null,
        micrositeLayoutRoutes.map(routeObject => getRoutePaths(routeObject)),
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(NotificationContextProvider, { value: NotificationValue },
                React.createElement(WelcomeFromPraxujPopup, null),
                React.createElement(Route, null,
                    React.createElement(LayoutMain, null,
                        React.createElement(Switch, null,
                            topLevelMainLayoutRoutes.map(routeObject => getRoutePaths(routeObject)),
                            React.createElement(Redirect, { from: "/profil", to: "/ucet" }),
                            React.createElement(Route, { path: "*" },
                                React.createElement(NotFound, null)))))))));
}
export { Routes as default, routes };
