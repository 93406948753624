import React from 'react';
import { AssetType } from '@src/types/asset';
import { Alert, FileDropzone, Panel, Spinner } from '@src/components/elements';
import { useConfig } from '@src/hooks/useConfig';
import { useAppMutation } from '@src/hooks/useAppMutation';
import * as Api from '@src/api';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import { useQueryClient } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { AssetsContainer, SpinnerContainer } from './AssetLibrary.style';
import { AssetCard } from './AssetCard';
import { AssetUpload } from './AssetUpload';
import { AssetDetailModal } from './AssetDetailModal';
import { AssetLibraryHeader } from './AssetLibraryHeader';
import { useAssetUpload } from './use-asset-upload';
import { UploadingAssetCard } from './UploadingAssetCard';
import { useAssetLibraryNotifications } from './use-asset-library-notifications';
import { useAssetDownload } from './use-asset-download';
import { getAssetsSorterFunc } from './asset-library.model';
export const AssetLibrary = React.forwardRef(({ isInSelectMode, allowSingleAssetSelectionOnly, areDocumentsDisabled, onAssetsDelete, showSelectedAssetOrder, }, ref) => {
    const config = useConfig();
    const queryClient = useQueryClient();
    const [selectedAssetIds, setSelectedAssetIds] = React.useState([]);
    const [filteredAssetType, setFilteredAssetType] = React.useState('ALL');
    const [selectedAssetIdForDetailView, setSelectedAssetIdForDetailView] = React.useState(null);
    const { showAssetsSuccessfullyDeletedNotification } = useAssetLibraryNotifications();
    const { data: { assets, activeAssetCount } = { assets: [], activeAssetCount: 0 }, isLoading: isFetchingAssets, refetch, } = useAppQuery(Queries.getMyAssets, async () => {
        const queriedAssetType = filteredAssetType === 'ALL' ? null : filteredAssetType;
        const { assets: fetchedAssets, activeAssetCount: fetchedActiveAssetCount } = await Api.Asset.getMyAssets(queriedAssetType);
        return { assets: fetchedAssets, activeAssetCount: fetchedActiveAssetCount };
    });
    const deselectAllAssets = () => {
        setSelectedAssetIds([]);
    };
    React.useEffect(() => {
        refetch();
        deselectAllAssets();
    }, [filteredAssetType]);
    const { mutateAsync: deleteAssets, isLoading: isDeletingAssets } = useAppMutation(Api.Asset.softDeleteAssets, {
        onSuccess: (_, removedAssetIds) => {
            queryClient.setQueryData(Queries.getMyAssets, ({ assets: currentAssets, activeAssetCount: currentActiveAssetCount }) => ({
                assets: currentAssets.filter((asset) => !removedAssetIds.includes(asset.id)),
                activeAssetCount: currentActiveAssetCount - removedAssetIds.length,
            }));
            deselectAllAssets();
            showAssetsSuccessfullyDeletedNotification(removedAssetIds.length);
            if (onAssetsDelete) {
                onAssetsDelete(removedAssetIds);
            }
        },
    });
    const setVideoAssetIsProcessed = (targetMuxAssetId) => {
        if (!targetMuxAssetId) {
            return;
        }
        queryClient.setQueryData(Queries.getMyAssets, ({ assets: currentAssets, activeAssetCount: currentActiveAssetCount }) => ({
            assets: currentAssets.map((asset) => asset.muxAssetId === targetMuxAssetId ? Object.assign(Object.assign({}, asset), { isProcessing: false }) : asset),
            activeAssetCount: currentActiveAssetCount,
        }));
    };
    const deleteAssetLocally = (targetAssetId) => {
        queryClient.setQueryData(Queries.getMyAssets, ({ assets: currentAssets, activeAssetCount: currentActiveAssetCount }) => ({
            assets: currentAssets.filter((asset) => asset.id !== targetAssetId),
            activeAssetCount: currentActiveAssetCount,
        }));
    };
    const isAssetCountLimitReached = activeAssetCount >= config.assets.countLimit;
    const addAssetIdToSelection = (assetId) => {
        const isAssetAlreadySelected = selectedAssetIds.includes(assetId);
        if (!isAssetAlreadySelected) {
            if (allowSingleAssetSelectionOnly) {
                setSelectedAssetIds([assetId]);
            }
            else {
                setSelectedAssetIds(currSelectedAssetIds => [...currSelectedAssetIds, assetId]);
            }
        }
    };
    const onUploadAssetSuccess = (savedAsset) => {
        if (filteredAssetType === 'ALL' || savedAsset.type === filteredAssetType) {
            queryClient.setQueryData(Queries.getMyAssets, ({ assets: currentAssets, activeAssetCount: fetchedActiveAssetCount }) => ({
                assets: [...currentAssets, savedAsset],
                activeAssetCount: fetchedActiveAssetCount + 1,
            }));
            const shouldSelectAssetAfterUpload = Boolean(isInSelectMode && !(savedAsset.type === AssetType.DOCUMENT && areDocumentsDisabled));
            if (shouldSelectAssetAfterUpload) {
                addAssetIdToSelection(savedAsset.id);
            }
        }
    };
    const { enqueueAssetFileForUpload, uploadingAssets } = useAssetUpload({
        activeAssetCount,
        onUploadAssetSuccess,
    });
    const { downloadAndSaveAssets, isDownloadingAssets } = useAssetDownload();
    const enqueueAssetFilesIfNotFetching = (files) => {
        if (!isFetchingAssets) {
            files.forEach(enqueueAssetFileForUpload);
        }
    };
    const toggleAssetSelect = (assetId) => {
        const isAssetAlreadySelected = selectedAssetIds.includes(assetId);
        if (isAssetAlreadySelected) {
            const filteredAssetById = selectedAssetIds.filter(assId => assId !== assetId);
            setSelectedAssetIds(filteredAssetById);
            return;
        }
        const newSelectedAssetIds = allowSingleAssetSelectionOnly ? [assetId] : [...selectedAssetIds, assetId];
        setSelectedAssetIds(newSelectedAssetIds);
    };
    const showAssetDetail = (assetId) => {
        setSelectedAssetIdForDetailView(assetId);
    };
    const closeAssetDetailModel = () => {
        setSelectedAssetIdForDetailView(null);
    };
    const selectedAssets = selectedAssetIds.map(id => assets.find(asset => asset.id === id));
    React.useImperativeHandle(ref, () => ({
        getSelectedAssets: () => selectedAssets,
        setSelectedAssetIds: (assetIds) => setSelectedAssetIds(assetIds.filter(assetId => Boolean(assets.find(ass => ass.id === assetId)))),
    }));
    const isSelectedProcessingVideoAsset = selectedAssetIds.some(assetId => {
        const asset = assets.find(({ id }) => assetId === id);
        return asset && asset.type === AssetType.VIDEO && asset.isProcessing;
    });
    return (React.createElement(FileDropzone, { onDrop: enqueueAssetFilesIfNotFetching },
        React.createElement(Panel, { className: "p-m" },
            React.createElement(AssetLibraryHeader, { displayedAssetCount: assets.length, selectedAssetIdsCount: selectedAssetIds.length, filteredAssetType: filteredAssetType, setFilteredAssetType: setFilteredAssetType, enqueueAssetFileForUpload: enqueueAssetFileForUpload, isUploadDisabled: isAssetCountLimitReached || isFetchingAssets, activeAssetCount: activeAssetCount, deleteSelectedAssets: () => deleteAssets(selectedAssetIds), downloadAndSaveSelectedAssets: () => downloadAndSaveAssets(selectedAssets), isSelectedProcessingVideoAsset: isSelectedProcessingVideoAsset, isDownloadingAssets: isDownloadingAssets, isDeletingAssets: isDeletingAssets, deselectAllAssets: deselectAllAssets }),
            allowSingleAssetSelectionOnly && (React.createElement(Alert, { message: React.createElement(FormattedMessage, { id: "assets.only_one_asset_message" }), showIcon: true, type: "info", className: "m-b-l flex-inline" })),
            React.createElement(AssetsContainer, { shouldBeScrollable: Boolean(isInSelectMode) },
                isFetchingAssets && (React.createElement(SpinnerContainer, null,
                    React.createElement(Spinner, null))),
                !isFetchingAssets && (React.createElement(React.Fragment, null,
                    uploadingAssets
                        .map(asset => (Object.assign(Object.assign({}, asset), { uploadStartedAt: new Date(asset.uploadStartedAt) })))
                        .sort(getAssetsSorterFunc(Boolean(areDocumentsDisabled)))
                        .map(uploadingAsset => (React.createElement(UploadingAssetCard, { key: uploadingAsset.uuid, uploadingAsset: uploadingAsset }))),
                    assets
                        .map(asset => (Object.assign(Object.assign({}, asset), { createdAt: new Date(asset.createdAt) })))
                        .sort(getAssetsSorterFunc(Boolean(areDocumentsDisabled)))
                        .map(asset => (React.createElement(AssetCard, { key: asset.id, asset: asset, selectedOrderIndex: showSelectedAssetOrder
                            ? selectedAssetIds.findIndex(assetId => assetId === asset.id)
                            : undefined, isAtLeastOneAssetCardSelected: selectedAssetIds.length > 0, isSelected: selectedAssetIds.includes(asset.id), forceShowCheckCircle: Boolean(isInSelectMode), isSelectingDisabled: isDeletingAssets ||
                            isDownloadingAssets ||
                            Boolean(areDocumentsDisabled && asset.type === AssetType.DOCUMENT), onAssetSelectToggle: () => toggleAssetSelect(asset.id), showAssetDetail: () => showAssetDetail(asset.id), setVideoAssetIsProcessed: () => setVideoAssetIsProcessed(asset.muxAssetId), deleteAssetLocally: () => deleteAssetLocally(asset.id) }))),
                    !isAssetCountLimitReached && (React.createElement(AssetUpload, { enqueueAssetFileForUpload: enqueueAssetFileForUpload }))))),
            React.createElement(AssetDetailModal, { asset: assets.find(asset => asset.id === selectedAssetIdForDetailView), isVisible: selectedAssetIdForDetailView !== null, isDownloadingAssets: isDownloadingAssets, isDeletingAssets: isDeletingAssets, closeAssetDetailModel: closeAssetDetailModel, deleteAsset: () => deleteAssets(selectedAssetIdForDetailView ? [selectedAssetIdForDetailView] : []), downloadAndSaveAsset: () => downloadAndSaveAssets(assets.filter(asset => asset.id === selectedAssetIdForDetailView)) }))));
});
