export var EOfferApplicationStage;
(function (EOfferApplicationStage) {
    EOfferApplicationStage["new"] = "new";
    EOfferApplicationStage["preselection"] = "preselection";
    EOfferApplicationStage["interview"] = "interview";
    EOfferApplicationStage["hired"] = "hired";
    EOfferApplicationStage["rejected"] = "rejected";
})(EOfferApplicationStage || (EOfferApplicationStage = {}));
export var OfferApplicationStageOrder;
(function (OfferApplicationStageOrder) {
    OfferApplicationStageOrder[OfferApplicationStageOrder["new"] = 1] = "new";
    OfferApplicationStageOrder[OfferApplicationStageOrder["preselection"] = 2] = "preselection";
    OfferApplicationStageOrder[OfferApplicationStageOrder["interview"] = 3] = "interview";
    OfferApplicationStageOrder[OfferApplicationStageOrder["hired"] = 4] = "hired";
    OfferApplicationStageOrder[OfferApplicationStageOrder["rejected"] = 5] = "rejected";
})(OfferApplicationStageOrder || (OfferApplicationStageOrder = {}));
export var EInterviewType;
(function (EInterviewType) {
    EInterviewType["video_call"] = "video_call";
    EInterviewType["personal"] = "personal";
})(EInterviewType || (EInterviewType = {}));
