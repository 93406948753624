import React from 'react';
import { Carousel } from '@src/components/elements';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useKeyboardControlls } from '@src/hooks/use-keyboard-controlls';
import MuxVideo from '@mux-elements/mux-video-react';
import { AssetType } from '@src/types';
import { AssetContainer, AssetImg, CarouselArrowContainer } from './AssetsModalCarousel.style';
function getAssetImgUrl(asset) {
    return getCdnImageUrl({
        file: asset.cloudinaryPublicId,
        shouldIncludeEnvironmentPart: false,
        addWatermarkedLogo: true,
    });
}
function CarouselPrevArrow({ onClick }) {
    return (React.createElement(CarouselArrowContainer, { position: "left", onClick: onClick },
        React.createElement(LeftOutlined, null)));
}
function CarouselNextArrow({ onClick }) {
    return (React.createElement(CarouselArrowContainer, { position: "right", onClick: onClick },
        React.createElement(RightOutlined, null)));
}
function VideoAsset({ asset, pauseTriggerSymbol }) {
    const videoRef = React.useRef(null);
    React.useEffect(() => {
        videoRef.current.pause();
    }, [pauseTriggerSymbol]);
    return (
    // @ts-ignore
    React.createElement(MuxVideo, { ref: videoRef, style: { maxWidth: '95%', maxHeight: '95%' }, playbackId: asset.muxPublicPlaybackId, metadata: {
            video_id: asset.id,
            video_title: asset.filename,
        }, 
        // @ts-ignore
        autoPlay: true, envKey: process.env.MUX_VIDEO_TRACK_KEY, 
        // @ts-ignore
        streamType: "on-demand", disablePictureInPicture: true, controls: true, loop: false }));
}
export function AssetsModalCarousel({ assets, initialAssetId, closeModal }) {
    const carouselRef = React.useRef(null);
    const [currentAssetIndex, setCurrentAssetIndex] = React.useState(null);
    const [triggerSymbol, setTriggerSymbol] = React.useState(Symbol('triggerSymbol'));
    React.useEffect(() => {
        const initialAssetIndex = assets.findIndex(ass => ass.id === initialAssetId);
        if (initialAssetIndex > -1) {
            setCurrentAssetIndex(initialAssetIndex);
        }
    }, [initialAssetId]);
    React.useEffect(() => {
        if (currentAssetIndex !== null) {
            carouselRef.current.goTo(currentAssetIndex);
            pauseAllVideos();
        }
    }, [currentAssetIndex]);
    const pauseAllVideos = () => {
        setTriggerSymbol(Symbol('triggerSymbol'));
    };
    const handleAfterChange = (newAssetIndex) => {
        if (currentAssetIndex !== newAssetIndex) {
            setCurrentAssetIndex(newAssetIndex);
        }
    };
    const handleImageClick = (e) => {
        e.stopPropagation();
    };
    useKeyboardControlls({
        onEscape: closeModal,
        onArrowLeft: () => carouselRef.current.prev(),
        onArrowRight: () => carouselRef.current.next(),
    });
    return (React.createElement(Carousel, { ref: carouselRef, dots: false, arrows: true, afterChange: handleAfterChange, prevArrow: React.createElement(CarouselPrevArrow, null), nextArrow: React.createElement(CarouselNextArrow, null) }, assets.map(asset => (React.createElement(AssetContainer, { key: asset.id },
        asset.type === AssetType.IMAGE && (React.createElement(AssetImg, { src: getAssetImgUrl(asset), onClick: handleImageClick })),
        asset.type === AssetType.VIDEO && React.createElement(VideoAsset, { asset: asset, pauseTriggerSymbol: triggerSymbol }))))));
}
