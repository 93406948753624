import React from 'react';
import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';
import { SelectTag } from '@src/components/elements/SelectTag/SelectTag';
const StyledSelect = styled(AntdSelect) `
	.ant-select-selection-item-remove {
		display: flex;
		align-items: center;
	}
	.ant-select-selection-overflow {
		gap: 4px;
	}
`;
function Select(props) {
    return React.createElement(StyledSelect, Object.assign({}, defaultProps, props));
}
Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
const defaultProps = {
    tagRender: tagProps => React.createElement(SelectTag, Object.assign({}, tagProps)),
};
export { Select };
