import styled from 'styled-components';
import { Modal } from 'antd';
import { down } from 'styled-breakpoints';
export const StyledModal = styled(Modal) `
	top: 0;
	width: 100vw !important;
	height: 100vh;
	max-width: none;
	padding: 0;
	margin: 0;
`;
export const CloseModalButton = styled.div `
	position: absolute;
	right: 1rem;
	top: 1rem;
	background-color: ${props => props.theme.color.gray2};
	width: 3rem;
	height: 3rem;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	cursor: pointer;
	pointer-events: auto;
	z-index: 1001;
	${down('sm')} {
		font-size: 1rem;
		width: 2rem;
		height: 2rem;
	}
`;
