import React from 'react';
import * as Api from 'api';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { SocketEvents } from '@src/types';
import { useSocket } from '@src/context/socket-context';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import { useQueryClient } from 'react-query';
const AdminContext = React.createContext({
    myOffers: {},
    areMyOffersLoading: false,
    companyInfo: {},
    isCompanyInfoLoading: false,
    companyInfoError: {},
    companyPremiumSettings: {},
    isOpenNotEnoughCreditsDialog: false,
    setIsOpenNotEnoughCreditsDialog: () => { },
    isCompanyPremiumActivatedDialogOpen: false,
    setIsCompanyPremiumActivatedDialogOpen: () => { },
});
export const useAdminContextProvider = () => {
    const [isOpenNotEnoughCreditsDialog, setIsOpenNotEnoughCreditsDialog] = React.useState(false);
    const [isCompanyPremiumActivatedDialogOpen, setIsCompanyPremiumActivatedDialogOpen] = React.useState(false);
    const socket = useSocket();
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { data: companyInfo = {}, isLoading: isCompanyInfoLoading, error: companyInfoError, } = useAppQuery(Queries.getCompany, Api.Company.getCompany, {
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        },
    });
    const { data: myOffers = {}, isLoading: areMyOffersLoading } = useAppQuery(Queries.getMyOffers, Api.Admin.getMyOffers, {
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        },
    });
    React.useEffect(() => {
        socket.on(SocketEvents.UPDATE_COMPANY_INFO, () => queryClient.invalidateQueries(Queries.getCompany));
    }, []);
    return {
        AdminContextProvider: AdminContext.Provider,
        value: {
            myOffers,
            areMyOffersLoading,
            companyInfo,
            isCompanyInfoLoading,
            companyInfoError,
            companyPremiumSettings: companyInfo.companyPremiumSettings,
            isOpenNotEnoughCreditsDialog,
            setIsOpenNotEnoughCreditsDialog,
            isCompanyPremiumActivatedDialogOpen,
            setIsCompanyPremiumActivatedDialogOpen,
        },
    };
};
export const useAdminContextValue = () => {
    const context = React.useContext(AdminContext);
    return context;
};
