import React from 'react';
import styled from 'styled-components';
import * as Api from '@src/api';
import { Spinner } from '@src/components/elements';
import { useAssetLibraryNotifications } from '@src/components/modules/AssetLibrary/use-asset-library-notifications';
import { FormattedMessage } from 'react-intl';
import { getMuxVideoThumbnailUrl } from '@src/shared/get-mux-video-thumbnail-url';
const AssetCardVideoThumbnail = styled.img `
	object-fit: contain;
	height: 100%;
	width: 100%;
`;
const ProcessingMessageContainer = styled.div `
	background-color: white;
	border-radius: 8px 8px 0 0;
	gap: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
export function AssetCardVideo({ asset, setVideoAssetIsProcessed, deleteAssetLocally }) {
    const { showGeneralErrorNotification, showVideoProcessingFailedNotification } = useAssetLibraryNotifications();
    const waitForVideoToBeProcessedAndUpdateAsset = async () => {
        try {
            await Api.Asset.waitForVideoToBeProcessed(asset.muxAssetId);
            setVideoAssetIsProcessed();
        }
        catch (err) {
            if (err === null || err === void 0 ? void 0 : err.response.data.error.videoProcessingFailed) {
                deleteAssetLocally();
                showVideoProcessingFailedNotification(asset.filename);
            }
            else {
                showGeneralErrorNotification();
            }
        }
    };
    React.useEffect(() => {
        if (asset.isProcessing) {
            waitForVideoToBeProcessedAndUpdateAsset();
        }
    }, []);
    if (asset.isProcessing) {
        return (React.createElement(ProcessingMessageContainer, null,
            React.createElement(FormattedMessage, { id: "assets.video_is_processing" }),
            React.createElement(Spinner, null)));
    }
    return (React.createElement(AssetCardVideoThumbnail, { src: getMuxVideoThumbnailUrl({ muxPublicPlaybackId: asset.muxPublicPlaybackId, width: 400 }), alt: "video-asset-thumbnail" }));
}
