import { Alert, Select } from 'antd';
import styled from 'styled-components';
export const BillingProfilesSelect = styled(Select) `
	width: 100%;
`;
export const ProductTitle = styled.div `
	font-size: 1.2rem;
	font-weight: bold;
`;
export const ProductDescription = styled.div `
	font-size: 1rem;
`;
export const OrderTitle = styled.div `
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
`;
export const TotalPrice = styled.span `
	margin: 0 0.5rem;
	color: ${props => props.theme.color.success};
`;
export const ProductNote = styled.div `
	color: ${props => props.theme.color.gray6};
	padding: 1em 0 0 0;
	font-size: 0.9em;
	text-align: center;
`;
export const BoxChosen = styled.div `
	padding: 0;
	font-size: 1.5em;
	color: ${props => props.theme.color.brand1};
	font-weight: 700;
`;
export const TotalPriceSmall = styled.small `
	margin-left: 0.5rem;
	padding: 0;
	font-size: 0.7em;
	font-weight: 400;
	color: ${props => props.theme.color.gray6};
`;
export const StyledInfoAlert = styled(Alert) `
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
	color: rgba(0, 0, 0, 0.85);
	.anticon {
		color: #1890ff;
	}
`;
