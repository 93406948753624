import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Icon } from '@src/components/elements';
import styled, { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';
export const MainFilterSearchWrapper = styled.div `
	padding: 0;
	${up('lg')} {
		padding: 0 5rem;
	}
	.ant-select-selector {
		padding: 0.25rem 0.25rem 0.25rem 2rem;
		border-radius: 0 !important;
		min-height: 3rem;
	}
`;
export const SelectWrapper = styled.div `
	position: relative;
	.ant-select-selection-placeholder {
		padding-left: 1.75rem;
		text-align: left;
	}
`;
export const SearchIcon = styled(SearchOutlined) `
	position: absolute;
	color: ${({ theme }) => theme.color.black};
	z-index: 2;
	top: 30%;
	left: 8px;
	margin-right: 0.2rem;
	svg {
		width: 18px;
		height: 18px;
	}
`;
export const LocationPinIcon = styled(Icon) `
	position: absolute;
	color: black;
	z-index: 2;
	top: 30%;
	left: 8px;
	margin-right: 0.2rem;
	svg {
		width: 18px;
	}
`;
export const SearchBarItem = styled.div `
	flex: 1;
	width: 50%;

	${props => props.hasNegativeMargin &&
    css `
			margin-left: -1px;
		`}

	${down('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
export const ButtonWrapper = styled(Button) `
	&& {
		width: 100%;
		height: 3rem;
		border-radius: 0;
		display: flex;
		justify-content: center;
	}
`;
export const Search = styled.div `
	margin-top: 24px;
	margin-left: -1px;
	flex: 0 0 auto;
	align-self: flex-start;

	${down('md')} {
		margin-left: 0;
		width: 100%;
	}
`;
export const SearchBar = styled.div `
	flex: 1;
	display: flex;

	${down('md')} {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;
export const SearchBarItemTitle = styled.div `
	text-align: left;
	font-size: 1rem;
`;
export const AlertMaxTagCount = styled(Alert) `
	border-top: none;
	border-radius: 0 0 4px 4px;
`;
