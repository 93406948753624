import React from 'react';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { SocketEvents } from '@src/types/socket-events';
import { NotificationType, NotificationSenderAccountTypeMap, } from '@src/types';
import { NotificationImageOrIcon } from '@src/components/modules/global/NotificationImageOrIcon';
import { getCdnImageUrlForAccountType } from '@src/helpers/get-cdn-image-url-for-account-type';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { socket } from '../socket';
const SocketContext = React.createContext(socket);
export const SocketProvider = props => {
    const intl = useIntl();
    const SSRFetchClient = useSSRFetchClient();
    function displayCompanySubscriptionNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: React.createElement("strong", null, context.offerTitle),
            description: intl.formatMessage({ id: 'global.notification.new_company_offer.popup' }, { companyName: context.companyName, strong: chunks => React.createElement("strong", null, chunks) }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayOfferSubscriptionNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: React.createElement("strong", null, context.offerTitle),
            description: intl.formatMessage({ id: 'global.notification.new_subscription_offer' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) })),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayNewCompanySubscriberNotification(receivedNotification) {
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.new_company_subscriber.title' }),
            description: intl.formatMessage({ id: 'global.notification.new_company_subscriber.content' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayNewHiringProcessResultNotification(receivedNotification) {
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.hiring_process_result.title' }),
            description: intl.formatMessage({ id: 'global.notification.hiring_process_result.content' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayNewHiringProcessClosedNotification(receivedNotification) {
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.hiring_process_result.title' }),
            description: intl.formatMessage({ id: 'global.notification.hiring_process_closed.content' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayNewOfferApplicationNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: React.createElement("strong", null, context.offerTitle),
            description: intl.formatMessage({ id: 'global.notification.new_offer_application.popup' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayCompanyViewedProfileNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: React.createElement("strong", null, context.companyName),
            description: intl.formatMessage({ id: 'global.notification.candidate_progress.viewed.content' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayNewCreditsExpirationNotification(receivedNotification) {
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.credits_expiration.title' }),
            description: intl.formatMessage({ id: 'global.notification.credits_expiration.content' }),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayCandidateNewInterviewNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.candidate_progress.interview.title' }),
            description: intl.formatMessage({ id: 'global.notification.candidate_progress.interview' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) })),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayCreditsPaidNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        const notificationSenderAccountType = NotificationSenderAccountTypeMap[receivedNotification.notification_type_id];
        const imageUrl = getCdnImageUrlForAccountType(receivedNotification.image, notificationSenderAccountType, 'c_scale,w_30');
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.paid_credits.title' }),
            description: intl.formatMessage({ id: 'global.notification.paid_credits.description' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) })),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: imageUrl })),
            duration: 10,
        });
    }
    function displayCompanyOfferDeadlineNotification(receivedNotification) {
        const context = JSON.parse(receivedNotification.context);
        notification.success({
            message: intl.formatMessage({ id: 'global.notification.offer_deadline.popup' }),
            description: React.createElement("strong", null, context.offerTitles),
            icon: (React.createElement(NotificationImageOrIcon, { notificationType: receivedNotification.notification_type_id, imageUrl: receivedNotification.image })),
            duration: 10,
        });
    }
    function handleNotificationReceived(receivedNotification) {
        switch (receivedNotification.notification_type_id) {
            case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION:
                displayCompanySubscriptionNotification(receivedNotification);
                break;
            case NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION:
                displayOfferSubscriptionNotification(receivedNotification);
                break;
            case NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION:
                displayNewCompanySubscriberNotification(receivedNotification);
                break;
            case NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION:
                displayNewHiringProcessResultNotification(receivedNotification);
                break;
            case NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION:
                displayNewHiringProcessClosedNotification(receivedNotification);
                break;
            case NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION:
                displayNewOfferApplicationNotification(receivedNotification);
                break;
            case NotificationType.CREDITS_EXPIRATION_NOTIFICATION:
                displayNewCreditsExpirationNotification(receivedNotification);
                break;
            case NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION:
                displayCandidateNewInterviewNotification(receivedNotification);
                break;
            case NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION:
                displayCompanyViewedProfileNotification(receivedNotification);
                break;
            case NotificationType.CREDITS_PAID_NOTIFICATION:
                displayCreditsPaidNotification(receivedNotification);
                break;
            case NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION:
                displayCompanyOfferDeadlineNotification(receivedNotification);
                break;
            default:
                throw new Error('Unknown notification type');
        }
    }
    React.useEffect(() => {
        socket.on(SocketEvents.ACCOUNT_AUTHENTICATED, () => {
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
        });
        socket.on(SocketEvents.ACCOUNT_LOG_OUT, () => {
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
        });
        socket.on(SocketEvents.ACCOUNT_VERIFIED, () => {
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
        });
        socket.on(SocketEvents.ACCOUNT_REGISTERED, () => {
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
        });
        socket.on(SocketEvents.NOTIFICATION_RECEIVED, handleNotificationReceived);
    }, []);
    return React.createElement(SocketContext.Provider, { value: socket }, props.children);
};
export const useSocket = () => {
    const context = React.useContext(SocketContext);
    return context;
};
