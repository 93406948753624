import React from 'react';
import { AssetType } from '@src/types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { Carousel } from '@src/components/elements';
import { AssetContainer, AssetImg, CarouselArrowContainer, CarouselDot, CarouselDotsContainer, } from './OfferDetailsTitleGallery.style';
import { TitleGalleryVideoAsset } from './TitleGalleryVideoAsset';
function CarouselPrevArrow({ onClick }) {
    return (React.createElement(CarouselArrowContainer, { position: "left", onClick: onClick },
        React.createElement(LeftOutlined, null)));
}
function CarouselNextArrow({ onClick }) {
    return (React.createElement(CarouselArrowContainer, { position: "right", onClick: onClick },
        React.createElement(RightOutlined, null)));
}
function getAssetImgUrl(asset) {
    return getCdnImageUrl({
        file: asset.cloudinaryPublicId,
        transformation: 'h_420,c_scale',
        shouldIncludeEnvironmentPart: false,
        addWatermarkedLogo: true,
    });
}
export function OfferDetailsTitleGallery({ assets }) {
    const [currentAssetIndex, setCurrentAssetIndex] = React.useState(0);
    const [videoAssetsPlayingStatuses, setVideoAssetsPlayingStatuses] = React.useState(assets.map(asset => ({ assetId: asset.id, isPlaying: false })));
    const carouselRef = React.useRef(null);
    React.useEffect(() => {
        carouselRef.current.goTo(currentAssetIndex);
        const currentAsset = assets[currentAssetIndex];
        setVideoAssetsPlayingStatuses(currentPlayingStatuses => currentPlayingStatuses.map(status => status.assetId === currentAsset.id ? Object.assign(Object.assign({}, status), { isPlaying: true }) : Object.assign(Object.assign({}, status), { isPlaying: false })));
    }, [currentAssetIndex]);
    const handleAfterChange = (newAssetIndex) => {
        if (currentAssetIndex !== newAssetIndex) {
            setCurrentAssetIndex(newAssetIndex);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Carousel, { dots: false, ref: carouselRef, afterChange: handleAfterChange, arrows: true, prevArrow: React.createElement(CarouselPrevArrow, null), nextArrow: React.createElement(CarouselNextArrow, null) }, assets.map(asset => (React.createElement(AssetContainer, { key: asset.id },
            asset.type === AssetType.IMAGE && React.createElement(AssetImg, { src: getAssetImgUrl(asset) }),
            asset.type === AssetType.VIDEO && (React.createElement(TitleGalleryVideoAsset, { asset: asset, isPlaying: videoAssetsPlayingStatuses.find(status => status.assetId === asset.id).isPlaying })))))),
        Boolean(assets.length && assets.length > 1) && (React.createElement(CarouselDotsContainer, null, assets.map((asset, index) => (React.createElement(CarouselDot, { key: asset.id, onClick: () => setCurrentAssetIndex(index), shouldBeHighlighted: index === currentAssetIndex })))))));
}
