import { Separator, Button } from '@src/components/elements';
import styled from 'styled-components';
import { darken } from 'polished';
import AreaIcon from '@public/media/icons/streamlinehq-briefcase-business-products.svg';
import CinIcon from '@public/media/icons/streamlinehq-real-estate-action-building-information-real-estate.svg';
import User from '@public/media/icons/streamlinehq-single-neutral-users.svg';
import { EyeOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { up } from 'styled-breakpoints';
export const AssetImg = styled.img `
	width: 100%;
	height: 250px;
	object-fit: cover;

	${up('sm')} {
		max-height: 550px;
		height: 100%;
	}
`;
export const EmptyPrimaryImageWrapper = styled.div `
	position: relative;
`;
export const DetailIcon = styled(EyeOutlined) `
	width: 3rem;
	height: 3rem;
	padding: 1rem;
	border-radius: 4rem;
	background: rgb(80, 80, 80);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.3s;
	transform: scale(0.5);

	svg {
		height: 100%;
		width: 100%;
	}
`;
export const DetailVideoIcon = styled(PlayCircleOutlined) `
	width: 3rem;
	height: 3rem;
	padding: 0.75rem;
	border-radius: 4rem;
	background: rgb(80, 80, 80);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.3s;
	transform: scale(0.5);

	svg {
		height: 100%;
		width: 100%;
	}
`;
export const ClickAssetWrapper = styled.div `
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
`;
export const PrimaryGalleryOverlay = styled.div `
	top: 0;
	position: absolute;
	color: ${props => props.theme.color.white};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: ${props => props.theme.fontSizes.m};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;
	background: rgba(0, 0, 0, 0);
	transition: 0.3s;

	&:hover {
		background: rgba(0, 0, 0, 0.2);

		${DetailIcon} {
			opacity: 1;
			transform: scale(1);
		}

		${DetailVideoIcon} {
			opacity: 1;
			transform: scale(1);
		}
	}
`;
export const TitleWrapper = styled.div `
	color: ${props => props.theme.color.brand3};
	font-weight: bold;
`;
export const StyledAreaIcon = styled(AreaIcon) `
	width: 1.25rem;
	height: 1.25rem;
	color: ${props => props.theme.color.brand1};
`;
export const StyledCinIcon = styled(CinIcon) `
	width: 1.25rem;
	height: 1.25rem;
	color: ${props => props.theme.color.brand1};
`;
export const StyledUserOutlined = styled(User) `
	width: 1.25rem;
	height: 1.25rem;
	color: ${props => props.theme.color.brand1};
`;
export const EditButton = styled(Button) `
	color: ${props => props.theme.color.brand1};
	border: 1px solid ${({ theme }) => theme.color.brand1};
	justify-content: end;
	:hover {
		background-color: ${props => darken(0.1, props.theme.color.white)};
	}
`;
export const CompanyInfo = styled.div `
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 1rem;
`;
export const CompanyAbout = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;
	margin-bottom: 1rem;
	border: 1px solid ${({ theme }) => theme.color.gray4};

	${up('md')} {
		flex-direction: row;
	}
`;
export const AboutCompanyTitle = styled.div `
	color: ${props => props.theme.color.brand3};
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 1rem;
	text-align: center;
`;
export const FlexPrimaryImage = styled.div `
	width: 100%;
	margin: auto;
	${up('md')} {
		width: unset;
	}
`;
export const FlexAboutCompanyText = styled.div `
	flex: 1 1 0;
`;
export const InfoFieldValue = styled.div ``;
export const FullHeightVerticalSeparator = styled(Separator) `
	height: 100%;
`;
export const CompanyInfoDetail = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	${up('md')} {
		justify-content: unset;
	}
`;
export const AddPrimaryGalleryButton = styled(Button) `
	width: 250px;
	height: 125px;
	margin: 1rem;
	font-size: 1.25rem;
	background: url('/media/images/background-gradient.jpg') 50% 50%;
	border: 1px solid transparent;

	&:hover {
		background: url('/media/images/background-gradient.jpg') 50% 0%;
	}
`;
export const AddPrimaryGalleryWrapper = styled.div `
	border: ${({ theme, hasPrimaryAsset }) => (!hasPrimaryAsset ? `1px solid ${theme.color.gray4}` : 'none')};
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
	flex: 1 1 0;
`;
export const StyledPlusOutlined = styled(PlusOutlined) `
	svg {
		width: 2rem;
		height: 2rem;
	}
`;
export const MainContentColumns = styled.div `
	padding: 1rem 1rem 0 1rem;
	gap: 1rem;
	${up('md')} {
		display: flex;
	}
`;
export const GalleryColumn = styled.div `
	display: ${({ shouldStretchTheCompanyInfo }) => (shouldStretchTheCompanyInfo ? 'none' : '')};
	${up('md')} {
		flex: 3;
	}
`;
export const InfoColumn = styled.div `
	${up('md')} {
		flex: 1;
	}
`;
export const CompanyInfoSectionWrapper = styled.div `
	display: flex;
	margin-bottom: 0.75rem;
	flex-direction: row;
`;
export const MainContent = styled.div `
	max-width: ${({ theme }) => theme.breakpoints.xl};
	margin: auto;
	padding-bottom: 1rem;
`;
export const CircleIconWrapper = styled.div `
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.5rem 0 0;
	border: 1px solid ${({ theme }) => theme.color.brand1};
	width: 2rem;
	height: 2rem;
	min-width: 2rem;
`;
