import React from 'react';
import { SupportedLanguage } from '@src/types';
export const AppContext = React.createContext({
    language: SupportedLanguage.sk,
    setLanguage: () => undefined,
});
// eslint-disable-next-line no-underscore-dangle
const currentLanguage = typeof window !== 'undefined' && window.__LANGUAGE__;
export const useAppContextProvider = () => {
    // todo take default language from url
    const [language, setLanguage] = React.useState(currentLanguage || SupportedLanguage.sk);
    return {
        AppContextProvider: AppContext.Provider,
        value: {
            language,
            setLanguage,
        },
    };
};
export const useAppContextValue = () => {
    const context = React.useContext(AppContext);
    return context;
};
