import styled from 'styled-components';
import { Link } from '@src/components/elements';
export const ImageBlock = styled(Link) `
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	height: 200px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;

	&:hover {
		& > img,
		svg {
			transform: scale(1.1);
			opacity: 0.5;
		}
	}

	& > img {
		transition: 0.3s ease-in-out;
		max-width: 100%;
		max-height: 100%;
	}

	& > svg {
		max-width: 50%;
		max-height: 100%;
		transition: 0.3s ease-in-out;
		color: ${({ theme }) => theme.color.gray5};
	}
`;
export const CompanyCard = styled.div `
	width: 200px;
	background: white;
	border-radius: ${({ theme }) => theme.borderRadius.base};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	transition: 0.3s ease-in-out;
	position: relative;

	&& {
		color: ${({ theme }) => theme.color.black};
	}
`;
export const CompanyCardContent = styled.div `
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
`;
export const CompaniesListGridWrapper = styled.div `
	padding: 2rem;
	display: grid;
	justify-content: center;
	justify-items: stretch;
	align-items: stretch;
	grid-template-columns: repeat(auto-fill, 200px);
	row-gap: 10px;
	column-gap: 10px;
`;
export const CompanyName = styled(Link) `
	margin-bottom: 0.75rem;
	font-size: 0.85rem;
	font-weight: 500;
	cursor: pointer;
	align-self: flex-start;

	&& {
		color: ${({ theme }) => theme.color.black};
	}

	&:hover {
		color: ${({ theme }) => theme.color.gray9};
	}
`;
export const MissingContentViewWrapper = styled.div `
	grid-column: 1 / -1;
`;
