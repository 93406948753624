import { Col, Row } from 'antd';
import React from 'react';
import { DataProcessingOrNewsletterModal } from '@src/components/modules/global/DataProcessingOrNewsletterModal';
import { Button, Link } from '@src/components/elements';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SendProfileResumeCheckbox } from './SendProfileResumeCheckbox';
import { ResumesSelectField } from './ResumesSelectField';
import { UploadResumeField } from './UploadResumeField';
import { UploadMotivationalLetterField } from './UploadMotivationalLetterField';
import { SendMessageField } from './SendMessageField';
import { CompanyAgreementField } from './CompanyAgreementField';
import { PersonalAgreementField } from './PersonalAgreementField';
import { NewsletterAgreementField } from './NewsletterAgreementField';
export function SendResumeFields({ rules, offer, myResumes, isUserLoggedIn, sendProfileAsResume, hasResumesAndWantsToUpload, setSendProfileAsResume, setHasResumesAndWantsToUpload, resumeFileList, motivationalLetterFileList, setMotivationalLetterFileList, setResumeFileList, }) {
    const { addResumeRules, motivationalLetterRules, agreementRules, messageRules } = rules;
    const [isPersonalAgreementByCompanyVisible, setIsPersonalAgreementByCompanyVisible] = React.useState(false);
    const [isPersonalAgreementVisible, setIsPersonalAgreementVisible] = React.useState(false);
    const [isNewsletterAgreementVisible, setIsNewsletterAgreementVisible] = React.useState(false);
    const companyName = React.useMemo(() => offer.account.company.name, [offer.account.company]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 16 } },
                offer.resumeRequired === 1 && (React.createElement("div", null,
                    isUserLoggedIn && (React.createElement(React.Fragment, null,
                        React.createElement(SendProfileResumeCheckbox, { sendProfileAsResume: sendProfileAsResume, setSendProfileAsResume: setSendProfileAsResume }),
                        React.createElement(React.Fragment, null,
                            !sendProfileAsResume && (myResumes === null || myResumes === void 0 ? void 0 : myResumes.length) >= 1 && (React.createElement(ResumesSelectField, { addResumeRules: addResumeRules, setHasResumesAndWantsToUpload: setHasResumesAndWantsToUpload, myResumes: myResumes })),
                            !sendProfileAsResume &&
                                (hasResumesAndWantsToUpload || (myResumes === null || myResumes === void 0 ? void 0 : myResumes.length) === 0) && (React.createElement(UploadResumeField, { addResumeRules: addResumeRules, resumeFileList: resumeFileList, setResumeFileList: setResumeFileList }))))),
                    !isUserLoggedIn && (React.createElement("div", { className: "flex-row align-start justify-space-between" },
                        React.createElement("div", { className: "flex-1" },
                            React.createElement(UploadResumeField, { addResumeRules: addResumeRules, resumeFileList: resumeFileList, setResumeFileList: setResumeFileList })),
                        React.createElement("div", { className: "flex-1" },
                            React.createElement(CreateResumeBlock, null,
                                React.createElement("div", { className: "m-b-s" },
                                    React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.dont_have_resume" })),
                                React.createElement(Link, { to: "/registracia-uchadzac" },
                                    React.createElement(Button, { type: "secondary", size: "middle" },
                                        React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.create_resume" }))))))))),
                offer.motivationalRequired === 1 && (React.createElement(UploadMotivationalLetterField, { motivationalLetterRules: motivationalLetterRules, motivationalLetterFileList: motivationalLetterFileList, setMotivationalLetterFileList: setMotivationalLetterFileList })))),
        React.createElement(SendMessageField, { messageRules: messageRules }),
        React.createElement(CompanyAgreementField, { agreementRules: agreementRules, companyName: companyName, setIsPersonalAgreementByCompanyVisible: setIsPersonalAgreementByCompanyVisible }),
        React.createElement(PersonalAgreementField, { agreementRules: agreementRules, setIsPersonalAgreementVisible: setIsPersonalAgreementVisible }),
        !isUserLoggedIn && (React.createElement(NewsletterAgreementField, { setIsNewsletterAgreementVisible: setIsNewsletterAgreementVisible })),
        React.createElement(DataProcessingOrNewsletterModal, { companyName: companyName, visible: isPersonalAgreementByCompanyVisible, setVisible: setIsPersonalAgreementByCompanyVisible, textId: "offer_detail.send_resume_form.personal_information_agreement" }),
        React.createElement(DataProcessingOrNewsletterModal, { companyName: companyName, visible: isPersonalAgreementVisible, setVisible: setIsPersonalAgreementVisible, textId: "offer_detail.send_resume_form.personal_information_agreement" }),
        React.createElement(DataProcessingOrNewsletterModal, { companyName: companyName, visible: isNewsletterAgreementVisible, setVisible: setIsNewsletterAgreementVisible, textId: "offer_detail.send_resume_form.personal_information_agreement_not_login" })));
}
const CreateResumeBlock = styled.div `
	width: fit-content;
	text-align: center;
`;
