import styled from 'styled-components';
export const AssetDetailContent = styled.div `
	max-width: 1000px;
	aspect-ratio: 16 / 9;
`;
export const AssetDetailModalFooterContainer = styled.div `
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 0.5rem 0;
`;
