import styled, { css } from 'styled-components';
export const HeadlineSubtitle = styled.h3 `
	margin: 1.5rem 0 1rem 0;
	color: ${({ theme }) => theme.color.brand1};
`;
export const Section = styled.div `
	border-top: 1px solid ${({ theme }) => theme.color.gray4};
	padding: 1rem 0;
	margin: 1rem 0;
	${({ isBlank }) => isBlank &&
    css `
			border: 0;
			margin-top: 0;
			padding-top: 0;
		`}
`;
export const MainHeadline = styled.h1 `
  color: ${({ theme }) => theme.color.brand1}
  margin: 0;
`;
export const Headline = styled.h2 `
	margin: 0;
`;
export const TagsWrapper = styled.ul `
	list-style: none;
	margin: 1rem 0 0 0;
	padding: 0;
`;
export const TagWrapper = styled.li `
	display: inline-block;
	margin: 0 0.5rem 0.5rem 0;
	padding: 0.3rem 0.8rem;
	border-radius: ${({ theme }) => theme.borderRadius.base};
	background: ${({ theme }) => theme.color.gray4};
`;
export const TextSection = styled.div `
	margin-top: 0.5rem;
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
	& > * {
		margin: 0;
	}
	& > p:empty {
		min-height: 20px;
	}
	${({ flex }) => flex &&
    css `
			display: flex;
		`}
`;
export const SkillTagsWrapper = styled.div `
	margin-top: 1.5rem;
`;
