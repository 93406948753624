import React from 'react';
import { Slider } from 'antd';
import styled, { css } from 'styled-components';
export const StyledSlider = styled(Slider) `
	&& {
		.ant-slider-dot {
			height: 14px;
			width: 14px;
			margin-left: -8px;
			top: -4px;
			border: 3px solid ${props => props.theme.color.gray6};
			box-shadow: 0px 0px 0px 2px ${props => props.theme.color.white};
			cursor: default !important;
		}
		.ant-slider-dot-active {
			background-color: ${props => props.theme.color.white};
			border: 3px solid ${props => props.theme.color.success} !important;
			box-shadow: 0px 0px 0px 2px ${props => props.theme.color.white};
			cursor: default !important;
		}
		.ant-slider-handle {
			height: 20px;
			width: 20px;
			margin-top: -7px;
			background-color: ${props => props.theme.color.success};
			border: solid 2px ${props => props.theme.color.white} !important;
			cursor: default !important;
		}
		.ant-slider-rail {
			height: 6px;
			background-color: ${props => props.theme.color.gray4};
			cursor: default !important;
		}
		.ant-slider-track {
			height: 6px;
			background-color: ${props => props.theme.color.success} !important;
			cursor: default !important;
		}
		&:hover .ant-slider-track {
			background-color: ${props => props.theme.color.success};
			cursor: default !important;
		}
		.ant-slider-mark {
			top: 29px;
			font-size: 14px;
			cursor: default !important;
		}
		.ant-slider-mark-text {
			color: ${props => props.theme.color.gray6};
			cursor: default !important;
			&:first-child {
				width: 14px;
			}
			&:last-child {
				width: 80px;
				text-align: right;
				left: auto !important;
				right: -46px !important;
			}
		}
		.ant-slider-mark-text-active {
			color: #555555;
			cursor: default !important;
		}
		.ant-slider-disabled {
			cursor: default !important;
		}

		.ant-slider-disabled .ant-slider-dot,
		.ant-slider-disabled .ant-slider-handle {
			background-color: ${props => props.theme.color.success};
			cursor: default !important;
		}

		.ant-slider-step {
			cursor: default !important;
		}
		.ant-slider {
			cursor: default !important;
		}
		.ant-slider-disabled .ant-slider-track {
			background-color: ${props => props.theme.color.success} !important;
			color: white;
		}
	}
	${props => !props.isHired &&
    !props.isProcessActive &&
    css `
			&& {
				.ant-slider-dot {
					border: 3px solid ${props.theme.color.error};
				}
				.ant-slider-dot-active {
					border: 3px solid ${props.theme.color.error} !important;
				}
				.ant-slider-handle {
					background-color: ${props.theme.color.error};
				}
				.ant-slider-rail {
					background-color: ${props.theme.color.error} !important;
				}
				.ant-slider-track {
					background-color: ${props.theme.color.error} !important;
				}
				&:hover .ant-slider-track {
					background-color: ${props.theme.color.error};
				}

				.ant-slider-disabled .ant-slider-dot,
				.ant-slider-disabled .ant-slider-handle {
					background-color: ${props.theme.color.error};
				}
			}
		`}
`;
function SliderProgress({ marks, value = 0, min = 0, isHired, isProcessActive }) {
    return (React.createElement(StyledSlider, { value: value, marks: marks, min: min, max: 30, disabled: true, step: null, tooltipVisible: false, range: false, isHired: isHired, isProcessActive: isProcessActive }));
}
export default SliderProgress;
