import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import _merge from 'lodash/merge';
export const transformCasing = (data, casingFunc) => {
    if (typeof data !== 'object' || !data) {
        return data;
    }
    if (Array.isArray(data)) {
        return data.map(d => transformCasing(d, casingFunc));
    }
    return Object.entries(data).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value) {
            acc[casingFunc(key)] = _merge(transformCasing(value, _snakeCase), transformCasing(value, _camelCase));
        }
        else {
            acc[casingFunc(key)] = value;
        }
        return acc;
    }, {});
};
