import { Form, Select } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
const { Option } = Select;
export function ResumesSelectField({ addResumeRules, setHasResumesAndWantsToUpload, myResumes }) {
    const intl = useIntl();
    return (React.createElement(Form.Item, { name: "savedResumes", label: intl.formatMessage({
            id: 'offer_detail.send_resume_form.resume',
        }), rules: addResumeRules },
        React.createElement(Select, { onChange: value => setHasResumesAndWantsToUpload(value === 0) },
            React.createElement(Option, { id: 0, value: 0, key: 0 },
                React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.send_new_resume" })),
            myResumes.map(resume => (React.createElement(Option, { id: resume.id, value: resume.id, key: resume.id }, resume.resume_name))))));
}
