import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
import { Holder, Title, Panel } from '@src/components/elements';
import { ResetPasswordForm } from './ResetPasswordForm';
export function ResetPassword() {
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, null,
                React.createElement(Title, { level: 1, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "reset_password.password_recovery" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, md: 18, lg: 12 },
                React.createElement(Panel, null,
                    React.createElement(ResetPasswordForm, null))))));
}
