import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Skeleton } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import striptags from 'striptags';
import { Holder, Panel, Head, Alert } from '@src/components/elements';
import getSocialImageUrl from '@src/backend/helpers/get-social-image-url';
import { CompanyProfileBox } from '@src/components/modules/global/CompanyProfileBox';
import { useConfig } from '@src/hooks/useConfig';
import { OfferDetails } from '@src/components/modules/global/OfferDetail/OfferDetails';
import { OfferNotActive } from '@src/components/modules/global/OfferDetail/OfferNotActive';
import { useAccount } from '@src/context/account-context';
import { getLanguageFromPathname } from '@src/shared/localized-path';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from '@src/api';
import { sampleSize } from 'lodash';
import { SSRFetchConfig, useSSRFetch, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { DeletedOffer } from './modules/DeletedOffer';
import { SendResumeForm } from './send-resume-form/SendResumeForm';
import { ReactToOfferButton } from './modules/ReactToOfferButton';
import { SuggestingOffers } from './modules/SuggestingOffers';
const CompanySendResumeBox = () => (React.createElement("div", { id: "send-resume", className: "m-t-m" },
    React.createElement(Alert, { showIcon: true, type: "warning", message: React.createElement(FormattedMessage, { id: "general.warning" }), description: React.createElement(FormattedMessage, { id: "offer_detail.details.company_send_resume_box" }) })));
function OfferDetailsPage() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const params = useParams();
    const location = useLocation();
    const locale = getLanguageFromPathname(location.pathname);
    const config = useConfig();
    const history = useHistory();
    const sendResumeButtonRef = React.useRef(null);
    const SSRFetchClient = useSSRFetchClient();
    const intl = useIntl();
    const account = useAccount();
    const { slug } = params;
    const offerSlug = slug.substring(0, slug.lastIndexOf('-'));
    const offerId = parseInt(slug.substring(slug.lastIndexOf('-') + 1, slug.length), 10);
    const { data: offerDetail, isLoading: isLoadingOfferDetail, error, } = useSSRFetch(SSRFetchConfig.offerDetail.key, { offerSlug, offerId }, [offerSlug, offerId]);
    const { data: suggestedSameCompanyOffers, isLoading: isLoadingSuggestedSameCompanyOffers } = useSSRFetch(SSRFetchConfig.suggestedSameCompanyOffers.key, offerId, [offerId]);
    const { data: suggestedSimilarOffers, isLoading: isLoadingSuggestedSimilarOffers } = useSSRFetch(SSRFetchConfig.suggestedSimilarOffers.key, offerId, [offerId]);
    // Despite offerSlug and offerId being in useSSRFetch dependencies, in some case, data are not being invalidated and refetched.
    // To fix this, we explicitly invalidate SSR context on each slug change
    React.useEffect(() => {
        SSRFetchClient.invalidateSSRContext(SSRFetchConfig.offerDetail.key);
        SSRFetchClient.invalidateSSRContext(SSRFetchConfig.suggestedSameCompanyOffers.key);
        SSRFetchClient.invalidateSSRContext(SSRFetchConfig.suggestedSimilarOffers.key);
    }, [slug]);
    React.useEffect(() => {
        if (error) {
            history.pushLocalized('/error');
        }
    }, [error]);
    const company = ((_a = offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.account) === null || _a === void 0 ? void 0 : _a.company) || null;
    const companyName = (company === null || company === void 0 ? void 0 : company.name) || '';
    const companyImageUrl = getSocialImageUrl({
        offerId,
        companyImage: ((_c = (_b = offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.account) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.image) || '',
        jobAreaId: ((_d = offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.jobAreas[0]) === null || _d === void 0 ? void 0 : _d.id) || '',
        locale,
    });
    const metaOfferDescription = striptags(offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.description);
    const companyActiveOffers = company ? company.offers.filter(o => Boolean(o.isActive)) : null;
    // @ts-ignore
    const isCompanyAccountFromCompany = company ? (account === null || account === void 0 ? void 0 : account.companyId) === company.id : false;
    const isActive = Boolean(offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.isActive);
    const wasOfferDeleted = !(offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.account) && !isActive;
    const isCompanyAccount = (account === null || account === void 0 ? void 0 : account.accountTypeId) === config.accountType.company;
    const isLoading = isLoadingOfferDetail || isLoadingSuggestedSameCompanyOffers || isLoadingSuggestedSimilarOffers;
    const { data: promotedOffers, isLoading: arePromotedOffersLoading } = useAppQuery(Queries.getAllPromotedOffers, () => Api.Offers.getAllPromotedOffers());
    const areDetailsLoading = isLoading || arePromotedOffersLoading;
    const promotedOffersExceptDetailOffer = (promotedOffers || []).filter(o => o.id !== (offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.id));
    const showPromotedOffers = promotedOffersExceptDetailOffer && promotedOffersExceptDetailOffer.length > 0;
    const showSuggestions = suggestedSameCompanyOffers && suggestedSameCompanyOffers.length > 0;
    const showSimilarOffers = suggestedSimilarOffers && suggestedSimilarOffers.length > 0;
    const isInitialized = !areDetailsLoading && Boolean(offerDetail);
    return (React.createElement(React.Fragment, null,
        React.createElement(Head, { title: `${offerDetail === null || offerDetail === void 0 ? void 0 : offerDetail.title} - ${companyName}`, description: metaOfferDescription, imageUrl: companyImageUrl }),
        React.createElement(Holder, { marginVertical: true },
            !isInitialized && (React.createElement(React.Fragment, null,
                React.createElement(Row, { justify: "center", gutter: [16, 16] },
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 16 } },
                        React.createElement(Panel, null,
                            React.createElement(Skeleton, { active: true, paragraph: {
                                    rows: 12,
                                } }))),
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 8 } },
                        React.createElement(Panel, null,
                            React.createElement(Skeleton, { active: true, avatar: {
                                    shape: 'circle',
                                    size: 'large',
                                } })))))),
            isInitialized && (React.createElement(React.Fragment, null,
                wasOfferDeleted && React.createElement(DeletedOffer, { similarOffers: suggestedSimilarOffers }),
                !wasOfferDeleted && (React.createElement(Row, { justify: "center", gutter: [16, 16] },
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 16 } },
                        React.createElement(OfferDetails, { offer: {
                                offerId: offerDetail.id,
                                isActive: offerDetail.isActive === 1,
                                offerTypeId: offerDetail.offerTypeId,
                                durationMin: (_f = (_e = offerDetail.offerContractDuration) === null || _e === void 0 ? void 0 : _e.durationMin) !== null && _f !== void 0 ? _f : null,
                                durationMax: (_h = (_g = offerDetail.offerContractDuration) === null || _g === void 0 ? void 0 : _g.durationMax) !== null && _h !== void 0 ? _h : null,
                                durationType: ((_j = offerDetail.offerContractDuration) === null || _j === void 0 ? void 0 : _j.durationType) || null,
                                isIndefinite: (_l = (_k = offerDetail.offerContractDuration) === null || _k === void 0 ? void 0 : _k.isIndefinite) !== null && _l !== void 0 ? _l : false,
                                isHomeOffice: Boolean(offerDetail.addressId) === false,
                                title: offerDetail.title,
                                description: offerDetail.description,
                                deadline: offerDetail.deadline,
                                contractTypeId: offerDetail.contractTypeId || null,
                                formattedAddress: ((_m = offerDetail.address) === null || _m === void 0 ? void 0 : _m.formattedAddress) || null,
                                jobAreas: offerDetail.jobAreas || null,
                                required: offerDetail.required,
                                niceToHave: offerDetail.niceToHave,
                                benefits: offerDetail.benefits || null,
                                isPaid: offerDetail.isPaid === 1,
                                salaryRate: offerDetail.salaryRate || null,
                                salaryAmountFrom: (_o = offerDetail.salaryAmountFrom) !== null && _o !== void 0 ? _o : null,
                                salaryAmountTo: (_p = offerDetail.salaryAmountTo) !== null && _p !== void 0 ? _p : null,
                                salaryCurrency: offerDetail.salaryCurrency || null,
                                salaryDescription: offerDetail.salaryDescription || null,
                                turnUpDate: offerDetail.turnUpDate,
                                createdAt: offerDetail.createdAt,
                                driverLicences: offerDetail.offerDrivingLicences,
                                languages: offerDetail.offerRequiredLanguages,
                                workingTime: {
                                    timeFrom: (_q = offerDetail.workingTime) === null || _q === void 0 ? void 0 : _q.timeFrom,
                                    timeTo: (_r = offerDetail.workingTime) === null || _r === void 0 ? void 0 : _r.timeTo,
                                    timeGrant: (_s = offerDetail.workingTime) === null || _s === void 0 ? void 0 : _s.timeGrant,
                                    timeType: (_t = offerDetail.workingTime) === null || _t === void 0 ? void 0 : _t.timeType,
                                    additionalInformation: (_u = offerDetail.workingTime) === null || _u === void 0 ? void 0 : _u.additionalInformation,
                                },
                                skillTags: offerDetail.skillTags,
                                skillTagsRates: offerDetail.skillTagsRates,
                                timezone: offerDetail.timezone,
                                galleries: (_v = offerDetail.galleries) !== null && _v !== void 0 ? _v : null,
                                companyId: offerDetail.companyId,
                                salaryType: offerDetail.salaryType || null,
                                redirectUrl: offerDetail.redirectUrl || null,
                            }, showOfferCta: true }),
                        !isActive && (React.createElement(Panel, { className: "m-t-m" },
                            React.createElement(OfferNotActive, null))),
                        isActive && (React.createElement(React.Fragment, null, isCompanyAccount ? (React.createElement(CompanySendResumeBox, null)) : (React.createElement(SendResumeForm, { sendResumeButtonRef: sendResumeButtonRef, offer: offerDetail }))))),
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 8 } },
                        company && (React.createElement(CompanyProfileBox, { className: "m-b-m", company: company, companyOfferCount: companyActiveOffers.length, isCompanyAccountFromCompany: isCompanyAccountFromCompany })),
                        showPromotedOffers && (React.createElement(SuggestingOffers, { offers: promotedOffersExceptDetailOffer.length > 2
                                ? sampleSize(promotedOffersExceptDetailOffer, 2)
                                : promotedOffersExceptDetailOffer, boxTitle: intl.formatMessage({
                                id: 'offer_detail.details.promoted_offers',
                            }), arePromotedOffers: true })),
                        showSuggestions && (React.createElement(SuggestingOffers, { offers: suggestedSameCompanyOffers, boxTitle: intl.formatMessage({
                                id: 'offer_detail.details.more_company_offers',
                            }) })),
                        showSimilarOffers && (React.createElement(SuggestingOffers, { offers: suggestedSimilarOffers, boxTitle: intl.formatMessage({
                                id: 'offer_detail.similar_offers.similar_offers',
                            }) })))))))),
        React.createElement(ReactToOfferButton, { sendResumeButtonRef: sendResumeButtonRef })));
}
export { OfferDetailsPage };
