// @ts-ignore
import { getType as getMimeType } from 'mime';
import { AssetDocumentFileFormat, AssetType } from '@src/types/asset';
function isUploadingAsset(asset) {
    return asset.progressPercentage !== undefined;
}
export function getAssetDocumentFileFormatFromUrl(url) {
    const extension = url.split('.').pop();
    if (!extension) {
        return AssetDocumentFileFormat.OTHER;
    }
    if (extension === 'pdf') {
        return AssetDocumentFileFormat.PDF;
    }
    // https://docs.microsoft.com/en-us/deployoffice/compat/office-file-format-reference
    if (['doc', 'docx', 'docm', 'dotm', 'dotx', 'dot'].includes(extension)) {
        return AssetDocumentFileFormat.WORD;
    }
    // https://support.microsoft.com/en-us/office/file-formats-that-are-supported-in-excel-0943ff2c-6014-4e8d-aaea-b83d51d46247
    if (['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt'].includes(extension)) {
        return AssetDocumentFileFormat.EXCEL;
    }
    return AssetDocumentFileFormat.OTHER;
}
export function getAssetTypeFromFile(file) {
    if (file.type.split('/')[0] === 'image') {
        return AssetType.IMAGE;
    }
    if (file.type.split('/')[0] === 'video') {
        return AssetType.VIDEO;
    }
    return AssetType.DOCUMENT;
}
export function isAssetFileTypeValid(file) {
    // file.type is not always set or correct
    const fileMimeType = getMimeType(file.name);
    const allowedImageMimeTypes = [
        'image/bmp',
        'image/gif',
        'image/ief',
        'image/jpeg',
        'image/svg+xml',
        'image/tiff',
        'image/avif',
        'image/apng',
        'image/png',
        'image/webp',
        'image/heic',
        'image/heif',
    ];
    const allowedVideoMimeTypes = [
        'video/mpeg',
        'video/mp4',
        'video/quicktime',
        'video/x-flv',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/webm',
        'video/x-m4v',
        'video/ogg',
        'video/3gpp',
        'video/3gpp2',
        'video/x-matroska',
        'video/avi',
    ];
    const allowedDocumentMimeTypes = [
        // pdf
        'application/pdf',
        // word
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        // excel
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        // powerpoint
        'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        // other
        'text/csv',
        'application/csv',
        'application/json',
        'text/plain',
        'text/html',
        'text/calendar',
        'text/html',
        'application/vnd.apple.pages',
        'application/vnd.apple.numbers',
        'application/vnd.apple.keynote',
        'application/wordperfect',
        'application/vnd.ms-write',
    ];
    return [...allowedImageMimeTypes, ...allowedVideoMimeTypes, ...allowedDocumentMimeTypes].includes(fileMimeType);
}
export function isAssetFileSizeValid(file, maxFileSizeInMb) {
    const fileSizeInMb = file.size / 1024 / 1024;
    return fileSizeInMb < maxFileSizeInMb;
}
export function getAssetsSorterFunc(moveDocumentsToBottom) {
    return (fst, snd) => {
        if (isUploadingAsset(fst) && isUploadingAsset(snd)) {
            return snd.uploadStartedAt.getTime() - fst.uploadStartedAt.getTime();
        }
        if (!isUploadingAsset(fst) && !isUploadingAsset(snd)) {
            if (!moveDocumentsToBottom || fst.type === snd.type) {
                return snd.createdAt.getTime() - fst.createdAt.getTime();
            }
            if (fst.type === AssetType.DOCUMENT) {
                return 1;
            }
            if (snd.type === AssetType.DOCUMENT) {
                return -1;
            }
            return snd.createdAt.getTime() - fst.createdAt.getTime();
        }
        throw new Error('Cannot sort Assets with Uploading assets');
    };
}
