import { QuestionMark } from '@src/components/elements';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { QuestionMarkWrapper } from './SendResumeFields.style';
export function NewsletterAgreementField({ setIsNewsletterAgreementVisible }) {
    return (React.createElement("div", { className: "flex-row" },
        React.createElement(Form.Item, { name: "newsletter", valuePropName: "checked", initialValue: false },
            React.createElement(Checkbox, null,
                React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.agree_with_newsleter" }))),
        React.createElement(QuestionMarkWrapper, null,
            React.createElement(QuestionMark, { onClick: () => setIsNewsletterAgreementVisible(true) }))));
}
