import { client as axios } from './clients/axios';
export async function incrementPageView(pathname) {
    try {
        const response = await axios.post('/0/analytics/page-view', {
            pathname,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function incrementPromotedOfferView(pathname) {
    try {
        const response = await axios.post('/0/analytics/promoted-offer-view', {
            pathname,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getAllDefaultCharts({ dateFrom, dateTo, }) {
    const response = await axios.get('/0/analytics/chart-list', {
        params: {
            dateFrom,
            dateTo,
        },
    });
    return response.data;
}
export async function getAnalyticsByType({ dateFrom, dateTo, analyticsType, offerId, specificDate, offerCompanyId, }) {
    const response = await axios.get(`/0/analytics/${analyticsType}`, {
        params: {
            dateFrom,
            dateTo,
            offerId,
            specificDate,
            offerCompanyId,
        },
    });
    return response.data;
}
