import React from 'react';
import styled from 'styled-components';
const QuestionMarkCircle = styled.span `
	color: white;
	cursor: ${props => (props.pointer ? 'pointer' : 'default')};
	width: 20px;
	height: 20px;
	background-color: ${({ theme }) => theme.color.brand1};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export function QuestionMark({ onClick, pointer = true }) {
    return (React.createElement(QuestionMarkCircle, { onClick: onClick, pointer: pointer }, "?"));
}
