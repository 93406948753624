var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable global-require */
import React from 'react';
import Icon from '@ant-design/icons';
// import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
function IconComponent(_a) {
    var { type } = _a, props = __rest(_a, ["type"]);
    // eslint-disable-next-line import/no-dynamic-require
    const component = require(`./custom/${type}`).default;
    return React.createElement(Icon, Object.assign({}, props, { component: component }));
}
export default IconComponent;
