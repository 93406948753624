import React from 'react';
import { useHistory } from 'react-router';
function useQueryParameters() {
    const history = useHistory();
    function getQueryParams() {
        const urlSearchParams = new URLSearchParams(history.location.search);
        // @ts-ignore
        const params = Object.fromEntries(urlSearchParams.entries());
        return React.useMemo(() => params, [history.location.search]);
    }
    function createQueryParams(input) {
        let queryString = '';
        Object.keys(input).forEach(key => {
            const value = input[key];
            if (value !== undefined && value !== null) {
                const valueString = encodeURIComponent(value);
                queryString += queryString ? `&${key}=${valueString}` : `${key}=${valueString}`;
            }
        });
        return queryString;
    }
    return { createQueryParams, getQueryParams };
}
export { useQueryParameters };
