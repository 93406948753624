import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, Button, Title, Icon } from '@src/components/elements';
import styled from 'styled-components';
const OfferNotificationSubscriptionPanel = ({ setIsModalOpen }) => {
    return (React.createElement(Panel, { className: "m-t-m m-b-m flex-col" },
        React.createElement(Title, { level: 3, type: "secondary" },
            React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_panel.didnt_find_suitable_offer" })),
        React.createElement(BellIcon, { type: "bell" }),
        React.createElement("div", { className: "text-center" },
            React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_panel.tell_us_which_offers" })),
        React.createElement("div", { className: "text-center" },
            React.createElement(Button, { className: "m-t-m", onClick: () => {
                    setIsModalOpen(true);
                } },
                React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_panel.subscribe" })))));
};
export const MainContent = styled(Panel) ``;
export const BellIcon = styled(Icon) `
	svg {
		width: 3rem;
		height: 3rem;
		stroke: ${({ theme }) => theme.color.gray6};
		margin-bottom: 1rem;
	}
`;
export { OfferNotificationSubscriptionPanel };
