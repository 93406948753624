import { StarFilled } from '@ant-design/icons';
import { Button, Tag } from '@src/components/elements';
import { getProductPricing } from '@src/shared/get-product-pricing';
import { ProductId, SubscriptionPeriods } from '@src/types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { up, only } from 'styled-breakpoints';
import { darken } from 'polished';
const PremiumCompanyProfileSubscriptionCard = ({ subscriptionPeriod, showPricesWithVat, isElevated }) => {
    const history = useHistory();
    const { totalPriceWithoutVat, discountRelative, productPriceWithoutVat, productPrice, totalPrice } = getProductPricing({
        productId: ProductId.companyPremium,
        productCount: subscriptionPeriod,
    });
    const costPerMonth = showPricesWithVat ? productPrice : productPriceWithoutVat;
    const total = showPricesWithVat ? totalPrice : totalPriceWithoutVat;
    return (React.createElement(BoxWhite, { subscriptionPeriod: subscriptionPeriod, isElevated: isElevated },
        React.createElement(ContentWrapper, null,
            React.createElement(React.Fragment, null,
                React.createElement(SubscriptionPeriodTitle, null,
                    React.createElement(FormattedMessage, { id: "general.month.pluralized", values: { count: subscriptionPeriod } })),
                subscriptionPeriod === SubscriptionPeriods.TwelveMonthsPeriod && (React.createElement(BestPriceTag, null,
                    React.createElement("div", { className: "flex-row align-center" },
                        React.createElement(StarFilled, { className: "m-r-s" }),
                        React.createElement(FormattedMessage, { id: "company_premium_profile.best_price" }))))),
            React.createElement(PricePerMonthSection, null,
                React.createElement(StyledPricePerMonth, null,
                    React.createElement(FormattedNumber, { value: costPerMonth, style: "currency", currency: "EUR" })),
                React.createElement(PerMonthFontWrapper, null,
                    React.createElement(FormattedMessage, { id: "admin.subscription.per_month" }))),
            React.createElement("div", { className: "flex-center" },
                React.createElement(StyledDiv, null))),
        React.createElement(CardFooter, null,
            discountRelative > 0 && (React.createElement(DiscountWrapper, null,
                React.createElement(GrayText, null,
                    React.createElement(FormattedMessage, { id: "admin.subscription.your_discount" })),
                React.createElement(Discount, null,
                    "~",
                    React.createElement(FormattedNumber, { value: discountRelative, style: "unit", unit: "percent" })))),
            React.createElement("div", { className: "text-center font-bold font-size-m" },
                React.createElement(FormattedMessage, { id: "admin.subscription.total" }),
                React.createElement(FormattedNumber, { value: total, style: "currency", currency: "EUR" })),
            React.createElement("div", { className: "flex-col align-center justify-center" },
                React.createElement(Button, { type: "primary", shape: "round", onClick: () => history.pushLocalized(`/admin/objednavka?product=${ProductId.companyPremium}&amount=${subscriptionPeriod}`) },
                    React.createElement(FormattedMessage, { id: "company_premium_profile.buy_subscription" }))))));
};
const BoxWhite = styled.div `
	padding: 3rem 2rem 1rem;
	width: 100%;
	min-height: 350px;
	position: relative;
	background: ${({ theme }) => theme.color.white};
	border-radius: 1rem;
	overflow: hidden;
	box-shadow: 0 15px 35px rgb(50 50 77 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${({ theme }) => theme.color.white};
	${({ subscriptionPeriod, theme }) => {
    if (subscriptionPeriod === SubscriptionPeriods.OneMonthPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${darken(0.1, theme.color.brand2)};
			`;
    }
    if (subscriptionPeriod === SubscriptionPeriods.SixMonthsPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${darken(0.1, theme.color.brand1)};
			`;
    }
    if (subscriptionPeriod === SubscriptionPeriods.TwelveMonthsPeriod) {
        return css `
				background: linear-gradient(150deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3) 90%),
					${theme.color.brandDarkBlue};
			`;
    }
}};
`;
const GrayText = styled.div `
	color: ${({ theme }) => theme.color.gray4};
`;
const SubscriptionPeriodTitle = styled.div `
	font-size: 1.25rem;
	font-weight: bold;
	display: flex;
	${up('xs')} {
		justify-content: center;
	}
	${only('xs')} {
		width: 100%;
	}
	${up('md')} {
		justify-content: flex-start;
	}
`;
const ContentWrapper = styled.div `
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
const StyledPricePerMonth = styled.div `
	font-size: 3rem;
	font-weight: bold;
	margin-right: 0.5rem;
	${up('xs')} {
		display: flex;
		justify-content: center;
	}
	${only('xs')} {
		width: 100%;
	}
	${up('md')} {
		font-size: 2.5rem;
	}
	${up('xl')} {
		font-size: 3rem;
	}
`;
const PricePerMonthSection = styled.div `
	display: flex;
	flex-direction: column;
	align-items: baseline;
	font-size: 1.5rem;

	${up('xs')} {
		justify-content: center;
	}
	${up('sm')} {
		flex-direction: row;
	}
	${up('md')} {
		justify-content: flex-start;
		flex-direction: column;
	}
	${up('lg')} {
		flex-direction: row;
	}
`;
const Discount = styled.span `
	margin-left: 0.5rem;
	font-size: 1.75rem;
	font-weight: bold;
	color: ${({ theme }) => theme.color.success};
	background: ${({ theme }) => theme.color.white};
	border-radius: 0.5rem;
	padding: 0 0.5rem;
`;
const CardFooter = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 0.5rem;
`;
const CardTag = styled(Tag) `
	color: ${({ theme }) => theme.color.gray4};
	font-size: 0.9rem;
	padding: 0.5rem 1rem;
	top: 0;
	position: absolute;
	border: 0;
	border-radius: 0 0 0.5rem 0.5rem;
	left: 50%;
	transform: translate(-50%, 0);
`;
const BestPriceTag = styled(CardTag) `
	background: ${({ theme }) => theme.color.success};
	color: ${({ theme }) => theme.color.white};
`;
const StyledDiv = styled.div `
	width: 100%;
	height: 0.125rem;
	background: rgba(255, 255, 255, 0.6);
`;
const DiscountWrapper = styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	${up('md')} {
		margin: 0;
	}
`;
const PerMonthFontWrapper = styled.div `
	font-size: 1.25rem;
	color: ${({ theme }) => theme.color.gray4};
	${up('xs')} {
		display: flex;
		justify-content: center;
	}
	${up('md')} {
		font-size: 1rem;
	}
	${only('xs')} {
		width: 100%;
	}
	${up('xl')} {
		font-size: 1.5rem;
	}
`;
export { PremiumCompanyProfileSubscriptionCard };
