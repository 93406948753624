import styled, { css } from 'styled-components';
import { Select } from '@src/components/elements';
export const AssetLibraryHeaderContainer = styled.div `
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid ${props => props.theme.color.gray4};
`;
export const AssetLibraryHeaderRow = styled.div `
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1rem;
	gap: 1rem 2rem;
	align-items: center;
`;
export const FilterAssetTypeSelect = styled(Select) `
	width: 200px;
`;
export const AssetUsage = styled.div `
	display: flex;
	align-items: center;
	${props => props.shouldDisplayInRed
    ? css `
					color: ${props.theme.color.error};
			  `
    : css `
					color: ${props.theme.color.success};
			  `}
`;
