import React from 'react';
import { Form, Row, Col, notification } from 'antd';
import { ImageUpload } from '@src/components/elements';
import { CompanyInfoEditFormField } from '@src/types';
const showBigSizeNotification = (intl, imageSize) => {
    notification.error({
        message: intl.formatMessage({
            id: 'profile.resume.notification',
        }),
        description: `${intl.formatMessage({
            id: 'profile.resume.max_img_size',
        })} ${imageSize}MB`,
        placement: 'bottomRight',
    });
};
export const checkImageSize = (e, imageSizeMb, intl) => {
    const isImageSizeInBounds = e.size / 1024 / 1024 < imageSizeMb;
    if (!isImageSizeInBounds) {
        showBigSizeNotification(intl, imageSizeMb);
        throw new Error('Image too large. Maximal size is 1 MB');
    }
    return isImageSizeInBounds;
};
export function UploadFields({ intl, image, setImage, titleImage, setTitleImage }) {
    return (React.createElement(Row, { gutter: [16, 16] },
        React.createElement(Col, { xs: 24, md: 12 },
            React.createElement(Form.Item, { label: intl.formatMessage({
                    id: 'registration.registration_form.image',
                }), name: CompanyInfoEditFormField.image },
                React.createElement(ImageUpload, { name: "image", accept: "image/*", aspect: 1, cropSize: { width: 600, height: 600 }, beforeUpload: e => checkImageSize(e, 1, intl), wholeFileList: image, setFileList: setImage, modalTitle: `${intl.formatMessage({
                        id: 'registration.registration_form.upload_modal_title',
                    })} (1:1, max. 1MB)` }))),
        React.createElement(Col, { xs: 24, md: 12 },
            React.createElement(Form.Item, { label: intl.formatMessage({
                    id: 'registration.registration_form.title_image',
                }), name: CompanyInfoEditFormField.title_image },
                React.createElement(ImageUpload, { name: "title_image_url", accept: "image/*", aspect: 6 / 1, cropSize: { width: 1200, height: 200 }, beforeUpload: e => checkImageSize(e, 2, intl), wholeFileList: titleImage, setFileList: setTitleImage, modalTitle: `${intl.formatMessage({
                        id: 'registration.registration_form.upload_modal_title',
                    })} (6:1, max. 1MB)` })))));
}
