import config from '@src/config';
import { OfferFilterType } from '@src/types/offers-filters';
import { client as axios } from './clients/axios';
export async function getOffersFilters(selectedFilterItems) {
    const response = await axios.get(`${config.api}/0/offers-filters`, {
        params: {
            typ: selectedFilterItems[OfferFilterType.type],
            loc: selectedFilterItems[OfferFilterType.location],
            job: selectedFilterItems[OfferFilterType.jobArea],
            salaryMin: selectedFilterItems[OfferFilterType.salaryMin],
            salaryMax: selectedFilterItems[OfferFilterType.salaryMax],
            salaryRate: selectedFilterItems[OfferFilterType.salaryRate],
            co: selectedFilterItems[OfferFilterType.searchCompaniesSelected],
            kw: selectedFilterItems[OfferFilterType.searchKeywordsSelected],
            page: selectedFilterItems.page,
        },
    });
    return response.data;
}
