import React from 'react';
import MuxVideo from '@mux-elements/mux-video-react';
export const TitleGalleryVideoAsset = ({ asset, isPlaying }) => {
    const videoRef = React.useRef(null);
    React.useEffect(() => {
        if (isPlaying) {
            videoRef.current.play();
        }
        else {
            videoRef.current.pause();
        }
    }, [isPlaying]);
    return (
    // @ts-ignore
    React.createElement(MuxVideo, { ref: videoRef, style: { maxWidth: '100%' }, playbackId: asset.muxPublicPlaybackId, metadata: {
            video_id: asset.id,
            video_title: asset.filename,
        }, 
        // @ts-ignore
        autoPlay: false, envKey: process.env.MUX_VIDEO_TRACK_KEY, 
        // @ts-ignore
        streamType: "on-demand", controls: false, height: "100%", loop: true, muted: true }));
};
