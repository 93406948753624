import React from 'react';
import { Button, Panel, RadioGroupBox } from '@src/components/elements';
import OfficialBuilding3 from '@public/media/icons/streamline-icon-official-building-3.svg';
import CreditCard from '@public/media/icons/streamline-icon-credit-card.svg';
import { PaymentMethod, ProductType } from '@src/types';
import { Checkout } from '@src/components/modules/global/Checkout';
import { StyledInfoAlert } from '@src/components/pages/offers/modules/OfferNotificationSubscriptionModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { notification } from 'antd';
import * as Api from '@src/api';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';
import { Queries } from '@src/types/queries';
const AdminOrderPayments = ({ selectedBillingProfile, isOrderLoading, appliedCouponCode, setIsOrderLoading, orderContent, orderParams, offerId, onOrderSuccess, companyPaymentMethodToken, }) => {
    const history = useHistory();
    const intl = useIntl();
    const queryClient = useQueryClient();
    const [paymentMethod, setPaymentMethod] = React.useState(PaymentMethod.credit_card);
    const paymentOptionsBoxes = [
        {
            icon: React.createElement(CreditCard, null),
            label: intl.formatMessage({
                id: 'global.checkout.pay_by_card',
            }),
            value: PaymentMethod.credit_card,
        },
        {
            icon: React.createElement(OfficialBuilding3, null),
            label: intl.formatMessage({
                id: 'global.checkout.pay_by_bank_transfer',
            }),
            value: PaymentMethod.bank_transfer,
        },
    ];
    const bankTransferDescriptionsConfig = {
        [ProductType.promotion]: intl.formatMessage({
            id: 'admin.payments.create_order_info.offer_promotion',
        }),
        [ProductType.credit]: intl.formatMessage({
            id: 'admin.payments.create_order_info.credits',
        }),
        [ProductType.subscription]: intl.formatMessage({
            id: 'admin.payments.create_order_info.subscription',
        }),
    };
    const bankTransferDescription = (orderContent === null || orderContent === void 0 ? void 0 : orderContent.orderType) ? bankTransferDescriptionsConfig[orderContent === null || orderContent === void 0 ? void 0 : orderContent.orderType]
        : '';
    const createInvoiceAndOrder = async () => {
        var _a;
        try {
            if (!orderContent) {
                throw new Error(intl.formatMessage({
                    id: 'admin.order.invalid_order',
                }));
            }
            setIsOrderLoading(true);
            let invoiceId = null;
            const invoice = await Api.Invoice.createInvoice({
                isProforma: true,
                orderContent,
                orderParams,
                couponCode: appliedCouponCode,
                billingProfileId: selectedBillingProfile === null || selectedBillingProfile === void 0 ? void 0 : selectedBillingProfile.id,
                offerId,
            });
            invoiceId = invoice.id || null;
            setIsOrderLoading(false);
            if (onOrderSuccess && invoiceId) {
                onOrderSuccess(PaymentMethod.bank_transfer, invoiceId);
            }
            if (!onOrderSuccess) {
                history.pushLocalized(`/admin/objednavka/platobne-informacie/${invoiceId}`);
            }
            queryClient.invalidateQueries(Queries.getCompany);
        }
        catch (err) {
            setIsOrderLoading(false);
            // eslint-disable-next-line no-console
            console.error(err);
            const errDescription = ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 422
                ? intl.formatMessage({
                    id: 'global.checkout.invalid_payment_information',
                })
                : intl.formatMessage({
                    id: 'general.something_went_wrong',
                });
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: errDescription,
            });
        }
    };
    return (React.createElement(Panel, { className: "m-t-m" },
        React.createElement("div", { className: "flex-center m-b-m" },
            React.createElement(RadioGroupBox, { size: "large", boxes: paymentOptionsBoxes, onChange: e => setPaymentMethod(e.target.value), value: paymentMethod })),
        paymentMethod === PaymentMethod.credit_card && (React.createElement(Checkout, { order: orderContent, isOrderLoading: isOrderLoading, orderParams: orderParams, couponCode: appliedCouponCode, hasRememberedCard: Boolean(companyPaymentMethodToken), billingProfile: selectedBillingProfile, offerId: offerId, onOrderSuccess: onOrderSuccess })),
        paymentMethod === PaymentMethod.bank_transfer && (React.createElement(React.Fragment, null,
            React.createElement(StyledInfoAlert, { type: "info", showIcon: true, message: intl.formatMessage({
                    id: 'admin.payments.create_order_info.notes',
                }), description: bankTransferDescription }),
            React.createElement("div", { className: "flex-center m-t-l" },
                React.createElement(Button, { type: "primary", loading: isOrderLoading, onClick: createInvoiceAndOrder },
                    React.createElement("span", { className: "m-r-xxs" },
                        React.createElement(FormattedMessage, { id: "global.checkout.create_order" }))))))));
};
export { AdminOrderPayments };
