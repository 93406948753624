import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';
import * as Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { getLanguageFromPathname, getPathWithoutLocale } from '@src/shared/localized-path';
import { history } from '@src/app/index';
const StyledAlertBar = styled.div `
	background: ${props => props.theme.color.brand3};
	display: flex;
	align-items: center;
	color: ${props => props.theme.color.white};
	padding: 0.5rem 1rem;
	justify-content: space-between;
	z-index: ${props => props.theme.zIndex.alertBar};
`;
const StyledLink = styled.a `
	padding: 0 0 0 28px;
	background: url('/media/images/flag-waving-ukraine.svg') 0 1px no-repeat;
	background-size: 1.6em auto;
	display: inline-block;
	height: 100%;

	&,
	&&,
	&&:hover,
	&&:visited,
	&&:focus {
		color: ${props => props.theme.color.white};
		text-decoration: underline;
	}
`;
const StyledSpan = styled.span `
	margin: 0 1em 0 0;
`;
const StyledClose = styled.button `
	cursor: pointer;
	border: 0;
	background: 0;
	padding: 0.5em 1em;
	margin: -0.5em -1em;
	color: ${props => props.theme.color.white};
`;
const AlertBar = () => {
    const location = useLocation();
    const locale = getLanguageFromPathname(location.pathname);
    const { pathWithoutLocale } = getPathWithoutLocale(location.pathname);
    const alertUaClosed = Cookies.get('pracujAlertUaClosed');
    const [alertUaClosedState, setAlertUaClosedState] = useState(Boolean(alertUaClosed));
    const closeAlert = () => {
        Cookies.set('pracujAlertUaClosed', 'true', {
            expires: 180,
        });
        setAlertUaClosedState(true);
    };
    const onChangeToUkrainian = () => {
        closeAlert();
        history.push(`/uk${pathWithoutLocale}`);
    };
    if (alertUaClosedState || locale === 'uk') {
        return null;
    }
    return (React.createElement(StyledAlertBar, null,
        React.createElement("span", null,
            React.createElement(StyledSpan, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "general.new" }),
                    "!"),
                ' ',
                React.createElement(FormattedMessage, { id: "alert.ukraine_support" })),
            React.createElement(StyledLink, { href: `/uk${pathWithoutLocale}`, onClick: onChangeToUkrainian },
                React.createElement(FormattedMessage, { id: "alert.switch_to_ukrainian" }))),
        React.createElement(StyledClose, { onClick: closeAlert },
            React.createElement(CloseCircleFilled, null))));
};
export { AlertBar };
