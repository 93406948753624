import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { notification, Form } from 'antd';
import { OnsFormAction, ABROAD, REMOTE, } from '@src/types';
import { Button, Title } from '@src/components/elements';
import * as Api from '@src/api';
import { useAccount } from '@src/context/account-context';
import { convertSelectedLocations } from '@src/helpers/convert-selected-locations';
import { formatCombinedValues, useOffersFilterContextValue } from '@src/components/pages/offers/offer-filter-context';
import { SpecificSearchSelectField } from '@src/components/modules/forms/specific-search-select-field';
import { getValidationRules } from './offer-notification-subscription-validation-rules';
import { LocationSelect } from './LocationSelect';
import { JobAreaSelectField } from '../job-area-select-field';
import { TypeSelectField } from '../type-select-field/typeSelectFields';
import { SalaryRangeField } from '../salary-range-field/salaryRangeField';
import { CancelSubscriptionButton, EmailInput, RedText, RequiredWrapper, } from './OfferNotificationSubscriptionForm.styles';
function OfferNotificationSubscriptionForm({ subscription, onSubmit, onDeleteRedirectToNotifications, showEmailField, onDelete, }) {
    const [formAction, setFormAction] = React.useState(OnsFormAction.CREATE);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [form] = Form.useForm();
    const account = useAccount();
    const [formValues, setFormValues] = React.useState(form.getFieldsValue());
    const history = useHistory();
    const intl = useIntl();
    React.useEffect(() => {
        setFormAction((subscription === null || subscription === void 0 ? void 0 : subscription.id) ? OnsFormAction.UPDATE : OnsFormAction.CREATE);
    }, [subscription]);
    const subscriptionToken = history.getQuery().onst;
    const { emailValidationRules, subscriptionFilterRules, salaryMaxValidationRules } = getValidationRules({
        intl,
        subscriptionToken,
        formValues,
    });
    const showSuccessNotification = (action) => {
        let message = intl.formatMessage({
            id: 'forms.offer_notification_subscription_form.data_successfully_changed',
        });
        if (action === OnsFormAction.CREATE) {
            message = intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.subscription_created',
            });
        }
        if (action === OnsFormAction.DELETE) {
            message = intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.subscription_deleted',
            });
        }
        notification.success({
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.notification',
            }),
            description: message,
        });
    };
    const showErrorNotification = () => {
        notification.error({
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.error',
            }),
            description: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.something_went_wrong',
            }),
        });
    };
    const submitForm = async (formData) => {
        setIsSubmitting(true);
        try {
            const subscriptionData = Object.assign(Object.assign({}, formData), { selectedLocations: convertSelectedLocations(formData.selectedLocations), specificSearch: formValues.specificSearch });
            if (!formData.email) {
                subscriptionData.email = ((subscription === null || subscription === void 0 ? void 0 : subscription.email) || (account === null || account === void 0 ? void 0 : account.email));
            }
            if (formAction === OnsFormAction.CREATE) {
                await Api.OfferNotificationSubscription.createSubscription(subscriptionData);
            }
            else {
                await Api.OfferNotificationSubscription.updateSubscription(subscriptionData, subscription.token);
            }
            showSuccessNotification(formAction);
            onSubmit();
        }
        catch (err) {
            showErrorNotification();
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const handleDelete = async () => {
        try {
            if (onDeleteRedirectToNotifications) {
                // @ts-ignore
                history.pushLocalized('/ucet/notifikacie');
            }
            else {
                await Api.OfferNotificationSubscription.deleteSubscription(subscription.token);
                showSuccessNotification(OnsFormAction.DELETE);
            }
            if (onDelete) {
                onDelete();
            }
        }
        catch (err) {
            showErrorNotification();
        }
    };
    const { jobAreaFiltersSelected, locationFiltersSelected, typeFiltersSelected, salaryFilter, searchKeywordsSelected, searchCompaniesSelected, } = useOffersFilterContextValue();
    const initialValues = {
        email: (subscription === null || subscription === void 0 ? void 0 : subscription.email) || (account === null || account === void 0 ? void 0 : account.email) || '',
        jobAreaIds: (subscription === null || subscription === void 0 ? void 0 : subscription.jobAreaIds) || jobAreaFiltersSelected || [],
        selectedLocations: (subscription === null || subscription === void 0 ? void 0 : subscription.locationIds) || locationFiltersSelected || [],
        typeIds: (subscription === null || subscription === void 0 ? void 0 : subscription.typeIds) || typeFiltersSelected || [],
        salaryRate: (subscription === null || subscription === void 0 ? void 0 : subscription.salaryRate) || salaryFilter.rate || undefined,
        salaryMin: (subscription === null || subscription === void 0 ? void 0 : subscription.salaryMin) || salaryFilter.minRange,
        salaryMax: (subscription === null || subscription === void 0 ? void 0 : subscription.salaryMax) || salaryFilter.maxRange,
    };
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.is_remote) && !initialValues.selectedLocations.includes(REMOTE)) {
        initialValues.selectedLocations.push(REMOTE);
    }
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.is_abroad) && !initialValues.selectedLocations.includes(ABROAD)) {
        initialValues.selectedLocations.push(ABROAD);
    }
    const specificSearch = formatCombinedValues({
        companies: (subscription === null || subscription === void 0 ? void 0 : subscription.companies) || searchCompaniesSelected || [],
        keywords: (subscription === null || subscription === void 0 ? void 0 : subscription.keywords) || searchKeywordsSelected || [],
        skillTags: (subscription === null || subscription === void 0 ? void 0 : subscription.skillTags) || [],
    });
    initialValues.specificSearch = specificSearch;
    React.useEffect(() => {
        form.setFieldsValue(initialValues);
        setFormValues(initialValues);
    }, []);
    return (React.createElement(Form, { layout: "vertical", onFinish: submitForm, form: form, onValuesChange: values => setFormValues(Object.assign(Object.assign({}, formValues), values)) },
        showEmailField && (React.createElement(Form.Item, { label: React.createElement("div", { className: "flex-row" },
                React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.email" })), rules: emailValidationRules, name: "email" },
            React.createElement(EmailInput, { placeholder: intl.formatMessage({ id: 'global.email.placeholder' }), type: "email" }))),
        React.createElement(Title, { className: "text-left font-normal m-b-m m-t-m", level: 5 }, intl.formatMessage({ id: 'offers.offer_notification_subscription_form.criteria' })),
        React.createElement(TypeSelectField, { label: intl.formatMessage({ id: 'forms.offer_notification_subscription_form.type' }), name: "typeIds", rules: subscriptionFilterRules }),
        React.createElement(Form.Item, { label: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.location',
            }), name: "selectedLocations", rules: subscriptionFilterRules },
            React.createElement(LocationSelect, { placeholder: intl.formatMessage({ id: 'global.location.placeholder' }) })),
        React.createElement(JobAreaSelectField, { label: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.job_area',
            }), name: "jobAreaIds", rules: subscriptionFilterRules }),
        React.createElement(SpecificSearchSelectField, { name: "specificSearch", rules: subscriptionFilterRules, formValues: formValues, setFormValues: setFormValues }),
        React.createElement("div", { className: "flex-center" },
            React.createElement(SalaryRangeField, { form: form, maxRules: salaryMaxValidationRules, rateRules: subscriptionFilterRules })),
        formAction === OnsFormAction.UPDATE && (React.createElement("div", { className: "flex-center m-b-l" },
            React.createElement(CancelSubscriptionButton, { type: "button", onClick: handleDelete },
                React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.cancel_subscription" })))),
        React.createElement("div", { className: "flex-center" },
            React.createElement(Button, { type: "primary", htmlType: "submit", loading: isSubmitting },
                React.createElement("span", { className: "m-l-xxs" }, formAction === OnsFormAction.CREATE ? (React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.subscribe" })) : (React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.save_changes" }))))),
        React.createElement(RequiredWrapper, null,
            React.createElement(RedText, null, "*"),
            React.createElement(FormattedMessage, { id: "offer_detail.send_resume_form.required_fields" }))));
}
export { OfferNotificationSubscriptionForm };
