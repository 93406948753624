import styled from 'styled-components';
export const UploadingAssetCardContainer = styled.div `
	width: 245px;
	height: 245px;
	border-radius: ${props => props.theme.borderRadius.large};
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;
export const UploadingAssetCardContent = styled.div `
	height: 85%;
	width: 100%;
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const UploadingAssetCardFooter = styled.div `
	width: 100%;
	height: 15%;
	background-color: ${props => props.theme.color.white};
	border-radius: 0 0 8px 8px;
	font-size: ${props => props.theme.fontSizes.s};
	padding: 0 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const UploadingAssetCardFooterText = styled.div `
	color: ${props => props.theme.color.gray8};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
