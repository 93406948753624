import React from 'react';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import PencilIcon from '@public/media/icons/streamline-icon-pencil.svg';
import { CompanySubscriptionBlock } from '@src/components/modules/global/CompanySubscriptionBlock/CompanySubscriptionBlock';
import { CompanyPremiumProfileContentType } from '@src/types';
import { FormattedMessage } from 'react-intl';
import PinIcon from '@public/media/icons/streamline-icon-pin.svg';
import { createGlobalStyle } from 'styled-components';
import { useAccount } from '@src/context/account-context';
import { useHeaderMenuContextValue } from '@src/context/header-menu-context';
import { useHistory } from 'react-router';
import { useAppQuery } from '@src/hooks/useAppQuery';
import * as Api from '@src/api';
import { Queries } from '@src/types/queries';
import { ActivatePremiumCompanyProfileBanner } from '@src/components/modules/global/ActivatePremiumCompanyProfileBanner/ActivatePremiumCompanyProfileBanner';
import { getHasCompanyActivePremiumCompanyProfileSubscription } from '@src/helpers/premium-subscription';
import { CompanyProfileHeaderEditModal } from './CompanyProfileHeaderEditModal';
import { SubSectionButton, Header, HeaderContent, HeaderTextWrapper, ImageBlock, StyledWebIcon, SubNavigation, TitleImageOverlay, FullHeaderWrapper, EditButton, EditButtonWrapper, HeaderEntryWrapper, EntryText, HeaderTitle, SubContentPagesNavigationWrapper, SubscribersWrapper, CircleIconWrapper, CompanyWebsiteLink, StyledBadge, HeaderTextInfo, SubNavigationContentWrapper, BannerWrapper, BannerContentWrapper, } from './CompanyProfileHeader.style';
const HidePopoverStyles = createGlobalStyle `
	.ant-popover.ant-avatar-group-popover.ant-popover-placement-top {
		display: none;
	}
`;
const CompanyProfileHeader = ({ contentTypeToShow, company }) => {
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
    const { isMenuSticked } = useHeaderMenuContextValue();
    const history = useHistory();
    const account = useAccount();
    const imageBackgroundUrl = (company === null || company === void 0 ? void 0 : company.titleImageUrl) ? getCdnImageUrl({
        file: `web/company/${company === null || company === void 0 ? void 0 : company.titleImageUrl}`,
    })
        : '/media/images/background-gradient.jpg';
    const isAccountFromViewedCompany = (account === null || account === void 0 ? void 0 : account.companyId) === (company === null || company === void 0 ? void 0 : company.id);
    const isAccountFromDifferentCompany = (account === null || account === void 0 ? void 0 : account.companyId) && !isAccountFromViewedCompany;
    const { data: visitingCompanyAccount } = useAppQuery([Queries.getCompanyById, isAccountFromDifferentCompany, account], () => Api.Company.getCompanyById(account === null || account === void 0 ? void 0 : account.companyId), {
        enabled: Boolean(isAccountFromDifferentCompany),
    });
    const hasActivePremiumSubscription = getHasCompanyActivePremiumCompanyProfileSubscription(company.companyPaymentSubscriptions);
    const hasVisitingCompanyAccountPremiumProfileSubscription = getHasCompanyActivePremiumCompanyProfileSubscription(visitingCompanyAccount === null || visitingCompanyAccount === void 0 ? void 0 : visitingCompanyAccount.companyPaymentSubscriptions);
    const activeOffers = company === null || company === void 0 ? void 0 : company.offers.filter(o => Boolean(o.isActive) === true);
    return (React.createElement(React.Fragment, null,
        React.createElement(HidePopoverStyles, null),
        React.createElement(Header, { background: imageBackgroundUrl }, company && (React.createElement(React.Fragment, null,
            React.createElement(TitleImageOverlay, null),
            isAccountFromDifferentCompany && !hasVisitingCompanyAccountPremiumProfileSubscription && (React.createElement(BannerWrapper, null,
                React.createElement(BannerContentWrapper, null,
                    React.createElement(ActivatePremiumCompanyProfileBanner, { hasActivePremiumSubscription: hasActivePremiumSubscription, shouldBeHiddenIfPremiumIsActive: false })))),
            isAccountFromViewedCompany && (React.createElement(EditButtonWrapper, null,
                React.createElement(EditButton, { type: "text", onClick: () => setIsEditModalOpen(true) },
                    React.createElement(FormattedMessage, { id: "general.edit" }),
                    React.createElement(PencilIcon, { width: 14, height: 14, className: "m-l-s" })))),
            React.createElement(FullHeaderWrapper, null,
                React.createElement(HeaderContent, { hasImage: Boolean(company.image) },
                    company.image && (React.createElement(ImageBlock, null,
                        React.createElement("img", { src: getCdnImageUrl({
                                file: `web/company/${company.image}`,
                                transformation: 'w_150,h_150,c_limit',
                            }), alt: `Logo: ${company.name}` }))),
                    React.createElement(HeaderTextInfo, null,
                        React.createElement(HeaderTitle, null, company.name),
                        React.createElement(HeaderTextWrapper, null,
                            company.address && (React.createElement(HeaderEntryWrapper, null,
                                React.createElement(CircleIconWrapper, null,
                                    React.createElement(PinIcon, { width: 14, height: 14 })),
                                React.createElement("div", null,
                                    React.createElement(EntryText, null, company.address.formattedAddress)))),
                            company.website && (React.createElement(HeaderEntryWrapper, null,
                                React.createElement(CircleIconWrapper, null,
                                    React.createElement(StyledWebIcon, null)),
                                React.createElement(CompanyWebsiteLink, { href: company.website, target: "_blank", rel: "noreferrer noopenner" },
                                    React.createElement(EntryText, null, company.website))))))))))),
        React.createElement(SubNavigation, { isMenuSticked: isMenuSticked },
            React.createElement(SubNavigationContentWrapper, null,
                React.createElement(SubContentPagesNavigationWrapper, null,
                    React.createElement(SubSectionButton, { isActive: contentTypeToShow === CompanyPremiumProfileContentType.profile, onClick: () => history.pushLocalized(`/firma/${company.companyUrl}/${CompanyPremiumProfileContentType.profile}`) },
                        React.createElement(FormattedMessage, { id: "general.profile" })),
                    React.createElement(SubSectionButton, { isActive: contentTypeToShow === CompanyPremiumProfileContentType.offers, onClick: () => history.pushLocalized(`/firma/${company.companyUrl}/${CompanyPremiumProfileContentType.offers}`) },
                        React.createElement(StyledBadge, { count: activeOffers.length, showZero: true, offset: [10, 5] },
                            React.createElement(FormattedMessage, { id: "general.offers" })))),
                React.createElement(SubscribersWrapper, null,
                    React.createElement(CompanySubscriptionBlock, { subscribers: company.subscribers, companyId: company.id })))),
        isEditModalOpen && isAccountFromViewedCompany && (React.createElement(CompanyProfileHeaderEditModal, { isEditModalOpen: isEditModalOpen, setIsEditModalOpen: setIsEditModalOpen, company: company }))));
};
export { CompanyProfileHeader };
