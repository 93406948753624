import styled, { css } from 'styled-components';
const colorSelected = '#1890ff';
export const AssetCardContainer = styled.div `
	cursor: pointer;
	position: relative;
	height: 245px;
	width: 245px;
	border-radius: ${props => props.theme.borderRadius.large};
	background-color: ${props => props.theme.color.gray9};
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	${props => props.shouldHaveLowerOpacity &&
    css `
			opacity: 0.75;
		`};
	${props => props.shouldHaveColoredOutline &&
    css `
			outline: 2px solid ${colorSelected};
		`};
`;
export const AssetCardOverlay = styled.div `
	position: absolute;
	color: ${props => props.theme.color.white};
	width: 100%;
	height: 100%;
	display: flex;
	font-size: ${props => props.theme.fontSizes.m};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(64, 64, 64, 0.5);
	opacity: 0;
	border-radius: inherit;
	transition: opacity 0.2s;
	z-index: 1;
	& svg {
		font-size: ${props => props.theme.fontSizes.xxxl};
	}

	${props => props.shouldDisplayOverlay &&
    css `
			opacity: 1;
		`}
`;
export const AssetCardContent = styled.div `
	height: 85%;
	width: 100%;
	border-radius: 8px 8px 0 0;
`;
export const CheckCircleSlot = styled.div `
	position: absolute;
	background-color: ${props => props.theme.color.white};
	color: ${colorSelected};
	top: 0;
	left: 0.5rem;
	border-radius: 100%;
	height: 1.75rem;
	width: 1.75rem;
	outline: 1px solid ${props => props.theme.color.gray9};
	z-index: 2;
	opacity: 0;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.shouldBeDisplayed &&
    css `
			opacity: 1;
			top: 0.5rem;
		`}
	${props => props.shouldHaveColoredOutline &&
    css `
			outline: 1px solid ${colorSelected};
		`}
	${props => props.isDisabled &&
    css `
			cursor: not-allowed;
		`}
	& svg {
		height: 1.75rem;
		width: 1.75rem;
	}
`;
export const SelectedAssetOrder = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: ${({ theme }) => theme.color.white};
	background: ${colorSelected};
	height: 100%;
	border-radius: 100%;
	width: 100%;
`;
export const AssetCardFooter = styled.div `
	width: 100%;
	height: 15%;
	background-color: ${props => props.theme.color.white};
	border-radius: 0 0 8px 8px;
	font-size: ${props => props.theme.fontSizes.s};
	padding: 0 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const AssetCardFooterText = styled.div `
	color: ${props => props.theme.color.gray8};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
