import styled from 'styled-components';
export const Hero = styled.div `
	position: relative;
	height: 240px;
	background: url('/media/images/hero-companies-list.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: 0 -1rem;
	padding-top: 80px;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.9;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(180deg);
	}

	& > * {
		z-index: 1;
		color: white;
	}
`;
export const MainContent = styled.div `
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.color.gray2};
	margin: 0 -1rem;
`;
export const InputBlock = styled.div `
	width: 300px;
`;
