import { useAppContextValue } from '@src/context/app-context';
import { useIntl } from 'react-intl';
const useSalaryFormatter = () => {
    const { language } = useAppContextValue();
    const intl = useIntl();
    const formatSalary = ({ rate, amountFrom, amountTo, currency, salaryType }) => {
        const amountFromString = amountFrom === null || amountFrom === void 0 ? void 0 : amountFrom.toLocaleString(language);
        const amountToString = amountTo === null || amountTo === void 0 ? void 0 : amountTo.toLocaleString(language);
        const rates = {
            hourly: intl.formatMessage({ id: 'offer_detail.details_model.one_hours' }),
            monthly: intl.formatMessage({ id: 'offer_detail.details_model.one_month' }),
            once: intl.formatMessage({ id: 'offer_detail.details_model.once' }),
        };
        const salaryTypeString = salaryType
            ? intl.formatMessage({ id: `offer_detail.details_model.salary_type.${salaryType}` })
            : '';
        let result = `${amountFromString} - ${amountToString} ${currency}`;
        if (!amountToString) {
            result = `${intl.formatMessage({
                id: 'offer_detail.salary.from',
            })} ${amountFromString} ${currency}`;
        }
        if (amountFromString === amountToString) {
            result = `${amountFromString} ${currency}`;
        }
        return `${result} / ${rates[rate]} ${salaryTypeString}`;
    };
    return { formatSalary };
};
export { useSalaryFormatter };
