import React from 'react';
import styled from 'styled-components';
import { Tag } from '@src/components/elements/Tag/Tag';
const SelectTag = ({ children, label, closable, onClose, loading, }) => {
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    if (loading) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(CustomFilterSelectTag, { onMouseDown: onPreventMouseDown, closable: closable, onClose: onClose },
        children && React.createElement(React.Fragment, null, children),
        !children && React.createElement(React.Fragment, null, label)));
};
const CustomFilterSelectTag = styled(Tag) `
	display: inline-flex;
	line-height: 1.6rem;
	font-size: 0.9rem;
	align-items: center;
	margin-right: 0;

	.ant-tag-close-icon {
		margin-left: 0.75rem;

		& > svg {
			width: 16px;
			height: 16px;
		}
	}
`;
export { SelectTag };
