import { textEllipsis } from '@src/helpers/text-ellipsis';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
const ReadMore = ({ text, maxChars }) => {
    const [readMoreActive, setReadMoreActive] = React.useState(false);
    const intl = useIntl();
    if (text.length <= maxChars) {
        return React.createElement("span", null, text);
    }
    return (React.createElement(React.Fragment, null,
        readMoreActive && (React.createElement(React.Fragment, null,
            React.createElement("span", null, text),
            ' ',
            React.createElement(ReadMoreButton, { onClick: () => setReadMoreActive(false) }, intl.formatMessage({ id: 'general.read_less' })))),
        !readMoreActive && (React.createElement(React.Fragment, null,
            React.createElement("span", { title: text }, textEllipsis(text, maxChars)),
            React.createElement(ReadMoreButton, { onClick: () => setReadMoreActive(true) }, intl.formatMessage({ id: 'general.read_more' }))))));
};
const ReadMoreButton = styled.span `
	margin-left: 0.25rem;
	cursor: pointer;
	color: ${({ theme }) => theme.color.brand1};
	word-break: keep-all;
	font-weight: bold;
`;
export { ReadMore };
