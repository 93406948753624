import styled, { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';
import Timestamp from '@src/components/modules/global/timestamp';
import { Link, Tag } from '@src/components/elements';
import { darken } from 'polished';
export const OfferBox = styled.article `
	padding: 1.5rem 1.5rem 1rem 1.5rem;
	position: relative;
	${({ isPromoted }) => isPromoted &&
    css `
			border: 1px solid ${({ theme }) => theme.color.premium};
			border-radius: ${({ theme }) => theme.borderRadius.base};
		`}

	${down('sm')} {
		text-align: center;
	}
`;
export const ImageWrapper = styled.div `
	margin: 0 auto 1rem;
	${up('sm')} {
		float: right;
		margin: 0 0 0 2rem;
		text-align: right;
	}
`;
export const Title = styled.h1 `
	color: ${({ theme }) => theme.color.brand3};
	margin: 0 0 0.2rem 0;
	padding: 0;
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
`;
export const CompanyWrapper = styled.div `
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
	color: ${({ theme }) => theme.color.gray7};
	font-weight: 600;
	font-size: 1.2rem;
	margin-bottom: 1rem;
`;
export const LinkWrapper = styled(Link) `
	position: relative;
	display: block;
	&:link,
	&:visited,
	&:active,
	&:hover,
	&:focus {
		text-decoration: inherit;
		color: inherit;
	}
`;
export const InfoTag = styled.div `
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
	align-items: center;
	font-size: 0.8rem;
	padding: 0.5rem;
	display: inline-flex;
	background: ${({ theme }) => theme.color.gray3};

	border-radius: 4px;
	border-bottom: 1px solid white;
	color: ${({ theme }) => theme.color.gray8};

	.icon > svg {
		height: 1rem;
		margin-right: 0.7rem;
	}
`;
export const PromotedBadge = styled.div `
	position: absolute;
	width: max-content;
	display: inline-flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	/* border: 1px solid ${({ theme }) => darken(0.4, theme.color.premium)}; */
	border-radius: ${({ theme }) => theme.borderRadius.base};
	background-color: ${({ theme }) => theme.color.premium};
	color: ${({ theme }) => theme.color.white};
	top: -15.5px;
	right: 3rem;
`;
export const OfferIntoTagGroup = styled.div `
	display: flex;
	flex-direction: column;
	width: fit-content;

	${down('sm')} {
		width: 100%;
	}
`;
export const ContentWrapper = styled.div `
	width: 100%;
	display: inline-flex;
	justify-content: end;
	align-items: center;

	${down('md')} {
		justify-content: space-between;
		align-items: flex-end;
	}
`;
export const IconWrapper = styled.span `
	display: flex;
	align-items: center;
	svg {
		color: ${({ theme }) => theme.color.brand1};
		width: 16px;
		g {
			stroke: ${({ theme }) => theme.color.brand1};
		}
		path {
			stroke: ${({ theme }) => theme.color.brand1};
		}
	}
`;
export const StyledTimestamp = styled(Timestamp) `
	text-align: right;
	font-size: 0.8rem;
	margin-right: 0.5rem;
	color: ${({ theme }) => theme.color.gray5};
`;
export const StyledTag = styled(Tag) `
	display: flex;
	justify-content: start;
	align-items: center;
`;
export const OfferFooter = styled.div `
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;

	${down('xs')} {
		flex-direction: column;
	}
`;
export const OfferTags = styled.div `
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;

	${down('sm')} {
		flex: 1;
		flex-wrap: wrap;
		align-content: end;

		.ant-tag {
			margin-top: 4px;
		}
	}

	${down('xs')} {
		margin-bottom: 0.5rem;
	}
`;
export const OfferBottomCol = styled.div `
	display: flex;
	justify-content: flex-end;
`;
