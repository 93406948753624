import styled from 'styled-components';
import { Table, Icon as CustomIcon, Panel, Link } from '@src/components/elements';
const mediaFromTablet = '@media screen and (max-width: 768px)';
const mediaFromLaptop = '@media screen and (max-width: 1024px)';
export const StyledPanel = styled(Panel) `
	&& {
		${mediaFromTablet} {
			padding: 1rem;
		}
	}
`;
export const CompanyMainPanel = styled(StyledPanel) `
	&& {
		border-top: none;
		border-radius: 0 0 4px 4px;
	}
`;
export const Main = styled.div `
	width: 100%;
`;
export const Header = styled.div `
	width: 100%;
	height: 200px;
	background: black url(${props => props.background}) no-repeat 50%;
	background-size: cover;
	border-radius: 4px 4px 0 0;

	${mediaFromLaptop} {
		height: 90px;
	}
`;
export const Heading = styled.h2 `
	margin-bottom: 1em;
	color: ${({ theme }) => theme.color.brand1};
	em {
		font-style: normal;
		color: ${({ theme }) => theme.color.gray6};
	}
`;
export const TotalCount = styled.small `
	margin: 0.5rem 0;
	font-size: 0.8rem;
	color: ${({ theme }) => theme.color.gray6};
`;
export const Subheading = styled.h3 `
	font-size: 1.25rem;
	color: ${({ theme }) => theme.color.brand2};
`;
export const Body = styled.div `
	display: flex;
	position: relative;

	${mediaFromLaptop} {
		flex-direction: column;
	}
`;
export const NumbersTitle = styled.div `
	color: ${({ theme }) => theme.color.gray6};
	font-size: 1rem;
	text-align: center;
`;
export const CompanyNumbers = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 2rem;
	width: 100%;
	max-width: 400px;
`;
export const IconWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	width: 44px;
	min-width: 44px;
	border-radius: 50%;
	background: ${({ theme }) => theme.color.gray6};

	path {
		fill: white;
	}
`;
export const SubText = styled.div `
	color: ${({ theme }) => theme.color.gray6};
`;
export const CompanyBlock = styled.div `
	position: relative;
	width: 400px;
	min-width: 400px;
	margin-right: 1rem;
	margin-bottom: 1rem;

	${mediaFromLaptop} {
		width: 100%;
		min-width: 100%;
		margin-right: 0;
		margin-bottom: 0;
	}
`;
export const CompanyContent = styled.div `
	padding-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	${mediaFromLaptop} {
		padding-top: 2.5rem;
	}
`;
export const ImageBlock = styled.div `
	position: absolute;
	top: -75px;
	left: 125px;
	border: 1px solid ${props => props.theme.color.gray4};
	background: white;
	z-index: 1;
	width: 150px;
	height: 150px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;

	${mediaFromLaptop} {
		top: -50px;
		left: calc(50% - 50px);
		width: 100px;
		height: 100px;
	}

	& > * {
		padding: 4px;
		background: white;
		max-width: 100%;
		max-height: 100%;
	}

	svg {
		width: 70%;
		height: 70%;
		color: ${({ theme }) => theme.color.gray4};
	}
`;
export const CompanyInfo = styled.div `
	width: 100%;
`;
export const MoreInfo = styled.div `
	width: 100%;
	display: none;
	${mediaFromLaptop} {
		display: block;
	}
`;
export const SocialButtons = styled.div `
	width: 100%;

	& span.anticon {
		font-size: 2rem;
		color: black;
		margin: 0.4rem;
	}
`;
export const AddressBox = styled.div `
	margin-top: 2rem;
	width: 100%;
	font-size: 1rem;
`;
export const BodyContent = styled.div `
	width: 100%;
	max-width: calc(100% - 415px);

	${mediaFromLaptop} {
		width: 100%;
		max-width: 100%;
	}
`;
export const Description = styled(CompanyMainPanel) `
	margin: auto;
	font-size: 1rem;

	${mediaFromLaptop} {
		display: none;
	}
`;
export const Position = styled(Link) `
	display: block;
	padding: 1em;
	color: ${({ theme }) => theme.color.gray9};
	width: 100%;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.color.gray3};
	overflow: hidden;
	position: relative;

	& + & {
		margin: 1em 0 0 0;
	}

	h2 {
		color: ${({ theme }) => theme.color.brand1};
		font-weight: bold;
		font-size: ${({ theme }) => theme.fontSizes.l};
		margin-bottom: 0.3em;
	}

	.type {
		font-weight: bold;
		color: ${({ theme }) => theme.color.gray7};
	}

	.city {
		color: ${({ theme }) => theme.color.gray7};
	}

	.salary {
		font-size: ${({ theme }) => theme.fontSizes.m};
		color: ${({ theme }) => theme.color.brand1};
		font-weight: bold;
		margin-top: 0.3em;
	}

	.detail {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 2em;
		text-transform: uppercase;
		font-size: 0.9em;
		display: flex;
		align-items: center;
		transition: all 0.2s ease;
		opacity: 0;
		transform: translateX(50px);
		color: ${({ theme }) => theme.color.white};
		background: ${({ theme }) => theme.color.success};
	}

	&:hover {
		cursor: pointer;

		.detail {
			transform: translateX(0);
			opacity: 1;
		}
	}
`;
export const TableBox = styled(Table) `
	margin-top: 1rem;
	word-break: keep-all;

	${mediaFromLaptop} {
		margin: 0;
	}
	${mediaFromTablet} {
		margin: 0;

		td {
			padding: 16px 8px !important;
		}
	}
`;
export const CompanyBox = styled.div `
	margin: 1rem auto;
	background: #929292;
	border-radius: 3px;
`;
export const SocialIcon = styled(CustomIcon) `
	width: 32px;
	svg {
		transition: 0.3s;
	}
`;
export const SocialIconFacebook = styled(SocialIcon) `
	svg {
		color: ${({ theme }) => theme.color.brand2};
	}
	&:hover {
		svg {
			fill: ${({ theme }) => theme.color.facebook};
		}
	}
`;
export const SocialIconInstagram = styled(SocialIcon) `
	svg {
		color: ${({ theme }) => theme.color.brand2};
	}
	&:hover {
		svg {
			fill: ${({ theme }) => theme.color.instagramGradient};
		}
	}
`;
export const SocialIconLinkedIn = styled(SocialIcon) `
	svg {
		color: ${({ theme }) => theme.color.brand2};
	}
	&:hover {
		svg {
			fill: ${({ theme }) => theme.color.linkedin};
		}
	}
`;
export const SocialIconTwitter = styled(SocialIcon) `
	svg {
		color: ${({ theme }) => theme.color.brand2};
	}
	&:hover {
		svg {
			fill: ${({ theme }) => theme.color.twitter};
		}
	}
`;
export const SocialIconYoutube = styled(SocialIcon) `
	svg {
		color: ${({ theme }) => theme.color.brand2};
	}
	&:hover {
		svg {
			fill: ${({ theme }) => theme.color.youtube};
		}
	}
`;
