import React from 'react';
import MoveUp1 from '@public/media/icons/streamline-icon-move-up-1.svg';
import { animateScroll } from 'react-scroll';
import { StickyButton } from '../elements/StickyButton/StickyButton';
const BackToTop = () => {
    return (React.createElement(StickyButton, { onClick: () => {
            animateScroll.scrollToTop();
            return false;
        }, shape: "circle" },
        React.createElement(MoveUp1, { width: 24, height: 24 })));
};
export { BackToTop };
