import React from 'react';
import { Select } from '@src/components/elements';
import { AssetType } from '@src/types/asset';
import { useConfig } from '@src/hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import { AssetLibraryHeaderContainer, AssetLibraryHeaderRow, AssetUsage, FilterAssetTypeSelect, } from './AssetLibraryHeader.style';
import { AssetLibraryActionButtons } from './AssetLibraryActionButtons';
import { AssetLibraryAssetCountInfo } from './AssetLibraryAssetCountInfo';
import { DeleteSelectedAssetsModal } from './DeleteSelectedAssetsModal';
const { Option } = Select;
export function AssetLibraryHeader({ selectedAssetIdsCount, displayedAssetCount, filteredAssetType, setFilteredAssetType, enqueueAssetFileForUpload, isUploadDisabled, activeAssetCount, isDownloadingAssets, isDeletingAssets, deleteSelectedAssets, downloadAndSaveSelectedAssets, isSelectedProcessingVideoAsset, deselectAllAssets, }) {
    const config = useConfig();
    const assetCountLimit = config.assets.countLimit;
    const [isDeleteSelectedAssetsModalOpen, setIsDeleteSelectedAssetsModalOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(AssetLibraryHeaderContainer, null,
            React.createElement(AssetLibraryHeaderRow, null,
                React.createElement(AssetLibraryAssetCountInfo, { displayedAssetCount: displayedAssetCount, activeAssetCount: activeAssetCount }),
                React.createElement(AssetLibraryActionButtons, { selectedAssetIdsCount: selectedAssetIdsCount, isUploadDisabled: isUploadDisabled, enqueueAssetFileForUpload: enqueueAssetFileForUpload, prompSelectedAssetsDeletion: () => setIsDeleteSelectedAssetsModalOpen(true), downloadAndSaveSelectedAssets: downloadAndSaveSelectedAssets, isSelectedProcessingVideoAsset: isSelectedProcessingVideoAsset, isDownloadingAssets: isDownloadingAssets, isDeletingAssets: isDeletingAssets, deselectAllAssets: deselectAllAssets })),
            React.createElement(AssetLibraryHeaderRow, null,
                React.createElement(FilterAssetTypeSelect, { value: filteredAssetType, onChange: value => setFilteredAssetType(value), disabled: isDownloadingAssets || isDeletingAssets },
                    React.createElement(Option, { value: "ALL" },
                        React.createElement(FormattedMessage, { id: "assets.filter.all" })),
                    React.createElement(Option, { value: AssetType.IMAGE },
                        React.createElement(FormattedMessage, { id: "assets.filter.image" })),
                    React.createElement(Option, { value: AssetType.DOCUMENT },
                        React.createElement(FormattedMessage, { id: "assets.filter.document" })),
                    React.createElement(Option, { value: AssetType.VIDEO },
                        React.createElement(FormattedMessage, { id: "assets.filter.video" }))),
                React.createElement(AssetUsage, { shouldDisplayInRed: activeAssetCount >= assetCountLimit },
                    React.createElement(FormattedMessage, { id: "assets.used_capacity" }),
                    ":\u00A0",
                    React.createElement("strong", null,
                        activeAssetCount,
                        "/",
                        assetCountLimit)))),
        React.createElement(DeleteSelectedAssetsModal, { isOpen: isDeleteSelectedAssetsModalOpen, selectedAssetIdsCount: selectedAssetIdsCount, onOk: () => {
                deleteSelectedAssets();
                setIsDeleteSelectedAssetsModalOpen(false);
            }, onCancel: () => setIsDeleteSelectedAssetsModalOpen(false) })));
}
