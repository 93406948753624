import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import { client as axios } from './clients/axios';
export async function getCompanyByInvitationToken(invitationToken) {
    try {
        const response = await axios.get(`/0/company/invitation/${invitationToken}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getCompaniesSuggestions(searchValue) {
    try {
        const response = await axios.get(`/0/finstat/autocomplete?query=${searchValue}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getCompanyInfo(parameterKey, parameterValue) {
    try {
        const response = await axios.get(`/0/finstat/detail?${parameterKey}=${parameterValue}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function registerCompany(citValue, companyRegistrationFormData) {
    const wasInvited = Boolean(citValue);
    const params = wasInvited ? { cit: citValue } : {};
    const response = await axios.post('/0/registration', companyRegistrationFormData, { params });
    socket.emit(SocketEvents.ACCOUNT_REGISTERED, response.data);
    return response.data;
}
export async function registerCandidate(candidateFieldsValues) {
    const response = await axios.post('/0/account', candidateFieldsValues);
    socket.emit(SocketEvents.ACCOUNT_REGISTERED, response.data);
    return response.data;
}
