import styled from 'styled-components';
export const RedText = styled.span `
	color: #f6512c;
	line-height: 1;
	content: '*';
	font-size: 14px;
	font-family: SimSun, sans-serif;
	margin-right: 4px;
	display: inline-block;
`;
export const RequiredWrapper = styled.div `
	padding-top: 2rem;
	border-top: 1px solid ${({ theme }) => theme.color.gray3};
	text-align: center;
	display: flex;
	justify-content: center;
`;
export const QuestionMarkWrapper = styled.div `
	margin-top: 5.2px;
`;
export const SubmitButtonWrapper = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 2.5rem 0 4rem 0;
`;
