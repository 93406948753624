import { client as axios } from '../clients/axios';
export async function getPartners() {
    const response = await axios.get('/0/cms/web-partners');
    return response.data;
}
export async function getTeam() {
    const response = await axios.get('/0/cms/web-team');
    return response.data;
}
export async function getTestimonials({ locale = 'sk' }) {
    const response = await axios.get('/0/cms/web-testimonials', {
        headers: {
            'Accept-Language': locale,
        },
    });
    return response.data;
}
