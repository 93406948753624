import React from 'react';
import { EProductValidationErrorCode } from '@src/types';
import { Alert } from '@src/components/elements';
import { useIntl } from 'react-intl';
export function AdminOrderErrorAlert({ code }) {
    const intl = useIntl();
    let message = intl.formatMessage({ id: 'general.error' });
    let description = intl.formatMessage({ id: 'general.something_went_wrong' });
    if (code === EProductValidationErrorCode.INVALID_PRODUCT) {
        message = intl.formatMessage({ id: 'admin.order.error.product.message' });
        description = intl.formatMessage({ id: 'admin.order.error.product.description' });
    }
    if (code === EProductValidationErrorCode.INVALID_AMOUNT) {
        message = intl.formatMessage({ id: 'admin.order.error.amount.message' });
        description = intl.formatMessage({ id: 'admin.order.error.amount.description' });
    }
    if (code === EProductValidationErrorCode.INVALID_COUPON) {
        message = intl.formatMessage({ id: 'admin.order.error.coupon.message' });
        description = intl.formatMessage({ id: 'admin.order.error.coupon.description' });
    }
    if (code === EProductValidationErrorCode.ALREADY_ACTIVE_PREMIUM_SUBSCRIPTION) {
        message = intl.formatMessage({ id: 'admin.order.error.has_active_subscription.message' });
        description = intl.formatMessage({ id: 'admin.order.error.has_active_subscription.description' });
    }
    return React.createElement(Alert, { className: "m-b-m", type: "error", message: message, description: description });
}
