import { darken } from 'polished';
import styled from 'styled-components';
import { Icon } from '@src/components/elements';
import { up } from 'styled-breakpoints';
export const SocialIconWrapper = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
	min-width: 2rem;
`;
export const SocialIconsContainer = styled.div `
	justify-content: center;
	display: flex;
	gap: 1rem;
`;
export const SocialLinksWrapper = styled.div `
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 1rem 0;
	padding: 1rem;
	border: 1px solid ${({ theme }) => theme.color.gray4};

	${up('md')} {
		margin: 0 0 1rem;
	}
`;
export const TitleWrapper = styled.div `
	color: ${props => props.theme.color.brand3};
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
`;
export const SocialIconLink = styled.a `
	height: 100%;
	width: 100%;
	border-radius: 1rem;
	transition: 0.3s;
	background: ${({ theme }) => theme.color.brand1};
	:hover {
		background: ${({ theme }) => darken(0.1, theme.color.white)};
	}
	svg {
		width: 1rem;
		color: ${({ theme }) => theme.color.white};
	}
`;
export const SocialIcon = styled(Icon) `
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const SocialIconFacebook = styled(SocialIcon) ``;
export const SocialIconInstagram = styled(SocialIcon) ``;
export const SocialIconLinkedIn = styled(SocialIcon) ``;
export const SocialIconTwitter = styled(SocialIcon) ``;
export const SocialIconYoutube = styled(SocialIcon) ``;
