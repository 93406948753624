/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import { AutoComplete, Col, notification, Row, Form } from 'antd';
import _debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { AddressSuggestionsTypes } from '@src/api/address';
import i18nIsoCountriesSkLocale from 'i18n-iso-countries/langs/sk.json';
import i18nIsoCountriesEnLocale from 'i18n-iso-countries/langs/en.json';
import i18nIsoCountriesUkLocale from 'i18n-iso-countries/langs/uk.json';
import { SearchOutlined } from '@ant-design/icons';
import * as Api from '@src/api';
import { useAppContextValue } from '@src/context/app-context';
import { Icon, Input, Select } from '@src/components/elements';
import { normalizeStr } from '@src/helpers/normalize-string';
import { SupportedLanguage } from '@src/types';
import styled from 'styled-components';
i18nIsoCountries.registerLocale(i18nIsoCountriesSkLocale);
i18nIsoCountries.registerLocale(i18nIsoCountriesEnLocale);
i18nIsoCountries.registerLocale(i18nIsoCountriesUkLocale);
function isInternalNamePath(name) {
    return Array.isArray(name);
}
function SuggestionAddressOptionLabel({ type, label }) {
    return (React.createElement("div", { className: "flex-row align-center" },
        type === AddressSuggestionsTypes.Addresses && React.createElement(IconStyled, { type: "pin-location" }),
        type === AddressSuggestionsTypes.Cities && React.createElement(IconStyled, { type: "building-outlined" }),
        label));
}
/**
 * Creates copy of current form data with only provided address values changed
 */
function getNewFieldsValue(currentFields, currentNamePath, newAddressValue) {
    const isAddress = currentFields.hasOwnProperty('google_place_id') &&
        currentFields.hasOwnProperty('formatted_address') &&
        currentFields.hasOwnProperty('country') &&
        currentFields.hasOwnProperty('utcOffset');
    if (isAddress) {
        return Object.assign(Object.assign({}, currentFields), newAddressValue);
    }
    const nextFieldsKey = isInternalNamePath(currentNamePath) ? currentNamePath[0] : currentNamePath;
    const nextFields = currentFields[nextFieldsKey];
    const nextNamePath = isInternalNamePath(currentNamePath) ? currentNamePath.slice(1) : [];
    return Object.assign(Object.assign({}, currentFields), { [nextFieldsKey]: getNewFieldsValue(nextFields, nextNamePath, newAddressValue) });
}
export const AddressFields = ({ baseName, form, formattedAddressLabel, countryLabel, initValues, }) => {
    const [addressSuggestions, setAddressSuggestions] = React.useState([]);
    const appContext = useAppContextValue();
    const intl = useIntl();
    function getFieldName(lastNameSegment) {
        return isInternalNamePath(baseName) ? [...baseName, lastNameSegment] : [baseName, lastNameSegment];
    }
    function setAddressFields(newAddressValue) {
        const newFieldsValue = getNewFieldsValue(form.getFieldsValue(), baseName, newAddressValue);
        form.setFieldsValue(newFieldsValue);
    }
    function showErrorNotification() {
        notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'general.something_went_wrong' }),
        });
    }
    async function fetchAndSetAddressSuggestions(newValue) {
        if (newValue.length < 2) {
            return;
        }
        try {
            const currentCountryCode = form.getFieldValue(getFieldName('country'));
            const addressSuggestionResponse = await Api.Address.getAddressSuggestions(newValue, currentCountryCode);
            setAddressSuggestions(Object.entries(addressSuggestionResponse).map(([key, options]) => ({
                label: intl.formatMessage({ id: key }),
                options: options.map(a => ({
                    value: a.description,
                    id: a.place_id,
                    label: (React.createElement(SuggestionAddressOptionLabel, { type: key, label: a.description })),
                })),
            })));
        }
        catch (err) {
            console.error(err);
            showErrorNotification();
        }
    }
    const debouncedFetchAndSetAddressSuggestions = _debounce(fetchAndSetAddressSuggestions, 300);
    const handleAddressSearch = (newFormattedAddress) => {
        debouncedFetchAndSetAddressSuggestions(newFormattedAddress);
        setAddressFields({ google_place_id: null, formatted_address: newFormattedAddress });
    };
    const onAutoCompleteSelect = async (selectedAddress, option) => {
        const details = await Api.Address.getAddressDetail(option.id);
        setAddressFields({
            google_place_id: option.id,
            formatted_address: selectedAddress,
            utcOffset: details.utcOffset,
        });
        setAddressSuggestions([]);
    };
    const handleCountrySelect = () => {
        setAddressFields({ google_place_id: null, formatted_address: null });
        setAddressSuggestions([]);
    };
    const countries = React.useMemo(() => Object.entries(i18nIsoCountries.getNames(appContext.language)), [appContext.language]);
    React.useEffect(() => {
        const countryByLanguage = {
            [SupportedLanguage.en]: 'GB',
            [SupportedLanguage.sk]: 'SK',
            [SupportedLanguage.uk]: 'UA',
        }[appContext.language];
        const currentAddress = form.getFieldValue(baseName);
        if (!(currentAddress === null || currentAddress === void 0 ? void 0 : currentAddress.country)) {
            setAddressFields({ country: countryByLanguage });
        }
        if (initValues) {
            form.setFieldsValue(initValues);
        }
    }, []);
    function checkAddressGooglePlaceId() {
        const googlePlaceId = form.getFieldValue(getFieldName('google_place_id'));
        if (!googlePlaceId) {
            return Promise.reject(new Error());
        }
        return Promise.resolve();
    }
    return (React.createElement(Row, { gutter: [16, 8] },
        React.createElement(Col, { xs: 24, sm: 6 },
            React.createElement(Form.Item, { label: countryLabel, name: getFieldName('country'), rules: [{ required: true, message: intl.formatMessage({ id: 'global.address.country.fill' }) }], labelCol: { span: 24 }, wrapperCol: { span: 24 } },
                React.createElement(Select, { showSearch: true, optionFilterProp: "children", filterOption: (input, option) => normalizeStr(option.children).indexOf(normalizeStr(input)) >= 0, onChange: handleCountrySelect }, countries.map(([code, name]) => (React.createElement(Select.Option, { value: code, key: code }, name)))))),
        React.createElement(Col, { xs: 24, sm: 18 },
            React.createElement(Form.Item, { label: formattedAddressLabel, name: getFieldName('formatted_address'), required: true, dependencies: [['address', 'google_place_id']], rules: [
                    {
                        validator: checkAddressGooglePlaceId,
                        message: intl.formatMessage({ id: 'global.address.formatted_address.fill' }),
                        validateTrigger: 'onBlur',
                    },
                ], labelCol: { span: 24 }, wrapperCol: { span: 24 } },
                React.createElement(AutoComplete, { onSearch: handleAddressSearch, options: addressSuggestions, onSelect: onAutoCompleteSelect },
                    React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: intl.formatMessage({ id: 'global.address.formatted_address.placeholder' }) })))),
        React.createElement(Form.Item, { name: getFieldName('google_place_id'), hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: getFieldName('utcOffset'), hidden: true },
            React.createElement(Input, null))));
};
const IconStyled = styled(Icon) `
	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
