import React from 'react';
import { Router } from 'react-router';
import { AccountContextProvider } from '@src/context/account-context';
import actions from '@src/actions';
import { useOfferPromotionContext } from '@src/components/pages/admin/modules/offers/offer-promotion-context';
import Routes from './Routes';
function RouterComponent(props) {
    const { store, history } = props;
    const { value: OfferPromotionValue, OfferPromotionContextProvider } = useOfferPromotionContext();
    React.useEffect(() => {
        store.dispatch(actions.mainInitStart());
    }, []);
    return (React.createElement(Router, { history: history },
        React.createElement(AccountContextProvider, null,
            React.createElement(OfferPromotionContextProvider, { value: OfferPromotionValue },
                React.createElement(Routes, null)))));
}
export { RouterComponent };
