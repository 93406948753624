/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { isEqual } from 'lodash';
import * as Api from '@src/api';
import styled from 'styled-components';
import { ABROAD, REMOTE } from '@src/types';
import { normalizeStr } from '@src/helpers/normalize-string';
import { SelectTag } from '@src/components/elements/SelectTag/SelectTag';
import { Icon, TreeSelect } from '@src/components/elements';
const IconStyled = styled(Icon) `
	z-index: 2;
	top: 30%;
	left: 8px;

	svg {
		width: 16px;
		margin-right: 4px;
	}
`;
function buildLocationTreeData(currentSubhierarchy, intl) {
    return currentSubhierarchy.map(location => {
        var _a;
        const result = {
            title: intl.formatMessage({
                id: `${location.translation_key}`,
            }),
            value: location.id,
        };
        if ((_a = location.sublocations) === null || _a === void 0 ? void 0 : _a.length) {
            result.children = buildLocationTreeData(location.sublocations, intl);
        }
        return result;
    });
}
export function LocationSelect({ value, onChange, locationTree, isLoading, className, placeholder, maxSearchLength = 27, setShowLocationPlaceholder, }) {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [fetchedHierarchicalLocations, setFetchedHierarchicalLocations] = React.useState([]);
    const [locationHierarchy, setLocationHierarchy] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const intl = useIntl();
    function showErrorNotification() {
        notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'general.something_went_wrong' }),
        });
    }
    async function initialize() {
        try {
            if (locationTree) {
                setLocationHierarchy(locationTree);
            }
            else {
                const newFetchedHierarchicalLocations = await Api.Location.getHierarchicalLocations();
                setFetchedHierarchicalLocations(newFetchedHierarchicalLocations);
            }
            setIsInitialized(true);
        }
        catch (err) {
            console.error(err);
            showErrorNotification();
        }
    }
    React.useEffect(() => {
        initialize();
    }, []);
    React.useEffect(() => {
        setLocationHierarchy([
            {
                title: intl.formatMessage({ id: 'location.abroad' }),
                value: ABROAD,
            },
            {
                title: intl.formatMessage({ id: 'location.remote' }),
                value: REMOTE,
            },
            ...buildLocationTreeData(fetchedHierarchicalLocations, intl),
        ]);
    }, [fetchedHierarchicalLocations, intl]);
    React.useEffect(() => {
        if (locationTree && !isEqual(locationTree, locationHierarchy)) {
            setLocationHierarchy(locationTree);
        }
    }, [locationTree]);
    const defaultExpandedKeys = React.useMemo(() => {
        const startOfHierarchy = locationHierarchy.find(node => node.value !== ABROAD && node.value !== REMOTE);
        return startOfHierarchy ? [startOfHierarchy.value] : [];
    }, [locationHierarchy]);
    function handleChange(locations) {
        onChange === null || onChange === void 0 ? void 0 : onChange(locations);
        if (!locationTree && setShowLocationPlaceholder) {
            setShowLocationPlaceholder(!locations.length);
        }
    }
    function filterTreeNode(val, treeNode) {
        return normalizeStr(treeNode.title).indexOf(normalizeStr(val)) >= 0;
    }
    React.useEffect(() => {
        if (setShowLocationPlaceholder && !inputValue) {
            setShowLocationPlaceholder(true);
        }
    }, [inputValue]);
    return (React.createElement(TreeSelect, { className: className, treeNodeFilterProp: "title", treeDefaultExpandedKeys: defaultExpandedKeys, filterTreeNode: filterTreeNode, value: value, loading: !isInitialized || isLoading, showArrow: true, style: { width: '100%' }, listHeight: 360, treeData: locationHierarchy, placeholder: placeholder, multiple: true, treeCheckable: true, showCheckedStrategy: TreeSelect.SHOW_PARENT, maxTagCount: "responsive", searchValue: inputValue, onSearch: val => {
            setInputValue(val.substring(0, maxSearchLength));
            if (setShowLocationPlaceholder && val) {
                setShowLocationPlaceholder(false);
            }
        }, 
        // @ts-ignore
        onChange: handleChange, onBlur: () => setInputValue(''), tagRender: tagProps => (React.createElement(SelectTag, Object.assign({ loading: Boolean(isLoading || !isInitialized) }, tagProps),
            React.createElement("div", { className: "flex-inline align-center" },
                React.createElement(IconStyled, { type: "pin-location" }),
                tagProps.label))) }));
}
