import * as Api from '@src/api';
import { notification } from 'antd';
async function failIfCompanyUrlExists(companyUrl, companyId) {
    if (!companyUrl) {
        return;
    }
    try {
        const company = await Api.Company.getCompanyByUrl(companyUrl);
        if (!company.id || companyId === company.id) {
            return;
        }
        throw new Error('exists');
    }
    catch (err) {
        if (err.message === 'exists') {
            throw err;
        }
    }
}
export const companyInfoEditFormRules = (intl, company) => {
    const companyNameRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.name',
            }),
        },
        {
            max: 250,
            message: intl.formatMessage({
                id: 'registration.sync_validation.name_too_long',
            }),
        },
    ];
    const cinRules = [
        {
            max: 8,
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.wrong_cin',
            }),
        },
        {
            pattern: /^[0-9]{1,8}$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_cin',
            }),
        },
    ];
    const tinRules = [
        {
            max: 12,
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.wrong_tin',
            }),
        },
    ];
    const tinVatRules = [
        {
            max: 14,
            min: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.wrong_tin_vat',
            }),
        },
    ];
    const socialLinkRules = [
        {
            max: 50,
            message: intl.formatMessage({
                id: 'registration.sync_validation.long_social_link',
            }),
        },
    ];
    const addressRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.street_address',
            }),
        },
        {
            // eslint-disable-next-line
            pattern: /^[^_~`!@#$%^&*();<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_adress',
            }),
        },
    ];
    const cityRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.city',
            }),
        },
        {
            pattern: /^[^_~`!@#$%^&*().;<>?={}]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_city',
            }),
        },
    ];
    const postalCodeRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.postal_code',
            }),
        },
        {
            min: 4,
            message: intl.formatMessage({
                id: 'registration.sync_validation.too_short',
            }),
        },
        {
            max: 8,
            message: intl.formatMessage({
                id: 'registration.sync_validation.too_long',
            }),
        },
        {
            pattern: /^([a-zA-Z0-9]([\s])*)*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_postal_code',
            }),
        },
    ];
    const descriptionRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.description',
            }),
            transform: (value) => (value || '').replace(/<[^>]*>?/g, '').replace(/\s/g, ''),
        },
    ];
    const areaRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.area',
            }),
        },
        {
            pattern: /^[^0-9_~`!@#$%^&*().;<>?]+$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_postal_code',
            }),
        },
    ];
    const employeeRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'registration.sync_validation.employees',
            }),
        },
        {
            pattern: /^\d+$/g,
            message: intl.formatMessage({
                id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
            }),
        },
    ];
    const companyUrlRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'admin.company_info_edit_validation.fill_company_url',
            }),
        },
        {
            pattern: /^[A-Za-z0-9-]*$/g,
            message: intl.formatMessage({
                id: 'registration.sync_validation.restricted_symbols_company_url',
            }),
        },
        {
            max: 250,
            message: intl.formatMessage({
                id: 'registration.sync_validation.company_url_too_long',
            }),
        },
        {
            validator: async (_, value) => new Promise((resolve, reject) => {
                failIfCompanyUrlExists(value, company.id)
                    .then(() => {
                    resolve(null);
                })
                    .catch(() => {
                    notification.warning({
                        message: intl.formatMessage({
                            id: 'registration.async_validation.company_url_taken',
                        }),
                        description: intl.formatMessage({
                            id: 'registration.async_validation.company_url_used',
                        }),
                    });
                    reject(new Error(intl.formatMessage({
                        id: 'registration.async_validation.company_url_taken',
                    })));
                });
            }),
        },
    ];
    const websiteRules = [
        {
            // eslint-disable-next-line no-useless-escape
            pattern: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/g,
            message: intl.formatMessage({
                id: 'registration.async_validation.wrong_website_format',
            }),
        },
    ];
    return {
        companyNameRules,
        cinRules,
        tinRules,
        addressRules,
        cityRules,
        postalCodeRules,
        descriptionRules,
        employeeRules,
        areaRules,
        companyUrlRules,
        websiteRules,
        tinVatRules,
        socialLinkRules,
    };
};
