import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Link } from '@src/components/elements';
export const NotificationItemWrapper = styled(Link) `
	padding: 0.6rem 3rem 0.6rem 1rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: flex-start;
	transition: all 0.2s ease;
	border-radius: 4px;
	position: relative;

	& + & {
		margin: 0.3rem 0 0 0;
	}

	&,
	&:link,
	&:visited,
	&:focus,
	&:hover,
	&:active {
		color: ${props => props.theme.color.brand1};
	}

	& > * + * {
		margin-left: 0.7rem;
	}
	&:hover,
	&:active {
		background: ${props => transparentize(0.8, props.theme.color.brand4)};
	}
	span {
		margin-top: 0.2rem;
		flex: 1;
	}

	${props => props.isNew &&
    css `
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0.5rem;
				width: 0.7rem;
				height: 0.7rem;
				background: ${props.theme.color.brand2};
				margin-top: -0.35rem;
				border-radius: 1rem;
			}
		`}
`;
export const Content = styled.div `
	color: ${props => props.theme.color.gray6};
	font-weight: normal;

	${props => props.isNew &&
    css `
			color: ${props.theme.color.brand1};
		`}
`;
export const ContentWrapper = styled.div `
	width: 100%;
	text-transform: none;
`;
export const NotificationImageOrIconWrapper = styled.div `
	width: 2rem;
	text-align: center;
	margin-top: 0.3rem;
`;
