import { Big } from 'big.js';
import config from '@src/config';
import { ProductId } from '@src/types';
const coefficientVAT = new Big(100).add(config.pricing.VAT).div(100).toNumber();
const getProductPricing = ({ productCount, couponDiscount = 0, productId, }) => {
    if (new Big(productCount).lte(0)) {
        throw new Error(`${productCount} is not a valid product amount`);
    }
    const DEFAULT_PRICE = new Big(config.pricing.products[productId].levels[0].price);
    const couponDiscountCoefficient = new Big(100).minus(new Big(couponDiscount)).div(new Big(100));
    const pricingLevelsSortedByLimit = [...config.pricing.products[productId].levels].sort((fst, snd) => fst.limit - snd.limit);
    const bestPriceLevel = pricingLevelsSortedByLimit[pricingLevelsSortedByLimit.length - 1];
    const pricingLevel = pricingLevelsSortedByLimit.find(item => new Big(productCount).lte(item.limit));
    const pricePerUnit = pricingLevel ? new Big(pricingLevel.price) : new Big(bestPriceLevel.price);
    if (productId === ProductId.offerCV && productCount >= 20) {
        const maxPrice = new Big(99);
        return {
            productPrice: pricePerUnit.times(coefficientVAT).times(couponDiscountCoefficient).toNumber(),
            productPriceWithoutVat: pricePerUnit.times(couponDiscountCoefficient).toNumber(),
            totalPrice: maxPrice.times(coefficientVAT).times(couponDiscountCoefficient).toNumber(),
            totalPriceWithoutVat: maxPrice.times(couponDiscountCoefficient).toNumber(),
            totalPriceBeforeCouponDiscount: maxPrice.times(coefficientVAT).toNumber(),
            totalPriceBeforeCouponDiscountWithoutVat: maxPrice.toNumber(),
            originalPrice: maxPrice.times(coefficientVAT).toNumber(),
            originalPriceWithoutVat: maxPrice.toNumber(),
            productCouponDiscount: maxPrice
                .times(coefficientVAT)
                .times(couponDiscountCoefficient)
                .minus(maxPrice.times(coefficientVAT))
                .toNumber(),
            productCouponDiscountWithoutVat: maxPrice.times(couponDiscountCoefficient).minus(maxPrice).toNumber(),
            discount: maxPrice.minus(maxPrice).times(coefficientVAT).toNumber(),
            discountWithoutVat: maxPrice.minus(maxPrice).toNumber(),
            discountRelative: new Big(100).minus(pricePerUnit.div(DEFAULT_PRICE).times(100).round()).toNumber(),
        };
    }
    return {
        productPrice: pricePerUnit.times(coefficientVAT).times(couponDiscountCoefficient).toNumber(),
        productPriceWithoutVat: pricePerUnit.times(couponDiscountCoefficient).toNumber(),
        totalPrice: pricePerUnit.times(productCount).times(coefficientVAT).times(couponDiscountCoefficient).toNumber(),
        totalPriceWithoutVat: pricePerUnit.times(productCount).times(couponDiscountCoefficient).toNumber(),
        totalPriceBeforeCouponDiscount: pricePerUnit.times(productCount).times(coefficientVAT).toNumber(),
        totalPriceBeforeCouponDiscountWithoutVat: pricePerUnit.times(productCount).toNumber(),
        originalPrice: DEFAULT_PRICE.times(productCount).times(coefficientVAT).toNumber(),
        originalPriceWithoutVat: DEFAULT_PRICE.times(productCount).toNumber(),
        productCouponDiscount: pricePerUnit
            .times(productCount)
            .times(coefficientVAT)
            .times(couponDiscountCoefficient)
            .minus(pricePerUnit.times(productCount).times(coefficientVAT))
            .toNumber(),
        productCouponDiscountWithoutVat: pricePerUnit
            .times(productCount)
            .times(couponDiscountCoefficient)
            .minus(pricePerUnit.times(productCount))
            .toNumber(),
        discount: DEFAULT_PRICE.times(productCount)
            .minus(pricePerUnit.times(productCount))
            .times(coefficientVAT)
            .toNumber(),
        discountWithoutVat: DEFAULT_PRICE.times(productCount).minus(pricePerUnit.times(productCount)).toNumber(),
        discountRelative: new Big(100).minus(pricePerUnit.div(DEFAULT_PRICE).times(100).round()).toNumber(),
    };
};
export { getProductPricing };
