export var ProductId;
(function (ProductId) {
    ProductId[ProductId["cvCredits"] = 1] = "cvCredits";
    ProductId[ProductId["testCredits"] = 2] = "testCredits";
    ProductId[ProductId["offerCredits"] = 3] = "offerCredits";
    ProductId[ProductId["companyPremium"] = 4] = "companyPremium";
    ProductId[ProductId["offerPromotion"] = 5] = "offerPromotion";
    ProductId[ProductId["offerCV"] = 6] = "offerCV";
})(ProductId || (ProductId = {}));
export var SubscriptionPeriods;
(function (SubscriptionPeriods) {
    SubscriptionPeriods[SubscriptionPeriods["OneMonthPeriod"] = 1] = "OneMonthPeriod";
    SubscriptionPeriods[SubscriptionPeriods["SixMonthsPeriod"] = 6] = "SixMonthsPeriod";
    SubscriptionPeriods[SubscriptionPeriods["TwelveMonthsPeriod"] = 12] = "TwelveMonthsPeriod";
})(SubscriptionPeriods || (SubscriptionPeriods = {}));
export var ProductType;
(function (ProductType) {
    ProductType["credit"] = "credit";
    ProductType["subscription"] = "subscription";
    ProductType["promotion"] = "promotion";
})(ProductType || (ProductType = {}));
export var EProductValidationErrorCode;
(function (EProductValidationErrorCode) {
    EProductValidationErrorCode["INVALID_AMOUNT"] = "INVALID_AMOUNT";
    EProductValidationErrorCode["INVALID_PRODUCT"] = "INVALID_PRODUCT";
    EProductValidationErrorCode["INVALID_COUPON"] = "INVALID_COUPON";
    EProductValidationErrorCode["ALREADY_ACTIVE_PREMIUM_SUBSCRIPTION"] = "ALREADY_ACTIVE_PREMIUM_SUBSCRIPTION";
})(EProductValidationErrorCode || (EProductValidationErrorCode = {}));
