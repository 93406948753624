import config from '@src/config';
import { OfferFilterType } from '@src/types';
import { client as axios } from './clients/axios';
export async function getOffers(selectedFilterItems) {
    const response = await axios.get(`/0/offers`, {
        params: {
            typ: selectedFilterItems[OfferFilterType.type],
            loc: selectedFilterItems[OfferFilterType.location],
            job: selectedFilterItems[OfferFilterType.jobArea],
            salaryMin: selectedFilterItems[OfferFilterType.salaryMin],
            salaryMax: selectedFilterItems[OfferFilterType.salaryMax],
            salaryRate: selectedFilterItems[OfferFilterType.salaryRate],
            co: selectedFilterItems[OfferFilterType.searchCompaniesSelected],
            kw: selectedFilterItems[OfferFilterType.searchKeywordsSelected],
            page: selectedFilterItems.page,
        },
    });
    return response.data;
}
export async function getHighestSalaryByRate(salaryRate) {
    const response = await axios.get('/0/offers/highest-salary', {
        params: {
            salaryRate,
        },
    });
    return response.data;
}
export async function getSearchSuggestions(searchedText) {
    const response = await axios.get(`${config.api}/0/offers/search-suggestions`, {
        params: { searchedText },
    });
    return response.data;
}
export async function getPromotedOffers(selectedFilterItems) {
    const response = await axios.get('/0/offers/promoted', {
        params: {
            typ: selectedFilterItems[OfferFilterType.type],
            loc: selectedFilterItems[OfferFilterType.location],
            job: selectedFilterItems[OfferFilterType.jobArea],
            salaryMin: selectedFilterItems[OfferFilterType.salaryMin],
            salaryMax: selectedFilterItems[OfferFilterType.salaryMax],
            salaryRate: selectedFilterItems[OfferFilterType.salaryRate],
            co: selectedFilterItems[OfferFilterType.searchCompaniesSelected],
            kw: selectedFilterItems[OfferFilterType.searchKeywordsSelected],
        },
    });
    return response.data;
}
export async function getAllPromotedOffers() {
    const response = await axios.get('/0/offers/all-promoted');
    return response.data;
}
export async function getOfferDetail({ offerId, offerSlug }) {
    const response = await axios.get('/0/offer', { params: { id: offerId, slug: offerSlug } });
    return response.data;
}
export async function getSuggestedSameCompanyOffers(offerId) {
    const response = await axios.get('/0/offer-detail/offer-suggestions-company', { params: { offerId } });
    return response.data;
}
export async function getSuggestedSimilarOffers(offerId) {
    const response = await axios.get('/0/offer-detail/offer-suggestions-similar', { params: { offerId } });
    return response.data;
}
export async function getAllOffersCount() {
    return (await axios.get('/0/offers/count-all')).data;
}
