import React from 'react';
const HeaderMenuContext = React.createContext({
    isMenuSticked: false,
    setIsMenuSticked: () => { },
});
export const useHeaderMenuContextProvider = () => {
    const [isMenuSticked, setIsMenuSticked] = React.useState(false);
    return {
        HeaderMenuContextProvider: HeaderMenuContext.Provider,
        value: {
            isMenuSticked,
            setIsMenuSticked,
        },
    };
};
export const useHeaderMenuContextValue = () => {
    const context = React.useContext(HeaderMenuContext);
    return context;
};
