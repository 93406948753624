import { Input } from '@src/components/elements';
import { CompanyRegistrationFormField } from '@src/types';
import { Col, Row, Form } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
export function LoginCredentialsForm({ intl, rules, wasNotInvitedOrInvitationWasUsed, form, }) {
    const { emailRules, passwordRules, repeatPasswordRules } = rules;
    const [isInitialized, setIsInitialized] = React.useState(false);
    React.useEffect(() => {
        setIsInitialized(true);
    }, []);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: "form__title" },
            React.createElement(FormattedMessage, { id: "registration.registration_form.login_credentials" })),
        React.createElement(Form.Item, { label: intl.formatMessage({
                id: 'registration.registration_form.email',
            }), name: CompanyRegistrationFormField.email, rules: emailRules, validateTrigger: "onBlur" },
            React.createElement(Input, { disabled: !wasNotInvitedOrInvitationWasUsed && form.getFieldValue('email'), type: "email" })),
        React.createElement("div", { className: "form__row" },
            React.createElement(Row, { gutter: [16, 0] },
                React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                    React.createElement(Form.Item, { label: intl.formatMessage({
                            id: 'registration.registration_form.password',
                        }), name: CompanyRegistrationFormField.password, rules: passwordRules, validateTrigger: "onBlur" },
                        React.createElement(Input.Password, { autoComplete: "off" }))),
                React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                    React.createElement(Form.Item, { label: intl.formatMessage({
                            id: 'registration.registration_form.passwordRepeat',
                        }), name: CompanyRegistrationFormField.password_repeat, rules: repeatPasswordRules, validateTrigger: "onBlur" },
                        React.createElement(Input.Password, { autoComplete: "off" })))))));
}
