import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Link, Panel, PremiumIcon } from '@src/components/elements';
import IconAdd from '@public/media/icons/streamline-icon-add.svg';
import IconCheck from '@public/media/icons/streamline-icon-check.svg';
import IconBuildings from '@public/media/icons/streamline-icon-buildings-1.svg';
import IconPencil from '@public/media/icons/streamline-icon-pencil.svg';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { useHistory } from 'react-router';
import * as Api from '@src/api';
import { useTheme } from 'styled-components';
import { getHasCompanyActivePremiumCompanyProfileSubscription } from '@src/helpers/premium-subscription';
import { useCompanyProfileBoxInfo } from './use-company-profile-box-info';
import { ImageBlockWrapper, CompanyName, Header, CompanyNumbers, CompanyNumbersTitle, ImageBlock, ProfileBoxBody, PrimaryColorWrapper, CompanyNumberBoxDiv, EditCompanyButton, PremiumTextWrapper, } from './CompanyProfileBox.style';
export function CompanyProfileBox({ company, className, companyOfferCount, isCompanyAccountFromCompany }) {
    const [localCompany, setLocalCompany] = React.useState(company);
    const [isRefetchCompanyLoading, setIsRefetchCompanyLoading] = React.useState(false);
    const history = useHistory();
    const theme = useTheme();
    const imageBackgroundUrl = localCompany.titleImageUrl
        ? getCdnImageUrl({
            file: `web/company/${localCompany.titleImageUrl}`,
            transformation: 'w_1172,h_200,c_limit',
        })
        : '/media/images/background-gradient.jpg';
    React.useEffect(() => {
        setLocalCompany(company);
    }, [company]);
    const { isAccountSubscribed, subscribersCount, toggleSubscription, isSubscriptionLoading } = useCompanyProfileBoxInfo(localCompany);
    const refetchCompany = React.useCallback(async () => {
        if (!localCompany.id) {
            return;
        }
        try {
            setIsRefetchCompanyLoading(true);
            const freshCompany = await Api.Company.getCompanyById(localCompany.id);
            setLocalCompany(freshCompany);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsRefetchCompanyLoading(false);
        }
    }, [localCompany]);
    React.useEffect(() => {
        refetchCompany();
    }, [subscribersCount]);
    /* @ts-ignore */
    const goToSettings = () => isCompanyAccountFromCompany && history.pushLocalized(`/admin/firemny-profil`);
    const hasActivePremiumSubscription = getHasCompanyActivePremiumCompanyProfileSubscription(localCompany.companyPaymentSubscriptions);
    return (React.createElement(Panel, { className: `${className} p-none` },
        React.createElement(Header, { isInAdmin: isCompanyAccountFromCompany, onClick: goToSettings, className: "flex-center", background: imageBackgroundUrl }),
        React.createElement(ImageBlockWrapper, null,
            React.createElement(ImageBlock, { isInAdmin: isCompanyAccountFromCompany, onClick: goToSettings },
                company.image && (React.createElement("img", { src: getCdnImageUrl({
                        file: `web/company/${company.image}`,
                        transformation: 'w_140,h_140,c_limit',
                    }), alt: `Logo: ${company.name}` })),
                !company.image && React.createElement(IconBuildings, { viewBox: "0 0 24 24" }))),
        React.createElement(ProfileBoxBody, null,
            React.createElement("div", null,
                hasActivePremiumSubscription && (React.createElement("div", { className: "flex-row align-center justify-center m-b-s" },
                    React.createElement("div", { className: "m-r-s" },
                        React.createElement(PremiumIcon, { isInCircle: true, color: theme.color.brand1 })),
                    React.createElement(PremiumTextWrapper, null,
                        React.createElement(FormattedMessage, { id: "offer_detail.premium_company" })))),
                React.createElement(CompanyName, null,
                    React.createElement(Link, { to: `/firma/${company.companyUrl}` }, company.name))),
            React.createElement(CompanyNumbers, null,
                React.createElement(CompanyNumberBoxDiv, null,
                    React.createElement(CompanyNumbersTitle, null,
                        React.createElement(FormattedMessage, { id: "company.profile_box.offers" })),
                    React.createElement("div", { className: "font-size-xl font-bold" }, companyOfferCount || 0)),
                React.createElement(CompanyNumberBoxDiv, null,
                    React.createElement(CompanyNumbersTitle, null,
                        React.createElement(FormattedMessage, { id: "company.profile_box.followers" })),
                    React.createElement("div", { className: "font-size-xl font-bold" }, subscribersCount))),
            React.createElement("div", { className: "flex-col justify-space-around p-t-m" },
                !isCompanyAccountFromCompany && (React.createElement(Button, { className: "m-b-s flex-center", size: "large", loading: isSubscriptionLoading || isRefetchCompanyLoading, icon: isAccountSubscribed ? (React.createElement(IconCheck, { viewBox: "0 0 24 24", width: 16 })) : (React.createElement(IconAdd, { viewBox: "0 0 24 24", width: 16 })), onClick: toggleSubscription },
                    React.createElement("span", { className: "m-l-s" },
                        !isAccountSubscribed && (React.createElement(FormattedMessage, { id: "company.subscription", defaultMessage: "Odber nov\u00FDch pon\u00FAk" })),
                        isAccountSubscribed && React.createElement(FormattedMessage, { id: "company.subscription.active" })))),
                isCompanyAccountFromCompany && (React.createElement(Link, { to: "/admin/firemny-profil" },
                    React.createElement(EditCompanyButton, { size: "large", type: "primary" },
                        React.createElement(IconPencil, { viewBox: "0 0 24 24", width: 14, className: "m-r-s" }),
                        React.createElement(FormattedMessage, { id: "company.profile_box.edit_company_profile" })))),
                React.createElement(Button, { className: "flex-center", 
                    /* @ts-ignore */
                    onClick: () => history.pushLocalized(`/firma/${company.companyUrl}`), type: "default" },
                    React.createElement(PrimaryColorWrapper, null,
                        React.createElement(FormattedMessage, { id: "offer_detail.company_profile", defaultMessage: "Profil Firmy" })))))));
}
