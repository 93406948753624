import React from 'react';
import styled from 'styled-components';
import { BulletListExtension, HardBreakExtension, BoldExtension, ItalicExtension, UnderlineExtension, LinkExtension, OrderedListExtension, } from 'remirror/extensions';
import { ComponentItem, EditorComponent, Remirror, ThemeProvider, Toolbar, useRemirror, } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/styled-components';
import { prosemirrorNodeToHtml } from 'remirror';
import { useIsFirstRender } from '@src/hooks/useIsFirstRender';
const toolbarItems = [
    {
        type: ComponentItem.ToolbarGroup,
        label: 'Simple Formatting',
        items: [
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: 'toggleBold',
                display: 'icon',
                displayShortcut: false,
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: 'toggleItalic',
                display: 'icon',
                displayShortcut: false,
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: 'toggleUnderline',
                display: 'icon',
                displayShortcut: false,
            },
        ],
    },
    {
        type: ComponentItem.ToolbarGroup,
        label: 'Lists',
        items: [
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: 'toggleBulletList',
                display: 'icon',
                displayShortcut: false,
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: 'toggleOrderedList',
                display: 'icon',
                displayShortcut: false,
            },
        ],
    },
];
const extensions = () => [
    new BulletListExtension(),
    new OrderedListExtension(),
    new LinkExtension(),
    new HardBreakExtension(),
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
];
const RemirrorEditorWrapper = styled.div `
	.remirror-editor {
		overflow-y: auto !important;
		border: 1px solid ${({ theme }) => theme.color.gray4};
		box-shadow: none !important;
	}
`;
export function RichTextEditor({ value, editable, onChange }) {
    const isFirstRender = useIsFirstRender();
    const { manager, state, onChange: onRemirrorChange, } = useRemirror({
        extensions,
        selection: 'end',
        stringHandler: 'html',
        content: value,
    });
    const htmlString = prosemirrorNodeToHtml(state.doc);
    React.useEffect(() => {
        // we want to skip the first render
        if (!isFirstRender) {
            onChange(htmlString);
        }
    }, [htmlString]);
    return (React.createElement("div", { className: "remirror-theme" },
        React.createElement(ThemeProvider, null,
            React.createElement(AllStyledComponent, null,
                React.createElement(Remirror, { manager: manager, state: state, onChange: onRemirrorChange, editable: editable },
                    React.createElement(Toolbar, { items: toolbarItems, refocusEditor: true }),
                    React.createElement(RemirrorEditorWrapper, null,
                        React.createElement(EditorComponent, null)))))));
}
