import { omit } from 'lodash';
import { client as axios } from './clients/axios';
export async function getMyOffers() {
    try {
        const response = await axios.get('/0/offer/my-offers');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function patchOfferTitle(id, value) {
    try {
        const response = await axios.patch(`/0/offer/${id}/title`, { title: value });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getOfferApplications(id) {
    try {
        const response = await axios.get(`/0/offer/${id}/offer-applications`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function buyProductByCard({ order, orderParams, savePaymentMethod, billingProfile, nonce, couponCode, deviceData, offerId, }) {
    try {
        const response = await axios.post(`/0/company/buy-product-by-card`, {
            order,
            orderParams,
            nonce,
            savePaymentMethod,
            billingProfile: omit(billingProfile, 'offers'),
            couponCode,
            deviceData,
            offerId,
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function deletePaymentMethod() {
    try {
        const response = await axios.delete(`/0/company/payment-method`, {});
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getPaymentMethods() {
    try {
        const response = await axios.get(`/0/company/payment-method`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getCompanyAccounts() {
    try {
        const response = await axios.get(`/0/company/accounts`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getClientToken() {
    try {
        const response = await axios.get(`/0/company/client-token`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getPaymentMethodNonce() {
    try {
        const response = await axios.get(`/0/company/payment-method-nonce`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function createPaymentMethod({ paymentMethodData, }) {
    await axios.post(`/0/company/payment-method`, { paymentMethodData });
}
export async function createInvitation(emails) {
    try {
        const response = await axios.post(`/0/company/invitation`, { emails });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function removeInvitation(invitationId) {
    try {
        const response = await axios.delete(`/0/company/invitation/${invitationId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function changeUserRole(userId, role) {
    try {
        const response = await axios.patch(`/0/company/account/${userId}`, { role });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function deleteUser(userId) {
    try {
        const response = await axios.delete(`/0/company/account/${userId}`);
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getCompanyEmptyFields() {
    try {
        const response = await axios.get('/0/company/empty-fields');
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function validateCoupon(code, productIds, offerId) {
    try {
        const response = await axios.post('/0/coupon/validate', { code, productIds, offerId });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function applyCoupon(code, productIds, offerId) {
    try {
        const response = await axios.post('/0/coupon/apply', { code, productIds, offerId });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function finishHiringProcess(offerId, notifyRejectedApplicants) {
    try {
        const response = await axios.patch('/0/company/finish-hiring-process', { offerId, notifyRejectedApplicants });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function updateColleaguesOffers() {
    await axios.get('/0/company/update-colleagues-offers');
}
export async function getCompanyPremiumSettings() {
    const response = await axios.get('/0/company/premium-settings');
    return response.data;
}
export async function getOrderContent({ orderParams }) {
    const response = await axios.get('/0/company/order-content', { params: orderParams });
    return response.data;
}
export async function generateNewApiKey() {
    return (await axios.post('/0/company-api-key', {})).data;
}
