import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNotificationContextValue } from '@src/context/notification-context';
import { getLanguageFromPathname } from '@shared/localized-path';
import { useLocation } from 'react-router';
const Head = (props) => {
    const intl = useIntl();
    const NotificationContext = useNotificationContextValue();
    const location = useLocation();
    const locale = getLanguageFromPathname(location.pathname);
    const { unseenNotificationsCount } = NotificationContext;
    const notificationsTitleString = unseenNotificationsCount > 0 ? `(${unseenNotificationsCount}) ` : '';
    const id = props.id || 'app';
    const defaultImage = locale === 'uk'
        ? `${process.env.PUBLIC_URL}/media/images/meta-homepage-uk.jpg`
        : `${process.env.PUBLIC_URL}/media/images/meta-homepage.jpg`;
    const imageUrl = props.imageUrl || defaultImage;
    const title = props.title
        ? `${notificationsTitleString}${props.title}`
        : `${notificationsTitleString}${intl.formatMessage({ id: `${id}.head.title` })}`;
    const titleSocial = props.customSocialTitle ? intl.formatMessage({ id: `${id}.head.title.social` }) : title;
    const description = props.description || intl.formatMessage({ id: `${id}.head.description` });
    const descriptionSocial = props.customSocialDescription
        ? intl.formatMessage({ id: `${id}.head.description.social` })
        : description;
    return (React.createElement(Helmet, null,
        React.createElement("title", null, title),
        React.createElement("meta", { property: "og:title", content: titleSocial }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:description", content: descriptionSocial }),
        React.createElement("meta", { property: "og:type", content: "article" }),
        React.createElement("meta", { property: "og:image", content: imageUrl })));
};
export { Head };
