import React from 'react';
import { ErrorBoundaryContents } from './components/ErrorBoundaryContents';
const initialState = {
    timestamp: null,
    error: null,
};
export class ErrorBoundary extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = initialState;
    }
    componentDidCatch(error) {
        this.setState({ error: error, timestamp: new Date() });
    }
    handleHome() {
        window.location.href = '/';
    }
    handleRefresh() {
        window.location.reload();
    }
    render() {
        const { error, timestamp } = this.state;
        const { children } = this.props;
        if (error) {
            return React.createElement(ErrorBoundaryContents, { error: error, timestamp: timestamp });
        }
        return children;
    }
}
