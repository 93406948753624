import React from 'react';
const ESCAPE_KEYCODE = 27;
const ARROW_LEFT_KEYCODE = 37;
const ARROW_RIGHT_KEYCODE = 39;
export function useKeyboardControlls({ onArrowLeft, onArrowRight, onEscape, }) {
    const handleKeydown = React.useCallback((e) => {
        if (e.keyCode === ESCAPE_KEYCODE) {
            onEscape();
        }
        if (e.keyCode === ARROW_LEFT_KEYCODE) {
            onArrowLeft();
        }
        if (e.keyCode === ARROW_RIGHT_KEYCODE) {
            onArrowRight();
        }
    }, [onArrowLeft, onArrowRight, onEscape]);
    React.useEffect(() => {
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [handleKeydown]);
}
