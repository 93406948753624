import { SearchFilterOptionGroup } from '@src/types';
export function formatSearchSelectValues(specificSearch) {
    if (!specificSearch) {
        return { companyIds: [], skillTagIds: [], keywords: [] };
    }
    const companyIds = specificSearch
        .filter(({ group }) => group === SearchFilterOptionGroup.company)
        .map(({ value: companyId }) => Number(companyId));
    const skillTagIds = specificSearch
        .filter(({ group }) => group === SearchFilterOptionGroup.skillTag)
        .map(({ value: skillTagId }) => Number(skillTagId));
    const keywords = specificSearch
        .filter(({ group }) => group === SearchFilterOptionGroup.keyword)
        .map(({ value: keyword }) => keyword.toString());
    return { companyIds, skillTagIds, keywords };
}
