import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Filter } from './filter/Filter';
import { ToggleFilterContentButton } from './Filters.style';
import { FilterSeparator, FilterTitle } from './filter/Filter.style';
import { useOffersFilterContextValue } from '../../offer-filter-context';
const TypeFilter = ({ typeFilterItems, loading }) => {
    const intl = useIntl();
    const { typeFiltersSelected, setTypeFiltersSelected } = useOffersFilterContextValue();
    const [isOpen, setIsOpen] = React.useState(false);
    const areCurrentFiltersNotEmpty = Boolean(typeFiltersSelected && typeFiltersSelected.length !== 0);
    const defaultTypeFiltersShownNum = 6;
    const toggleOfferType = (id) => {
        let newSelectedTypesIds = [];
        if (typeFiltersSelected.includes(id)) {
            newSelectedTypesIds = typeFiltersSelected.filter(typeId => typeId !== id);
        }
        else {
            newSelectedTypesIds = [...typeFiltersSelected, id];
        }
        setTypeFiltersSelected(newSelectedTypesIds);
    };
    const typeFilters = React.useMemo(() => typeFilterItems
        .map(filter => (Object.assign(Object.assign({}, filter), { isDisabled: filter.count === 0 })))
        .sort((a, b) => {
        if (a.translationKey && b.translationKey) {
            return intl
                .formatMessage({ id: `${a.translationKey}` })
                .localeCompare(intl.formatMessage({ id: `${b.translationKey}` }));
        }
        return a.name.localeCompare(b.name);
    })
        .sort((a, b) => {
        if (a.isDisabled && !b.isDisabled) {
            return 1;
        }
        if (!a.isDisabled && b.isDisabled) {
            return -1;
        }
        return 0;
    })
        .slice(0, isOpen ? typeFilterItems.length : defaultTypeFiltersShownNum), [intl, typeFilterItems, isOpen]);
    return (React.createElement("div", null,
        React.createElement(FilterTitle, { noMargin: true },
            React.createElement(FormattedMessage, { id: "offers.filter.type" })),
        React.createElement(FilterSeparator, null),
        React.createElement("div", { className: "m-b-l font-size-m" },
            typeFilters.map(filterItem => (React.createElement(Filter, { key: filterItem.id, filterItem: filterItem, loading: loading, areCurrentFiltersNotEmpty: areCurrentFiltersNotEmpty, isSelected: typeFiltersSelected.includes(filterItem.id), isDisabled: filterItem.isDisabled, toggleFilter: toggleOfferType }))),
            typeFilterItems.length > defaultTypeFiltersShownNum && (React.createElement(ToggleFilterContentButton, { onClick: () => setIsOpen(!isOpen), type: "button" },
                isOpen && React.createElement(FormattedMessage, { id: "general.hide_more_options" }),
                !isOpen && (React.createElement(React.Fragment, null,
                    React.createElement(FormattedMessage, { id: "general.see_more_options" }),
                    ` (${typeFilterItems.length - defaultTypeFiltersShownNum})`)))))));
};
export { TypeFilter };
