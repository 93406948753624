/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useIntl } from 'react-intl';
import { Timestamp } from '@src/components/elements';
import { getLocalizedPath, getLanguageFromPathname } from '@src/shared/localized-path';
import Slug from '@src/shared/slug';
import { NotificationType, EOfferApplicationStage, NotificationSenderAccountTypeMap, } from '@src/types';
import { NotificationImageOrIcon } from '@src/components/modules/global/NotificationImageOrIcon';
import { getCdnImageUrlForAccountType } from '@src/helpers/get-cdn-image-url-for-account-type';
import { Content, ContentWrapper, NotificationImageOrIconWrapper, NotificationItemWrapper, } from './NotificationItem.style';
function getNotificationLink(notificationType, context) {
    let page = '/';
    const locale = typeof window !== 'undefined' ? getLanguageFromPathname(window.location.pathname) : 'sk';
    switch (notificationType) {
        case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION: {
            const c = context;
            page = `/ponuka/${new Slug({ text: c.offerTitle, suffix: c.offerId }).result}`;
            break;
        }
        case NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION: {
            const c = context;
            page = `/ponuka/${new Slug({ text: c.offerTitle, suffix: c.offerId }).result}`;
            break;
        }
        case NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION: {
            const c = context;
            page = `/firma/${c.companyUrl}`;
            break;
        }
        case NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION: {
            page = `/ucet`;
            break;
        }
        case NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION: {
            const c = context;
            page = `/admin/vyberovy-proces/${c.offerId}`;
            break;
        }
        case NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION: {
            const c = context;
            page = `/admin/vyberovy-proces/${c.offerId}`;
            break;
        }
        case NotificationType.CREDITS_EXPIRATION_NOTIFICATION: {
            page = '/admin/kredity';
            break;
        }
        case NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION: {
            page = `/ucet`;
            break;
        }
        case NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION: {
            page = '/ucet';
            break;
        }
        case NotificationType.CREDITS_PAID_NOTIFICATION: {
            page = '/admin/platby';
            break;
        }
        case NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION: {
            page = '/admin';
            break;
        }
        default:
            page = '/';
    }
    return getLocalizedPath(page, locale);
}
function getNotificationContent(intl, notificationTypeId, context) {
    switch (notificationTypeId) {
        case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.new_company_offer.menu' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.new_subscription_offer' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.new_company_subscriber.content' });
        case NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION: {
            const { stage, companyName, offerTitle, offerId } = context;
            const result = stage === EOfferApplicationStage.hired
                ? intl.formatMessage({ id: 'global.notification.hired' })
                : intl.formatMessage({ id: 'global.notification.rejected' });
            const contextWithTranslatedStage = {
                companyName,
                offerTitle,
                stage: result,
                offerId,
            };
            return intl.formatMessage({ id: 'global.notification.hiring_process_result' }, Object.assign(Object.assign({}, contextWithTranslatedStage), { strong: chunks => React.createElement("strong", null, chunks) }));
        }
        case NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.hiring_process_closed' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.new_offer_application.menu' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.CREDITS_EXPIRATION_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.credits_expiration.content' });
        case NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.candidate_progress.interview' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.candidate_progress.viewed' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.CREDITS_PAID_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.paid_credits' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        case NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.offer_deadline.menu' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        default:
            return '';
    }
}
function NotificationItem({ notification, onClick }) {
    const intl = useIntl();
    const now = new Date();
    const context = notification.context ? JSON.parse(notification.context) : {};
    const notificationSenderAccountType = NotificationSenderAccountTypeMap[notification.notification_type_id];
    const imageUrl = getCdnImageUrlForAccountType(notification.image, notificationSenderAccountType, 'c_scale,w_30');
    return (React.createElement("span", { onClick: onClick },
        React.createElement(NotificationItemWrapper, { to: getNotificationLink(notification.notification_type_id, context), isNew: !notification.clicked_at },
            React.createElement(NotificationImageOrIconWrapper, null,
                React.createElement(NotificationImageOrIcon, { notificationType: notification.notification_type_id, imageUrl: imageUrl, isNew: !notification.clicked_at })),
            React.createElement(ContentWrapper, null,
                React.createElement(Content, { isNew: !notification.clicked_at }, getNotificationContent(intl, notification.notification_type_id, context)),
                React.createElement(Timestamp, { now: now, date: new Date(notification.created_at) })))));
}
export { NotificationItem };
