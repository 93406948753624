import { Separator } from '@src/components/elements';
import styled, { css } from 'styled-components';
export const FilterTitle = styled.h2 `
	color: ${({ theme }) => theme.color.brand1};
	font-size: 1rem;

	${({ noMargin }) => !noMargin &&
    css `
			margin-top: 2rem;
		`}
`;
export const FilterSeparator = styled(Separator) `
	margin: 0.5rem 0 1rem;
`;
export const FilterCount = styled.span `
	color: ${({ theme }) => theme.color.gray5}; ;
`;
