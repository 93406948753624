import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import actions from 'actions';
import MenuHeader from '@src/components/modules/navigation/Header';
import { Footer } from '@src/components/modules/navigation/Footer';
import CookieConsent from 'components/modules/global/cookie-consent';
import { AlertBar } from '@src/components/modules/global/AlertBar/AlertBar';
import { AccountAuthenticationModal } from '@src/components/modules/AccountAuthenticationModal';
import { Head } from '@src/components/elements';
import { useAuthContextProvider } from '@src/context/auth-context';
import { routes } from '@src/router/Routes';
import { useLocation, matchPath } from 'react-router';
import { useSavedOffersContextProvider } from '@src/context/saved-offer-context';
import { useHeaderMenuContextProvider } from '@src/context/header-menu-context';
import { PremiumCompanyProfileSubscriptionModal } from '@src/components/pages/admin/modules/company-subscription/PremiumCompanyProfileSubscriptionModal';
import { usePremiumCompanyProfileModalContextProvider } from '@src/context/premium-company-profile-modal-context';
import { BackToTop } from './BackToTop';
export const MainSection = styled.div `
	flex: 1 0 auto;
	background: ${props => props.theme.color.background};
	${props => !props.withHero &&
    css `
			padding-top: ${props.theme.size.headerHeight}px; /* to handle fixed header */
		`}
`;
const LayoutMain = props => {
    var _a;
    // @ts-ignore
    const cookieConsent = useSelector(state => state.app.cookieConsent);
    const dispatch = useDispatch();
    const location = useLocation();
    const { value: authContextValue, AuthContextProvider } = useAuthContextProvider();
    const { value: savedOfferContextValue, SavedOffersContextProvider } = useSavedOffersContextProvider();
    const { value: headerMenuContextValue, HeaderMenuContextProvider } = useHeaderMenuContextProvider();
    const { value: PremiumCompanyProfileModalContextValue, PremiumCompanyProfileModalContextProvider } = usePremiumCompanyProfileModalContextProvider();
    const matchedTopLevelRoute = routes.find(routeObj => {
        const paths = Object.entries(routeObj.paths).map(([locale, path]) => locale !== 'sk' ? `/${locale}${path}` : path);
        const match = matchPath(location.pathname, {
            path: paths,
            exact: !routeObj.routes,
        });
        return Boolean(match);
    });
    return (React.createElement(AuthContextProvider, { value: authContextValue },
        React.createElement(SavedOffersContextProvider, { value: savedOfferContextValue },
            React.createElement(PremiumCompanyProfileModalContextProvider, { value: PremiumCompanyProfileModalContextValue },
                React.createElement(HeaderMenuContextProvider, { value: headerMenuContextValue },
                    React.createElement(Layout, null,
                        React.createElement(AlertBar, null),
                        !cookieConsent && React.createElement(CookieConsent, { onAgree: () => dispatch(actions.cookieConsentStart()) }),
                        React.createElement(Head, null),
                        React.createElement(MenuHeader, { navigation: matchedTopLevelRoute === null || matchedTopLevelRoute === void 0 ? void 0 : matchedTopLevelRoute.props.navigation }),
                        React.createElement(MainSection, { withHero: (_a = matchedTopLevelRoute === null || matchedTopLevelRoute === void 0 ? void 0 : matchedTopLevelRoute.props.navigation) === null || _a === void 0 ? void 0 : _a.withHero }, props.children),
                        React.createElement(Footer, null),
                        React.createElement(BackToTop, null))),
                React.createElement(PremiumCompanyProfileSubscriptionModal, null)),
            React.createElement(AccountAuthenticationModal, null))));
};
export { LayoutMain };
