import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OAuthType } from '@src/types/auth';
import { LinkedinFilled } from '@ant-design/icons';
import { useConfig } from '@src/hooks/useConfig';
import randomstring from 'randomstring';
import { LinkedInButton as StyledButton } from './LinkedInLoginButton.style';
function LinkedInLoginButton({ type }) {
    const config = useConfig();
    const determineButtonText = () => {
        if (type === OAuthType.login) {
            return React.createElement(FormattedMessage, { id: "linkedin.login", defaultMessage: "Prihl\u00E1si\u0165 cez linkedin" });
        }
        if (type === OAuthType.registration) {
            return React.createElement(FormattedMessage, { id: "linkedin.registration", defaultMessage: "Registrova\u0165 cez linkedin" });
        }
    };
    const linkedinButtonText = determineButtonText();
    const scopes = 'r_liteprofile r_emailaddress';
    const redirect = type === OAuthType.login || type === OAuthType.registration
        ? `${config.baseUrl}/prihlasenie`
        : `${config.baseUrl}/ucet/zivotopis`;
    const linkedinAuthorization = async () => {
        const generatedStateString = randomstring.generate(10);
        window.localStorage.setItem('pracuj_linkedin_state_string', generatedStateString);
        const url = `${config.linkedinAuthUri}/authorization?response_type=code&client_id=${process.env.LINKEDIN_CLIENT_ID}&scope=${scopes}&state=${generatedStateString}&redirect_uri=${redirect}`;
        window.location.href = url;
    };
    return (React.createElement(StyledButton, { size: "middle", type: "default", onClick: linkedinAuthorization },
        React.createElement(LinkedinFilled, { className: "m-r-m" }),
        linkedinButtonText));
}
export { LinkedInLoginButton };
