import React from 'react';
import { Form } from 'antd';
import { SwitchButtonsGroup, InputNumber } from '@src/components/elements';
import { useConfig } from '@src/hooks/useConfig';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
const FieldsWrapper = styled.div `
	width: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.ant-form-item-has-error button {
		border: 1px solid ${({ theme }) => theme.color.error};
	}
`;
const InputsWrapper = styled.div `
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
const StyledInputNumber = styled(InputNumber) `
	width: 130px;
`;
const InputNumberWrap = styled.div `
	max-width: 130px;
`;
function checkMinMaxCorrectness({ getFieldValue, setFieldsValue }) {
    const min = getFieldValue('salaryMin');
    const max = getFieldValue('salaryMax');
    if (min && max) {
        if (min >= max) {
            setFieldsValue({ salaryMax: min + 1 });
        }
    }
}
export const SalaryRangeField = ({ form, maxRules, minRules, rateRules, dependencies }) => {
    const config = useConfig();
    const intl = useIntl();
    const radioOptions = config.salaryRates.map(rate => ({
        label: intl.formatMessage({ id: `offer.filter.${rate.value}` }),
        value: rate.value,
    }));
    const onSwitchChange = (value) => {
        if (!value) {
            form.setFieldsValue({
                salaryMin: undefined,
                salaryMax: undefined,
            });
        }
    };
    return (React.createElement(FieldsWrapper, null,
        React.createElement(Form.Item, { label: intl.formatMessage({ id: 'offer.filter.how_big_salary' }), name: "salaryRate", valuePropName: "activeButton", rules: rateRules, dependencies: dependencies },
            React.createElement(SalaryRateButtons, { options: radioOptions, size: "middle", 
                // @ts-ignore
                onChange: onSwitchChange })),
        React.createElement(InputsWrapper, null,
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: (prev, curr) => prev.salaryRate !== curr.salaryRate }, ({ getFieldValue }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(InputNumberWrap, null,
                        React.createElement(Form.Item, { name: "salaryMin", label: intl.formatMessage({
                                id: 'offers.offer_notification_subscription_form.minimum',
                            }), rules: minRules },
                            React.createElement(StyledInputNumber, { disabled: Boolean(!getFieldValue('salaryRate')), placeholder: "0", onBlur: () => checkMinMaxCorrectness({
                                    getFieldValue,
                                    setFieldsValue: form.setFieldsValue,
                                }) }))),
                    React.createElement(InputNumberWrap, null,
                        React.createElement(Form.Item, { name: "salaryMax", label: intl.formatMessage({
                                id: 'offers.offer_notification_subscription_form.maximum',
                            }), rules: maxRules },
                            React.createElement(StyledInputNumber, { disabled: Boolean(!getFieldValue('salaryRate')), onBlur: () => checkMinMaxCorrectness({
                                    getFieldValue,
                                    setFieldsValue: form.setFieldsValue,
                                }), placeholder: intl.formatMessage({ id: 'offer.filter.unlimited' }) })))));
            }))));
};
export const SalaryRateButtons = styled(SwitchButtonsGroup) `
	${down('xs')} {
		.ant-btn {
			font-size: 13px;
		}
	}
`;
