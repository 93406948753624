/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Document, Page } from '@src/components/modules/global/ReactPdf';
import { Spinner } from '@src/components/elements';
import { FilePdfOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { AssetDetailDocumentIconContainer } from './AssetDetailDocument.style';
import { DOCUMENT_FILE_ICON_STYLES } from './AssetDetailDocument';
import { PDFDocumentContainer } from './AssetDetailDocumentPdf.style';
export function AssetDetailDocumentPdf({ url }) {
    const [pageCount, setPageCount] = React.useState(1);
    const [hasError, setHasError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const handleLoadSuccess = pdf => {
        setPageCount(pdf.numPages);
        setIsLoading(false);
    };
    const handlePdfError = err => {
        console.error(err);
        setHasError(true);
    };
    return (React.createElement(PDFDocumentContainer, { showGrayBackground: !hasError && !isLoading },
        React.createElement(Document, { file: url, loading: React.createElement(Spinner, { size: "large" }), onLoadSuccess: handleLoadSuccess, error: React.createElement(AssetDetailDocumentIconContainer, null,
                React.createElement(FormattedMessage, { id: "assets.document_cannot_be_displayed" }),
                React.createElement(FilePdfOutlined, { style: DOCUMENT_FILE_ICON_STYLES })), onLoadError: handlePdfError, onSourceError: handlePdfError }, new Array(pageCount).fill(null).map((_, pageIndex) => (React.createElement(Page, { pageIndex: pageIndex, key: pageIndex, width: 1000 }))))));
}
