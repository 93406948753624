import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@src/components/elements';
import { AssetType } from '@src/types/asset';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { AssetDetailContent, AssetDetailModalFooterContainer } from './AssetDetailModal.style';
import { AssetDetailDocument } from './AssetDetailDocument';
import { AssetDetailImage } from './AssetDetailImage';
import { AssetDetailVideo } from './AssetDetailVideo';
function AssetDetailModalFooter({ isProcessing, closeAssetDetailModel, deleteAsset, downloadAndSaveAsset, isDownloadingAssets, isDeletingAssets, }) {
    return (React.createElement(AssetDetailModalFooterContainer, null,
        React.createElement(Button, { type: "default", size: "middle", onClick: closeAssetDetailModel },
            "\u00A0",
            React.createElement(FormattedMessage, { id: "general.cancel" })),
        React.createElement(Button, { type: "primary", size: "middle", icon: React.createElement(DownloadOutlined, null), loading: isProcessing || isDownloadingAssets, disabled: isDeletingAssets, onClick: downloadAndSaveAsset },
            "\u00A0",
            React.createElement(FormattedMessage, { id: "general.download" })),
        React.createElement(Button, { type: "primary", danger: true, size: "middle", icon: React.createElement(DeleteOutlined, null), disabled: isDownloadingAssets, onClick: deleteAsset, loading: isDeletingAssets },
            "\u00A0",
            React.createElement(FormattedMessage, { id: "general.delete" }))));
}
export function AssetDetailModal({ asset, isVisible, isDownloadingAssets, isDeletingAssets, closeAssetDetailModel, deleteAsset, downloadAndSaveAsset, }) {
    if (!asset) {
        return null;
    }
    const theme = useTheme();
    return (React.createElement(Modal, { title: asset.filename, visible: isVisible, width: theme.modalWidth.largeModal, onCancel: closeAssetDetailModel, footer: React.createElement(AssetDetailModalFooter, { isProcessing: asset.isProcessing, isDeletingAssets: isDeletingAssets, isDownloadingAssets: isDownloadingAssets, closeAssetDetailModel: closeAssetDetailModel, deleteAsset: deleteAsset, downloadAndSaveAsset: downloadAndSaveAsset }) },
        React.createElement(AssetDetailContent, null,
            asset.type === AssetType.IMAGE && React.createElement(AssetDetailImage, { asset: asset }),
            asset.type === AssetType.DOCUMENT && React.createElement(AssetDetailDocument, { asset: asset }),
            asset.type === AssetType.VIDEO && React.createElement(AssetDetailVideo, { asset: asset }))));
}
