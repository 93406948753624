import React from 'react';
import { useSalaryFormatter } from '@src/hooks/useSalaryFormatter';
import { useConfig } from '@src/hooks/useConfig';
import SlugOffer from '@src/shared/slug-offer';
import { FormattedMessage, useIntl } from 'react-intl';
import { getOfferTypeTagIcon } from '@src/helpers/get-offer-type-icon';
import { OfferPropsText, OfferLink, OfferLinkWrapper, OffersTitle, StyledSeparator, OffersContainer, MoneyBagIcon, MapIcon, OfferTypeIconWrapper, DetailButton, ButtonWrapper, OfferPropsWrapper, OfferMain, OfferPropItem, OfferTitle, OffersSubPageContent, } from './CompanyProfileOffersContent.style';
const CompanyProfileOffersContent = ({ company }) => {
    const { formatSalary } = useSalaryFormatter();
    const config = useConfig();
    const intl = useIntl();
    const activeOffers = company.offers.filter(o => Boolean(o.isActive) === true);
    const companyActiveOffers = activeOffers.map(({ id, title, address, offerTypeId, salaryRate, salaryAmountFrom, salaryAmountTo, salaryCurrency, isPaid }) => {
        var _a;
        const salaryString = salaryAmountFrom && isPaid
            ? formatSalary({
                rate: salaryRate,
                amountFrom: salaryAmountFrom,
                amountTo: salaryAmountTo,
                currency: salaryCurrency,
            })
            : '';
        return {
            key: id,
            position: title,
            salary: salaryString,
            type: (_a = config.offerTypes.find(type => type.id === offerTypeId)) === null || _a === void 0 ? void 0 : _a.name,
            offerTypeId,
            city: (address === null || address === void 0 ? void 0 : address.city) || intl.formatMessage({ id: 'general.remote' }),
            link: `/ponuka/${new SlugOffer({ text: title }).result}-${id}`,
        };
    });
    return (React.createElement(OffersSubPageContent, null, companyActiveOffers && companyActiveOffers.length > 0 && (React.createElement(React.Fragment, null,
        React.createElement(OffersTitle, null,
            React.createElement(FormattedMessage, { id: "admin.company_premium_profile.offers_count", values: {
                    allActiveOffersCount: companyActiveOffers.length,
                } }),
            companyActiveOffers.length && React.createElement(StyledSeparator, null)),
        React.createElement(OffersContainer, null, companyActiveOffers.map(item => {
            return (React.createElement(OfferLinkWrapper, { key: item.key },
                React.createElement(OfferLink, { href: item.link },
                    React.createElement(OfferMain, null,
                        React.createElement(OfferTitle, null, item.position),
                        React.createElement(OfferPropsWrapper, null,
                            React.createElement(OfferPropItem, null,
                                React.createElement(OfferTypeIconWrapper, null, getOfferTypeTagIcon(item.offerTypeId)),
                                React.createElement(OfferPropsText, null, item.type)),
                            React.createElement(OfferPropItem, null,
                                React.createElement(MapIcon, { type: "maps-pin" }),
                                React.createElement(OfferPropsText, null, item.city)),
                            React.createElement(OfferPropItem, null,
                                React.createElement(MoneyBagIcon, { type: "money-bag-euro" }),
                                React.createElement(OfferPropsText, null, item.salary)))),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(DetailButton, { size: "middle", shape: "round" },
                            React.createElement(FormattedMessage, { id: "admin.company_premium_profile.go_to_offer" }))))));
        }))))));
};
export { CompanyProfileOffersContent };
