import { client as axios } from './clients/axios';
export async function getOfferApplicationHistory(offerApplicationId) {
    const response = await axios.get(`/0/offer-application-history`, {
        params: {
            offerApplicationId,
        },
    });
    return response.data;
}
export async function createOfferApplicationStandardNote(noteText, offerApplicationId, type) {
    const response = await axios.post(`/0/offer-application-history`, { noteText, offerApplicationId, type });
    return response.data;
}
export async function updateOfferApplicationHistoryItem(historyItemId, newHistoryItemText) {
    const response = await axios.put(`/0/offer-application-history/${historyItemId}`, {
        newHistoryItemText,
    });
    return response.data;
}
export async function deleteOfferApplicationHistoryItem(historyItemId) {
    const response = await axios.delete(`/0/offer-application-history/${historyItemId}`);
    return response.data;
}
