import { Upload } from 'antd';
import styled from 'styled-components';
export const StyledAssetUpload = styled(Upload) `
	width: auto;
	.ant-upload-select-picture-card {
		width: 245px;
		height: 245px;
		border-radius: ${props => props.theme.borderRadius.large};
	}
`;
