import { HeartFilled } from '@ant-design/icons';
import { Badge, Link } from '@src/components/elements';
import { useSavedOffersContextValue } from '@src/context/saved-offer-context';
import React from 'react';
import styled from 'styled-components';
export function HeaderMenuSavedOffers() {
    var _a;
    const { savedOffers } = useSavedOffersContextValue();
    return (React.createElement(SavedOfferLink, { to: "/ucet/ulozene-ponuky" },
        React.createElement(Badge, { size: "small", count: (_a = savedOffers.length) !== null && _a !== void 0 ? _a : undefined },
            React.createElement(HeartFilled, null))));
}
const SavedOfferLink = styled(Link) `
	height: 2.5rem;
	display: flex;
	align-items: center;
	width: 2.5rem;
	justify-content: center;

	svg {
		width: 18px;
		height: 18px;
		color: ${({ theme }) => theme.color.white};
		opacity: 0.4;
		transition: all ${({ theme }) => theme.transition.basic};
	}

	.ant-badge-count {
		top: 0px;
		right: -4px;
	}

	&:hover svg {
		opacity: 1;
		transform: scale(1.1);
	}
`;
