import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { LockOutlined } from '@ant-design/icons';
import { history } from '@src/app/index';
import * as Api from '@src/api';
import { BraintreeHostedField } from '@src/components/modules/forms/renderBraintreeHostedField';
import { Icon, Button, Checkbox, Link } from '@src/components/elements';
import actions from '@src/actions';
import { useBraintree } from '@src/hooks/useBraintree';
import { useConfig } from '@src/hooks/useConfig';
import { useAccount } from '@src/context/account-context';
import { PaymentMethod, ProductType } from '@src/types';
import { Queries } from '@src/types/queries';
import { useQueryClient } from 'react-query';
export const StyledLink = styled(Link) `
	font-weight: 700;
	text-decoration: underline;
	display: block;
	margin: 0.5em auto 0;
`;
const EFieldsAttributes = {
    Id: 'Id',
    Label: 'Label',
    ClassName: 'ClassName',
};
const getFieldsConfig = intl => [
    {
        [EFieldsAttributes.Id]: 'card-number',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.card_number',
            defaultMessage: 'Číslo platobnej karty',
        }),
        [EFieldsAttributes.ClassName]: 'col-12',
    },
    {
        [EFieldsAttributes.Id]: 'expiration-date',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.expiration_date',
            defaultMessage: 'Platnosť karty do',
        }),
        [EFieldsAttributes.ClassName]: 'col-6_xs-12',
    },
    {
        [EFieldsAttributes.Id]: 'cvv',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.cvv',
            defaultMessage: 'Bezpečnostný kód (CVC/CVV)',
        }),
        [EFieldsAttributes.ClassName]: 'col-6_xs-12',
    },
];
const Checkout = ({ isOrderLoading, hasRememberedCard, billingProfile, couponCode, orderParams, order, offerId, onOrderSuccess, }) => {
    var _a, _b;
    const [isCheckoutInitialized, setIsCheckoutInitialized] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [useSavedCard, setUseSavedCard] = React.useState(false);
    const [shouldSaveCard, setShouldSaveCard] = React.useState(true);
    const [isPaymentInProgress, setIsPaymentInProgress] = React.useState(false);
    const { isBraintreeLoading, initCheckout, hostedFieldsInstance, setHostedFieldsInstance, braintree3DSecureInstance, braintreeDataCollector, } = useBraintree();
    const intl = useIntl();
    const dispatch = useDispatch();
    const config = useConfig();
    const queryClient = useQueryClient();
    const { email, telephone, firstName, lastName } = (_a = useAccount()) !== null && _a !== void 0 ? _a : {};
    // @ts-ignore
    const { formatted_address, city, postal_code } = useSelector(state => state.admin.company);
    const formattedTelephone = (_b = telephone) === null || _b === void 0 ? void 0 : _b.replace('+', '00');
    const hasAbroadBillingProfile = (billingProfile === null || billingProfile === void 0 ? void 0 : billingProfile.country) !== config.countryCode;
    React.useEffect(() => {
        if (hasRememberedCard) {
            setUseSavedCard(true);
        }
        setIsCheckoutInitialized(true);
    }, [hasRememberedCard]);
    React.useEffect(() => {
        if (isCheckoutInitialized) {
            initCheckout(!useSavedCard);
            if (useSavedCard) {
                setShouldSaveCard(false);
            }
            if (!useSavedCard && (order === null || order === void 0 ? void 0 : order.orderType) === ProductType.subscription) {
                setShouldSaveCard(true);
            }
        }
    }, [isCheckoutInitialized, useSavedCard, order === null || order === void 0 ? void 0 : order.orderType]);
    const sendPayment = async () => {
        try {
            if (!order) {
                throw new Error(intl.formatMessage({
                    id: 'admin.order.invalid_order',
                }));
            }
            setIsPaymentInProgress(true);
            let paymentMethodData = null;
            if (hasRememberedCard && useSavedCard) {
                paymentMethodData = await Api.Admin.getPaymentMethodNonce();
            }
            else {
                paymentMethodData = await hostedFieldsInstance.tokenize();
            }
            const amount = hasAbroadBillingProfile
                ? Number(order === null || order === void 0 ? void 0 : order.totalPriceWithoutVat.toFixed(2))
                : Number(order === null || order === void 0 ? void 0 : order.totalPrice.toFixed(2));
            const response = await braintree3DSecureInstance.verifyCard({
                // @ts-ignore
                onLookupComplete(data, next) {
                    next();
                },
                amount,
                nonce: paymentMethodData.nonce,
                bin: paymentMethodData.details.bin,
                email,
                mobilePhoneNumber: formattedTelephone,
                challengeRequested: true,
                billingAddress: {
                    givenName: firstName,
                    surname: lastName,
                    phoneNumber: formattedTelephone,
                    streetAddress: formatted_address,
                    locality: city,
                    postalCode: postal_code,
                },
            });
            if (!response.threeDSecureInfo.liabilityShifted) {
                return;
            }
            await Api.Admin.buyProductByCard({
                orderParams,
                order,
                savePaymentMethod: shouldSaveCard,
                nonce: response.nonce,
                billingProfile,
                deviceData: braintreeDataCollector.deviceData,
                couponCode,
                offerId,
            });
            if (order.orderType === ProductType.credit) {
                dispatch(actions.adminToggleCreditsReplenishedDialog(true));
            }
            if (onOrderSuccess) {
                onOrderSuccess(PaymentMethod.credit_card);
            }
            history.pushLocalized('/admin');
            queryClient.invalidateQueries(Queries.getCompany);
        }
        catch (err) {
            console.error(err);
            setError(true);
        }
        finally {
            setIsPaymentInProgress(false);
        }
    };
    const fieldsConfig = getFieldsConfig(intl);
    return (React.createElement("div", { className: "Checkout m-t-m" },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: "panel panel--payment" },
                    React.createElement("div", { className: "Checkout__headline" },
                        React.createElement(Icon, { className: "Checkout__icon-shield", type: "shield" }),
                        React.createElement(FormattedMessage, { id: "global.checkout.secure_payment", defaultMessage: "Bezpe\u010Dn\u00E1 platba kartou" })),
                    error && (React.createElement("div", { className: "form__errors" },
                        React.createElement("p", { className: "form__errors-item" },
                            React.createElement(FormattedMessage, { id: "global.checkout.invalid_payment_information", defaultMessage: "Neplatn\u00E9 platobn\u00E9 \u00FAdaje" })))),
                    React.createElement("div", null,
                        hasRememberedCard && (React.createElement("div", { className: "u-text-center form__row" },
                            React.createElement("label", { className: "form__label form__label--checkbox" },
                                React.createElement(Checkbox, { checked: useSavedCard, onChange: e => {
                                        setUseSavedCard(e.target.checked);
                                        setHostedFieldsInstance(null);
                                    } },
                                    React.createElement(FormattedMessage, { id: "global.checkout.use_saved_card", defaultMessage: "Pou\u017Ei\u0165 zapam\u00E4tan\u00FA kartu?" }))))),
                        (!useSavedCard || !hasRememberedCard) && (React.createElement(Row, { gutter: [16, 16], justify: "center" }, fieldsConfig.map(field => (React.createElement(BraintreeHostedField, { "data-cy": field[EFieldsAttributes.Id], key: field[EFieldsAttributes.Id], id: field[EFieldsAttributes.Id], label: field[EFieldsAttributes.Label], className: field[EFieldsAttributes.ClassName] }))))),
                        React.createElement(Row, { gutter: [16, 16], justify: "center", className: "m-t-l" },
                            React.createElement(Button, { type: "primary", onClick: sendPayment, loading: isPaymentInProgress || isBraintreeLoading || isOrderLoading, icon: React.createElement(LockOutlined, null) }, intl.formatMessage({
                                id: 'global.checkout.pay_with_card',
                                defaultMessage: 'Zaplatiť kartou',
                            }))),
                        (order === null || order === void 0 ? void 0 : order.orderType) !== ProductType.subscription && (React.createElement(React.Fragment, null, (!useSavedCard || !hasRememberedCard) && (React.createElement("div", { className: "flex-center m-t-l" },
                            React.createElement(Checkbox, { checked: shouldSaveCard, onChange: e => setShouldSaveCard(e.target.checked) }, intl.formatMessage({
                                id: 'global.checkout.save_card',
                            }))))))))))));
};
export { Checkout };
