const getCmsAsset = (asset, size = 'preview') => {
    var _a;
    if (!asset) {
        return '/media/images/placeholder.png';
    }
    if (size === 'original') {
        return `${process.env.CMS_URL}/storage/uploads${asset.path}`;
    }
    return `${process.env.CMS_URL}/storage/uploads${(_a = asset.sizes[size]) === null || _a === void 0 ? void 0 : _a.path}`;
};
export default getCmsAsset;
