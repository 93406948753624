import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin } from 'react-google-login';
import { FormattedMessage } from 'react-intl';
import { GoogleOutlined } from '@ant-design/icons';
import { Button } from '@src/components/elements';
import { client as axios } from '@src/api/clients/axios';
import { OAuthType } from '@src/types/auth';
import { useSocket } from '@src/context/socket-context';
import { SocketEvents, AccountType } from '@src/types';
import styled from 'styled-components';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
const StyledButton = styled(Button) `
	border-radius: 1rem;
`;
function GoogleLoginButton({ type, shouldStayOnPage, onLoginSuccess }) {
    const history = useHistory();
    const socket = useSocket();
    const SSRFetchClient = useSSRFetchClient();
    const onSuccessfulLogin = React.useCallback(async (response) => {
        const { profileObj } = response;
        const account = await axios.post('/0/auth/google', Object.assign({}, profileObj));
        socket.emit(SocketEvents.ACCOUNT_AUTHENTICATED, account.data);
        SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
        if (onLoginSuccess) {
            onLoginSuccess(account.data);
        }
        if (shouldStayOnPage) {
            return;
        }
        if (account.data.account_type_id === AccountType.applicant) {
            // @ts-ignore
            history.pushLocalized('/ucet');
        }
        else {
            // @ts-ignore
            history.pushLocalized('/admin');
        }
    }, [shouldStayOnPage]);
    const { signIn } = useGoogleLogin({
        onSuccess: (res) => {
            onSuccessfulLogin(res);
        },
        clientId: '556754196203-lq3ahq4rbo5volp6arr53luoqtfo7ju6.apps.googleusercontent.com',
        fetchBasicProfile: true,
        onFailure: err => {
            console.error(err);
        },
    });
    return (React.createElement(StyledButton, { className: "p-s m-t-s m-b-s flex-row align-center justify-start", size: "middle", type: "default", onClick: signIn },
        React.createElement(GoogleOutlined, { className: "m-r-m" }),
        type === OAuthType.registration && (React.createElement(FormattedMessage, { id: "login.signup_with_google", defaultMessage: "Registrova\u0165 cez Google" })),
        type === OAuthType.login && (React.createElement(FormattedMessage, { id: "login.login_with_google", defaultMessage: "Prihl\u00E1si\u0165 cez Google" }))));
}
export { GoogleLoginButton };
