import React from 'react';
import { Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _debounce from 'lodash/debounce';
import OfficialBuilding3 from '@public/media/icons/streamline-icon-official-building-3.svg';
import CloseQuote from '@public/media/icons/streamline-icon-close-quote.svg';
import { SearchFilterOptionGroup } from '@src/types';
import { normalizeStr } from '@src/helpers/normalize-string';
import { find } from 'lodash';
import { SelectTag } from '@src/components/elements/SelectTag/SelectTag';
import { SelectWrapper } from './SpecificSearchSelect.style';
function findGroup(arraysToSearch, valueToSearch, labelToSearch) {
    var _a;
    const group = (_a = find(arraysToSearch, { values: [{ name: labelToSearch, id: valueToSearch }] })) === null || _a === void 0 ? void 0 : _a.group;
    return group || SearchFilterOptionGroup.keyword;
}
export const tagOptions = {
    maxTagCount: 10,
    maxKeywordTextLength: 40,
};
const { Option, OptGroup } = Select;
export function SpecificSearchSelect({ selectedValues, onChange, getSearchSuggestions, isLoading, emptySuggestions, suggestedCompanies, isTagCountInLimit, handleSelect, handleDeselect, }) {
    const intl = useIntl();
    const [searchValue, setSearchValue] = React.useState('');
    const onDeselect = (deselectedOption) => {
        handleDeselect(deselectedOption);
        setSearchValue('');
        emptySuggestions();
    };
    const onSelect = ({ value: targetValue, label: targetLabel, key }) => {
        const formattedTargetLabel = targetLabel.replace(/\s\s+/g, ' ');
        const isAlreadySelected = (selectedValues || []).find(({ label: selectedLabel }) => normalizeStr(selectedLabel) === normalizeStr(formattedTargetLabel));
        if (!isTagCountInLimit || isAlreadySelected || !formattedTargetLabel.trim()) {
            setSearchValue('');
            emptySuggestions();
            return;
        }
        const valuesToSearch = [
            {
                values: suggestedCompanies,
                group: SearchFilterOptionGroup.company,
            },
        ];
        const group = findGroup(valuesToSearch, targetValue, formattedTargetLabel);
        const newSelectedItem = { value: targetValue, label: formattedTargetLabel, key, group };
        handleSelect(newSelectedItem);
        setSearchValue('');
        emptySuggestions();
    };
    const debounceOnSearch = React.useCallback(_debounce(getSearchSuggestions, 300), []);
    return (React.createElement(SelectWrapper, { mode: "multiple", labelInValue: true, tokenSeparators: [','], notFoundContent: !searchValue ? null : React.createElement(React.Fragment, null), onSearch: search => {
            let searchText = search;
            const isSearchValueTooLong = searchText.length > tagOptions.maxKeywordTextLength;
            if (isSearchValueTooLong) {
                searchText = searchText.substring(0, tagOptions.maxKeywordTextLength);
            }
            setSearchValue(searchText);
            debounceOnSearch(searchText);
        }, searchValue: searchValue, filterOption: false, placeholder: intl.formatMessage({ id: 'global.specific_search.placeholder' }), loading: isLoading, tagRender: ({ onClose, closable, value: itemValue, label: itemLabel }) => {
            const tag = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.find(({ value: tagValue, label: tagLabel }) => tagValue === itemValue && tagLabel === itemLabel);
            return (React.createElement(SelectTag, { onClose: onClose, closable: closable, label: tag.label }, tag && (React.createElement(React.Fragment, null,
                (!tag.group || tag.group === SearchFilterOptionGroup.keyword) && (React.createElement("div", { className: "flex-inline align-center" },
                    React.createElement(CloseQuote, { width: 12, className: "m-r-s" }),
                    `"${tag.label}"`)),
                tag.group === SearchFilterOptionGroup.company && (React.createElement("div", { className: "flex-inline align-center" },
                    React.createElement(OfficialBuilding3, { width: 12, className: "m-r-xs" }),
                    tag.label))))));
        }, onSelect: onSelect, onChange: (newSpecificSearchFormValues) => {
            if (onChange) {
                onChange(newSpecificSearchFormValues);
            }
        }, onDeselect: onDeselect, value: selectedValues, maxTagCount: "responsive", onBlur: () => {
            if (searchValue) {
                onSelect({ value: searchValue, key: searchValue, label: searchValue });
                setSearchValue('');
            }
        }, dropdownClassName: !isTagCountInLimit || !searchValue ? 'hidden' : '', dropdownRender: menu => (React.createElement(React.Fragment, null, (Boolean(suggestedCompanies.length) || searchValue) && isTagCountInLimit && React.createElement(React.Fragment, null, menu))) },
        searchValue && (React.createElement(OptGroup, { key: SearchFilterOptionGroup.keyword, label: React.createElement("div", { className: "flex-inline align-center font-size-m" },
                React.createElement(CloseQuote, { width: 16, className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "general.search_keyword" })) },
            React.createElement(Option, { key: `keyword-${searchValue}`, value: searchValue }, searchValue))),
        Boolean(suggestedCompanies.length) && (React.createElement(OptGroup, { key: SearchFilterOptionGroup.company, label: React.createElement("div", { className: "flex-inline align-center font-size-m" },
                React.createElement(OfficialBuilding3, { width: 16, className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "general.company" })) }, suggestedCompanies.map(({ id, name: companyName }) => (React.createElement(Option, { key: `${companyName}-${id}`, value: id }, companyName)))))));
}
