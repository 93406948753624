import React from 'react';
import { Slider } from 'antd';
import styled from 'styled-components';
export const StyledSlider = styled(Slider) `
	.ant-slider-dot {
		height: 16px;
		width: 16px;
		margin-left: -8px;
		top: -4px;
		border: 4px solid ${props => props.theme.color.gray5};
		box-shadow: 0px 0px 0px 3px ${props => props.theme.color.white};
	}
	.ant-slider-dot-active {
		background-color: ${props => props.theme.color.white};
		border: 4px solid ${props => props.theme.color.success};
		box-shadow: 0px 0px 0px 3px ${props => props.theme.color.white};
	}
	.ant-slider-handle {
		height: 25px;
		width: 25px;
		margin-top: -9px;
		background-color: ${props => props.theme.color.success};
		border: solid 2px ${props => props.theme.color.white};
	}
	&:hover .ant-slider-handle:not(.ant-tooltip-open) {
		border-color: ${props => props.theme.color.white};
	}
	.ant-slider-rail {
		height: 8px;
		background-color: ${props => props.theme.color.gray5};
	}
	.ant-slider-track {
		height: 8px;
		background-color: ${props => props.theme.color.success};
	}
	&:hover .ant-slider-track {
		background-color: ${props => props.theme.color.success};
	}
	.ant-slider-mark {
		top: 29px;
		font-size: 18px;
	}
	.ant-slider-mark-text {
		color: ${props => props.theme.color.gray5};
	}
	.ant-slider-mark-text-active {
		color: ${props => props.theme.color.success};
	}

	${props => props.dark
    ? `
			.ant-slider-dot-active {
				background-color: ${props.theme.color.success};
				border: 4px solid ${props.theme.color.white};
				box-shadow: none;
			}
			.ant-slider-dot {
				border: 4px solid ${props.theme.color.gray1};
				box-shadow: none;
			}
			.ant-slider-rail {
				background-color: ${props.theme.color.gray1};
			}
			.ant-slider-mark-text {
				color: ${props.theme.color.white};
			}
			`
    : ``}
`;
const marks = {
    1: '1',
    5: '5',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
};
function SliderCredits({ value = 1, min = 1, max = 50, dark = false, onChange, step = 1, dots, marks: overrideMarks, }) {
    return (React.createElement(StyledSlider, { defaultValue: value, marks: overrideMarks || marks, min: min, max: max, dots: dots, step: step, onChange: onChange, tooltipVisible: false, range: false, dark: dark }));
}
export default SliderCredits;
