import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spin as Hamburger } from 'hamburger-react';
import actions from '@src/actions';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { getConfig } from '@src/config';
import ProfessionalNetworkLinkedin from '@public/media/icons/streamline-icon-professional-network-linkedin.svg';
import SocialInstagram from '@public/media/icons/streamline-icon-social-instagram.svg';
import SocialMediaFacebook1 from '@public/media/icons/streamline-icon-social-media-facebook-1.svg';
import EmailActionUnread from '@public/media/icons/streamline-icon-email-action-unread.svg';
import EmailActionDownload from '@public/media/icons/streamline-icon-email-action-download.svg';
import GiftBox from '@public/media/icons/streamline-icon-gift-box.svg';
import SingleNeutral from '@public/media/icons/streamline-icon-single-neutral.svg';
import Lock1 from '@public/media/icons/streamline-icon-lock-1.svg';
import StyleTwoPinInformation from '@public/media/icons/streamline-icon-style-two-pin-information.svg';
import BrowserPageText2 from '@public/media/icons/streamline-icon-browser-page-text-2.svg';
import CommonFileTextCash from '@public/media/icons/streamline-icon-common-file-text-cash.svg';
import ReadEmailAtAlternate from '@public/media/icons/streamline-icon-read-email-at-alternate.svg';
import CoffeeRead from '@public/media/icons/streamline-icon-coffee-read.svg';
import MultipleCircle from '@public/media/icons/streamline-icon-multiple-circle.svg';
import SingleMan from '@public/media/icons/streamline-icon-single-man.svg';
import BuildingsModern from '@public/media/icons/streamline-icon-buildings-modern.svg';
import LoginKey2 from '@public/media/icons/streamline-icon-login-key-2.svg';
import SingleNeutralCircleAlternate from '@public/media/icons/streamline-icon-single-neutral-circle-alternate.svg';
import Logout2 from '@public/media/icons/streamline-icon-logout-2.svg';
import { getRouteId, isRouteActive } from '@src/shared/localized-path';
import { AccountType } from '@src/types';
import { useAccount } from '@src/context/account-context';
import { useAuthContextValue } from '@src/context/auth-context';
import { PremiumIcon } from '@src/components/elements';
import { ProfileBox } from './ProfileBox';
import LogoMain from '../../../../public/media/images/logo-main.svg';
import { HeaderMenuNotifications } from './HeaderMenuNotifications';
import { StyledHolder, Logo, Menu, MenuMobile, MenuContent, MenuContentColumn, MenuContentInner, MenuContentInnerAside, MenuContentHighlighted, MenuMasterItem, MenuLabel, MenuLabelWrapper, MenuLink, MenuLinkCentered, MenuContentSection, UserControls, UserControlsWrapper, MyAccountLink, MyAccountLinkLabel, MyAccountInner, HamburgerWrapper, MenuMobileContent, MenuMobileContentInner, } from './HeaderMenu.style';
import { HeaderMenuSavedOffers } from './HeaderMenuSavedOffers';
// TODO - could be just typechecked to remove the duplicity
const isOtherLabelActive = (activeCategory) => {
    if (!activeCategory) {
        return false;
    }
    return ['candidate', 'company', 'contact', 'magazine', 'offers'].includes(activeCategory);
};
const HeaderMenu = ({ isMenuSticked, mobileMenuOpen, setMobileMenuOpen, setIsSubscriptionModalOpen }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();
    const config = getConfig();
    const history = useHistory();
    const currentRoute = history.location.pathname;
    const routeId = getRouteId(history.location.pathname);
    const user = useAccount();
    const { logout } = useAuthContextValue();
    // @ts-ignore
    const company = useSelector(state => state.admin.company);
    const isUserAdmin = (user === null || user === void 0 ? void 0 : user.accountTypeId) === config.accountType.company;
    const mobileMenuRef = React.useRef(null);
    const [routeIdBuffer, setRouteIdBuffer] = React.useState();
    const [mounted, setMounted] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState({ category: null });
    const getLoginLink = React.useCallback(() => {
        if (!(user === null || user === void 0 ? void 0 : user.email)) {
            return '/prihlasenie';
        }
        if (isUserAdmin) {
            return '/admin';
        }
        return '/ucet';
    }, [user]);
    const onCategoryLeave = () => setActiveCategory({ category: null, offset: activeCategory.offset });
    useClickAway(mobileMenuRef, e => {
        // not really a bulletproof solution, but it it here to prevent glitch with closing and reopening the menu after clicking on the mobile menu close button
        // e.path is non-standard
        // @ts-ignore
        const path = e.path || (e.composedPath && e.composedPath());
        if (!path.find(item => item.className === 'hamburger-react')) {
            setMobileMenuOpen(false);
        }
    });
    React.useEffect(() => {
        if (routeId !== routeIdBuffer) {
            setMobileMenuOpen(false);
        }
        setRouteIdBuffer(routeId);
    }, [routeId, isMenuSticked]);
    React.useEffect(() => {
        if (mobileMenuOpen) {
            document.body.className = 'overflow-hidden';
        }
        else {
            document.body.className = '';
        }
    }, [mobileMenuOpen]);
    async function getAdminInfo() {
        if (isUserAdmin) {
            dispatch(actions.myCompanyInfoInitStart());
        }
    }
    React.useEffect(() => {
        setMounted(true);
        getAdminInfo();
    }, []);
    React.useEffect(() => {
        getAdminInfo();
    }, [user]);
    const hasImage = Boolean(company.image) || Boolean(user === null || user === void 0 ? void 0 : user.profilePictureUrl);
    const isBelowMd = !useBreakpoint(down('md'));
    return (React.createElement(StyledHolder, null,
        React.createElement(Logo, { to: "/", title: intl.formatMessage({ id: 'global.logo_alt' }) },
            React.createElement(LogoMain, { width: "90", height: "60" })),
        React.createElement(Menu, null,
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'offers', offset: '-30vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/ponuky", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'offers', otherLabelActive: isOtherLabelActive(activeCategory.category), hasBackground: isRouteActive(currentRoute, '/ponuky') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.offers" })))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'candidate', offset: '-25vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/uchadzac", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'candidate', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/uchadzac') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.candidate" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'candidate', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, { width: 270 },
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.for_candidates" })),
                            React.createElement(MenuLink, { to: "/ponuky" },
                                React.createElement(FormattedMessage, { id: "general.job_offers" })),
                            React.createElement(MenuLink, { to: "/uchadzac" },
                                React.createElement(FormattedMessage, { id: "general.service_description" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { href: "#", onClick: e => {
                                    e.preventDefault();
                                    setMobileMenuOpen(false);
                                    setIsSubscriptionModalOpen(true);
                                } },
                                React.createElement(EmailActionDownload, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.watchdog" }))))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, { width: 200 },
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.account" })),
                            !user && (React.createElement(React.Fragment, null,
                                React.createElement(MenuLink, { to: "/prihlasenie" },
                                    React.createElement(FormattedMessage, { id: "general.login" })),
                                React.createElement(MenuLink, { to: "/registracia-uchadzac" },
                                    React.createElement(FormattedMessage, { id: "general.registration_new" })))),
                            Boolean(user) && (React.createElement(React.Fragment, null,
                                isUserAdmin && (React.createElement(React.Fragment, null,
                                    React.createElement(MenuLink, { to: "/admin" },
                                        React.createElement(FormattedMessage, { id: "general.login" })),
                                    React.createElement(MenuLink, { to: "/registracia-prihlaseny-pouzivatel" },
                                        React.createElement(FormattedMessage, { id: "general.registration_new" })))),
                                !isUserAdmin && (React.createElement(MenuLink, { to: "/ucet" },
                                    React.createElement(FormattedMessage, { id: "general.my_account" }))))))))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'company', offset: '-20vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/firmy", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'company', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/firmy') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.employer" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'company', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, { width: 280 },
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.for_employers" })),
                            React.createElement(MenuLink, { to: "/firmy" },
                                React.createElement(FormattedMessage, { id: "general.service_description_and_pricing" })),
                            React.createElement(MenuLink, { to: "/recruitment" },
                                React.createElement(FormattedMessage, { id: "general.recruitment_label" })),
                            React.createElement(MenuLink, { to: "/zoznam-zamestnavatelov" },
                                React.createElement(FormattedMessage, { id: "companies_list" })),
                            React.createElement(MenuLink, { to: "/premium-firemny-profil" },
                                React.createElement(FormattedMessage, { id: "general.company_premium_profile" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { to: "/firmy" },
                                React.createElement(GiftBox, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.get_free_credit" }))))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, { width: 200 },
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.account_employer" })),
                            !(user === null || user === void 0 ? void 0 : user.email) && (React.createElement(React.Fragment, null,
                                React.createElement(MenuLink, { to: "/prihlasenie" },
                                    React.createElement(FormattedMessage, { id: "general.login" })),
                                React.createElement(MenuLink, { to: "/registracia" },
                                    React.createElement(FormattedMessage, { id: "general.registration_new" })))),
                            (user === null || user === void 0 ? void 0 : user.email) && (React.createElement(React.Fragment, null,
                                isUserAdmin && (React.createElement(MenuLink, { to: "/admin" },
                                    React.createElement(FormattedMessage, { id: "general.my_account" }))),
                                !isUserAdmin && (React.createElement(React.Fragment, null,
                                    React.createElement(MenuLink, { to: "/ucet" },
                                        React.createElement(FormattedMessage, { id: "general.login" })),
                                    React.createElement(MenuLink, { to: "/registracia-prihlaseny-pouzivatel" },
                                        React.createElement(FormattedMessage, { id: "general.registration_new" })))))))))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'magazine', offset: '-10vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/magazin", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'magazine', otherLabelActive: isOtherLabelActive(activeCategory.category), hasBackground: isRouteActive(currentRoute, '/magazin') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.magazine" })))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'contact' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/kontakt", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'contact', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/kontakt') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.contact" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'contact', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, null,
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.contact_us" })),
                            React.createElement(MenuLink, { to: "/kontakt" },
                                React.createElement(FormattedMessage, { id: "general.contact_form" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { href: "mailto:info@pracuj.sk" },
                                React.createElement(EmailActionUnread, null),
                                React.createElement("span", null, "info@pracuj.sk")))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, null,
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.social_networks" })),
                            React.createElement(MenuLink, { href: "https://www.facebook.com/Pracuj.sk", target: "_blank" },
                                React.createElement(SocialMediaFacebook1, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.brand_name" }),
                                    " \u2014",
                                    ' ',
                                    React.createElement(FormattedMessage, { id: "general.claim" }))),
                            React.createElement(MenuLink, { href: "https://www.instagram.com/pracuj.sk", target: "_blank" },
                                React.createElement(SocialInstagram, null),
                                React.createElement("span", null, "Instagram")),
                            React.createElement(MenuLink, { href: "https://www.linkedin.com/company/pracuj", target: "_blank" },
                                React.createElement(ProfessionalNetworkLinkedin, null),
                                React.createElement("span", null, "LinkedIn"))))))),
        React.createElement(UserControlsWrapper, null,
            React.createElement(UserControls, null,
                (user === null || user === void 0 ? void 0 : user.accountTypeId) === AccountType.applicant && React.createElement(HeaderMenuSavedOffers, null),
                Boolean(user) && (React.createElement(HeaderMenuNotifications, { isActive: activeCategory.category === 'notifications', setActiveCategory: setActiveCategory, onCategoryLeave: onCategoryLeave })),
                React.createElement(MyAccountLink, { onMouseEnter: () => setActiveCategory({ category: 'user', offset: '0' }), onMouseLeave: onCategoryLeave },
                    React.createElement(MenuLabelWrapper, { to: getLoginLink(), title: intl.formatMessage({
                            id: 'navigation.login_indicator.account_administration',
                        }), active: activeCategory.category === 'user' && isBelowMd, hasContent: Boolean(user === null || user === void 0 ? void 0 : user.email) },
                        React.createElement(MyAccountInner, null, (user === null || user === void 0 ? void 0 : user.email) ? (React.createElement(React.Fragment, null,
                            React.createElement(SingleNeutral, { viewBox: "0 0 24 24" }),
                            React.createElement(MyAccountLinkLabel, null,
                                isUserAdmin && React.createElement(FormattedMessage, { id: "general.my_account" }),
                                !isUserAdmin && React.createElement(FormattedMessage, { id: "general.my_profile" })))) : (React.createElement(React.Fragment, null,
                            React.createElement(Lock1, { viewBox: "0 0 24 24" }),
                            React.createElement(MyAccountLinkLabel, null,
                                React.createElement(FormattedMessage, { id: "general.login" })))))),
                    Boolean(user) && isBelowMd && (React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'user', userControls: true },
                        React.createElement(MenuContentColumn, null,
                            React.createElement(MenuContentInner, null,
                                React.createElement(ProfileBox, { company: isUserAdmin ? company === null || company === void 0 ? void 0 : company.name : null, image: hasImage
                                        ? getCdnImageUrl({
                                            file: isUserAdmin
                                                ? `web/company/${company.image}`
                                                : `web/account/${user === null || user === void 0 ? void 0 : user.profilePictureUrl}`,
                                            transformation: 'w_140,h_140,c_limit',
                                        })
                                        : undefined, name: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}`, email: user === null || user === void 0 ? void 0 : user.email, companyUrl: company.company_url, onAccountClick: () => 
                                    // @ts-ignore
                                    history.pushLocalized(isUserAdmin ? '/admin' : '/ucet'), onLogoutClick: logout })))))),
                React.createElement(HamburgerWrapper, null,
                    React.createElement(Hamburger, { size: 18, color: mobileMenuOpen ? theme.color.brand1 : theme.color.white, label: intl.formatMessage({
                            id: 'general.menu.show',
                        }), rounded: true, toggle: setMobileMenuOpen, toggled: mobileMenuOpen })))),
        React.createElement(MenuMobile, { open: mobileMenuOpen, mounted: mounted, ref: mobileMenuRef },
            React.createElement(MenuMobileContent, null,
                React.createElement(MenuMobileContentInner, null,
                    Boolean(user) && (React.createElement(React.Fragment, null,
                        React.createElement(MenuContentSection, { marginTop: true, ellipsis: true, paddingRight: true }, user === null || user === void 0 ? void 0 : user.email),
                        React.createElement(MenuLink, { to: isUserAdmin ? '/admin' : '/ucet' },
                            React.createElement(SingleNeutralCircleAlternate, null),
                            React.createElement("span", null,
                                isUserAdmin && React.createElement(FormattedMessage, { id: "general.my_account" }),
                                !isUserAdmin && React.createElement(FormattedMessage, { id: "general.my_profile" }))),
                        isUserAdmin && (React.createElement(MenuLink, { to: `/firma/${company.company_url}` },
                            React.createElement(SingleNeutralCircleAlternate, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.profile_public_company" })))),
                        React.createElement(MenuLink, { onClick: logout },
                            React.createElement(Logout2, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.log_off" }))))),
                    !user && (React.createElement(React.Fragment, null,
                        React.createElement(MenuContentSection, { marginTop: true },
                            React.createElement(FormattedMessage, { id: "general.account" })),
                        React.createElement(MenuLink, { to: "/prihlasenie" },
                            React.createElement(LoginKey2, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.login" }))),
                        React.createElement(MenuLink, { to: "/registracia-uchadzac" },
                            React.createElement(SingleMan, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.registration_candidate" }))),
                        React.createElement(MenuLink, { to: "/registracia" },
                            React.createElement(BuildingsModern, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.registration_company" }))))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.for_candidates" })),
                    React.createElement(MenuLink, { to: "/ponuky" },
                        React.createElement(BrowserPageText2, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.job_offers" }))),
                    React.createElement(MenuLink, { to: "/uchadzac" },
                        React.createElement(StyleTwoPinInformation, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.service_description" }))),
                    React.createElement(MenuLink, { href: "#", onClick: e => {
                            e.preventDefault();
                            setIsSubscriptionModalOpen(true);
                        } },
                        React.createElement(EmailActionDownload, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.watchdog" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.for_employers" })),
                    React.createElement(MenuLink, { to: "/firmy" },
                        React.createElement(CommonFileTextCash, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.service_description_and_pricing" }))),
                    React.createElement(MenuLink, { to: "/premium-firemny-profil" },
                        React.createElement(PremiumIcon, { width: 24, height: 24 }),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.company_premium_profile" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.extra" })),
                    React.createElement(MenuLink, { to: "/magazin" },
                        React.createElement(CoffeeRead, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.magazine" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.contact_us" })),
                    React.createElement(MenuLink, { to: "/o-nas" },
                        React.createElement(MultipleCircle, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.team" }))),
                    React.createElement(MenuLink, { to: "/kontakt" },
                        React.createElement(EmailActionUnread, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.contact_form" }))),
                    React.createElement(MenuLink, { href: "mailto:info@pracuj.sk" },
                        React.createElement(ReadEmailAtAlternate, null),
                        React.createElement("span", null, "info@pracuj.sk")),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.social_networks" })),
                    React.createElement(MenuLink, { href: "https://www.facebook.com/Pracuj.sk", target: "_blank" },
                        React.createElement(SocialMediaFacebook1, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.brand_name" }),
                            " \u2014 ",
                            React.createElement(FormattedMessage, { id: "general.claim" }))),
                    React.createElement(MenuLink, { href: "https://www.instagram.com/pracuj.sk", target: "_blank" },
                        React.createElement(SocialInstagram, null),
                        React.createElement("span", null, "Instagram")),
                    React.createElement(MenuLink, { href: "https://www.linkedin.com/company/pracuj", target: "_blank" },
                        React.createElement(ProfessionalNetworkLinkedin, null),
                        React.createElement("span", null, "LinkedIn")))))));
};
export { HeaderMenu };
