import React from 'react';
import { Form, Row, Col } from 'antd';
import { Input } from '@src/components/elements';
import { CandidateRegistrationFormField } from '@src/types';
export function Passwords({ rules, intl }) {
    const { passwordRules, repeatPasswordRules } = rules;
    return (React.createElement(Row, { gutter: [16, 16] },
        React.createElement(Col, { xs: 24, lg: 12 },
            React.createElement(Form.Item, { name: CandidateRegistrationFormField.password, rules: passwordRules, label: intl.formatMessage({
                    id: 'registration_student.registration_student_form.password',
                }), validateFirst: true },
                React.createElement(Input.Password, { autoComplete: "off" }))),
        React.createElement(Col, { xs: 24, lg: 12 },
            React.createElement(Form.Item, { name: CandidateRegistrationFormField.password_repeat, rules: repeatPasswordRules, label: intl.formatMessage({
                    id: 'registration_student.registration_student_form.passwordRepeat',
                }), validateFirst: true },
                React.createElement(Input.Password, { autoComplete: "off" })))));
}
