import Cookie from 'js-cookie';
import * as Api from '@src/api';
const PAGE_VIEW_CODE_NAME = 'pracuj-tracking';
const PROMOTED_OFFER_VIEW_CODE_NAME = 'pracuj-promoted-tracking';
function getThisMidnight() {
    return new Date(new Date().setHours(24, 0, 0, 0));
}
export function startTracking(name) {
    let cookie = Cookie.get(name);
    if (!cookie) {
        const expires = getThisMidnight();
        Cookie.set(name, '', { expires });
        cookie = Cookie.get(name);
    }
    return cookie;
}
export const trackPageView = async () => {
    try {
        let cookie = Cookie.get(PAGE_VIEW_CODE_NAME);
        if (!cookie) {
            cookie = startTracking(PAGE_VIEW_CODE_NAME);
        }
        const { pathname } = window.location;
        const wasPathnameVisited = cookie.split(',').includes(pathname);
        if (!wasPathnameVisited) {
            const expires = getThisMidnight();
            Cookie.set(PAGE_VIEW_CODE_NAME, `${cookie},${pathname}`, { expires });
            await Api.Analytics.incrementPageView(pathname);
        }
    }
    catch (err) {
        console.error(err);
    }
};
function checkIncrementConditions(search, wasPathnameVisited, pathname) {
    if (!search || wasPathnameVisited) {
        return false;
    }
    if (!search.includes('isPromoted=true') || !pathname.match(/(\/ponuka|\/offer)\/.+-\d+$/).length) {
        return false;
    }
    return true;
}
export const trackPromotedOfferView = async () => {
    try {
        let cookie = Cookie.get(PROMOTED_OFFER_VIEW_CODE_NAME);
        if (!cookie) {
            cookie = startTracking(PROMOTED_OFFER_VIEW_CODE_NAME);
        }
        const { search, pathname } = window.location;
        const wasPathnameVisited = cookie.split(',').includes(pathname);
        if (checkIncrementConditions(search, wasPathnameVisited, pathname)) {
            const expires = getThisMidnight();
            Cookie.set(PROMOTED_OFFER_VIEW_CODE_NAME, `${cookie},${pathname}`, { expires });
            await Api.Analytics.incrementPromotedOfferView(pathname);
        }
    }
    catch (err) {
        console.error(err);
    }
};
