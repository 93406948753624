import { client as axios } from './clients/axios';
export async function setStage(id, stage) {
    try {
        const response = await axios.put(`/0/offer-application/${id}/set-stage`, { stage });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function updateViewedAt(id, viewedAt) {
    try {
        const response = await axios.patch(`/0/offer-application/${id}`, { viewed_at: viewedAt });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function sendGroupedMessage(groups, language) {
    try {
        const response = await axios.put(`/0/offer-application/send-grouped-message`, { groups }, {
            params: { language },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getMyOfferApplicationsStatuses(page) {
    try {
        const response = await axios.get(`/0/offer-application/statuses`, { params: { page } });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getMyOfferApplicationStatus(applicationId) {
    try {
        const response = await axios.get(`/0/offer-application/status`, {
            params: { applicationId },
        });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function getOfferApplicationByOfferId(offerId) {
    const response = await axios.get(`/0/offer-application/${offerId}`);
    return response.data;
}
export async function updateGroupViewedAt(offerApplicationIds, viewedAt, offerId) {
    const response = await axios.patch(`/0/offer-application/viewed-at-group`, {
        viewedAt,
        offerApplicationIds,
        offerId,
    });
    return response.data;
}
export async function setStageGroup(offerApplicationIds, stage, offerId) {
    const response = await axios.patch('/0/offer-application/set-stage-group', {
        offerApplicationIds,
        stage,
        offerId,
    });
    return response.data;
}
export async function getAllOfferApplicationsCount() {
    return (await axios.get('/0/offer-application/count-all')).data;
}
