import React from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as Api from '@src/api';
import { AssetType } from '@src/types';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { useAssetLibraryNotifications } from './use-asset-library-notifications';
function useAssetDownload() {
    const [isDownloadingAssets, setIsDownloadingAssets] = React.useState(false);
    const { showGeneralErrorNotification } = useAssetLibraryNotifications();
    const getDOcumentAssetBlob = async (downloadUrl) => {
        const response = await axios.get(downloadUrl, { responseType: 'blob' });
        return response.data;
    };
    const getImageAssetBlob = async (cloudinaryPublicId) => {
        const assetImageUrl = getCdnImageUrl({
            file: cloudinaryPublicId,
            shouldIncludeEnvironmentPart: false,
            addWatermarkedLogo: true,
        });
        const response = await axios.get(assetImageUrl, { responseType: 'blob' });
        return response.data;
    };
    const getVideoAssetBlob = async (muxAssetId) => {
        const downloadUrl = await Api.Asset.waitForVideoMp4VersionToBeProcessedAndGetDownloadUrl(muxAssetId);
        const response = await axios.get(downloadUrl, { responseType: 'blob' });
        return response.data;
    };
    const downloadAndSaveAssets = async (assets) => {
        try {
            setIsDownloadingAssets(true);
            const zip = new JSZip();
            await Promise.all(assets.map(async (asset) => {
                if (asset.type === AssetType.DOCUMENT) {
                    const documentAssetBlob = await getDOcumentAssetBlob(asset.url);
                    zip.file(asset.filename, documentAssetBlob);
                }
                if (asset.type === AssetType.IMAGE) {
                    const imageAssetBlob = await getImageAssetBlob(asset.cloudinaryPublicId);
                    zip.file(asset.filename, imageAssetBlob);
                }
                if (asset.type === AssetType.VIDEO) {
                    const videoAssetBlob = await getVideoAssetBlob(asset.muxAssetId);
                    zip.file(asset.filename, videoAssetBlob);
                }
            }));
            const zipFile = await zip.generateAsync({ type: 'blob' });
            saveAs(zipFile, `pracuj_assets_${Date.now()}.zip`);
        }
        catch (err) {
            console.error(err);
            showGeneralErrorNotification();
        }
        finally {
            setIsDownloadingAssets(false);
        }
    };
    return { downloadAndSaveAssets, isDownloadingAssets };
}
export { useAssetDownload };
