import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
export const AssetContainer = styled.div `
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 100vw !important;
	height: 100vh;
`;
export const AssetImg = styled.img `
	max-width: 95%;
	max-height: 95%;
	object-fit: contain;
`;
export const CarouselArrowContainer = styled.div `
	position: absolute;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	background-color: ${props => props.theme.color.gray2};
	border-radius: 100%;
	z-index: 1001;
	pointer-events: auto;
	font-size: 1.5rem;
	cursor: pointer;
	transform: translateY(-50%);
	${props => props.position === 'left'
    ? css `
					left: 1rem;
			  `
    : css `
					right: 1rem;
			  `}
	${down('sm')} {
		font-size: 1rem;
		width: 2rem;
		height: 2rem;
	}
`;
