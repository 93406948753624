import { CompanyRegistrationFormField } from '@src/types';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
export function PersonalInformationForm({ intl, rules }) {
    const { firstNameRules, lastNameRules, titleAfterRules, titleBeforeRules, telephoneRules } = rules;
    const [isInitialized, setIsInitialized] = React.useState(false);
    React.useEffect(() => {
        setIsInitialized(true);
    }, []);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: "form__title" },
            React.createElement(FormattedMessage, { id: "registration.registration_form.personal_info" })),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.first_name',
                    }), name: CompanyRegistrationFormField.first_name, rules: firstNameRules },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.last_name',
                    }), name: CompanyRegistrationFormField.last_name, rules: lastNameRules },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.telephone',
                    }), name: CompanyRegistrationFormField.telephone, rules: telephoneRules, validateTrigger: "onBlur" },
                    React.createElement(Input, null))),
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.title_before',
                    }), name: CompanyRegistrationFormField.title_before, rules: titleBeforeRules },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.title_after',
                    }), name: CompanyRegistrationFormField.title_after, rules: titleAfterRules },
                    React.createElement(Input, null))))));
}
