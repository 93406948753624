import { SocketEvents } from '@src/types';
import React from 'react';
import * as Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { Auth } from '@src/api';
import { notification } from 'antd';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { Queries } from '@src/types/queries';
import { useSocket } from './socket-context';
import { useAccount } from './account-context';
const AuthContext = React.createContext({
    isModalOpen: false,
    setIsModalOpen: () => { },
    checkAuthentication: () => { },
    authModalPromise: { resolve: () => { }, reject: () => { } },
    logout: async () => { },
});
export const useAuthContextProvider = () => {
    const socket = useSocket();
    const intl = useIntl();
    const SSRFetchClient = useSSRFetchClient();
    const queryClient = useQueryClient();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [authModalPromise, setAuthModalPromise] = React.useState({
        resolve: () => { },
        reject: () => { },
    });
    const account = useAccount();
    const location = useLocation();
    const history = useHistory();
    React.useEffect(() => {
        setIsModalOpen(false);
    }, [location]);
    const checkAuthentication = () => {
        if (account) {
            return Promise.resolve(account);
        }
        setIsModalOpen(true);
        return new Promise((resolve, reject) => {
            setAuthModalPromise({ resolve, reject });
        });
    };
    const { mutateAsync: mutateLogout } = useAppMutation(Auth.logout, {
        onSuccess: () => {
            if (typeof FB !== 'undefined') {
                FB === null || FB === void 0 ? void 0 : FB.logout();
            }
            Cookies.remove('connect.sid');
            Cookies.remove('pracujSocketCookieSession');
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            queryClient.invalidateQueries(Queries.getCompany);
            socket.emit(SocketEvents.ACCOUNT_LOG_OUT, account);
            notification.success({
                message: intl.formatMessage({ id: 'general.notice' }),
                description: intl.formatMessage({ id: 'logout.logged_out_successfully' }),
            });
            history.pushLocalized('/');
        },
    });
    return {
        AuthContextProvider: AuthContext.Provider,
        value: {
            isModalOpen,
            setIsModalOpen,
            checkAuthentication,
            authModalPromise,
            logout: async () => {
                await mutateLogout();
            },
        },
    };
};
export const useAuthContextValue = () => {
    const context = React.useContext(AuthContext);
    return context;
};
