import styled from 'styled-components';
import { down } from 'styled-breakpoints';
export const GalleryAssetsContainer = styled.div `
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 1rem 0;
	gap: 1rem;

	${down('sm')} {
		width: auto;
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: 0.25rem;
	}
`;
export const GalleryAssetCardContainer = styled.div `
	width: 100px;
	height: 100px;
	border-radius: ${props => props.theme.borderRadius.base};
	background-color: ${props => props.theme.color.gray9};
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	cursor: pointer;
	margin-bottom: 0.25rem;
	${down('sm')} {
		flex-shrink: 0;
		border-radius: 2px;
	}
`;
export const AssetImg = styled.img `
	width: 100%;
	height: 100%;
	border-radius: inherit;
	object-fit: cover;
`;
