import { client as axios } from './clients/axios';
export async function postOfferDraft(data) {
    const response = await axios.post('/0/offer/draft', data);
    return response.data;
}
export async function getOffer(id, slug, fetchForEdit) {
    const response = await axios.get('/0/offer', {
        params: {
            id,
            edit: fetchForEdit,
            slug,
        },
    });
    return response.data;
}
export async function updateOffer(offerId, data) {
    const response = await axios.put('/0/offer', data, { params: { id: offerId } });
    return response.data;
}
export async function archiveOffer(id) {
    const response = await axios.put('/0/offer/archive', {
        id,
    });
    return response.data;
}
export async function createOffer(data) {
    const response = await axios.post('/0/offer', data);
    return response.data.offerId;
}
export async function getOfferTypes() {
    const response = await axios.get('/0/offer-type', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}
export async function fetchOfferFromExternalLink(link) {
    const response = await axios.get('/0/offer/load-external-offer', { params: { link } });
    return response.data;
}
