import React from 'react';
import { Upload } from 'antd';
import { CloseOutlined, DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
const AssetLibraryActionButtonsContainer = styled.div `
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
`;
export function AssetLibraryActionButtons({ selectedAssetIdsCount, enqueueAssetFileForUpload, isUploadDisabled, isDownloadingAssets, isDeletingAssets, prompSelectedAssetsDeletion, downloadAndSaveSelectedAssets, isSelectedProcessingVideoAsset, deselectAllAssets, }) {
    return (React.createElement(AssetLibraryActionButtonsContainer, null,
        (selectedAssetIdsCount > 0 || isDownloadingAssets || isDeletingAssets) && (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "default", size: "middle", icon: React.createElement(CloseOutlined, null), onClick: deselectAllAssets },
                "\u00A0",
                React.createElement(FormattedMessage, { id: "assets.buttons.cancel_selection" })),
            React.createElement(Button, { type: "primary", danger: true, size: "middle", icon: React.createElement(DeleteOutlined, null), loading: isDeletingAssets, disabled: isDownloadingAssets, onClick: prompSelectedAssetsDeletion },
                "\u00A0",
                React.createElement(FormattedMessage, { id: "assets.buttons.delete_selected", values: { selectedAssetIdsCount } })),
            React.createElement(Button, { type: "default", size: "middle", icon: React.createElement(DownloadOutlined, null), onClick: downloadAndSaveSelectedAssets, loading: isSelectedProcessingVideoAsset || isDownloadingAssets, disabled: isDeletingAssets },
                "\u00A0",
                React.createElement(FormattedMessage, { id: "assets.buttons.download_selected", values: { selectedAssetIdsCount } })))),
        React.createElement(Upload, { disabled: isUploadDisabled, showUploadList: false, customRequest: options => enqueueAssetFileForUpload(options.file), multiple: true },
            React.createElement(Button, { icon: React.createElement(UploadOutlined, null), size: "middle", type: "default", disabled: isUploadDisabled },
                "\u00A0",
                React.createElement(FormattedMessage, { id: "assets.buttons.upload.short" })))));
}
