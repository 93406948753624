import { AccountType } from './account';
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["COMPANY_SUBSCRIPTION_NOTIFICATION"] = 1] = "COMPANY_SUBSCRIPTION_NOTIFICATION";
    NotificationType[NotificationType["OFFER_SUBSCRIPTION_NOTIFICATION"] = 2] = "OFFER_SUBSCRIPTION_NOTIFICATION";
    NotificationType[NotificationType["NEW_COMPANY_SUBSCRIBER_NOTIFICATION"] = 3] = "NEW_COMPANY_SUBSCRIBER_NOTIFICATION";
    NotificationType[NotificationType["HIRING_PROCESS_RESULT_NOTIFICATION"] = 4] = "HIRING_PROCESS_RESULT_NOTIFICATION";
    NotificationType[NotificationType["HIRING_PROCESS_CLOSED_NOTIFICATION"] = 5] = "HIRING_PROCESS_CLOSED_NOTIFICATION";
    NotificationType[NotificationType["NEW_OFFER_APPLICATION_NOTIFICATION"] = 6] = "NEW_OFFER_APPLICATION_NOTIFICATION";
    NotificationType[NotificationType["NEW_CANDIDATE_INTERVIEW_NOTIFICATION"] = 7] = "NEW_CANDIDATE_INTERVIEW_NOTIFICATION";
    NotificationType[NotificationType["COMPANY_VIEWED_RESUME_NOTIFICATION"] = 8] = "COMPANY_VIEWED_RESUME_NOTIFICATION";
    NotificationType[NotificationType["CREDITS_EXPIRATION_NOTIFICATION"] = 9] = "CREDITS_EXPIRATION_NOTIFICATION";
    NotificationType[NotificationType["COMPANY_OFFER_DEADLINE_NOTIFICATION"] = 10] = "COMPANY_OFFER_DEADLINE_NOTIFICATION";
    NotificationType[NotificationType["CREDITS_PAID_NOTIFICATION"] = 11] = "CREDITS_PAID_NOTIFICATION";
})(NotificationType || (NotificationType = {}));
export const NotificationSenderAccountTypeMap = {
    [NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION]: AccountType.company,
    [NotificationType.OFFER_SUBSCRIPTION_NOTIFICATION]: AccountType.company,
    [NotificationType.HIRING_PROCESS_RESULT_NOTIFICATION]: AccountType.company,
    [NotificationType.NEW_CANDIDATE_INTERVIEW_NOTIFICATION]: AccountType.company,
    [NotificationType.COMPANY_VIEWED_RESUME_NOTIFICATION]: AccountType.company,
    [NotificationType.COMPANY_OFFER_DEADLINE_NOTIFICATION]: AccountType.company,
    [NotificationType.NEW_COMPANY_SUBSCRIBER_NOTIFICATION]: AccountType.applicant,
    [NotificationType.HIRING_PROCESS_CLOSED_NOTIFICATION]: AccountType.applicant,
    [NotificationType.NEW_OFFER_APPLICATION_NOTIFICATION]: AccountType.applicant,
    [NotificationType.CREDITS_EXPIRATION_NOTIFICATION]: AccountType.applicant,
    [NotificationType.CREDITS_PAID_NOTIFICATION]: AccountType.applicant,
};
