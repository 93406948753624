import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { AccountType } from '@src/types';
export function getCdnImageUrlForAccountType(imageSrc, accountType, transformation) {
    if (!imageSrc) {
        return null;
    }
    const cdnFolder = accountType === AccountType.company ? 'company' : 'account';
    return getCdnImageUrl({
        file: `web/${cdnFolder}/${imageSrc}`,
        transformation,
    });
}
