var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../Button';
function getBase64(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(file);
    });
}
const showBigSizeNotification = (intl, fileSize) => {
    notification.error({
        message: intl.formatMessage({
            id: 'profile.resume.notification',
            defaultMessage: 'Upozornenie',
        }),
        description: `${intl.formatMessage({
            id: 'profile.resume.max_img_size',
            defaultMessage: 'Maximálna veľkosť obrázku je',
        })} ${fileSize}MB`,
        placement: 'bottomRight',
    });
};
function FileUpload(_a) {
    var { name, accept, maxFileSize, wholeFileList, setFileList } = _a, props = __rest(_a, ["name", "accept", "maxFileSize", "wholeFileList", "setFileList"]);
    const intl = useIntl();
    const checkFileSize = React.useCallback((e, fileSizeMb) => {
        const isFileSizeInBounds = e.size / 1024 / 1024 < fileSizeMb;
        if (!isFileSizeInBounds) {
            showBigSizeNotification(intl, fileSizeMb);
            setFileList([]);
        }
        return isFileSizeInBounds;
    }, []);
    return (React.createElement(Upload, Object.assign({}, props, { beforeUpload: e => checkFileSize(e, maxFileSize), action: file => getBase64(file), onChange: ({ file, fileList }) => {
            if (file.status) {
                props.onChange(fileList);
                setFileList(fileList);
            }
        }, accept: accept, name: name, fileList: wholeFileList, maxCount: props.maxCount || 1 }),
        React.createElement(Button, { size: props.size || 'middle', type: "default", icon: React.createElement(UploadOutlined, { className: "m-r-s" }) },
            React.createElement(FormattedMessage, { id: "registration.registration_form.upload", defaultMessage: "Upload" }))));
}
export { FileUpload };
