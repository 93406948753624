/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';
import { Link, Holder, Icon } from '@src/components/elements';
import { Row, Col } from 'antd';
import { up } from 'styled-breakpoints';
// TODO: move to a global modal component
import { OfferNotificationSubscriptionModal } from '@src/components/pages/offers/modules/OfferNotificationSubscriptionModal';
import { useAccount } from '@src/context/account-context';
import { LanguageSelector } from '../../elements/LanguageSelector/LanguageSelector';
const FooterWrapper = styled.footer `
	padding: 5rem 0 0;
	border-top: 1px solid ${props => props.theme.color.gray3};
	background: ${props => props.theme.color.gray1};

	&& {
		a {
			color: ${props => props.theme.color.gray6};
		}
		a:hover,
		a:active {
			text-decoration: underline;
		}
	}
`;
const Motto = styled.div `
	margin: 3rem 0 0 0;
	padding: 3rem 0 0 0;
	border-top: 1px solid ${props => props.theme.color.gray3};
	color: ${props => props.theme.color.gray4};
	text-align: center;
	letter-spacing: 1px;
	font-size: 2em;
	user-select: none;
`;
const LinkBox = styled.div `
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	li {
		margin: 0 0 0.5em;
		padding: 0;
	}
	& > * + * {
		margin: 1.5em 0 0 0;
	}
`;
const Bottom = styled.div `
	border-top: 1px solid ${props => props.theme.color.gray2};
	padding: 3rem 0 3rem 0;
	font-size: 0.9em;
`;
const Headline = styled.h4 `
	font-size: 1.2em;
	font-weight: bold;
	padding: 0 0 0.8em;
`;
const LinkWithIcon = styled.a `
	display: inline-flex;
`;
const Copyright = styled.div `
	color: ${props => props.theme.color.gray6};
	text-align: center;
	margin-top: 0.5em;
	${up('lg')} {
		margin-top: 0;
		text-align: left;
	}
`;
const Legal = styled.div `
	justify-content: flex-end;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 1em 0;
	& > * + * {
		margin: 0.5em 0 0 0;
	}
	${up('lg')} {
		flex-direction: row;
		justify-content: flex-end;
		margin: 0;
		& > * + * {
			margin: 0 0 0 2em;
		}
	}
`;
const StyledLanguageSelector = styled(LanguageSelector) `
	margin-bottom: 1em;
	${up('lg')} {
		margin-bottom: 0;
	}
`;
const StyledCol = styled(Col) `
	text-align: center;
	${up('md')} {
		text-align: left;
	}
`;
const RowPadded = styled(Row) `
	padding-bottom: 3rem;
`;
export const NavigationIcon = styled(Icon) `
	width: 1.2em;
	margin-right: 0.6em;
	margin-top: 0.1em;
	align-self: flex-start;
`;
const Footer = () => {
    const currentYear = moment().year();
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = React.useState(false);
    const user = useAccount();
    return (React.createElement(FooterWrapper, null,
        React.createElement(Holder, { className: "overflow-hidden" },
            React.createElement(RowPadded, { gutter: [32, 32] },
                React.createElement(StyledCol, { xs: 24, md: { span: 9, offset: 3, order: 1 }, lg: { offset: 0, span: 4, order: 1 } },
                    React.createElement(LinkBox, null,
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.brand_name" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/" },
                                    React.createElement(FormattedMessage, { id: "general.home" })))),
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.extra" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/magazin" },
                                    React.createElement(FormattedMessage, { id: "general.magazine" }))),
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://testzebra.com", title: "TestZebra.com" }, "TestZebra.com"))))),
                React.createElement(StyledCol, { xs: 24, md: { span: 9, offset: 3, order: 2 }, lg: { offset: 0, span: 4, order: 2 } },
                    React.createElement(LinkBox, null,
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.candidates" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/prihlasenie" },
                                    React.createElement(FormattedMessage, { id: "general.login" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: user ? '/registracia-prihlaseny-pouzivatel' : '/registracia-uchadzac' },
                                    React.createElement(FormattedMessage, { id: "general.registration" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/uchadzac" },
                                    React.createElement(FormattedMessage, { id: "general.service_description" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/ponuky" },
                                    React.createElement(FormattedMessage, { id: "general.job_offers" }))),
                            React.createElement("li", null,
                                React.createElement("a", { href: "#", onClick: e => {
                                        e.preventDefault();
                                        setIsSubscriptionModalOpen(true);
                                    } },
                                    React.createElement(FormattedMessage, { id: "general.watchdog" })))))),
                React.createElement(StyledCol, { xs: 24, md: { span: 9, offset: 3, order: 3 }, lg: { offset: 0, span: 4, order: 3 } },
                    React.createElement(LinkBox, null,
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.employers" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/prihlasenie" },
                                    React.createElement(FormattedMessage, { id: "general.login" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: user ? '/registracia-prihlaseny-pouzivatel' : '/registracia' },
                                    React.createElement(FormattedMessage, { id: "general.registration" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/firmy" },
                                    React.createElement(FormattedMessage, { id: "general.pricing" }))),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/zoznam-zamestnavatelov" },
                                    React.createElement(FormattedMessage, { id: "companies_list" })))))),
                React.createElement(StyledCol, { xs: 24, md: { span: 9, offset: 3, order: 4 }, lg: { offset: 2, span: 6, order: 4 } },
                    React.createElement(LinkBox, null,
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.social_networks" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(LinkWithIcon, { href: "https://www.facebook.com/Pracuj.sk" },
                                    React.createElement(NavigationIcon, { type: "social-facebook" }),
                                    React.createElement(FormattedMessage, { id: "general.brand_name" }),
                                    " \u2014",
                                    ' ',
                                    React.createElement(FormattedMessage, { id: "general.claim" }))),
                            React.createElement("li", null,
                                React.createElement(LinkWithIcon, { href: "https://www.instagram.com/pracuj.sk" },
                                    React.createElement(NavigationIcon, { type: "social-instagram" }),
                                    " Instagram")),
                            React.createElement("li", null,
                                React.createElement(LinkWithIcon, { href: "https://www.linkedin.com/company/pracuj" },
                                    React.createElement(NavigationIcon, { type: "social-linkedin" }),
                                    " LinkedIn"))))),
                React.createElement(StyledCol, { xs: 24, md: { span: 9, offset: 3, order: 4 }, lg: { offset: 0, span: 4, order: 5 } },
                    React.createElement(LinkBox, null,
                        React.createElement(Headline, null,
                            React.createElement(FormattedMessage, { id: "general.contact" })),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/kontakt" },
                                    React.createElement(FormattedMessage, { id: "general.contact_form" }))),
                            React.createElement("li", null,
                                React.createElement("a", { href: "mailto:info@pracuj.sk" }, "info@pracuj.sk"))))))),
        React.createElement(Holder, null,
            React.createElement(Bottom, null,
                React.createElement(Row, { align: "middle" },
                    React.createElement(Col, { xs: { span: 24, order: 2 }, lg: { span: 12, order: 1 } },
                        React.createElement(Copyright, null,
                            React.createElement(FormattedMessage, { id: "footer.copyright", values: {
                                    currentYear,
                                } }),
                            React.createElement("br", null),
                            React.createElement(FormattedMessage, { id: "footer.development_partner" }),
                            "\u00A0",
                            React.createElement("strong", null,
                                React.createElement("a", { href: "https://templarstudio.com", title: "TemplarStudio.com - Design & Software" }, "TemplarStudio.com")))),
                    React.createElement(Col, { xs: { span: 24, order: 1 }, lg: { span: 12, order: 2 } },
                        React.createElement(Legal, null,
                            React.createElement(StyledLanguageSelector, null),
                            React.createElement(Link, { to: "/obchodne-podmienky" },
                                React.createElement(FormattedMessage, { id: "footer.business_conditions" })),
                            React.createElement(Link, { to: "/ochrana-osobnych-udajov" },
                                React.createElement(FormattedMessage, { id: "footer.privacy" }))))),
                React.createElement(Motto, null,
                    React.createElement(FormattedMessage, { id: "general.claim" })))),
        React.createElement(OfferNotificationSubscriptionModal, { isOpen: isSubscriptionModalOpen, setIsOpen: setIsSubscriptionModalOpen })));
};
export { Footer };
