import { Icon } from '@src/components/elements';
import { EOfferType } from '@src/types';
import React from 'react';
export function getOfferTypeTagIcon(offerTypeId) {
    switch (offerTypeId) {
        case EOfferType.internship:
            return React.createElement(Icon, { type: "man-graduate" });
        case EOfferType.permanent:
            return React.createElement(Icon, { type: "business-deal-handshake" });
        case EOfferType.temporary_job:
            return React.createElement(Icon, { type: "profession-man-constructor" });
        case EOfferType.volunteering:
            return React.createElement(Icon, { type: "heart-hands-handshake" });
        case EOfferType.work_and_travel:
            return React.createElement(Icon, { type: "sailing-person" });
        case EOfferType.contractor:
            return React.createElement(Icon, { type: "briefcase-contractor" });
        default:
            break;
    }
}
