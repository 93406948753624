export var CompanyEmptyFieldsNames;
(function (CompanyEmptyFieldsNames) {
    CompanyEmptyFieldsNames["description"] = "description";
    CompanyEmptyFieldsNames["title_image_url"] = "title_image_url";
    CompanyEmptyFieldsNames["website"] = "website";
    CompanyEmptyFieldsNames["image"] = "image";
    CompanyEmptyFieldsNames["social_media"] = "social_media";
    CompanyEmptyFieldsNames["company_url"] = "company_url";
    CompanyEmptyFieldsNames["name"] = "name";
    CompanyEmptyFieldsNames["city"] = "city";
    CompanyEmptyFieldsNames["country"] = "country";
})(CompanyEmptyFieldsNames || (CompanyEmptyFieldsNames = {}));
export var CompanyInfoEditFormField;
(function (CompanyInfoEditFormField) {
    CompanyInfoEditFormField["name"] = "name";
    CompanyInfoEditFormField["street_address"] = "street_address";
    CompanyInfoEditFormField["city"] = "city";
    CompanyInfoEditFormField["postal_code"] = "postal_code";
    CompanyInfoEditFormField["cin"] = "cin";
    CompanyInfoEditFormField["description"] = "description";
    CompanyInfoEditFormField["tin"] = "tin";
    CompanyInfoEditFormField["tin_vat"] = "tin_vat";
    CompanyInfoEditFormField["website"] = "website";
    CompanyInfoEditFormField["area"] = "area";
    CompanyInfoEditFormField["employees"] = "employees";
    // keep those camelCase names for onSocialInputChange function
    CompanyInfoEditFormField["facebook_url"] = "facebookUrl";
    CompanyInfoEditFormField["instagram_url"] = "instagramUrl";
    CompanyInfoEditFormField["twitter_url"] = "twitterUrl";
    CompanyInfoEditFormField["linkedin_url"] = "linkedinUrl";
    CompanyInfoEditFormField["youtube_url"] = "youtubeUrl";
    CompanyInfoEditFormField["image"] = "image";
    CompanyInfoEditFormField["title_image"] = "title_image";
    CompanyInfoEditFormField["company_url"] = "company_url";
    CompanyInfoEditFormField["companyUrl"] = "companyUrl";
    CompanyInfoEditFormField["address"] = "address";
})(CompanyInfoEditFormField || (CompanyInfoEditFormField = {}));
