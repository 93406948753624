import React from 'react';
import { Holder, Panel, Title, Button } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
import { useShouldRedirectToAccountIfLoggedIn } from '@src/hooks/use-redirect-to-account-if-logged-in';
import config from '@src/config';
import { useAccount } from '@src/context/account-context';
import { useAuthContextValue } from '@src/context/auth-context';
const AuthenticatedRegistrationWarning = () => {
    const history = useHistory();
    const user = useAccount();
    const { logout } = useAuthContextValue();
    const isUserAdmin = (user === null || user === void 0 ? void 0 : user.accountTypeId) === config.accountType.company;
    const useProfileDestination = useShouldRedirectToAccountIfLoggedIn();
    const logoutAndRedirectToRegistration = async () => {
        await logout();
        const registrationPath = isUserAdmin ? '/registracia-uchadzac' : '/registracia';
        history.pushLocalized(registrationPath);
    };
    // This should be updated with Antd - Result component
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, lg: { span: 12 } },
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "registration.impossible" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, xl: { span: 12 } },
                React.createElement(Panel, { className: "text-center flex-col align-center" },
                    React.createElement(FormattedMessage, { id: "registration.we_are_sorry" }),
                    React.createElement("div", { className: "m-t-l flex-row" },
                        React.createElement(Button, { link: useProfileDestination, type: "default", className: "m-r-l" },
                            React.createElement(FormattedMessage, { id: "registration.continue_to_account" })),
                        React.createElement(Button, { onClick: logoutAndRedirectToRegistration, type: "primary" },
                            React.createElement(FormattedMessage, { id: "registration.logout_and_register" }))))))));
};
export { AuthenticatedRegistrationWarning };
