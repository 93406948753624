import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '@src/hooks/useConfig';
import { SocialIconFacebook, SocialIconLinkedIn, SocialIconInstagram, SocialIconTwitter, SocialIconYoutube, SocialIconWrapper, SocialIconLink, SocialIconsContainer, TitleWrapper, SocialLinksWrapper, } from './SocialLinks.style';
const SocialLinks = ({ company }) => {
    const config = useConfig();
    return (React.createElement(SocialLinksWrapper, null,
        React.createElement(TitleWrapper, null,
            React.createElement(FormattedMessage, { id: "company.social_media" })),
        React.createElement(SocialIconsContainer, null,
            Boolean(company.facebookUrl) && (React.createElement(SocialIconWrapper, null,
                React.createElement(SocialIconLink, { href: `${config.socialBaseUrl.facebook}${company.facebookUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(SocialIconFacebook, { type: "social-facebook" })))),
            Boolean(company.linkedinUrl) && (React.createElement(SocialIconWrapper, null,
                React.createElement(SocialIconLink, { href: `${config.socialBaseUrl.linkedinCompany}${company.linkedinUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(SocialIconLinkedIn, { type: "social-linkedin" })))),
            Boolean(company.instagramUrl) && (React.createElement(SocialIconWrapper, null,
                React.createElement(SocialIconLink, { href: `${config.socialBaseUrl.instagram}${company.instagramUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(SocialIconInstagram, { type: "social-instagram" })))),
            Boolean(company.twitterUrl) && (React.createElement(SocialIconWrapper, null,
                React.createElement(SocialIconLink, { href: `${config.socialBaseUrl.twitter}${company.twitterUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(SocialIconTwitter, { type: "social-twitter" })))),
            Boolean(company.youtubeUrl) && (React.createElement(SocialIconWrapper, null,
                React.createElement(SocialIconLink, { href: `${config.socialBaseUrl.youtube}${company.youtubeUrl}`, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(SocialIconYoutube, { type: "social-youtube" })))))));
};
export { SocialLinks };
