import React from 'react';
import { useAuthContextValue } from '@src/context/auth-context';
import * as Api from '@src/api';
import { useAccount } from '@src/context/account-context';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
export function useCompanyProfileBoxInfo(company) {
    var _a, _b;
    const [subscribersCount, setSubscribersCount] = React.useState((_a = company === null || company === void 0 ? void 0 : company.subscribers) === null || _a === void 0 ? void 0 : _a.length);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = React.useState(false);
    const account = useAccount();
    const { checkAuthentication } = useAuthContextValue();
    const isAccountSubscribed = (_b = company === null || company === void 0 ? void 0 : company.subscribers) === null || _b === void 0 ? void 0 : _b.find(({ account_id }) => account_id === (account === null || account === void 0 ? void 0 : account.id));
    const SSRFetchClient = useSSRFetchClient();
    React.useEffect(() => {
        var _a, _b;
        if (((_a = company === null || company === void 0 ? void 0 : company.subscribers) === null || _a === void 0 ? void 0 : _a.length) !== subscribersCount) {
            setSubscribersCount((_b = company === null || company === void 0 ? void 0 : company.subscribers) === null || _b === void 0 ? void 0 : _b.length);
        }
    }, [company]);
    const toggleSubscription = React.useCallback(async () => {
        try {
            setIsSubscriptionLoading(true);
            await checkAuthentication();
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
            await Api.Company.toggleCompanySubscription(company.id);
            setSubscribersCount(isAccountSubscribed ? subscribersCount - 1 : subscribersCount + 1);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsSubscriptionLoading(false);
        }
    }, [company, isAccountSubscribed]);
    return { isAccountSubscribed, subscribersCount, toggleSubscription, isSubscriptionLoading };
}
