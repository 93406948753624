import React from 'react';
import styled from 'styled-components';
import { AssetType } from '@src/types';
import { FileTextOutlined, PlayCircleOutlined, FileImageOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
const AssetTypeTextContainer = styled.div `
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;
export function AssetTypeText({ assetType }) {
    const intl = useIntl();
    const assetTypeTitle = {
        [AssetType.DOCUMENT]: intl.formatMessage({ id: 'assets.types.document' }),
        [AssetType.IMAGE]: intl.formatMessage({ id: 'assets.types.image' }),
        [AssetType.VIDEO]: intl.formatMessage({ id: 'assets.types.video' }),
    }[assetType];
    const AssetTypeIcon = {
        [AssetType.DOCUMENT]: FileTextOutlined,
        [AssetType.IMAGE]: FileImageOutlined,
        [AssetType.VIDEO]: PlayCircleOutlined,
    }[assetType];
    return (React.createElement(AssetTypeTextContainer, null,
        React.createElement(AssetTypeIcon, null),
        assetTypeTitle));
}
