export var CandidateRegistrationFormField;
(function (CandidateRegistrationFormField) {
    CandidateRegistrationFormField["first_name"] = "first_name";
    CandidateRegistrationFormField["last_name"] = "last_name";
    CandidateRegistrationFormField["email"] = "email";
    CandidateRegistrationFormField["password"] = "password";
    CandidateRegistrationFormField["password_repeat"] = "password_repeat";
    CandidateRegistrationFormField["protection"] = "protection";
    CandidateRegistrationFormField["newsletter"] = "newsletter";
    CandidateRegistrationFormField["account_type_id"] = "account_type_id";
    CandidateRegistrationFormField["telephone"] = "telephone";
})(CandidateRegistrationFormField || (CandidateRegistrationFormField = {}));
export var CompanyRegistrationFormField;
(function (CompanyRegistrationFormField) {
    CompanyRegistrationFormField["name"] = "name";
    CompanyRegistrationFormField["street_address"] = "street_address";
    CompanyRegistrationFormField["city"] = "city";
    CompanyRegistrationFormField["postal_code"] = "postal_code";
    CompanyRegistrationFormField["cin"] = "cin";
    CompanyRegistrationFormField["description"] = "description";
    CompanyRegistrationFormField["tin"] = "tin";
    CompanyRegistrationFormField["tin_vat"] = "tin_vat";
    CompanyRegistrationFormField["website"] = "website";
    CompanyRegistrationFormField["area"] = "area";
    CompanyRegistrationFormField["employees"] = "employees";
    CompanyRegistrationFormField["first_name"] = "first_name";
    CompanyRegistrationFormField["last_name"] = "last_name";
    CompanyRegistrationFormField["telephone"] = "telephone";
    CompanyRegistrationFormField["title_before"] = "title_before";
    CompanyRegistrationFormField["title_after"] = "title_after";
    CompanyRegistrationFormField["email"] = "email";
    CompanyRegistrationFormField["password"] = "password";
    CompanyRegistrationFormField["password_repeat"] = "password_repeat";
    CompanyRegistrationFormField["protection"] = "protection";
    CompanyRegistrationFormField["newsletter"] = "newsletter";
    CompanyRegistrationFormField["company_url"] = "company_url";
})(CompanyRegistrationFormField || (CompanyRegistrationFormField = {}));
