import { Input } from '@src/components/elements';
import styled from 'styled-components';
export const CancelSubscriptionButton = styled.button `
	color: ${props => props.theme.color.gray7};
	border: none;
	text-decoration: underline;
	cursor: pointer;
	transition: all 0.167s ease;
	&:hover {
		opacity: 0.8;
	}
`;
export const RedText = styled.span `
	color: #f6512c;
	line-height: 1;
	content: '*';
	font-size: 14px;
	font-family: SimSun, sans-serif;
	margin-right: 4px;
	display: inline-block;
`;
export const RequiredWrapper = styled.div `
	padding-top: 2rem;
	margin-top: 2rem;
	border-top: 1px solid ${({ theme }) => theme.color.gray3};
	text-align: center;
	display: flex;
	justify-content: center;
`;
export const EmailInput = styled(Input) `
	max-width: 300px;
`;
