import styled from 'styled-components';
import { Select } from '@src/components/elements';
import { down } from 'styled-breakpoints';
export const SearchFilterWrapper = styled.div `
	display: flex;
	width: 100%;
	min-height: 3rem;
	margin-bottom: ${({ isTagCountInLimit }) => (isTagCountInLimit ? '1rem' : '0.5rem')};
	.ant-select-focused {
		z-index: 1;
	}

	.ant-select:hover {
		z-index: 1;
	}

	${down('md')} {
		flex-direction: column;
	}
`;
export const SelectWrapper = styled(Select) `
	width: 100%;

	.ant-select-selection-search {
		max-width: 100%;
	}
`;
