/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import * as Api from '@src/api';
import { SalaryRate, SupportedLanguage } from '@src/types';
import { useIsFirstRender } from '@src/hooks/useIsFirstRender';
export const SSRFetchConfig = {
    partners: {
        key: 'unique-initial-state-partners',
        fetch: Api.Cms.Web.getPartners,
    },
    testimonials: {
        key: 'unique-initial-state-testimonials',
        fetch: (locale = SupportedLanguage.sk) => Api.Cms.Web.getTestimonials({ locale }),
    },
    teamPeople: {
        key: 'unique-initial-state-team-people',
        fetch: Api.Cms.Web.getTeam,
    },
    companyProfile: {
        key: 'unique-initial-state-company-profile',
        fetch: (companyUniqueUrl) => Api.Company.getCompanyByUrl(companyUniqueUrl),
    },
    magazinePosts: {
        key: 'unique-initial-state-magazine-posts',
        fetch: (currentPage) => Api.Cms.Magazine.getPosts(currentPage),
    },
    magazinePostsCount: {
        key: 'unique-initial-state-magazine-posts-count',
        fetch: Api.Cms.Magazine.getPostsCount,
    },
    magazinePost: {
        key: 'unique-initial-state-magazine-post',
        fetch: (slug) => Api.Cms.Magazine.getPost({ slug }),
    },
    companiesList: {
        key: 'unique-initial-state-companies-list',
        fetch: (params) => Api.Company.getCompaniesForList(params === null || params === void 0 ? void 0 : params.search, params === null || params === void 0 ? void 0 : params.page),
    },
    companiesListPreview: {
        key: 'unique-initial-state-companies-list-preview',
        fetch: (params) => Api.Company.getCompaniesForListPreview(params.limit),
    },
    companies: {
        key: 'unique-initial-state-search-companies',
        fetch: (companiesIds) => Api.Company.getCompaniesByIds(companiesIds),
    },
    highestSalary: {
        key: 'unique-initial-state-highest-offer-salary',
        fetch: (salaryRate) => Api.Offers.getHighestSalaryByRate(salaryRate),
    },
    globalHighestSalary: {
        key: 'unique-initial-state-global-highest-offer-salary',
        fetch: () => Api.Offers.getHighestSalaryByRate(SalaryRate.monthly),
    },
    offersFilters: {
        key: 'unique-initial-state-offers-filters',
        fetch: (query) => Api.OffersFilters.getOffersFilters(query),
    },
    offers: {
        key: 'unique-initial-state-offers',
        fetch: (query) => Api.Offers.getOffers(query),
    },
    promotedOffers: {
        key: 'unique-initial-state-promoted-offers',
        fetch: (query) => Api.Offers.getPromotedOffers(query),
    },
    account: {
        key: 'unique-initial-state-account',
        fetch: Api.Account.getAccount,
    },
    offerDetail: {
        key: 'unique-initial-state-offer-detail',
        fetch: ({ offerId, offerSlug }) => Api.Offers.getOfferDetail({ offerId, offerSlug }),
    },
    suggestedSimilarOffers: {
        key: 'unique-initial-state-suggested-similar-offers',
        fetch: (offerId) => Api.Offers.getSuggestedSimilarOffers(offerId),
    },
    suggestedSameCompanyOffers: {
        key: 'unique-initial-state-suggested-same-company-offers',
        fetch: (offerId) => Api.Offers.getSuggestedSameCompanyOffers(offerId),
    },
};
export const SSRDataContext = React.createContext({
    SSRContext: {},
    setSSRContext: () => { },
});
export const useSSRDataContextProvider = () => {
    const isServer = typeof window === 'undefined';
    const initialContextValue = {};
    if (!isServer) {
        Object.values(SSRFetchConfig).forEach(({ key }) => {
            if (window[key]) {
                initialContextValue[key] = window[key];
                delete window[key];
            }
        });
    }
    const [SSRContext, setSSRContext] = React.useState(initialContextValue);
    return {
        SSRDataContextProvider: SSRDataContext.Provider,
        value: {
            SSRContext,
            setSSRContext,
        },
    };
};
export const useSSRDataContextValue = () => {
    const context = React.useContext(SSRDataContext);
    return context;
};
export const SSRFetchClient = {
    invalidateSSRContext: (key) => {
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent(key));
        }
    },
};
export function useSSRFetchClient() {
    return SSRFetchClient;
}
export function useSSRFetch(globalStateKey, fetchArgs = undefined, dependencies = []) {
    const isServer = typeof window === 'undefined';
    const { SSRContext, setSSRContext } = useSSRDataContextValue();
    const [state, setState] = React.useState(SSRContext[globalStateKey]);
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const isFirstRender = useIsFirstRender();
    const [refetchPromise, setRefetchPromise] = React.useState({
        resolve: (_value) => { },
        reject: (_error) => { },
    });
    if (isServer && state === undefined) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SSR fetch did not match route in prefetch-routes for following key:', globalStateKey);
    }
    React.useEffect(() => {
        // this fixes race condition
        let isActive = true;
        const fetch = async () => {
            var _a;
            setIsLoading(true);
            try {
                const fetchRequest = (_a = Object.values(SSRFetchConfig).find(obj => obj.key === globalStateKey)) === null || _a === void 0 ? void 0 : _a.fetch;
                const data = await fetchRequest(fetchArgs);
                if (isActive) {
                    setState(data);
                }
                refetchPromise.resolve(data);
            }
            catch (err) {
                console.error(err);
                setError(err);
                refetchPromise.reject(err);
            }
            finally {
                setIsLoading(false);
            }
        };
        if ((isFirstRender && !state) || !isFirstRender) {
            fetch();
        }
        return () => {
            isActive = false;
        };
    }, [...dependencies, refetchPromise]);
    const refetch = React.useCallback(() => {
        return new Promise((resolve, reject) => {
            setRefetchPromise({
                resolve,
                reject,
            });
        });
    }, []);
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener(globalStateKey, () => {
                if (SSRContext[globalStateKey] && setSSRContext) {
                    setSSRContext({});
                }
                refetch();
            });
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener(globalStateKey, () => {
                    refetch();
                });
            }
        };
    }, [SSRContext]);
    if (state) {
        return { data: state, isLoading, error, refetch };
    }
    return { data: null, isLoading, error, refetch };
}
