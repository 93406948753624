import React from 'react';
import { useAccount } from '@src/context/account-context';
import { EditCompanyAssetGalleryModal } from './EditCompanyAssetGalleryModal';
import { AddCompanyAssetGalleryModal } from './AddCompanyAssetGalleryModal';
import { GalleryContainer } from './GalleryContainer';
const CompanyProfileGallery = ({ company }) => {
    const [isAddGalleryModalOpen, setIsAddGalleryModalOpen] = React.useState(false);
    const [selectedAssetGalleryAssetIds, setSelectedAssetGalleryAssetIds] = React.useState([]);
    const [assetGalleryToEdit, setAssetGalleryToEdit] = React.useState(null);
    const account = useAccount();
    const isCompanyAccount = (account === null || account === void 0 ? void 0 : account.companyId) === company.id;
    return (React.createElement("div", { className: "m-t-m" },
        React.createElement(GalleryContainer, { setSelectedAssetGalleryAssetIds: setSelectedAssetGalleryAssetIds, setIsAddGalleryModalOpen: setIsAddGalleryModalOpen, setAssetGalleryToEdit: setAssetGalleryToEdit, company: company }),
        isCompanyAccount && (React.createElement(React.Fragment, null,
            React.createElement(AddCompanyAssetGalleryModal, { isAddGalleryModalOpen: isAddGalleryModalOpen, setIsAddGalleryModalOpen: setIsAddGalleryModalOpen }),
            React.createElement(EditCompanyAssetGalleryModal, { selectedAssetGalleryAssetIds: selectedAssetGalleryAssetIds, setAssetGalleryToEdit: setAssetGalleryToEdit, assetGalleryToEdit: assetGalleryToEdit })))));
};
export { CompanyProfileGallery };
