import TagManager from 'react-gtm-module';
const gtmShouldRun = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';
const initialize = () => {
    if (gtmShouldRun) {
        TagManager.initialize({
            gtmId: process.env.GTM_ID,
            auth: process.env.GTM_AUTH,
            preview: process.env.GTM_PREVIEW,
        });
    }
};
const dataLayer = (payload) => {
    if (gtmShouldRun) {
        TagManager.dataLayer({
            dataLayer: Object.assign(Object.assign({}, payload), { event: `c_${payload.event}` }),
        });
    }
};
export default { initialize, dataLayer };
