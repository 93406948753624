import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { getMuxVideoThumbnailUrl } from '@src/shared/get-mux-video-thumbnail-url';
import { AssetType } from '@src/types';
import React from 'react';
import { DetailsAssetsModal } from '@src/components/modules/AssetDetailModal';
import { FormattedMessage } from 'react-intl';
import { useAccount } from '@src/context/account-context';
import { CompanyAssetGalleryCardMenu } from './CompanyAssetGalleryCardMenu';
import { AddGalleryButton, GalleryCard, MultipleAssetsIcon, GalleryCardTitle, GalleryCardAssetImageWrapper, StyledDivOverlay, StyledPlusOutlined, GalleryCardsContainer, StyledPlayCircleOutlined, DetailIcon, } from './GalleryContainer.style';
function getAssetImgUrl(asset, width, height) {
    if (asset.type === AssetType.IMAGE) {
        return getCdnImageUrl({
            file: asset.cloudinaryPublicId,
            transformation: `/w_${width || 500},h_${height || 500},c_fill,g_faces,q_auto:best`,
            shouldIncludeEnvironmentPart: false,
            shouldOptimizeQuality: false,
        });
    }
    if (asset.type === AssetType.VIDEO) {
        return getMuxVideoThumbnailUrl({ muxPublicPlaybackId: asset.muxPublicPlaybackId, width: 200 });
    }
    throw new Error('Assets in profile gallery should be only images and videos');
}
export function GalleryCardAssetThumbnail({ asset }) {
    const [ref, setRef] = React.useState(null);
    return (React.createElement(GalleryCardAssetImageWrapper, null,
        React.createElement("img", { ref: r => setRef(r), src: getAssetImgUrl(asset, Math.round((ref === null || ref === void 0 ? void 0 : ref.getBoundingClientRect().width) || 0), Math.round((ref === null || ref === void 0 ? void 0 : ref.getBoundingClientRect().height) || 0)), alt: asset.filename })));
}
const GalleryContainer = ({ setIsAddGalleryModalOpen, setAssetGalleryToEdit, setSelectedAssetGalleryAssetIds, company, }) => {
    var _a;
    const [galleryIdToOpen, setGalleryIdToOpen] = React.useState(null);
    const [initialAssetIdToOpen, setInitialAssetIdToOpen] = React.useState(null);
    const account = useAccount();
    const assetIdsToOpen = (_a = company.companyAssetGalleries
        .find(({ id }) => galleryIdToOpen === id)) === null || _a === void 0 ? void 0 : _a.companyAssetGalleryAssets.map(({ assetId }) => assetId);
    const assetsToOpen = company.assets.filter(({ id }) => assetIdsToOpen === null || assetIdsToOpen === void 0 ? void 0 : assetIdsToOpen.includes(id));
    if (!company) {
        return null;
    }
    return (React.createElement(GalleryCardsContainer, null, company === null || company === void 0 ? void 0 :
        company.companyAssetGalleries.filter(({ isPrimaryGallery }) => !isPrimaryGallery).map(gallery => {
            var _a;
            const thumbnailAsset = (_a = company.assets) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id === gallery.companyAssetGalleryAssets[0].assetId);
            return (React.createElement(GalleryCard, { key: gallery.id },
                React.createElement(StyledDivOverlay, { onClick: () => {
                        var _a;
                        const initialAssetId = (_a = company.assets) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id === gallery.companyAssetGalleryAssets[0].assetId).id;
                        setInitialAssetIdToOpen(initialAssetId);
                        setGalleryIdToOpen(gallery.id);
                    } },
                    React.createElement(DetailIcon, null)),
                React.createElement("div", { className: "width-100 position-relative" },
                    React.createElement(GalleryCardTitle, null, gallery.title),
                    thumbnailAsset.type === AssetType.VIDEO && React.createElement(StyledPlayCircleOutlined, null),
                    React.createElement(GalleryCardAssetThumbnail, { asset: thumbnailAsset }),
                    (account === null || account === void 0 ? void 0 : account.companyId) === (company === null || company === void 0 ? void 0 : company.id) && (React.createElement(CompanyAssetGalleryCardMenu, { gallery: gallery, setSelectedAssetGalleryAssetIds: setSelectedAssetGalleryAssetIds, setAssetGalleryToEdit: setAssetGalleryToEdit })),
                    gallery.companyAssetGalleryAssets.length > 1 && React.createElement(MultipleAssetsIcon, null))));
        }),
        (account === null || account === void 0 ? void 0 : account.companyId) === company.id && (React.createElement(AddGalleryButton, { onClick: () => setIsAddGalleryModalOpen(true) },
            React.createElement("div", { className: "flex-col width-100" },
                React.createElement(StyledPlusOutlined, null),
                React.createElement(FormattedMessage, { id: "admin.company_premium_profile.add_gallery" })))),
        React.createElement(DetailsAssetsModal, { isOpen: Boolean(galleryIdToOpen), assets: assetsToOpen || [], closeModal: () => setGalleryIdToOpen(null), initialAssetId: initialAssetIdToOpen })));
};
export { GalleryContainer };
