import * as React from 'react';
import { Menu, Dropdown, notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Api from '@src/api';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { DropdownAbsoluteWrapper, IconWrapper, StopPropagationWrapper, StyledEllipsisOutlined, } from './CompanyAssetGalleryCardMenu.style';
export function CompanyAssetGalleryCardMenu({ gallery, setSelectedAssetGalleryAssetIds, setAssetGalleryToEdit, }) {
    const intl = useIntl();
    const { invalidateSSRContext } = useSSRFetchClient();
    const { mutateAsync: deleteCompanyAssetGallery } = useAppMutation(Api.CompanyAssetGallery.deleteCompanyAssetGallery, {
        onSuccess: () => {
            notification.success({
                message: intl.formatMessage({
                    id: 'general.warning',
                }),
                description: intl.formatMessage({
                    id: 'general.successfuly_removed',
                }),
            });
        },
    });
    const menuButtons = [
        {
            key: 'edit',
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                if (setSelectedAssetGalleryAssetIds) {
                    const galleryAssetsIds = gallery.companyAssetGalleryAssets.map(({ assetId }) => assetId);
                    setSelectedAssetGalleryAssetIds(galleryAssetsIds);
                }
                setAssetGalleryToEdit(gallery);
            },
            icon: React.createElement(EditOutlined, { className: "m-r-xs" }),
        },
        {
            key: 'delete',
            onClick: async ({ domEvent }) => {
                domEvent.stopPropagation();
                await deleteCompanyAssetGallery({ galleryId: gallery.id });
                invalidateSSRContext(SSRFetchConfig.companyProfile.key);
            },
            icon: React.createElement(DeleteOutlined, { className: "m-r-xs" }),
        },
    ];
    return (React.createElement(DropdownAbsoluteWrapper, null,
        React.createElement(Dropdown, { overlayStyle: { zIndex: 3000000001 }, overlay: React.createElement(Menu, { className: "flex-col" }, menuButtons.map(menuButton => (React.createElement(Menu.Item, { key: menuButton.key, onClick: menuButton.onClick },
                React.createElement(IconWrapper, null, menuButton.icon),
                React.createElement(FormattedMessage, { id: `admin.company_premium_profile.gallery_menu.${menuButton.key}` }))))), trigger: ['click'] },
            React.createElement(StopPropagationWrapper, { onClick: e => e.stopPropagation() },
                React.createElement(StyledEllipsisOutlined, null)))));
}
