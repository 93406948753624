import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { lighten, transparentize } from 'polished';
import { PremiumIcon } from '@src/components/elements';
import { useHistory } from 'react-router-dom';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { CompanyPremiumElementWrapper } from '../CompanyPremiumElementWrapper';
const ActivatePremiumCompanyProfileBanner = ({ shouldBeHiddenIfPremiumIsActive, hasActivePremiumSubscription, }) => {
    const [isPremiumBannerHovered, setIsPremiumBannedHovered] = React.useState(false);
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const history = useHistory();
    const onClickHandler = () => {
        if (!hasActivePremiumSubscription) {
            setIsPremiumCompanyProfileSubscriptionModalOpen(true);
            return;
        }
        history.pushLocalized('/admin/premium-firemny-profil');
    };
    return (React.createElement(CompanyPremiumElementWrapper, { shouldBeHiddenIfPremiumIsActive: shouldBeHiddenIfPremiumIsActive, hasActivePremiumSubscription: hasActivePremiumSubscription, onClick: onClickHandler },
        React.createElement(MainContentWrapper, { onMouseEnter: () => setIsPremiumBannedHovered(true), onMouseLeave: () => setIsPremiumBannedHovered(false) },
            React.createElement(StyledBanner, null,
                React.createElement(FormattedMessage, { id: "admin.activate_premium_profile" }),
                React.createElement(PremiumDiamondWrapper, { isPremiumBannerHovered: isPremiumBannerHovered },
                    React.createElement(PremiumIcon, { width: 16, height: 16 }))))));
};
const MainContentWrapper = styled.div `
	position: relative;
	background: ${({ theme }) => theme.color.brandDarkBlue};
	transition: all 0.3s ease;
	color: ${({ theme }) => theme.color.white};
	border-radius: 0.5rem;
	:hover {
		background: ${({ theme }) => lighten(0.1, theme.color.brandDarkBlue)};
	}
`;
const StyledBanner = styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 0.9rem;
	padding: 0.5rem 0;
	text-transform: uppercase;
`;
const PremiumDiamondWrapper = styled.div `
	color: ${({ theme }) => theme.color.gold};
	border: 2px solid ${({ theme }) => theme.color.gold};
	background: ${({ theme }) => transparentize(0.4, theme.color.gold)};
	border-radius: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	min-width: 1.5rem;
	min-height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0.5rem;
`;
export { ActivatePremiumCompanyProfileBanner };
