import React from 'react';
import { CheckCircleFilled, EyeOutlined } from '@ant-design/icons';
import { AssetType } from '@src/types/asset';
import { isTouchDevice } from '@src/helpers/is-touch-device';
import { Badge } from '@src/components/elements';
import { theme } from '@src/styles/theme';
import { FormattedMessage } from 'react-intl';
import { AssetCardContainer, AssetCardContent, AssetCardFooter, AssetCardFooterText, AssetCardOverlay, CheckCircleSlot, SelectedAssetOrder, } from './AssetCard.style';
import { AssetCardDocument } from './AssetCardDocument';
import { AssetCardVideo } from './AssetCardVideo';
import { AssetTypeText } from './AssetTypeText';
import { AssetCardImage } from './AssetCardImage';
export function AssetCard({ forceShowCheckCircle, asset, isSelected, selectedOrderIndex, isAtLeastOneAssetCardSelected, isSelectingDisabled, onAssetSelectToggle, showAssetDetail, setVideoAssetIsProcessed, deleteAssetLocally, }) {
    const [isOverlayDisplayed, setIsOverlayDisplayed] = React.useState(false);
    const handleCheckCircleSlotClick = e => {
        e.stopPropagation();
        if (!isSelectingDisabled) {
            onAssetSelectToggle();
        }
    };
    const showOverlayOrAssetDetail = () => {
        if (isTouchDevice() && !isOverlayDisplayed) {
            setIsOverlayDisplayed(true);
            return;
        }
        if (isOverlayDisplayed) {
            showAssetDetail();
        }
    };
    const showOverlayIfNotTouchDevice = () => {
        if (!isTouchDevice()) {
            setIsOverlayDisplayed(true);
        }
    };
    const hideOverlay = () => {
        setIsOverlayDisplayed(false);
    };
    const selectedOrder = selectedOrderIndex !== undefined ? selectedOrderIndex + 1 : undefined;
    return (React.createElement(Badge.Ribbon, { color: theme.color.brand1, text: React.createElement(AssetTypeText, { assetType: asset.type }) },
        React.createElement(AssetCardContainer, { shouldHaveColoredOutline: isSelected, shouldHaveLowerOpacity: isSelectingDisabled, onClick: showOverlayOrAssetDetail, onMouseEnter: showOverlayIfNotTouchDevice, onMouseLeave: hideOverlay },
            React.createElement(CheckCircleSlot, { shouldBeDisplayed: isAtLeastOneAssetCardSelected || isOverlayDisplayed || forceShowCheckCircle, shouldHaveColoredOutline: isOverlayDisplayed || isSelected, isDisabled: isSelectingDisabled, onClick: handleCheckCircleSlotClick }, isSelected && (React.createElement(React.Fragment, null,
                !selectedOrder && React.createElement(CheckCircleFilled, null),
                Boolean(selectedOrder) && React.createElement(SelectedAssetOrder, null, selectedOrder)))),
            React.createElement(AssetCardOverlay, { shouldDisplayOverlay: isOverlayDisplayed },
                React.createElement(FormattedMessage, { id: "assets.show_detail" }),
                React.createElement(EyeOutlined, null)),
            React.createElement(AssetCardContent, null,
                asset.type === AssetType.IMAGE && React.createElement(AssetCardImage, { asset: asset }),
                asset.type === AssetType.DOCUMENT && React.createElement(AssetCardDocument, { asset: asset }),
                asset.type === AssetType.VIDEO && (React.createElement(AssetCardVideo, { asset: asset, setVideoAssetIsProcessed: setVideoAssetIsProcessed, deleteAssetLocally: deleteAssetLocally }))),
            React.createElement(AssetCardFooter, null,
                React.createElement(AssetCardFooterText, null, asset.filename)))));
}
