import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Content, Picture, StyledFeatures } from './Features.style';
const features = [
    {
        name: React.createElement(FormattedMessage, { id: "recruitment.features.feature_1_headline" }),
        image: 'recruiting-1',
        text: React.createElement(FormattedMessage, { id: "recruitment.features.feature_1_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "recruitment.features.feature_2_headline" }),
        image: 'searching-network',
        text: React.createElement(FormattedMessage, { id: "recruitment.features.feature_2_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "recruitment.features.feature_3_headline" }),
        image: 'coworkers-2',
        text: React.createElement(FormattedMessage, { id: "recruitment.features.feature_3_body" }),
    },
];
const Features = () => {
    return (React.createElement(StyledFeatures, null, features.map(item => {
        return (React.createElement(Box, { key: item.image },
            React.createElement(Picture, null,
                React.createElement("img", { src: `/media/illustrations/streamline-icon-${item.image}.svg`, alt: "" })),
            React.createElement(Content, null,
                React.createElement("dt", null, item.name),
                React.createElement("dd", null, item.text))));
    })));
};
export { Features };
