import styled from 'styled-components';
import { transparentize } from 'polished';
import { Link } from '@src/components/elements';
export const NotificationsWrapper = styled.div ``;
export const NoNotificationsMessage = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	color: ${({ theme }) => theme.color.gray3};
`;
export const BottomLink = styled(Link) `
	margin: 0.4rem 0 0 0;
	padding: 0.6rem 1rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	transition: all 0.2s ease;
	border-radius: 4px;
	position: relative;

	&,
	&:link,
	&:visited,
	&:focus,
	&:hover,
	&:active {
		color: ${props => props.theme.color.brand1};
		background: ${props => transparentize(0.9, props.theme.color.brand4)};
	}
	&:hover,
	&:active {
		background: ${props => transparentize(0.8, props.theme.color.brand4)};
	}
	span {
		margin-top: 0.2rem;
		flex: unset;
	}
`;
