import React from 'react';
import * as Api from '@src/api';
import config from '@src/config';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Head, Holder } from '@src/components/elements';
import Pagination from '@src/components/elements/Pagination';
import { Offer } from '@src/components/pages/offers/modules/Offer';
import LoadingWrapper from '@src/components/modules/global/loading-wrapper';
import { FilterTags } from '@src/components/pages/offers/modules/FilterTags';
import { Filters } from '@src/components/pages/offers/modules/filters/Filters';
import MissingContentView from '@src/components/modules/MissingContentView/MissingContentView';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { useAccount } from '@src/context/account-context';
import { OfferSearchFilterWrapper } from '@src/components/pages/offers/modules/OfferSearchFilterWrapper';
import { mergePromotedOffersWithOffers } from '@src/helpers/mergePromotedOffersWithOffers';
import { useOffersFilterContextValue } from './offer-filter-context';
import { OfferNotificationSubscriptionModal } from './modules/OfferNotificationSubscriptionModal';
import { OfferNotificationSubscriptionPanel } from './modules/OfferNotificationSubscriptionPanel';
export const StyledPagination = styled(Pagination) `
	display: flex;
	justify-content: center;
	padding: 2em 0;
`;
function OffersList({ offers }) {
    const areOffersNotEmpty = offers && offers.length;
    return (React.createElement(React.Fragment, null, areOffersNotEmpty && (offers === null || offers === void 0 ? void 0 : offers.map((offer) => (React.createElement("li", { className: classNames({
            panel: true,
            'panel--offer': true,
            'panel--offer-important': offer.importantEmployer,
        }), key: `offer-${offer.id}${offer.isPromoted ? '-promoted' : null}` },
        React.createElement(Offer, { values: offer })))))));
}
function Offers() {
    const account = useAccount();
    const intl = useIntl();
    const { offers, promotedOffers, arePromotedOffersLoading, areOffersLoading, areFiltersLoading, changePage } = useOffersFilterContextValue();
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = React.useState(false);
    const [userIsSubscribed, setUserIsSubscribed] = React.useState(false);
    const mergedOffers = mergePromotedOffersWithOffers({ promotedOffers, offers });
    const isOfferListEmpty = Boolean(mergedOffers && ((Array.isArray(mergedOffers) && !mergedOffers.length) || !mergedOffers));
    const isBelowMd = useBreakpoint(down('md'));
    const fetchAndSetIsSubscribed = React.useCallback(async () => {
        try {
            const isUserLoggedIn = Boolean(account);
            if (isUserLoggedIn) {
                await Api.OfferNotificationSubscription.getSubscription();
                setUserIsSubscribed(true);
            }
            else {
                setUserIsSubscribed(false);
            }
        }
        catch (err) {
            setUserIsSubscribed(false);
        }
    }, [account]);
    React.useEffect(() => {
        fetchAndSetIsSubscribed();
    }, [fetchAndSetIsSubscribed, isSubscriptionModalOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Head, { id: "offers" }),
        React.createElement(Holder, { marginVertical: true },
            isBelowMd && React.createElement(OfferSearchFilterWrapper, null),
            React.createElement(OffersMain, null,
                React.createElement(FilterColumn, null,
                    React.createElement(Filters, { loading: areFiltersLoading }),
                    isBelowMd && (React.createElement(FilterTags, { offerCounts: {
                            offersCount: offers === null || offers === void 0 ? void 0 : offers.rowCount,
                            allOffersCount: offers === null || offers === void 0 ? void 0 : offers.allOffersCount,
                        } })),
                    !userIsSubscribed && (React.createElement(OfferNotificationSubscriptionPanel, { setIsModalOpen: setIsSubscriptionModalOpen }))),
                React.createElement(OffersColumn, null,
                    !isBelowMd && (React.createElement(React.Fragment, null,
                        React.createElement(OfferSearchFilterWrapper, null),
                        React.createElement(FilterTags, { offerCounts: {
                                offersCount: offers === null || offers === void 0 ? void 0 : offers.rowCount,
                                allOffersCount: offers === null || offers === void 0 ? void 0 : offers.allOffersCount,
                            } }))),
                    !isOfferListEmpty && offers && (React.createElement(LoadingWrapper, { isLoading: areOffersLoading && arePromotedOffersLoading, wrapperClassNames: "panel panel--offers" },
                        React.createElement("ul", { key: "list", className: "offers__list", "data-cy": "offers-list" },
                            React.createElement(OffersList, { offers: mergedOffers })))),
                    !areOffersLoading && isOfferListEmpty && (React.createElement(MissingContentView, { heading: intl.formatMessage({
                            id: 'offers.missing_content_heading',
                        }), message: intl.formatMessage({
                            id: 'offers.missing_content_message',
                        }) })),
                    !areOffersLoading && !isOfferListEmpty && offers && (React.createElement(StyledPagination, { current: offers.page, total: offers.rowCount, defaultPageSize: config.offers.itemsPerPage, onChange: changePage, showSizeChanger: false })))),
            React.createElement(OfferNotificationSubscriptionModal, { isOpen: isSubscriptionModalOpen, setIsOpen: setIsSubscriptionModalOpen }))));
}
export const OffersMain = styled.div `
	display: flex;

	${down('md')} {
		flex-direction: column;
	}
`;
export const FilterColumn = styled.div `
	min-width: 290px;
	max-width: 332px;
	flex: 1;
	margin-right: 1rem;

	${down('md')} {
		width: 100%;
		max-width: 100%;
	}
`;
export const OffersColumn = styled.div `
	flex: 1;
`;
export { Offers };
