import dayjs from 'dayjs';
export function getHasCompanyActivePremiumCompanyProfileSubscription(companySubscriptions) {
    const now = dayjs();
    const hasActivePremiumSubscription = companySubscriptions === null || companySubscriptions === void 0 ? void 0 : companySubscriptions.some(({ startDate, endDate, deletedAt }) => {
        const isStartDateBeforeNow = dayjs(startDate).isBefore(now);
        const isEndDateAfterNow = dayjs(endDate).isAfter(now);
        return isStartDateBeforeNow && isEndDateAfterNow && !deletedAt;
    });
    return Boolean(hasActivePremiumSubscription);
}
export function getCompanyActivePremiumCompanyProfileSubscription(companySubscriptions) {
    const now = dayjs();
    const activePremiumSubscription = companySubscriptions === null || companySubscriptions === void 0 ? void 0 : companySubscriptions.find(({ startDate, endDate, deletedAt }) => {
        const isStartDateBeforeNow = dayjs(startDate).isBefore(now);
        const isEndDateAfterNow = dayjs(endDate).isAfter(now);
        return isStartDateBeforeNow && isEndDateAfterNow && !deletedAt;
    });
    return activePremiumSubscription;
}
