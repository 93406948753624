import React from 'react';
import { isEqual, isFinite } from 'lodash';
import { notification } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { OfferFilterType } from '@src/types';
import { useSSRFetch, SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { SearchFilterOptionGroup } from '@src/types/offer';
import { getOffersPageFromPathname } from '@src/helpers/get-filters-states-from-pathname';
import * as Api from '@src/api';
import { useIsFirstRender } from '@src/hooks/useIsFirstRender';
import { useQueryParameters } from '@src/helpers/use-query-parameters';
import { roundSalary } from '@src/helpers/roundSalaryToTen';
import { useConvertFilterStatesTypes } from './utils/offers-filter';
const OffersFilterContext = React.createContext({
    typeFiltersSelected: [],
    locationFiltersSelected: [],
    jobAreaFiltersSelected: [],
    searchCompaniesSelected: [],
    searchKeywordsSelected: [],
    setTypeFiltersSelected: () => null,
    setLocationFiltersSelected: () => null,
    setJobAreaFiltersSelected: () => null,
    setSearchCompaniesSelected: () => null,
    setSearchKeywordsSelected: () => null,
    offers: {},
    promotedOffers: [],
    offersFiltersSSR: {},
    areOffersLoading: false,
    areFiltersLoading: false,
    arePromotedOffersLoading: false,
    changePage: () => null,
    setPage: () => null,
    salaryFilter: {},
    setSalaryFilter: () => null,
    getHighestSalary: async () => 0,
    isFilterActive: false,
    highestSalary: 0,
    companies: [],
    highestGlobalSalary: 0,
});
export function formatCombinedValues(values) {
    const { companies, keywords, skillTags } = values;
    const kws = (keywords || []).map(itemValue => ({
        value: itemValue,
        label: itemValue,
        key: itemValue,
        group: SearchFilterOptionGroup.keyword,
    }));
    const comps = (companies || []).map(({ name: itemName, id: itemId }) => ({
        value: itemId,
        label: itemName,
        key: itemId,
        group: SearchFilterOptionGroup.company,
    }));
    const skills = (skillTags || []).map(({ name: itemName, id: itemId }) => ({
        value: itemId,
        label: itemName,
        key: itemId,
        group: SearchFilterOptionGroup.skillTag,
    }));
    return [...kws, ...comps, ...skills];
}
export const useOffersFilterContextProvider = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const intl = useIntl();
    const isFirstRender = useIsFirstRender();
    const { getQueryParams, createQueryParams } = useQueryParameters();
    const filterStates = getQueryParams();
    const SSRFetchClient = useSSRFetchClient();
    const { selectedTypeIds, selectedLocations, selectedJobAreaIds, salaryMin, salaryMax, salaryRate, selectedCompaniesIds, selectedKeywords, } = React.useMemo(() => useConvertFilterStatesTypes(filterStates), [filterStates]);
    const initPage = getOffersPageFromPathname(location.pathname);
    const { data: highestSalary } = useSSRFetch(SSRFetchConfig.highestSalary.key);
    const { data: globalHighestSalary } = useSSRFetch(SSRFetchConfig.globalHighestSalary.key);
    const { data: companies, refetch: refetchCompanies } = useSSRFetch(SSRFetchConfig.companies.key, selectedCompaniesIds);
    const [highestGlobalSalary, setHighestGlobalSalary] = React.useState(roundSalary(globalHighestSalary));
    const [wasHighestSalaryChangedFromHook, setWasHighestSalaryChangedFromHook] = React.useState(!highestSalary);
    const [page, setPage] = React.useState(initPage);
    const [typeFiltersSelected, setTypeFiltersSelected] = React.useState(selectedTypeIds || []);
    const [locationFiltersSelected, setLocationFiltersSelected] = React.useState(selectedLocations || []);
    const [jobAreaFiltersSelected, setJobAreaFiltersSelected] = React.useState(selectedJobAreaIds || []);
    const [searchCompaniesSelected, setSearchCompaniesSelected] = React.useState(
    // this condition handles the case when companies are prefetched and user returns to offers page from different page
    // otherwise prefetched companies would be automatically set to state
    (selectedCompaniesIds && selectedCompaniesIds.length && companies) || []);
    const [searchKeywordsSelected, setSearchKeywordsSelected] = React.useState(selectedKeywords || []);
    const [salaryFilter, setSalaryFilter] = React.useState({
        minRange: salaryMin || 0,
        maxRange: salaryMax || roundSalary(highestSalary),
        highestSalary: roundSalary(highestSalary),
        rate: salaryRate || null,
    });
    const selectedTypeFilterItemsRef = React.useRef(typeFiltersSelected);
    const selectedLocationFilterItemsRef = React.useRef(locationFiltersSelected);
    const selectedJobAreaFiltersSelectedRef = React.useRef(jobAreaFiltersSelected);
    const searchCompaniesSelectedRef = React.useRef(searchCompaniesSelected);
    const searchKeywordsSelectedRef = React.useRef(searchKeywordsSelected);
    const salaryFilterRef = React.useRef(salaryFilter);
    const isFilterActive = typeFiltersSelected.length !== 0 ||
        locationFiltersSelected.length !== 0 ||
        jobAreaFiltersSelected.length !== 0 ||
        Boolean(salaryFilter.rate) ||
        selectedCompaniesIds.length !== 0 ||
        searchCompaniesSelected.length !== 0 ||
        searchKeywordsSelected.length !== 0;
    const getHighestSalary = async (value) => {
        try {
            const newHighestSalary = await Api.Offers.getHighestSalaryByRate(value || salaryFilter.rate);
            const maxRoundedSalary = roundSalary(newHighestSalary);
            return maxRoundedSalary;
        }
        catch (err) {
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
            console.error(err);
        }
    };
    const changePage = (newPage) => {
        const pageHasNotChanged = (!parseInt(params.page, 10) && newPage === 1) || parseInt(params.page, 10) === newPage;
        if (pageHasNotChanged) {
            return;
        }
        setPage(newPage);
        const { search: filterQuery } = history.location;
        if (newPage === 1) {
            return history.pushLocalized(`/ponuky${filterQuery}`);
        }
        return history.pushLocalized(`/ponuky/${newPage}${filterQuery}`);
    };
    React.useEffect(() => {
        if (page !== Number(params.page)) {
            setPage(Number(params.page) || 1);
        }
    }, [params]);
    React.useEffect(() => {
        const areTypesEqual = isEqual(selectedTypeFilterItemsRef.current, typeFiltersSelected);
        const areLocationsEqual = isEqual(locationFiltersSelected, selectedLocationFilterItemsRef.current);
        const areJobAreasEqual = isEqual(jobAreaFiltersSelected, selectedJobAreaFiltersSelectedRef.current);
        const hasHighestSalaryChanged = salaryFilter.highestSalary !== salaryFilterRef.current.highestSalary;
        const isSalaryFilterEqual = isEqual(salaryFilter, salaryFilterRef.current);
        const areCompaniesEqual = isEqual(searchCompaniesSelected, searchCompaniesSelectedRef.current);
        const areKeywordsEqual = isEqual(searchKeywordsSelected, searchKeywordsSelectedRef.current);
        const hasStateChanged = !areTypesEqual ||
            !areLocationsEqual ||
            !areJobAreasEqual ||
            !isSalaryFilterEqual ||
            !areCompaniesEqual ||
            !areKeywordsEqual;
        if (hasStateChanged && !isFirstRender) {
            if (!wasHighestSalaryChangedFromHook) {
                changePage(1);
            }
            selectedTypeFilterItemsRef.current = typeFiltersSelected;
            selectedLocationFilterItemsRef.current = locationFiltersSelected;
            selectedJobAreaFiltersSelectedRef.current = jobAreaFiltersSelected;
            salaryFilterRef.current = salaryFilter;
            searchCompaniesSelectedRef.current = searchCompaniesSelected;
            searchKeywordsSelectedRef.current = searchKeywordsSelected;
        }
        if (isFilterActive && !isFirstRender && !wasHighestSalaryChangedFromHook) {
            const hasMaximumSetToMaximum = salaryFilter.maxRange === salaryFilter.highestSalary;
            const qs = createQueryParams({
                [OfferFilterType.type]: typeFiltersSelected.length !== 0 ? typeFiltersSelected : null,
                [OfferFilterType.location]: locationFiltersSelected.length !== 0 ? locationFiltersSelected : null,
                [OfferFilterType.jobArea]: jobAreaFiltersSelected.length !== 0 ? jobAreaFiltersSelected : null,
                [OfferFilterType.salaryMin]: salaryFilter.rate ? salaryFilter.minRange : null,
                [OfferFilterType.salaryMax]: salaryFilter.rate && !hasMaximumSetToMaximum ? salaryFilter.maxRange : null,
                [OfferFilterType.salaryRate]: salaryFilter.rate ? salaryFilter.rate : null,
                [OfferFilterType.searchCompaniesSelected]: searchCompaniesSelected.length !== 0 ? searchCompaniesSelected.map(company => company.id) : null,
                [OfferFilterType.searchKeywordsSelected]: searchKeywordsSelected.length !== 0 ? searchKeywordsSelected : null,
            });
            if (qs !== location.search.slice(1)) {
                history.push({
                    search: qs,
                });
            }
        }
        if (hasHighestSalaryChanged && wasHighestSalaryChangedFromHook) {
            setWasHighestSalaryChangedFromHook(false);
        }
        if (!isFilterActive && location.search && hasStateChanged) {
            history.push({
                search: '',
            });
        }
    }, [
        typeFiltersSelected,
        locationFiltersSelected,
        jobAreaFiltersSelected,
        salaryFilter,
        searchCompaniesSelected,
        searchKeywordsSelected,
    ]);
    React.useEffect(() => {
        const areTypesEqual = isEqual(typeFiltersSelected, selectedTypeIds);
        if (!areTypesEqual) {
            setTypeFiltersSelected(selectedTypeIds);
        }
    }, [selectedTypeIds]);
    React.useEffect(() => {
        const areLocationsEqual = isEqual(locationFiltersSelected, selectedLocations);
        if (!areLocationsEqual) {
            setLocationFiltersSelected(selectedLocations);
        }
    }, [selectedLocations]);
    React.useEffect(() => {
        const areJobAreasEqual = isEqual(jobAreaFiltersSelected, selectedJobAreaIds);
        if (!areJobAreasEqual) {
            setJobAreaFiltersSelected(selectedJobAreaIds);
        }
    }, [selectedJobAreaIds]);
    React.useEffect(() => {
        const areKeywordsEqual = isEqual(searchKeywordsSelected, selectedKeywords);
        if (!areKeywordsEqual) {
            setSearchKeywordsSelected(selectedKeywords);
        }
    }, [selectedKeywords]);
    React.useEffect(() => {
        let isSubscribed = true;
        const getHighestSalaryByRateAndSetValues = async () => {
            let roundedHighestSalary;
            if (salaryRate) {
                roundedHighestSalary = await getHighestSalary(salaryRate);
            }
            if (!roundedHighestSalary) {
                roundedHighestSalary = roundSalary(highestSalary);
            }
            const isSalaryMaxEqual = salaryMax
                ? salaryFilter.maxRange === salaryMax
                : salaryFilter.maxRange === roundedHighestSalary;
            const isSalaryMinEqual = isFinite(salaryMin) ? salaryFilter.minRange === salaryMin : true;
            const isSalaryRateEqual = salaryFilter.rate === salaryRate || (!salaryFilter.rate && !salaryRate);
            const isSalaryEqual = isSalaryRateEqual && isSalaryMaxEqual && isSalaryMinEqual;
            if (isSubscribed) {
                if (!isSalaryEqual) {
                    if (salaryRate) {
                        setSalaryFilter({
                            minRange: salaryMin,
                            maxRange: salaryMax || roundedHighestSalary,
                            highestSalary: roundedHighestSalary,
                            rate: salaryRate,
                        });
                    }
                    else if (highestGlobalSalary) {
                        setSalaryFilter({
                            minRange: 0,
                            maxRange: highestGlobalSalary,
                            highestSalary: highestGlobalSalary,
                            rate: null,
                        });
                    }
                }
                setPage(initPage);
            }
        };
        getHighestSalaryByRateAndSetValues();
        return () => {
            isSubscribed = false;
        };
    }, [salaryMin, salaryMax, salaryRate]);
    React.useEffect(() => {
        const roundedHighestSalary = roundSalary(highestSalary);
        if (roundedHighestSalary !== salaryFilter.highestSalary) {
            setWasHighestSalaryChangedFromHook(true);
            if (salaryRate) {
                setSalaryFilter({
                    minRange: salaryMin,
                    maxRange: salaryMax || roundedHighestSalary,
                    highestSalary: roundedHighestSalary,
                    rate: salaryRate,
                });
            }
            else if (highestSalary) {
                setSalaryFilter({
                    minRange: 0,
                    maxRange: roundedHighestSalary,
                    highestSalary: roundedHighestSalary,
                    rate: null,
                });
            }
        }
    }, [highestSalary]);
    React.useEffect(() => {
        const areCompaniesEqual = isEqual(searchCompaniesSelected.map(({ id }) => id), selectedCompaniesIds);
        if (!areCompaniesEqual) {
            refetchCompanies();
        }
    }, [selectedCompaniesIds]);
    React.useEffect(() => {
        if (companies && !isEqual(companies, searchCompaniesSelected) && selectedCompaniesIds.length) {
            setSearchCompaniesSelected(companies);
        }
        else if (!selectedCompaniesIds.length && (companies === null || companies === void 0 ? void 0 : companies.length)) {
            refetchCompanies();
            setSearchCompaniesSelected([]);
        }
    }, [companies]);
    React.useEffect(() => {
        if (globalHighestSalary) {
            setHighestGlobalSalary(roundSalary(globalHighestSalary));
        }
    }, [globalHighestSalary]);
    const { data: offers, isLoading: areOffersLoading, error: offersError, } = useSSRFetch(SSRFetchConfig.offers.key, {
        [OfferFilterType.type]: typeFiltersSelected,
        [OfferFilterType.location]: locationFiltersSelected,
        [OfferFilterType.jobArea]: jobAreaFiltersSelected,
        [OfferFilterType.salaryMin]: salaryFilter.minRange,
        [OfferFilterType.salaryMax]: salaryFilter.maxRange,
        [OfferFilterType.salaryRate]: salaryFilter.rate,
        [OfferFilterType.searchCompaniesSelected]: searchCompaniesSelected.map(({ id: companyId }) => companyId),
        [OfferFilterType.searchKeywordsSelected]: searchKeywordsSelected,
        page,
    }, [
        typeFiltersSelected,
        locationFiltersSelected,
        jobAreaFiltersSelected,
        salaryFilter,
        searchCompaniesSelected,
        searchKeywordsSelected,
        page,
    ]);
    const { data: offersFiltersSSR, isLoading: areFiltersLoading, error: filterError, } = useSSRFetch(SSRFetchConfig.offersFilters.key, {
        [OfferFilterType.type]: typeFiltersSelected,
        [OfferFilterType.location]: locationFiltersSelected,
        [OfferFilterType.jobArea]: jobAreaFiltersSelected,
        [OfferFilterType.salaryMin]: salaryFilter.minRange,
        [OfferFilterType.salaryMax]: salaryFilter.maxRange,
        [OfferFilterType.salaryRate]: salaryFilter.rate,
        [OfferFilterType.searchCompaniesSelected]: searchCompaniesSelected.map(({ id: companyId }) => companyId),
        [OfferFilterType.searchKeywordsSelected]: searchKeywordsSelected,
        page,
    }, [
        typeFiltersSelected,
        locationFiltersSelected,
        jobAreaFiltersSelected,
        salaryFilter,
        searchCompaniesSelected,
        searchKeywordsSelected,
        page,
    ]);
    const { data: promotedOffers, isLoading: arePromotedOffersLoading, error: promotedOffersError, } = useSSRFetch(SSRFetchConfig.promotedOffers.key, {
        [OfferFilterType.type]: typeFiltersSelected,
        [OfferFilterType.location]: locationFiltersSelected,
        [OfferFilterType.jobArea]: jobAreaFiltersSelected,
        [OfferFilterType.salaryMin]: salaryFilter.minRange,
        [OfferFilterType.salaryMax]: salaryFilter.maxRange,
        [OfferFilterType.salaryRate]: salaryFilter.rate,
        [OfferFilterType.searchCompaniesSelected]: searchCompaniesSelected.map(({ id: companyId }) => companyId),
        [OfferFilterType.searchKeywordsSelected]: searchKeywordsSelected,
    }, [
        typeFiltersSelected,
        locationFiltersSelected,
        jobAreaFiltersSelected,
        salaryFilter,
        searchCompaniesSelected,
        searchKeywordsSelected,
    ]);
    React.useEffect(() => {
        if (filterError || offersError || promotedOffersError) {
            // @ts-ignore
            history.pushLocalized('/error');
        }
    }, [filterError, offersError]);
    React.useEffect(() => {
        return () => {
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.companies.key);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.highestSalary.key);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.globalHighestSalary.key);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.offers.key);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.offersFilters.key);
            SSRFetchClient.invalidateSSRContext(SSRFetchConfig.promotedOffers.key);
        };
    }, []);
    return {
        OffersFilterContextProvider: OffersFilterContext.Provider,
        value: {
            typeFiltersSelected,
            locationFiltersSelected,
            jobAreaFiltersSelected,
            searchCompaniesSelected,
            searchKeywordsSelected,
            setTypeFiltersSelected: id => setTypeFiltersSelected(id),
            setLocationFiltersSelected: loc => setLocationFiltersSelected(loc),
            setJobAreaFiltersSelected: id => setJobAreaFiltersSelected(id),
            setSearchCompaniesSelected: comps => setSearchCompaniesSelected(comps),
            setSearchKeywordsSelected: keywords => setSearchKeywordsSelected(keywords),
            offers,
            promotedOffers,
            offersFiltersSSR,
            areOffersLoading,
            areFiltersLoading,
            arePromotedOffersLoading,
            changePage: n => changePage(n),
            setPage: p => setPage(p),
            salaryFilter,
            setSalaryFilter: f => setSalaryFilter(f),
            highestSalary: highestSalary || 0,
            getHighestSalary,
            isFilterActive,
            companies,
            highestGlobalSalary,
        },
    };
};
export const useOffersFilterContextValue = () => {
    const context = React.useContext(OffersFilterContext);
    return context;
};
