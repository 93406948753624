import { client as axios } from './clients/axios';
export async function getCompanyByUrl(companyUrl) {
    const response = await axios.get(`/0/company/get-by-url/${companyUrl}`);
    return response.data;
}
export async function getCompanyById(id) {
    const response = await axios.get(`/0/company/${id}`);
    return response.data;
}
export async function toggleCompanySubscription(companyId) {
    const response = await axios.put('/0/company/toggle-subscription', {
        companyId,
    });
    return response.data;
}
export async function fetchCompanyCandidatesForSubscription() {
    const response = await axios.get('/0/company/subscription-candidates');
    return response.data;
}
export async function subscribeToCompanies(companyIds) {
    await axios.post('/0/company/batch-subscribe', { companyIds });
}
export async function getMessageSettings() {
    const response = await axios.get('/0/company/message-settings');
    return response.data;
}
export async function getCompanyApplicants(filterValues, page) {
    const { name, school, position } = filterValues;
    const response = await axios.get('/0/company/applicants', {
        params: {
            page,
            name,
            school,
            position,
        },
    });
    return response.data;
}
export async function getCompany() {
    const response = await axios.get('/0/company');
    return response.data;
}
export async function getCompaniesForList(search = '', page = 1) {
    const response = await axios.get('/0/company/list', { params: { search, page } });
    return response.data;
}
export async function getCompaniesForListPreview(limit) {
    const response = await axios.get('/0/company/list-preview', { params: { limit } });
    return response.data;
}
export async function getCompaniesByIds(companiesIds) {
    const response = await axios.get('/0/company/selected-companies', { params: { companiesIds } });
    return response.data;
}
export async function setCompanyInfo(companyInfo) {
    const response = await axios.put('/0/company', companyInfo);
    return response.data;
}
export async function setCompanyImages(image, titleImage) {
    const companyImages = new FormData();
    if (image) {
        companyImages.append('image', image);
    }
    if (titleImage) {
        companyImages.append('title_image_url', titleImage);
    }
    const response = await axios.put('/0/company/images', companyImages);
    return response.data;
}
export async function deleteCompanyImage(imageType) {
    const response = await axios.delete('/0/company/image', { params: { imageType } });
    return response.data;
}
export async function getAllCompaniesCount() {
    return (await axios.get('/0/company/count-all')).data;
}
