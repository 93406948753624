import React from 'react';
import { Form, notification } from 'antd';
import * as Api from '@src/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Checkbox, Button, QuestionMark } from '@src/components/elements';
import { CandidateRegistrationFormField } from '@src/types';
import { DataProcessingOrNewsletterModal } from '@src/components/modules/global/DataProcessingOrNewsletterModal';
import { SSRFetchConfig, useSSRFetchClient } from '@src/hooks/useSSRFetch';
import { PersonalInformation } from './PersonalInformation';
import { Passwords } from './Passwords';
import { registrationCandidateFormRules } from './registrationCandidateFormRules';
import { QuestionMarkWrapper, RedText, SubmitButtonWrapper, RequiredWrapper } from './RegistrationCandidateForm.style';
export function RegistrationCandidateForm() {
    const [form] = Form.useForm();
    const intl = useIntl();
    const history = useHistory();
    const SSRFetchClient = useSSRFetchClient();
    const [showDataProcessingModal, setShowDataProcessingModal] = React.useState(false);
    const [showNesletterModal, setShowNesletterModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const rules = registrationCandidateFormRules(intl);
    const { agreementRules } = rules;
    const onSubimt = async (registrationFormValues) => {
        try {
            setLoading(true);
            const registrationResponse = await Api.Registration.registerCandidate(Object.assign(Object.assign({}, registrationFormValues), { account_type_id: 2 }));
            if (registrationResponse.status === 201) {
                SSRFetchClient.invalidateSSRContext(SSRFetchConfig.account.key);
                // @ts-ignore
                history.pushLocalized('/ucet');
                notification.success({
                    message: intl.formatMessage({
                        id: 'general.warning',
                    }),
                    description: intl.formatMessage({
                        id: 'registration.registration_form.registration_success',
                    }),
                });
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        }
        finally {
            setLoading(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, layout: "vertical", onFinish: onSubimt, scrollToFirstError: true },
            React.createElement(PersonalInformation, { rules: rules, intl: intl }),
            React.createElement(Passwords, { rules: rules, intl: intl }),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.protection, valuePropName: "checked", rules: agreementRules },
                    React.createElement(Checkbox, null,
                        React.createElement(RedText, null, "*"),
                        React.createElement(FormattedMessage, { id: "registration_student.registration_student_form.protection" }))),
                React.createElement(QuestionMarkWrapper, null,
                    React.createElement(QuestionMark, { onClick: () => setShowDataProcessingModal(true) }))),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Form.Item, { name: CandidateRegistrationFormField.newsletter, valuePropName: "checked" },
                    React.createElement(Checkbox, null,
                        React.createElement(FormattedMessage, { id: "registration_student.registration_student_form.newsletter" }))),
                React.createElement(QuestionMarkWrapper, null,
                    React.createElement(QuestionMark, { onClick: () => setShowNesletterModal(true) }))),
            React.createElement(SubmitButtonWrapper, null,
                React.createElement(Button, { color: "brand", htmlType: "submit", loading: loading },
                    React.createElement("span", { className: "m-l-xxs" },
                        React.createElement(FormattedMessage, { id: "registration.registration_form.register" })))),
            React.createElement(RequiredWrapper, null,
                React.createElement(RedText, null, "*"),
                React.createElement(FormattedMessage, { id: "registration_student.registration_student_form.mandatory" }))),
        React.createElement(DataProcessingOrNewsletterModal, { visible: showDataProcessingModal, setVisible: setShowDataProcessingModal, textId: "registration.registration_form.data_processing" }),
        React.createElement(DataProcessingOrNewsletterModal, { visible: showNesletterModal, setVisible: setShowNesletterModal, textId: "registration_student.registration_student_form.email_agreement" })));
}
