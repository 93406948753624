export var AssetType;
(function (AssetType) {
    AssetType["IMAGE"] = "IMAGE";
    AssetType["VIDEO"] = "VIDEO";
    AssetType["DOCUMENT"] = "DOCUMENT";
})(AssetType || (AssetType = {}));
export var AssetDocumentFileFormat;
(function (AssetDocumentFileFormat) {
    AssetDocumentFileFormat["PDF"] = "PDF";
    AssetDocumentFileFormat["WORD"] = "WORD";
    AssetDocumentFileFormat["EXCEL"] = "EXCEL";
    AssetDocumentFileFormat["OTHER"] = "OTHER";
})(AssetDocumentFileFormat || (AssetDocumentFileFormat = {}));
