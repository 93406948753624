import React from 'react';
import styled from 'styled-components';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
export const AssetImage = styled.img `
	object-fit: contain;
	height: 100%;
	width: 100%;
`;
export function AssetCardImage({ asset }) {
    const assetImageUrl = getCdnImageUrl({
        file: asset.cloudinaryPublicId,
        transformation: 'w_400,c_scale',
        shouldIncludeEnvironmentPart: false,
    });
    return React.createElement(AssetImage, { src: assetImageUrl, alt: "image-asset" });
}
