import React from 'react';
import DiamondOutlined from '@public/media/icons/streamline-icon-mystery.svg';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
const PremiumIcon = ({ shouldBeHiddenIfPremiumIsActive = true, width = 16, height = 16, isInCircle, className, color, hasActivePremiumSubscription, }) => {
    if (shouldBeHiddenIfPremiumIsActive && hasActivePremiumSubscription) {
        return null;
    }
    if (isInCircle) {
        return (React.createElement(CircleIconWrapper, null,
            React.createElement(DiamondOutlined, { width: width, height: height, className: className })));
    }
    return (React.createElement(IconWrapper, { color: color },
        React.createElement(DiamondOutlined, { width: width, height: height, className: className })));
};
const CircleIconWrapper = styled.div `
	margin-left: 0.5rem;
	border-radius: 1rem;
	background: ${({ theme }) => transparentize(0.8, theme.color.brand1)};
	width: 1.75rem;
	height: 1.75rem;
	max-width: 1.75rem;
	max-height: 1.75rem;
	min-width: 1.75rem;
	min-height: 1.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		color: ${({ theme }) => theme.color.brand1};
	}
`;
const IconWrapper = styled.div `
	${({ color }) => color &&
    css `
			color: ${color};
		`}
`;
export { PremiumIcon };
