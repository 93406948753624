export const jobInformationMap = {
    sk: {
        job_info: 'Náplň práce',
        benefits: 'výhody, benefity',
    },
    en: {
        job_info: 'Job description',
        benefits: 'perks, benefits',
    },
};
export const applicantRequirementsMap = {
    sk: {
        school: 'uchádzači so vzdelaním',
        in_field_education: 'Vzdelanie v odbore',
        practice: 'Prax na pozícii',
        personal_skills: 'Osobnostné predpoklady',
        other_skills: 'Ostatné znalosti',
        language: 'Jazykové znalosti',
        driving_license: 'Vodičský preukaz',
    },
    en: {
        school: 'education suit the position',
        in_field_education: 'Vzdelanie v odbore',
        practice: 'Experience in the position',
        personal_skills: 'Personality requirements',
        other_skills: 'Other',
        language: 'Language skills',
        driving_license: 'Driving licence',
    },
};
export var TurnUpDate;
(function (TurnUpDate) {
    TurnUpDate["asap"] = "asap";
    TurnUpDate["immediately"] = "ihned";
    TurnUpDate["instantly"] = "okamzite";
})(TurnUpDate || (TurnUpDate = {}));
export const offerTypeMapScrape = {
    sk: {
        'plný úväzok': 2,
        živnosť: 6,
        'skrátený úväzok': 2,
        'na dohodu (brigády)': 4,
        internship: 1,
    },
    en: {
        'full-time': 2,
        'trade-licence': 6,
        'part-time': 2,
        'agreement-based (Temporary jobs)': 4,
        internship: 1,
    },
};
export const contractTypeMap = {
    sk: {
        'plný úväzok': 1,
        'skrátený úväzok': 2,
    },
    en: {
        'full-time': 1,
        'part-time': 2,
    },
};
export const HOME_OFFICE_STRING = 'Práca z domu';
