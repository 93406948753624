import { Form } from 'antd';
import React from 'react';
import { tagOptions } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelect';
import { useIntl } from 'react-intl';
import { Alert } from '@src/components/elements';
import { SpecificSearchSelectWrapper, } from '@src/components/modules/SpecificSearchSelect/SpecificSearchSelectWrapper';
const SpecificSearchSelectField = ({ name, rules, formValues, setFormValues, }) => {
    const intl = useIntl();
    const setSelectedValues = (newSpecificSearchValues) => {
        const newFormValues = Object.assign(Object.assign({}, formValues), { [name]: newSpecificSearchValues });
        // @ts-ignore
        setFormValues(newFormValues);
    };
    const isTagCountInLimit = Boolean(formValues.specificSearch && formValues.specificSearch.length < tagOptions.maxTagCount);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { label: intl.formatMessage({
                id: 'global.specific_search.title',
            }), rules: rules, name: name, className: isTagCountInLimit ? '' : 'm-b-s' },
            React.createElement(SpecificSearchSelectWrapper, { isTagCountInLimit: isTagCountInLimit, selectedValues: formValues.specificSearch, setSelectedValues: setSelectedValues })),
        !isTagCountInLimit && (React.createElement(Alert, { className: "m-b-m", message: intl.formatMessage({ id: 'offers.max_tag_count_alert' }), type: "warning", showIcon: true }))));
};
export { SpecificSearchSelectField };
