import React from 'react';
import classNames from 'classnames';
const CompanyImage = ({ name, imageUrl }) => {
    const [companyImageDimensions, setCompanyImageDimensions] = React.useState(null);
    function onLoadCompanyImage({ target: img }) {
        setCompanyImageDimensions({
            width: img.clientWidth,
            height: img.clientHeight,
        });
    }
    function getCompanyImageClasses() {
        let classes = { 'company-image': true };
        if (companyImageDimensions) {
            const imageRatio = companyImageDimensions.width / companyImageDimensions.height;
            const modifierClasses = {
                'company-image--square': imageRatio === 1,
                'company-image--landscape': imageRatio > 1,
                'company-image--portrait': imageRatio < 1,
            };
            classes = Object.assign(Object.assign({}, classes), modifierClasses);
        }
        return classNames(classes);
    }
    return (React.createElement("div", { className: getCompanyImageClasses() },
        React.createElement("img", { className: "company-image__img", onLoad: onLoadCompanyImage, src: imageUrl, alt: name })));
};
export { CompanyImage };
