import { Button } from 'antd';
import styled from 'styled-components';
export const LinkedInButton = styled(Button) `
	background: ${({ theme }) => theme.color.linkedin};
	color: ${({ theme }) => theme.color.white};
	justify-content: flex-start;
	text-align: center;
	border-radius: 32px;
	color: white;
	margin-bottom: 0.5rem;
	border: none;
	padding: 0.5rem;

	&&:hover {
		background: ${({ theme }) => theme.color.linkedin};
		color: ${({ theme }) => theme.color.white};
	}
`;
