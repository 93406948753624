import React from 'react';
import { FileExcelOutlined, FileOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { Document, Page } from '@src/components/modules/global/ReactPdf';
import { Spinner } from '@src/components/elements';
import { AssetDocumentFileFormat } from '@src/types/asset';
import { getAssetDocumentFileFormatFromUrl } from '@src/components/modules/AssetLibrary/asset-library.model';
import { DocumentContainer, PDFPreviewContainer } from './AssetCardDocument.style';
const DOCUMENT_FILE_ICON_STYLES = { fontSize: '80px' };
export function AssetCardDocument({ asset }) {
    const fileFormat = getAssetDocumentFileFormatFromUrl(asset.url);
    return (React.createElement(DocumentContainer, null,
        fileFormat === AssetDocumentFileFormat.PDF && (React.createElement(PDFPreviewContainer, null,
            React.createElement(Document, { file: asset.url, loading: React.createElement(Spinner, null), error: React.createElement(FilePdfOutlined, { style: DOCUMENT_FILE_ICON_STYLES }) },
                React.createElement(Page, { pageNumber: 1 })))),
        fileFormat === AssetDocumentFileFormat.WORD && React.createElement(FileWordOutlined, { style: DOCUMENT_FILE_ICON_STYLES }),
        fileFormat === AssetDocumentFileFormat.EXCEL && React.createElement(FileExcelOutlined, { style: DOCUMENT_FILE_ICON_STYLES }),
        fileFormat === AssetDocumentFileFormat.OTHER && React.createElement(FileOutlined, { style: DOCUMENT_FILE_ICON_STYLES })));
}
