import ReactGA from '@src/helpers/react-ga';
import gtm from '@src/helpers/gtm';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CompanyRegistrationFormField } from '@src/types';
import { RichText } from '@src/components/elements';
import styled from 'styled-components';
const Error = styled.div `
	background: ${({ theme }) => theme.color.error};
	color: white;
	padding: 1rem;
`;
function AddressError() {
    const intl = useIntl();
    const el = React.useRef(null);
    React.useEffect(() => {
        if (el.current) {
            ;
            el.current.scrollIntoView(false);
        }
    }, [el.current]);
    return React.createElement(Error, { ref: el }, intl.formatMessage({ id: 'registration.async_validation.address' }));
}
export function CompanyInformationForm({ intl, invalidAddressError, isCompanyRegistered, companyInfo, rules, }) {
    const { cinRules, tinRules, addressRules, cityRules, postalCodeRules, descriptionRules, employeeRules, areaRules, websiteRules, } = rules;
    const [isInitialized, setIsInitialized] = React.useState(false);
    React.useEffect(() => {
        setIsInitialized(true);
    }, []);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.street_address',
                    }), name: CompanyRegistrationFormField.street_address, rules: addressRules },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.city',
                    }), name: CompanyRegistrationFormField.city, rules: cityRules },
                    React.createElement(Input, null)),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                        React.createElement(Form.Item, { label: intl.formatMessage({
                                id: 'registration.registration_form.postal_code',
                            }), name: CompanyRegistrationFormField.postal_code, rules: postalCodeRules, validateTrigger: "onBlur" },
                            React.createElement(Input, null)))),
                invalidAddressError && (React.createElement(Row, { justify: "start" },
                    React.createElement(Col, null,
                        React.createElement(AddressError, null))))),
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.cin',
                    }), name: CompanyRegistrationFormField.cin, rules: cinRules, validateTrigger: "onBlur" },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.tin',
                    }), name: CompanyRegistrationFormField.tin, rules: tinRules, validateTrigger: "onBlur" },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.tin_vat',
                    }), name: CompanyRegistrationFormField.tin_vat },
                    React.createElement(Input, null)),
                companyInfo.url && (React.createElement(Row, { justify: "end" },
                    React.createElement(Col, null,
                        React.createElement("div", { className: "finstat" },
                            React.createElement("div", { className: "finstat__text" },
                                React.createElement(FormattedMessage, { id: "registration.registration_form.data_security" })),
                            React.createElement("div", { className: "finstat__logo" },
                                React.createElement("a", { target: "_blank", rel: "noreferrer", href: companyInfo.url, onClick: () => {
                                        ReactGA.event({
                                            category: 'Ads',
                                            action: 'Finstat click',
                                            label: companyInfo.name,
                                        });
                                        gtm.dataLayer({
                                            event: 'finstat_clicked',
                                            label: companyInfo.name,
                                        });
                                    } },
                                    React.createElement("img", { src: "/media/images/logo-finstat.png", height: "25", alt: "FinStat" })))))))),
            React.createElement(Col, { xs: { span: 24 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.description',
                    }), name: CompanyRegistrationFormField.description, rules: descriptionRules },
                    React.createElement(RichText.RichTextEditorAnt, { editable: !isCompanyRegistered })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.website',
                    }), name: CompanyRegistrationFormField.website, rules: websiteRules, validateTrigger: ['onBlur'] },
                    React.createElement(Input, { disabled: isCompanyRegistered })),
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.area',
                    }), name: CompanyRegistrationFormField.area, rules: areaRules },
                    React.createElement(Input, { disabled: isCompanyRegistered }))),
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
                React.createElement(Form.Item, { label: intl.formatMessage({
                        id: 'registration.registration_form.employees',
                    }), name: CompanyRegistrationFormField.employees, rules: employeeRules },
                    React.createElement(Input, { disabled: isCompanyRegistered }))))));
}
