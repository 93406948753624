import { client as axios } from './clients/axios';
export async function setCompanyPremiumProfileHeaderInfo({ companyPremiumProfileInfo, shouldDeleteTitleImage, shouldDeleteCompanyImage, shouldSetCompanyImage, shouldSetTitleImage, companyImage, companyTitleImage, }) {
    const premiumProfileDataForForm = {
        companyPremiumProfileInfo,
        shouldDeleteTitleImage,
        shouldDeleteCompanyImage,
        shouldSetCompanyImage,
        shouldSetTitleImage,
    };
    const premiumProfileForm = new FormData();
    Object.keys(premiumProfileDataForForm).forEach(key => {
        const value = premiumProfileDataForForm[key];
        if (key === 'companyPremiumProfileInfo') {
            premiumProfileForm.append(key, JSON.stringify(value));
        }
        else {
            premiumProfileForm.append(key, value);
        }
    });
    if (shouldSetCompanyImage && companyImage) {
        premiumProfileForm.append('companyImage', companyImage);
    }
    if (shouldSetTitleImage && companyTitleImage) {
        premiumProfileForm.append('companyTitleImage', companyTitleImage);
    }
    await axios.put('/0/company-premium-profile/header', premiumProfileForm);
}
export async function setCompanyPremiumProfileMainInfo(companyPremiumProfileInfo) {
    await axios.put('/0/company-premium-profile/main', companyPremiumProfileInfo);
}
