import React from 'react';
import { Form, Typography, Col, Row, notification, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Holder, Panel } from '@src/components/elements';
import { useAppMutation } from '@src/hooks/useAppMutation';
import { contactUs } from '@src/api/contact-us';
import { getEmailRules } from '@src/helpers/form-validation-rules/email';
import styled from 'styled-components';
import { ContactFormField, ContactFormFieldType } from '@src/types';
const { Title } = Typography;
const { TextArea } = Input;
const maxMessageLength = 1000;
const ContactUs = () => {
    const [form] = Form.useForm();
    const intl = useIntl();
    const { mutateAsync, isLoading } = useAppMutation(contactUs, {
        onSuccess: () => {
            notification.success({
                message: intl.formatMessage({ id: 'general.notice' }),
                description: intl.formatMessage({ id: 'contact.contact_us.sent_successfully' }),
            });
            form.resetFields();
        },
    });
    const getTypePreset = () => {
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('type');
        }
    };
    const initialValues = {
        [ContactFormField.type]: getTypePreset() === 'recruitment'
            ? ContactFormFieldType.servicesRecruitment
            : ContactFormFieldType.servicesJobAdverts,
    };
    return (React.createElement(Holder, { marginVertical: true, "data-cy": "contact-us" },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, null,
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "general.contact_us" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement(Panel, null,
                    React.createElement(Form, { layout: "vertical", form: form, onFinish: mutateAsync, initialValues: initialValues },
                        React.createElement(Form.Item, { name: ContactFormField.name, label: intl.formatMessage({
                                id: 'contact.contact_us.name_and_surname',
                            }), rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'contact.validation.please_fill_name_and_surname',
                                    }),
                                },
                            ] },
                            React.createElement(StyledInput, { disabled: isLoading })),
                        React.createElement(Form.Item, { name: ContactFormField.email, label: intl.formatMessage({
                                id: 'contact.contact_us.email',
                            }), rules: getEmailRules(intl) },
                            React.createElement(StyledInput, { type: "email", disabled: isLoading })),
                        React.createElement(Form.Item, { name: ContactFormField.type, label: intl.formatMessage({
                                id: 'contact.contact_us.type',
                            }) },
                            React.createElement(StyledSelect, { options: [
                                    {
                                        value: 'SERVICES_JOB_ADVERTS',
                                        label: intl.formatMessage({
                                            id: 'contact.type_job_adverts',
                                        }),
                                    },
                                    {
                                        value: 'SERVICES_RECRUITMENT',
                                        label: intl.formatMessage({
                                            id: 'contact.type_recruitment',
                                        }),
                                    },
                                    {
                                        value: 'COOPERATION',
                                        label: intl.formatMessage({
                                            id: 'contact.type_cooperation',
                                        }),
                                    },
                                    {
                                        value: 'PR_AND_MARKETING',
                                        label: intl.formatMessage({
                                            id: 'contact.type_pr_and_marketing',
                                        }),
                                    },
                                    {
                                        value: 'SUPPORT',
                                        label: intl.formatMessage({
                                            id: 'contact.type_support',
                                        }),
                                    },
                                ] })),
                        React.createElement(Form.Item, { name: ContactFormField.message, label: intl.formatMessage({
                                id: 'contact.contact_us.message',
                            }), rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'contact.validation.please_fill_message',
                                    }),
                                },
                                {
                                    max: maxMessageLength,
                                    message: intl.formatMessage({
                                        id: 'contact.validation.message_max_length',
                                    }),
                                },
                            ] },
                            React.createElement(TextArea, { disabled: isLoading, showCount: true, maxLength: maxMessageLength, rows: 5 })),
                        React.createElement("div", { className: "flex-center" },
                            React.createElement(Button, { htmlType: "submit", loading: isLoading }, intl.formatMessage({
                                id: 'contact.contact_us.send',
                            })))))))));
};
const StyledInput = styled(Input) `
	max-width: 300px;
`;
const StyledSelect = styled(Select) `
	max-width: 300px;
`;
export { ContactUs };
