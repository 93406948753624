import { Button, Modal } from '@src/components/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MainContent, PopupHeader } from './WelcomeFromPraxujPopup.style';
const LOCAL_STORAGE_KEY_APPROVED = 'pracuj.sk_welcome_from_praxuj.sk_approved';
export function WelcomeFromPraxujPopup() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const isRedirectedFromPraxuj = () => {
        // @ts-ignore
        return document.referrer.includes(process.env.PUBLIC_URL_PRAXUJ);
    };
    const popupApproved = () => {
        return window.localStorage.getItem(LOCAL_STORAGE_KEY_APPROVED);
    };
    const approvePopup = () => {
        setIsModalOpen(false);
        window.localStorage.setItem(LOCAL_STORAGE_KEY_APPROVED, 'true');
    };
    React.useEffect(() => {
        if (isRedirectedFromPraxuj() && !popupApproved()) {
            setIsModalOpen(true);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { width: 730, centered: true, visible: isModalOpen, onCancel: () => {
                setIsModalOpen(false);
            }, closable: false, footer: false },
            React.createElement(PopupHeader, { src: "/media/images/popup-welcome.jpg", width: "680", height: "150", alt: "" }),
            React.createElement(MainContent, null,
                React.createElement("h1", null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "welcomeModal.headline2", values: {
                                em: chunks => React.createElement("em", null, chunks),
                            } }))),
                React.createElement("div", { className: "main" },
                    React.createElement("p", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "welcomeModal.headline1", values: {
                                    em: chunks => React.createElement("em", null, chunks),
                                } }),
                            "!")),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "welcomeModal.paragraph1", values: {
                                strong: chunks => React.createElement("strong", null, chunks),
                            } }),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: "welcomeModal.paragraph2" })),
                    React.createElement("div", { className: "note" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "welcomeModal.note1" })),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: "welcomeModal.note2" }))),
                React.createElement("div", { className: "confirm" },
                    React.createElement(Button, { onClick: approvePopup },
                        React.createElement(FormattedMessage, { id: "welcomeModal.startUsingPortal" })))))));
}
