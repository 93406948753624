import { Holder, Switch } from '@src/components/elements';
import { SubscriptionPeriods } from '@src/types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useConfig } from '@src/hooks/useConfig';
import { PremiumCompanyProfileFeatures } from './PremiumCompanyProfileFeatures';
import { PremiumCompanyProfileSubscriptionCard } from './PremiumCompanyProfileSubscriptionCard';
import { PremiumCompanyProfileComparisonTable } from './PremiumCompanyProfileComparisonTable';
const PremiumCompanyProfilePage = () => {
    const [showPricesWithVat, setShowPricesWithVat] = React.useState(false);
    const config = useConfig();
    return (React.createElement("div", null,
        React.createElement(Wrapper, null,
            React.createElement(Hero, null,
                React.createElement(Holder, null,
                    React.createElement(Headline, null,
                        React.createElement("h1", null,
                            React.createElement(FormattedMessage, { id: "company_premium_profile.headline" })),
                        React.createElement("h2", null,
                            React.createElement(FormattedMessage, { id: "company_premium_profile.sub_headline" }))))),
            React.createElement(Content, null,
                React.createElement(Holder, null,
                    React.createElement(BoxesWrapper, null,
                        React.createElement(BoxesTitle, null,
                            React.createElement(FormattedMessage, { id: "company_premium_profile.features.subscription_price" })),
                        React.createElement(Boxes, null,
                            React.createElement(PremiumCompanyProfileSubscriptionCard, { subscriptionPeriod: SubscriptionPeriods.OneMonthPeriod, showPricesWithVat: showPricesWithVat }),
                            React.createElement(PremiumCompanyProfileSubscriptionCard, { subscriptionPeriod: SubscriptionPeriods.SixMonthsPeriod, showPricesWithVat: showPricesWithVat, isElevated: true }),
                            React.createElement(PremiumCompanyProfileSubscriptionCard, { subscriptionPeriod: SubscriptionPeriods.TwelveMonthsPeriod, showPricesWithVat: showPricesWithVat })),
                        React.createElement(SwitchWrapper, null,
                            React.createElement(SwitchVatText, null,
                                React.createElement(FormattedMessage, { id: "companies.credits.show_prices_with_vat" }),
                                ' ',
                                React.createElement(FormattedNumber, { value: config.pricing.VAT, style: "unit", unit: "percent" })),
                            React.createElement(Switch, { checked: showPricesWithVat, onChange: checked => setShowPricesWithVat(checked) })))),
                React.createElement(Holder, null,
                    React.createElement(PremiumCompanyProfileComparisonTable, null)),
                React.createElement(FeaturesWrapper, null,
                    React.createElement(Holder, null,
                        React.createElement("h2", null,
                            React.createElement(FormattedMessage, { id: "company_premium_profile.features.headline" })),
                        React.createElement(PremiumCompanyProfileFeatures, null)))))));
};
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: ${({ theme }) => theme.color.gray2};
`;
const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 3.5em 2em 0;

	${up('sm')} {
		padding: 2.5em 2em 0;
	}

	${up('md')} {
		padding: 5em 2em 0;
	}

	& > * {
		color: ${props => props.theme.color.white};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
	}
`;
const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 800px;
	position: relative;
	background: url('/media/images/hero-premium-profile.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 0;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.95;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
`;
const Content = styled.div `
	width: 100%;
`;
const SwitchVatText = styled.div `
	font-size: 1rem;
	color: ${({ theme }) => theme.color.white};
	margin-right: 0.5rem;
`;
const SwitchWrapper = styled.div `
	border-radius: 0.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.color.lightSlateGray};
	padding: 1rem;
	/* ${up('md')} {
		background: unset;
		padding: 0;
	} */
`;
const Boxes = styled.div `
	position: relative;
	z-index: 1;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 1rem;
	${up('md')} {
		flex-direction: row;
	}
`;
const BoxesWrapper = styled.div `
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	margin-top: -420px;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;
	${up('sm')} {
		margin-top: -490px;
	}

	${up('md')} {
		margin-top: -460px;
	}
`;
const BoxesTitle = styled.div `
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.4;
	z-index: 1;
	text-align: center;
	color: ${({ theme }) => theme.color.white};
`;
const FeaturesWrapper = styled.div `
	width: 100%;
	padding: 5rem 0 2rem;
	background: ${({ theme }) => theme.color.white};

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2rem;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2rem;
	}
`;
export { PremiumCompanyProfilePage };
