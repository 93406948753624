import { client as axios } from './clients/axios';
export async function login({ email, password }) {
    try {
        const response = await axios.post('/0/login', { email, password });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
export async function logout() {
    return (await axios.get('/0/logout')).data;
}
export async function linkedinAuthentication(code) {
    try {
        const response = await axios.post('/0/auth/linkedin', { code });
        return response.data;
    }
    catch (err) {
        throw err;
    }
}
