import React from 'react';
import { Skeleton } from 'antd';
import * as uuid from 'uuid';
import _truncate from 'lodash/truncate';
import { useIntl } from 'react-intl';
import IconBuildingsModern from '@src/components/elements/Icon/custom/buildings-modern';
import { getCdnImageUrl } from '@shared/get-cdn-image-url';
import { createGlobalStyle } from 'styled-components';
import { CompanySubscriptionBlock } from '@src/components/modules/global/CompanySubscriptionBlock/CompanySubscriptionBlock';
import { CompanyCard, ImageBlock, CompaniesListGridWrapper, CompanyCardContent, CompanyName, MissingContentViewWrapper, } from './CompaniesListGrid.style';
import MissingContentView from '../../MissingContentView/MissingContentView';
const HidePopoverStyles = createGlobalStyle `
	.ant-popover.ant-avatar-group-popover.ant-popover-placement-top {
		display: none;
	}
`;
function CompaniesListGrid({ companies, isLoading }) {
    const intl = useIntl();
    const isCompanyListNotEmpty = Boolean(companies && companies.length);
    return (React.createElement(React.Fragment, null,
        React.createElement(HidePopoverStyles, null),
        React.createElement(CompaniesListGridWrapper, null,
            isLoading &&
                new Array(10).fill(null).map(() => (React.createElement(CompanyCard, { className: "p-m", key: uuid.v4() },
                    React.createElement(Skeleton.Image, null),
                    React.createElement(Skeleton, { active: true, paragraph: { rows: 3 } })))),
            !isLoading &&
                isCompanyListNotEmpty && (companies === null || companies === void 0 ? void 0 : companies.map(c => {
                const companyLogoSrc = getCdnImageUrl({
                    file: `web/company/${c.image}`,
                    transformation: 'w_300,h_300,c_limit',
                });
                return (React.createElement(CompanyCard, { key: c.id },
                    React.createElement(ImageBlock, { to: `/firma/${c.company_url}`, alt: c.name },
                        c.image && React.createElement("img", { src: companyLogoSrc, alt: "logo" }),
                        !c.image && React.createElement(IconBuildingsModern, null)),
                    React.createElement(CompanyCardContent, null,
                        React.createElement(CompanyName, { to: `/firma/${c.company_url}`, alt: c.name }, _truncate(c.name, { length: 40 })),
                        React.createElement(CompanySubscriptionBlock, { companyId: c.id, subscribers: c.subscribers }))));
            })),
            !isLoading && !isCompanyListNotEmpty && (React.createElement(MissingContentViewWrapper, null,
                React.createElement(MissingContentView, { heading: intl.formatMessage({
                        id: 'companies_list.missing_content_heading',
                    }), message: intl.formatMessage({
                        id: 'companies_list.missing_content_message',
                    }) }))))));
}
export { CompaniesListGrid };
