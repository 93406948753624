import { Modal } from '@src/components/elements';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import CompanyProfileHeaderEditForm from './CompanyProfileHeaderEditForm';
const CompanyProfileHeaderEditModal = ({ isEditModalOpen, setIsEditModalOpen, company }) => {
    const companyProfileHeaderFormRef = React.useRef(null);
    const intl = useIntl();
    const theme = useTheme();
    return (React.createElement(Modal, { title: intl.formatMessage({
            id: 'admin.company_premium_profile.edit_company_profile_header',
        }), visible: isEditModalOpen, width: theme.modalWidth.largeModal, onOk: () => { var _a; return (_a = companyProfileHeaderFormRef.current) === null || _a === void 0 ? void 0 : _a.submitForm(); }, onCancel: () => setIsEditModalOpen(false) },
        React.createElement(CompanyProfileHeaderEditForm, { setIsEditModalOpen: setIsEditModalOpen, company: company, ref: companyProfileHeaderFormRef })));
};
export { CompanyProfileHeaderEditModal };
