import styled, { css } from 'styled-components';
export const AssetContainer = styled.div `
	padding: 0 1px; // This must be here to hide edges of neighbouring images.
	height: 420px;
	background-color: ${props => props.theme.color.white};
	width: 100%;
	display: flex !important;
	justify-content: center;
`;
export const AssetImg = styled.img `
	width: 100%;
	height: 100%;
	object-fit: contain;
`;
export const CarouselDot = styled.div `
	border-radius: 100%;
	height: 12px;
	width: 12px;
	background-color: ${props => props.theme.color.gray4};
	cursor: pointer;
	${props => props.shouldBeHighlighted &&
    css `
			background-color: ${({ theme }) => theme.color.brand1};
		`}
`;
export const CarouselDotsContainer = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	gap: 0.5rem;
`;
export const CarouselArrowContainer = styled.div `
	position: absolute;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	background-color: ${props => props.theme.color.gray2};
	border-radius: 100%;
	z-index: 1;
	font-size: 1rem;
	cursor: pointer;
	transform: translateY(-50%);
	${props => props.position === 'left'
    ? css `
					left: 1rem;
			  `
    : css `
					right: 1rem;
			  `}
`;
