import { Button } from '@src/components/elements';
import { ProductId } from '@src/types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LeftOutlined } from '@ant-design/icons';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { useAdminContextValue } from '@src/components/pages/admin/admin-context';
import { useAppQuery } from '@src/hooks/useAppQuery';
import { Queries } from '@src/types/queries';
import * as Api from 'api';
import { notification } from 'antd';
import { EPremiumCompanySubscriptionModalSteps } from './PremiumCompanyProfileSubscriptionModal';
import AdminOrder from '../order';
const StepCheckout = ({ setCurrentStep, selectedSubscriptionPeriod, onOrderSuccess }) => {
    const { setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const intl = useIntl();
    const { companyInfo } = useAdminContextValue();
    const isCompanyOutOfContext = !companyInfo.id;
    const { data: fetchedCompany } = useAppQuery(Queries.getCompany, Api.Company.getCompany, {
        onError: err => {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'global.notification_variants.error',
                }),
                description: intl.formatMessage({
                    id: 'global.notification_variants.something_went_wrong',
                }),
            });
        },
        enabled: isCompanyOutOfContext,
    });
    return (React.createElement("div", null,
        React.createElement(AdminOrder, Object.assign({ productId: ProductId.companyPremium, amount: selectedSubscriptionPeriod, onOrderSuccess: onOrderSuccess }, (isCompanyOutOfContext && { companyOutOfContext: fetchedCompany }))),
        React.createElement("div", { className: "flex-center m-t-l" },
            React.createElement(Button, { className: "m-s", size: "middle", type: "ghost", onClick: () => setIsPremiumCompanyProfileSubscriptionModalOpen(false) },
                React.createElement(FormattedMessage, { id: "global.cancel" })),
            React.createElement(Button, { className: "m-s", size: "middle", type: "ghost", onClick: () => setCurrentStep(EPremiumCompanySubscriptionModalSteps.PREMIUM_COMPANY_PROFILE) },
                React.createElement(LeftOutlined, null),
                React.createElement("span", { className: "m-l-xs" },
                    React.createElement(FormattedMessage, { id: "admin.subscription.back_to_period_selection" }))))));
};
export { StepCheckout };
