import React from 'react';
import { FileExcelOutlined, FileOutlined, FileWordOutlined } from '@ant-design/icons';
import { getAssetDocumentFileFormatFromUrl } from '@src/components/modules/AssetLibrary/asset-library.model';
import { AssetDocumentFileFormat } from '@src/types/asset';
import { FormattedMessage } from 'react-intl';
import { AssetDetailDocumentIconContainer, AssetDetailDocumentContainer } from './AssetDetailDocument.style';
import { AssetDetailDocumentPdf } from './AssetDetailDocumentPdf';
export const DOCUMENT_FILE_ICON_STYLES = { fontSize: '120px' };
export function AssetDetailDocument({ asset }) {
    const fileFormat = getAssetDocumentFileFormatFromUrl(asset.url);
    return (React.createElement(AssetDetailDocumentContainer, null,
        fileFormat === AssetDocumentFileFormat.PDF && React.createElement(AssetDetailDocumentPdf, { url: asset.url }),
        fileFormat !== AssetDocumentFileFormat.PDF && (React.createElement(AssetDetailDocumentIconContainer, null,
            React.createElement(FormattedMessage, { id: "assets.document_cannot_be_displayed" }),
            fileFormat === AssetDocumentFileFormat.WORD && (React.createElement(FileWordOutlined, { style: DOCUMENT_FILE_ICON_STYLES })),
            fileFormat === AssetDocumentFileFormat.EXCEL && (React.createElement(FileExcelOutlined, { style: DOCUMENT_FILE_ICON_STYLES })),
            fileFormat === AssetDocumentFileFormat.OTHER && React.createElement(FileOutlined, { style: DOCUMENT_FILE_ICON_STYLES })))));
}
