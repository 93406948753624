import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
export const StyledPanel = styled.div `
	padding: 2rem;
	background: ${({ theme }) => theme.color.white};

	${({ mobileFriendly }) => !mobileFriendly &&
    css `
			border: 1px solid ${({ theme }) => theme.color.gray4};
			border-radius: ${({ theme }) => theme.borderRadius.base};
		`}

	${down('sm')} {
		padding: 1rem;
	}
`;
