var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
export const HolderWrapper = styled.div `
	width: 100%;
	max-width: ${props => props.theme.breakpoints.xl};
	margin: ${props => (props.marginVertical ? '2rem' : 0)} auto;
	padding: ${props => (props.isXsLayout ? 0 : '0 1em')};
`;
const Holder = (_a) => {
    var { marginVertical = false, children } = _a, rest = __rest(_a, ["marginVertical", "children"]);
    // TODO disscuss 0 padding on XS layout
    // const isXsLayout = useBreakpoint(only('xs'))
    return (React.createElement(HolderWrapper, Object.assign({ marginVertical: marginVertical }, rest), children));
};
export { Holder };
