import React from 'react';
import { useHistory } from 'react-router';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { Holder, Input, Pagination, Spinner } from '@src/components/elements';
import { SSRFetchConfig, useSSRFetch } from '@src/hooks/useSSRFetch';
import { useIntl } from 'react-intl';
import { CompaniesListGrid } from '@src/components/modules/global/CompaniesList/CompaniesListGrid';
import { InputBlock, Hero, MainContent } from './CompaniesList.style';
export function CompaniesList() {
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const { data: companies, isLoading } = useSSRFetch(SSRFetchConfig.companiesList.key, { search, page }, [search, page]);
    const isCompanyListNotEmpty = Boolean(companies && companies.data && companies.data.length);
    React.useEffect(() => {
        setPage(1);
    }, [search]);
    const intl = useIntl();
    const onPaginationChange = (actualPage) => {
        const params = new URLSearchParams();
        if (actualPage !== 1) {
            params.append('page', actualPage.toString());
            history.push({ search: params.toString() });
        }
        else {
            // @ts-ignore
            history.pushLocalized(`/zoznam-zamestnavatelov`);
        }
        setPage(actualPage);
    };
    const onChangeSearch = (searchValue) => {
        if (!searchValue || searchValue.trim()) {
            setSearch(searchValue);
        }
    };
    const debouncedSearch = debounce(onChangeSearch, 300);
    return (React.createElement(React.Fragment, null,
        React.createElement(Hero, { className: "flex-col align-center" },
            React.createElement("h1", { className: "m-t-l m-b-l" }, intl.formatMessage({ id: 'companies_list' })),
            React.createElement(InputBlock, null,
                React.createElement(Input, { prefix: isLoading && search ? React.createElement(Spinner, { delay: 0 }) : React.createElement(SearchOutlined, null), placeholder: intl.formatMessage({ id: 'companies_list.filter_by_name' }), className: "m-b-l", onChange: e => debouncedSearch(e.target.value) }))),
        React.createElement(MainContent, null,
            React.createElement(Pagination, { className: "m-t-l", current: page, onChange: onPaginationChange, total: companies === null || companies === void 0 ? void 0 : companies.total, showSizeChanger: false }),
            React.createElement(Holder, null,
                React.createElement(CompaniesListGrid, { companies: (companies === null || companies === void 0 ? void 0 : companies.data) || [], isLoading: isLoading }),
                isCompanyListNotEmpty && (React.createElement(Pagination, { className: "m-b-m", current: page, onChange: onPaginationChange, total: companies === null || companies === void 0 ? void 0 : companies.total, showSizeChanger: false }))))));
}
