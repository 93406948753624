export const configFrontendUK = {
    api: process.env.API,
    adSenseClientId: 'ca-pub-2352455228582816',
    cookieConsentDuration: 365,
    dateDisplayFormat: 'DD. MM. YYYY',
    analyticsDateFormat: 'D.M.YYYY',
    environment: process.env.ENVIRONMENT,
    sizeBreakpointXs: 568,
    sizeBreakpointSm: 768,
    sizeBreakpointMd: 1024,
    sizeBreakpointLg: 1280,
    transitionUiSlow: 667,
    socialBaseUrl: {
        facebook: 'https://www.facebook.com/',
        linkedinCompany: 'https://www.linkedin.com/company/',
        linkedin: 'https://www.linkedin.com/in/',
        instagram: 'https://www.instagram.com/',
        twitter: 'https://www.twitter.com/',
        youtube: 'https://www.youtube.com/channel/',
    },
    accountType: {
        company: 1,
        student: 2,
    },
    offerTypes: [
        { name: 'Стажування', id: 1 },
        { name: 'Постійна робота', id: 2 },
        { name: 'Волонтерство', id: 3 },
        { name: 'Тимчасова робота', id: 4 },
        { name: 'Робота та подорожі', id: 5 },
        { name: 'Підрядник', id: 6 },
    ],
    locations: [
        { name: 'Словаччина', id: 1 },
        { name: 'Банська Бистрицька область', id: 2 },
        { name: 'Братиславська область', id: 3 },
        { name: 'Кошицький регіон', id: 4 },
        { name: 'Нітранська область', id: 5 },
        { name: 'Пряшівський край', id: 6 },
        { name: 'Тренчинський край', id: 7 },
        { name: 'Трнавська область', id: 8 },
        { name: 'Жилинська область', id: 9 },
        { name: 'Братислава', id: 10 },
        { name: 'Кошице', id: 11 },
        { name: 'Лученцький район', id: 12 },
        { name: 'Римавсько-Соботський район', id: 13 },
        { name: 'Район Галанта', id: 14 },
        { name: 'Бардейівський район', id: 15 },
        { name: 'Кежмарокський район', id: 16 },
        { name: 'Турцянське Теплице район', id: 17 },
        { name: 'Пряшівський район', id: 18 },
        { name: 'Тренчинський район', id: 19 },
        { name: 'Гуменський район', id: 20 },
        { name: 'Нітранський район', id: 21 },
        { name: 'Район Новозамки', id: 22 },
        { name: 'Тополчанський район', id: 23 },
        { name: 'Рознавський район', id: 24 },
        { name: 'Район Списська Нова Весь', id: 25 },
        { name: 'Вранів-над-Топлуйський район', id: 26 },
        { name: 'Намівський район', id: 27 },
        { name: 'Зволенський район', id: 28 },
        { name: 'Район Дунайська Стреда', id: 29 },
        { name: 'Требісовський район', id: 30 },
        { name: 'Кошице - околиці', id: 31 },
        { name: 'Брезненський район', id: 32 },
        { name: 'Банська Стявниця район', id: 33 },
        { name: 'Банська Бистриця район', id: 34 },
        { name: 'Пезиноцький район', id: 35 },
        { name: 'Михаловцевський район', id: 36 },
        { name: 'Район Комарно', id: 37 },
        { name: 'Сабінівський район', id: 38 },
        { name: 'Район Леві', id: 39 },
        { name: 'Район Левоча', id: 40 },
        { name: 'Район Велки Кртис', id: 41 },
        { name: 'Стропківський район', id: 42 },
        { name: `П'єштянський район`, id: 43 },
        { name: 'Район Бановце-над-Бебравою', id: 44 },
        { name: 'Район Зяр над Гроном', id: 45 },
        { name: 'Район Собранце', id: 46 },
        { name: 'Попрадський район', id: 47 },
        { name: 'Район Нове Місце над Вагом', id: 48 },
        { name: 'Жилинський район', id: 49 },
        { name: 'Мартинський район', id: 50 },
        { name: 'Снінський район', id: 51 },
        { name: 'Район Злате Моравце', id: 52 },
        { name: 'Свідницький район', id: 53 },
        { name: 'Пухівський район', id: 54 },
        { name: 'Липтовський Микуласький район', id: 55 },
        { name: 'Сенецький район', id: 56 },
        { name: 'Ружомбероцький район', id: 57 },
        { name: 'Трнавський район', id: 58 },
        { name: 'Сеницький район', id: 59 },
        { name: 'Повазька Бистриця', id: 60 },
        { name: 'Ілавський район', id: 61 },
        { name: 'Прієвідзанський район', id: 62 },
        { name: 'Глоговецький район', id: 63 },
        { name: 'Малацький район', id: 64 },
        { name: 'Партизанський район', id: 65 },
        { name: 'Братиславський V район', id: 66 },
        { name: 'Братиславський IV район', id: 67 },
        { name: 'Братиславський III район', id: 68 },
        { name: 'Братиславський ІІ район', id: 69 },
        { name: 'Братиславський I район', id: 70 },
        { name: 'Район Зарновица', id: 71 },
        { name: 'Медзілаборський район', id: 72 },
        { name: `М'явський район`, id: 73 },
        { name: 'Полтарський район', id: 74 },
        { name: 'Тврдосинський район', id: 75 },
        { name: 'Скалицький район', id: 76 },
        { name: 'Бицький район', id: 77 },
        { name: 'Район Долникубин', id: 78 },
        { name: 'Крупинський район', id: 79 },
        { name: 'Кадцянський район ', id: 80 },
        { name: 'Старолюбівський район', id: 81 },
        { name: 'Район Ревуца', id: 82 },
        { name: 'Детванський район', id: 83 },
        { name: 'Район Сала', id: 84 },
        { name: 'Район Кисуцьке Нове Місто', id: 85 },
        { name: 'Гельницький район', id: 86 },
        { name: 'Кошице IV район', id: 87 },
        { name: 'Кошице III район', id: 88 },
        { name: 'Кошице I район', id: 89 },
        { name: 'Кошице II район', id: 90 },
    ],
    contractTypes: [
        { value: 1, label: 'Повний робочий день' },
        { value: 2, label: 'Неповний робочий день' },
    ],
    salaryRates: [
        { value: 'hourly', label: 'Щогодинно' },
        { value: 'monthly', label: 'Щомісячно' },
        { value: 'once', label: 'Одноразово' },
    ],
    salaryCurrencies: [{ value: '€', label: '€' }],
    durationTypes: [
        { value: 'day', label: 'День' },
        { value: 'week', label: 'Тиждень' },
        { value: 'month', label: 'Місяць' },
        { value: 'year', label: 'Рік' },
    ],
    languages: [
        { code: 'sk', value: 'Словацька' },
        { code: 'en', value: 'Англійська' },
        { code: 'fr', value: 'Французька' },
        { code: 'cs', value: 'Чеська' },
        { code: 'de', value: 'Німецька' },
        { code: 'pl', value: 'Польська' },
        { code: 'es', value: 'Іспанська' },
        { code: 'pt', value: 'Португальська' },
        { code: 'zh', value: 'Китайська' },
        { code: 'uk', value: 'Українська' },
        { code: 'ru', value: 'Російська' },
        { code: 'hu', value: 'Угорська' },
    ],
    monthNames: [
        'січень',
        'лютий',
        'березень',
        'квітень',
        'Може',
        'червень',
        'липень',
        'серпень',
        'Вересень',
        'жовтень',
        'Листопад',
        'Грудень',
    ],
    universities: [
        'Akadémia ozbrojených síl generála Milana Rastislava Štefánika',
        'Akadémia Policajného zboru',
        'Akadémia umení v Banskej Bystrici',
        'Bratislavská medzinárodná škola liberálnych štúdií',
        'Ekonomická univerzita v Bratislave',
        'Hudobná a umelecká akadémia Jána Albrechta - Banská Štiavnica, s. r. o., odborná vysoká škola',
        'Katolícka univerzita v Ružomberku',
        'Paneurópska vysoká škola',
        'Prešovská univerzita v Prešove',
        'Slovenská poľnohospodárska univerzita v Nitre',
        'Slovenská technická univerzita v Bratislave',
        'Slovenská zdravotnícka univerzita v Bratislave',
        'Stredoeurópska vysoká škola v Skalici',
        'Technická univerzita v Košiciach',
        'Technická univerzita vo Zvolene',
        'Trenčianska univerzita Alexandra Dubčeka v Trenčíne',
        'Trnavská univerzita v Trnave',
        'Univerzita J. Selyeho',
        'Univerzita Komenského v Bratislave',
        'Univerzita Konštantína Filozofa v Nitre',
        'Univerzita Mateja Bela v Banskej Bystrici',
        'Univerzita Pavla Jozefa Šafárika v Košiciach',
        'Univerzita sv. Cyrila a Metoda v Trnave',
        'Univerzita veterinárskeho lekárstva a&nbsp;farmácie v Košiciach',
        'Vysoká škola bezpečnostného manažérstva v Košiciach',
        'Vysoká škola Danubius',
        'Vysoká škola DTI',
        'Vysoká škola ekonómie a manažmentu verejnej správy v Bratislave',
        'Vysoká škola manažmentu',
        'Vysoká škola medzinárodného podnikania ISM Slovakia v Prešove',
        'Vysoká škola medzinárodných a veřejných vztahů Praha, o. p. s.',
        'Vysoká škola múzických umení v Bratislave',
        'Vysoká škola výtvarných umení v Bratislave',
        'Vysoká škola zdravotníctva a sociálnej práce sv. Alžbety v Bratislave, n. o.',
        'Žilinská univerzita v Žiline',
    ],
    universitySections: [
        'архітектура та урбанізм',
        'науки безпеки',
        'біологія',
        'біотехнологія',
        'транспорт',
        'лісоматеріали',
        'екологічні та екологічні науки',
        'економіка та менеджмент',
        'електротехніка',
        'аптека',
        'філологія',
        'філософія',
        'фізика',
        'геодезія та картографія',
        'історичні науки',
        'хімія',
        'хімічна інженерія та технології',
        'інформатика',
        'кібернетика',
        'лісове господарство',
        'логопедична та медична педагогіка',
        'математика',
        'студії медіа та комунікацій',
        'оборона та військо',
        'догляд',
        'політологія',
        'сільське господарство та озеленення',
        'харчова промисловість',
        'акушерка',
        'закон',
        'просторове планування',
        'психологія',
        'соціальна робота',
        'соціологія та соціальна антропологія',
        'будівництво',
        'інженерія',
        'теологія',
        'навчально-педагогічні науки',
        'мистецтво',
        'спортивні науки',
        'науки про мистецтво та культуру',
        'науки про Землю',
        `охорона здоров'я`,
        'ветеринарна медицина',
        'загальна медицина',
        'медичні науки',
        'придбання та переробка земельних ресурсів',
        'стоматологія',
    ],
};
