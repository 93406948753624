import React from 'react';
import { Modal, Steps } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriods } from '@src/types';
import { usePremiumCompanyProfileModalContextValue } from '@src/context/premium-company-profile-modal-context';
import { useTheme } from 'styled-components';
import { useSSRFetchClient, SSRFetchConfig } from '@src/hooks/useSSRFetch';
import { StepPremiumCompanyProfile } from './StepPremiumCompanyProfile';
import { StepCheckout } from './StepCheckout';
import { StepConfirmation } from './StepConfirmation';
const { Step } = Steps;
export var EPremiumCompanySubscriptionModalSteps;
(function (EPremiumCompanySubscriptionModalSteps) {
    EPremiumCompanySubscriptionModalSteps[EPremiumCompanySubscriptionModalSteps["PREMIUM_COMPANY_PROFILE"] = 0] = "PREMIUM_COMPANY_PROFILE";
    EPremiumCompanySubscriptionModalSteps[EPremiumCompanySubscriptionModalSteps["CHECKOUT"] = 1] = "CHECKOUT";
    EPremiumCompanySubscriptionModalSteps[EPremiumCompanySubscriptionModalSteps["CONFIRMATION"] = 2] = "CONFIRMATION";
})(EPremiumCompanySubscriptionModalSteps || (EPremiumCompanySubscriptionModalSteps = {}));
const PremiumCompanyProfileSubscriptionModal = () => {
    const theme = useTheme();
    const [currentStep, setCurrentStep] = React.useState(EPremiumCompanySubscriptionModalSteps.CONFIRMATION);
    const [selectedSubscriptionPeriod, setSelectedSubscriptionPeriod] = React.useState(SubscriptionPeriods.OneMonthPeriod);
    const [usedPaymentMethod, setUsedPaymentMethod] = React.useState(null);
    const [issuedInvoiceId, setIssuedInvoiceId] = React.useState(null);
    const { invalidateSSRContext } = useSSRFetchClient();
    const { isPremiumCompanyProfileSubscriptionModalOpen, setIsPremiumCompanyProfileSubscriptionModalOpen } = usePremiumCompanyProfileModalContextValue();
    const onOrderSuccess = (paymentMethod, invoiceId) => {
        setUsedPaymentMethod(paymentMethod || null);
        if (invoiceId) {
            setIssuedInvoiceId(invoiceId);
        }
        setCurrentStep(EPremiumCompanySubscriptionModalSteps.CONFIRMATION);
        invalidateSSRContext(SSRFetchConfig.companyProfile.key);
    };
    React.useEffect(() => {
        if (!isPremiumCompanyProfileSubscriptionModalOpen) {
            setCurrentStep(EPremiumCompanySubscriptionModalSteps.PREMIUM_COMPANY_PROFILE);
        }
    }, [isPremiumCompanyProfileSubscriptionModalOpen]);
    return (React.createElement(Modal, { onCancel: () => setIsPremiumCompanyProfileSubscriptionModalOpen(false), footer: null, title: React.createElement(Steps, { direction: "horizontal", current: currentStep },
            React.createElement(Step, { title: React.createElement(FormattedMessage, { id: "admin.premium_profile" }) }),
            React.createElement(Step, { title: React.createElement(FormattedMessage, { id: "admin.checkout" }) }),
            React.createElement(Step, { title: React.createElement(FormattedMessage, { id: "admin.confirmation" }) })), visible: isPremiumCompanyProfileSubscriptionModalOpen, width: theme.modalWidth.largeModal, closable: false },
        EPremiumCompanySubscriptionModalSteps.PREMIUM_COMPANY_PROFILE === currentStep && (React.createElement(StepPremiumCompanyProfile, { setCurrentStep: setCurrentStep, selectedSubscriptionPeriod: selectedSubscriptionPeriod, setSelectedSubscriptionPeriod: setSelectedSubscriptionPeriod })),
        EPremiumCompanySubscriptionModalSteps.CHECKOUT === currentStep && (React.createElement(StepCheckout, { setCurrentStep: setCurrentStep, selectedSubscriptionPeriod: selectedSubscriptionPeriod, onOrderSuccess: onOrderSuccess })),
        EPremiumCompanySubscriptionModalSteps.CONFIRMATION === currentStep && (React.createElement(StepConfirmation, { invoiceId: issuedInvoiceId, paymentMethod: usedPaymentMethod }))));
};
export { PremiumCompanyProfileSubscriptionModal };
