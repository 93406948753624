// import { HomeIcon, RefreshIcon, SadIcon } from '@/app/styling/icons'
import styled from 'styled-components';
import { Button, Modal } from '@src/components/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FrownOutlined, HomeOutlined, MailOutlined, RedoOutlined } from '@ant-design/icons';
import { useConfig } from '@src/hooks/useConfig';
export const ErrorBoundaryContents = ({ error, timestamp }) => {
    const config = useConfig();
    const handleHome = () => {
        window.location.href = '/';
    };
    const handleRefresh = () => {
        window.location.reload();
    };
    return (React.createElement(Modal, { closable: false, visible: true, title: React.createElement("div", { className: "flex-row align-center" },
            React.createElement("span", { className: "m-r-s" },
                React.createElement(FormattedMessage, { id: "general.something_went_wrong" })),
            React.createElement(FrownOutlined, null)), footer: React.createElement(Actions, null,
            React.createElement(Button, { size: "middle", onClick: handleHome },
                React.createElement(HomeOutlined, { className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "general.home" })),
            React.createElement(Button, { type: "default", size: "middle", onClick: handleRefresh },
                React.createElement(RedoOutlined, { className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "general.refresh" }))) },
        React.createElement(Desc, null,
            React.createElement(FormattedMessage, { id: "error.support" })),
        React.createElement("a", { href: `mailto:${config.supportEmail}` },
            React.createElement(Button, { type: "default", size: "middle" },
                React.createElement(MailOutlined, { className: "m-r-s" }),
                React.createElement(FormattedMessage, { id: "general.support" }))),
        React.createElement("div", { className: "m-t-l m-b-s" },
            React.createElement(FormattedMessage, { id: "error.info_for_support" })),
        React.createElement(Items, null,
            React.createElement(ItemTitle, null, "Time"),
            React.createElement(ItemValue, null, timestamp && timestamp.toISOString()),
            React.createElement(ItemTitle, null, "Environment"),
            React.createElement(ItemValue, null, navigator.userAgent),
            React.createElement(ItemTitle, null, "Error message"),
            React.createElement(ItemValue, null, error.message),
            React.createElement(ItemTitle, null, "Error stack"),
            React.createElement(ItemValue, null,
                React.createElement("pre", null, error.stack)))));
};
const Desc = styled.div `
	margin-bottom: 1rem;
`;
const Items = styled.div `
	font-size: 10px;
`;
const ItemTitle = styled.div `
	color: #aaa;
`;
const ItemValue = styled.div `
	overflow-x: auto;
	margin-bottom: 0.2rem;
	padding-bottom: 0.1rem;

	pre {
		margin: 0;
		font-size: 10px;
	}
`;
const Actions = styled.div `
	flex-grow: 0;
	margin-left: auto;
	display: flex;

	> button {
		margin-left: 0.5rem;
	}
`;
