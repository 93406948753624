import React from 'react';
import { Select } from 'antd';
import { useAppContextValue } from '@src/context/app-context';
import ISO6391 from 'iso-639-1';
import { useHistory } from 'react-router-dom';
import { getLocalizedPath } from '@src/shared/localized-path';
import styled from 'styled-components';
const StyledSelect = styled(Select) `
	& {
		width: 10rem;
	}
`;
const Flag = styled.img `
	width: 2rem;
	margin-right: 0.5rem;
	border: 1px solid lightgray;
	padding: 1px;
`;
const { Option } = Select;
const LanguageSelector = props => {
    const appContext = useAppContextValue();
    const history = useHistory();
    const onSelectHandler = value => {
        history.push(getLocalizedPath(history.location.pathname + history.location.search, value));
        appContext.setLanguage(value);
    };
    return (React.createElement(StyledSelect, Object.assign({ value: appContext.language, onSelect: onSelectHandler }, props),
        React.createElement(Option, { value: "sk" },
            React.createElement("div", null,
                React.createElement(Flag, { src: "/media/images/flag-slovakia.png", alt: ISO6391.getNativeName('sk') }),
                ISO6391.getNativeName('sk'))),
        React.createElement(Option, { value: "en" },
            React.createElement("div", null,
                React.createElement(Flag, { src: "/media/images/flag-united-kingdom.png", alt: ISO6391.getNativeName('en') }),
                ISO6391.getNativeName('en'))),
        React.createElement(Option, { value: "uk" },
            React.createElement("div", null,
                React.createElement(Flag, { src: "/media/images/flag-ukraine.png", alt: ISO6391.getNativeName('uk') }),
                ISO6391.getNativeName('uk')))));
};
export { LanguageSelector };
