import { AssetType } from '@src/types';
import React from 'react';
import { getMuxVideoThumbnailUrl } from '@src/shared/get-mux-video-thumbnail-url';
import { DetailsAssetsModal } from '@src/components/modules/AssetDetailModal';
import { getCdnImageUrl } from '@src/shared/get-cdn-image-url';
import { FormattedMessage } from 'react-intl';
import PencilIcon from '@public/media/icons/streamline-icon-pencil.svg';
import { useAccount } from '@src/context/account-context';
import { ReadMore } from '@src/components/elements';
import striptags from 'striptags';
import { AboutCompanyTitle, ClickAssetWrapper, CompanyAbout, CompanyInfo, FlexAboutCompanyText, FlexPrimaryImage, TitleWrapper, AssetImg, EditButton, StyledAreaIcon, StyledCinIcon, AddPrimaryGalleryButton, AddPrimaryGalleryWrapper, StyledPlusOutlined, StyledUserOutlined, MainContentColumns, MainContent, GalleryColumn, InfoColumn, CompanyInfoDetail, InfoFieldValue, CompanyInfoSectionWrapper, CircleIconWrapper, DetailVideoIcon, DetailIcon, PrimaryGalleryOverlay, } from './CompanyProfileMainContent.style';
import { CompanyProfileMainContentEditModal } from './CompanyProfileMainContentEditModal';
import { CompanyAssetGalleryCardMenu } from '../company-profile-gallery/CompanyAssetGalleryCardMenu';
import { AddAndEditPrimaryAssetGalleryModal } from './AddAndEditPrimaryAssetGalleryModal';
import { SocialLinks } from './SocialLinks';
import { CompanyProfileGallery } from '../company-profile-gallery';
function getAssetImgUrl(asset, width, height) {
    if (asset.type === AssetType.IMAGE) {
        return getCdnImageUrl({
            file: asset.cloudinaryPublicId,
            transformation: `/w_${width || 500},h_${height || 500},c_fill,g_faces,q_auto:best`,
            shouldOptimizeQuality: false,
            shouldIncludeEnvironmentPart: false,
        });
    }
    if (asset.type === AssetType.VIDEO) {
        return getMuxVideoThumbnailUrl({ muxPublicPlaybackId: asset.muxPublicPlaybackId, width: 600 });
    }
    throw new Error('Assets in profile gallery should be only images and videos');
}
const CompanyProfileMainContent = ({ company }) => {
    var _a;
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
    const [isPrimaryAssetGalleryModalOpen, setIsPrimaryAssetGalleryModalOpen] = React.useState(false);
    const [primaryAssetGalleryToEdit, setPrimaryAssetGalleryToEdit] = React.useState(null);
    const [isAssetPreviewOpen, setIsAssetPreviewOpen] = React.useState(false);
    const [ref, setRef] = React.useState(null);
    const account = useAccount();
    const primaryGallery = company.companyAssetGalleries.find(({ isPrimaryGallery }) => Boolean(isPrimaryGallery));
    const primaryGalleryAsset = company.assets.find(({ id: assetId }) => assetId === (primaryGallery === null || primaryGallery === void 0 ? void 0 : primaryGallery.companyAssetGalleryAssets[0].assetId));
    const isCompanyAccount = (account === null || account === void 0 ? void 0 : account.companyId) === company.id;
    const hasAtLeastOneSocialMedia = Boolean(company.instagramUrl || company.twitterUrl || company.youtubeUrl || company.facebookUrl || company.linkedinUrl);
    const hasAtLeastOneNotPrimaryAssetGallery = Boolean((_a = company === null || company === void 0 ? void 0 : company.companyAssetGalleries) === null || _a === void 0 ? void 0 : _a.filter(({ isPrimaryGallery }) => !isPrimaryGallery).length);
    return (React.createElement(MainContent, null,
        isCompanyAccount && (React.createElement("div", { className: "width-100 flex-row justify-end p-t-m p-r-m" },
            React.createElement(EditButton, { type: "text", onClick: () => setIsEditModalOpen(true) },
                React.createElement(FormattedMessage, { id: "general.edit" }),
                React.createElement(PencilIcon, { width: 14, height: 14, className: "m-l-s" })))),
        React.createElement(MainContentColumns, null,
            React.createElement(GalleryColumn, { shouldStretchTheCompanyInfo: !isCompanyAccount && !primaryGallery && !hasAtLeastOneNotPrimaryAssetGallery },
                primaryGallery && primaryGalleryAsset && (React.createElement(FlexPrimaryImage, null,
                    React.createElement(ClickAssetWrapper, { onClick: () => setIsAssetPreviewOpen(true) },
                        React.createElement(AssetImg, { ref: r => setRef(r), src: getAssetImgUrl(primaryGalleryAsset, Math.round((ref === null || ref === void 0 ? void 0 : ref.getBoundingClientRect().width) || 0), Math.round((ref === null || ref === void 0 ? void 0 : ref.getBoundingClientRect().height) || 0)), alt: primaryGalleryAsset.filename }),
                        isCompanyAccount && (React.createElement(CompanyAssetGalleryCardMenu, { gallery: primaryGallery, setAssetGalleryToEdit: setPrimaryAssetGalleryToEdit })),
                        React.createElement(PrimaryGalleryOverlay, null,
                            primaryGalleryAsset.type === AssetType.IMAGE && React.createElement(DetailIcon, null),
                            primaryGalleryAsset.type === AssetType.VIDEO && React.createElement(DetailVideoIcon, null))))),
                !primaryGallery && !primaryGalleryAsset && isCompanyAccount && (React.createElement(AddPrimaryGalleryWrapper, { hasPrimaryAsset: Boolean(primaryGallery) },
                    React.createElement(AddPrimaryGalleryButton, { onClick: () => setIsPrimaryAssetGalleryModalOpen(true) },
                        React.createElement("div", { className: "flex-col width-100" },
                            React.createElement(StyledPlusOutlined, null),
                            React.createElement(FormattedMessage, { id: "admin.company_premium_profile.add_photo_video" }))))),
                React.createElement(CompanyProfileGallery, { company: company })),
            React.createElement(InfoColumn, null,
                hasAtLeastOneSocialMedia && React.createElement(SocialLinks, { company: company }),
                React.createElement(CompanyAbout, null,
                    React.createElement(FlexAboutCompanyText, null,
                        React.createElement(AboutCompanyTitle, null,
                            React.createElement(FormattedMessage, { id: "company.about" })),
                        company.description && React.createElement(ReadMore, { text: striptags(company.description), maxChars: 400 }))),
                React.createElement(CompanyInfo, null,
                    React.createElement(CompanyInfoSectionWrapper, null,
                        React.createElement(CircleIconWrapper, null,
                            React.createElement(StyledAreaIcon, null)),
                        React.createElement(CompanyInfoDetail, null,
                            React.createElement(TitleWrapper, null,
                                React.createElement(FormattedMessage, { id: "company.area" })),
                            React.createElement(InfoFieldValue, null, company.area))),
                    React.createElement(CompanyInfoSectionWrapper, null,
                        React.createElement(CircleIconWrapper, null,
                            React.createElement(StyledUserOutlined, null)),
                        React.createElement(CompanyInfoDetail, null,
                            React.createElement(TitleWrapper, null,
                                React.createElement(FormattedMessage, { id: "company.employees" })),
                            React.createElement(InfoFieldValue, null, company.employees))),
                    company.cin && (React.createElement(CompanyInfoSectionWrapper, null,
                        React.createElement(CircleIconWrapper, null,
                            React.createElement(StyledCinIcon, null)),
                        React.createElement(CompanyInfoDetail, null,
                            React.createElement(TitleWrapper, null,
                                React.createElement(FormattedMessage, { id: "company.ico" })),
                            React.createElement(InfoFieldValue, null, company.cin))))))),
        isCompanyAccount && (React.createElement(React.Fragment, null,
            React.createElement(CompanyProfileMainContentEditModal, { isEditModalOpen: isEditModalOpen, setIsEditModalOpen: setIsEditModalOpen, company: company }),
            React.createElement(AddAndEditPrimaryAssetGalleryModal, { isPrimaryAssetGalleryModalOpen: isPrimaryAssetGalleryModalOpen, setIsPrimaryAssetGalleryModalOpen: setIsPrimaryAssetGalleryModalOpen, setPrimaryAssetGalleryToEdit: setPrimaryAssetGalleryToEdit, gallery: primaryAssetGalleryToEdit, assetIdToPreSelect: primaryGalleryAsset === null || primaryGalleryAsset === void 0 ? void 0 : primaryGalleryAsset.id }))),
        React.createElement(DetailsAssetsModal, { isOpen: isAssetPreviewOpen, assets: primaryGalleryAsset ? [primaryGalleryAsset] : [], closeModal: () => setIsAssetPreviewOpen(false), initialAssetId: primaryGalleryAsset ? primaryGalleryAsset.id : null })));
};
export { CompanyProfileMainContent };
