import React from 'react';
import * as uuid from 'uuid';
import axios from 'axios';
import { AssetType } from '@src/types/asset';
import { useConfig } from '@src/hooks/useConfig';
import { LANGUAGE_HEADER_NAME } from '@src/types';
import { useAccount } from '@src/context/account-context';
import { useAppMutation } from '@src/hooks/useAppMutation';
import * as Api from '@src/api';
import { getAssetTypeFromFile, isAssetFileSizeValid, isAssetFileTypeValid } from './asset-library.model';
import { useAssetLibraryNotifications } from './use-asset-library-notifications';
function useAssetUpload({ activeAssetCount, onUploadAssetSuccess }) {
    const config = useConfig();
    const account = useAccount();
    const [uploadingAssets, setUploadingAssets] = React.useState([]);
    const { showAssetCountLimitReachedNotification, showInvalidFileTypeNotification, showAssetFileTooLargeNotification, showAssetsFeatureDisabledNotification, showGeneralErrorNotification, } = useAssetLibraryNotifications();
    function enqueueAssetFileForUpload(file) {
        const assetType = getAssetTypeFromFile(file);
        const maxFileSizeInMb = config.assets.sizeLimitsInMb[assetType];
        if (activeAssetCount + uploadingAssets.length >= config.assets.countLimit) {
            showAssetCountLimitReachedNotification(config.assets.countLimit);
            return;
        }
        if (!isAssetFileTypeValid(file)) {
            showInvalidFileTypeNotification(file.name);
            return;
        }
        if (!isAssetFileSizeValid(file, maxFileSizeInMb)) {
            showAssetFileTooLargeNotification(file.name, maxFileSizeInMb);
            return;
        }
        const newUploadingAsset = {
            file,
            isUploading: false,
            uuid: uuid.v4(),
            uploadStartedAt: new Date(),
            type: getAssetTypeFromFile(file),
            progressPercentage: 0,
        };
        setUploadingAssets(prevUploadingAssets => [...prevUploadingAssets, newUploadingAsset]);
    }
    React.useEffect(() => {
        const pendingAssets = uploadingAssets.filter(asset => asset.isUploading === false);
        pendingAssets.forEach(asset => {
            markAssetAsUploading(asset);
            uploadAsset(asset);
        });
    }, [uploadingAssets]);
    function getAssetCloudinaryFolder() {
        const isCompanyAccount = account.accountTypeId === config.accountType.company;
        if (isCompanyAccount) {
            return `${process.env.ENVIRONMENT}/assets/companies/${account.companyId}`;
        }
        return `${process.env.ENVIRONMENT}/assets/applicants/${account.id}`;
    }
    function markAssetAsUploading(targetAsset) {
        setUploadingAssets(prevUploadingAssets => prevUploadingAssets.map(asset => asset.uuid === targetAsset.uuid ? Object.assign(Object.assign({}, asset), { isUploading: true }) : asset));
    }
    function removeUploadingAsset(targetAsset) {
        setUploadingAssets(prevUploadingAssets => prevUploadingAssets.filter(asset => asset.uuid !== targetAsset.uuid));
    }
    function updateAssetProgressPercentage(targetAsset, progressPercentage) {
        setUploadingAssets(prevUploadingAssets => prevUploadingAssets.map(asset => asset.uuid === targetAsset.uuid ? Object.assign(Object.assign({}, asset), { progressPercentage }) : asset));
    }
    async function uploadImageOrDocumentToCloudinaryAndGetData(asset) {
        const cdnResourceType = asset.type === AssetType.IMAGE ? 'image' : 'raw';
        const cdnCloudname = process.env.CLOUDINARY_NAME;
        const url = `https://api.cloudinary.com/v1_1/${cdnCloudname}/${cdnResourceType}/upload`;
        const cdnUploadPresent = process.env.CLOUDINARY_UPLOAD_PRESET;
        const uploadFormData = new FormData();
        uploadFormData.append('file', asset.file);
        uploadFormData.append('upload_preset', cdnUploadPresent);
        uploadFormData.append('folder', getAssetCloudinaryFolder());
        uploadFormData.append('publicId', asset.file.name);
        const response = await axios.post(url, uploadFormData, {
            transformRequest: (data, headers) => {
                // eslint-disable-next-line no-param-reassign
                delete headers.common[LANGUAGE_HEADER_NAME];
                return data;
            },
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                updateAssetProgressPercentage(asset, percentage);
            },
        });
        return {
            url: response.data.secure_url,
            publicId: response.data.public_id,
        };
    }
    async function uploadImageOrDocumentAndGetSavedAsset(uploadingImageOrDocumentAsset) {
        const { url, publicId: cloudinaryPublicId } = await uploadImageOrDocumentToCloudinaryAndGetData(uploadingImageOrDocumentAsset);
        const savedAsset = await Api.Asset.saveUploadedImageOrDocumentAsset({
            uploadingAsset: uploadingImageOrDocumentAsset,
            url,
            cloudinaryPublicId,
        });
        return savedAsset;
    }
    async function uploadVideoToMux(muxVideoUploadUrl, uploadingVideoAsset) {
        await axios.put(muxVideoUploadUrl, uploadingVideoAsset.file, {
            transformRequest: (data, headers) => {
                // eslint-disable-next-line no-param-reassign
                delete headers.common[LANGUAGE_HEADER_NAME];
                return data;
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                updateAssetProgressPercentage(uploadingVideoAsset, percentage);
            },
        });
    }
    async function uploadVideoAndGetSavedAsset(uploadingVideoAsset) {
        const { muxVideoUploadUrl, muxDirectUploadId } = await Api.Asset.getUrlForVideoUpload();
        await uploadVideoToMux(muxVideoUploadUrl, uploadingVideoAsset);
        const savedAsset = await Api.Asset.saveUploadedVideoAsset({
            uploadingAsset: uploadingVideoAsset,
            muxDirectUploadId,
        });
        return savedAsset;
    }
    const { mutateAsync: uploadAsset } = useAppMutation(async (uploadingAsset) => {
        if (uploadingAsset.type === AssetType.VIDEO) {
            return uploadVideoAndGetSavedAsset(uploadingAsset);
        }
        if (uploadingAsset.type === AssetType.DOCUMENT || uploadingAsset.type === AssetType.IMAGE) {
            return uploadImageOrDocumentAndGetSavedAsset(uploadingAsset);
        }
        throw new Error(`Cannot upload asset: Invalid asset type ${uploadingAsset.type}`);
    }, {
        onSuccess: onUploadAssetSuccess,
        onError: (err) => {
            var _a;
            console.error(err);
            const code = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status;
            if (code === 402) {
                showAssetsFeatureDisabledNotification();
                return;
            }
            if (code === 409) {
                showAssetCountLimitReachedNotification(config.assets.countLimit);
                return;
            }
            showGeneralErrorNotification();
        },
        onSettled: (_, __, uploadingAsset) => {
            removeUploadingAsset(uploadingAsset);
        },
    });
    return { uploadingAssets, enqueueAssetFileForUpload };
}
export { useAssetUpload };
